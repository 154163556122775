import { Tab } from '@headlessui/react';
import { Tabs, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { GroupBox } from 'components';
import { ADDRESS_TYPE } from 'constants/addressType';
import { COOPERATIONS_STATUS } from 'constants/cooperationStatus';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { AGENT } from 'constants/queries/agent';
import { getAddress, getAgentBusinessInfo } from 'helpers/agents';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getAgent, getAgentBlockades } from 'services/agent';
import { AgentDetails, AgentDetailsBlockade } from 'types/agent';

import Communication from '../Communication/Communication';
import Address from './components/Address/Address';
import AgentAccount from './components/AgentAccount/AgentAccount';
import BillingDetails from './components/BillingDetails/BillingDetails';
import Contact from './components/Contact/Contact';
import AgentHeader from './components/Header/Header';
import AgentTables from './components/tables/AgentTables';
import Blockades from './components/tables/Blockades/Blockades';
import Comments from './components/tables/Comments/Comments';
import Parameters from './components/tables/Parameters/Parameters';
import useAgentId from './useAgentId';

const AgentInfo = () => {
  const agentId = useAgentId();
  const { translate } = useDictionaryContext();

  const {
    data,
    isFetched,
    refetch: refetchAgent,
  } = useQuery<AgentDetails, AxiosError>([AGENT.AGENT, agentId], () => getAgent(agentId));

  const blockadesQueryResult = useQuery<AgentDetailsBlockade[], AxiosError>(
    [AGENT.AGENT_BLOCKADES, agentId],
    () => getAgentBlockades(agentId)
  );

  const findLastEconomyActivity = () => {
    if (data?.economyActivities && data?.economyActivities.length)
      return data.economyActivities.reduce((prev, current) =>
        new Date(prev.validFrom).getTime() > new Date(current.validFrom).getTime() ? prev : current
      );
  };

  const economyActivity = useMemo(
    () => data?.economyActivities?.find((activity) => activity.valid) ?? findLastEconomyActivity(),
    [data]
  );

  return (
    <>
      <div className="flex-1 p-8 space-y-6 overflow-hidden">
        <AgentHeader
          agentCode={data?.agentCode}
          economyActivities={economyActivity}
          cooperationStatus={data?.cooperationStatus}
          debtRecovery={data?.debtRecovery}
          blockades={blockadesQueryResult.data || []}
          refetchAgent={refetchAgent}
        />

        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4">
          <GroupBox
            isFetched={isFetched}
            name="NIP / REGON"
            icon="bi-person-fill"
            color="bg-icon-blue"
            value={getAgentBusinessInfo(economyActivity)}
          />

          <GroupBox
            isFetched={isFetched}
            name="Współpraca"
            icon="bi-journal-text"
            color="bg-icon-orange"
            value={
              data && data.cooperationStatus !== COOPERATIONS_STATUS.NOTICE
                ? translate(DICTIONARY_TYPES.COOPERATION_STATUS, data.cooperationStatus)
                : ''
            }>
            {data && data.cooperationStatus === COOPERATIONS_STATUS.NOTICE && (
              <Text weight="semibold" className="flex flex-col text-sm ">
                <p>Aktywna</p>
                <p className="flex flex-wrap gap-x-1 text-rose-600">
                  Wypowiedzenie do
                  <span className="whitespace-nowrap">
                    {economyActivity?.validTo.split(' ')[0]}
                  </span>
                </p>
              </Text>
            )}
          </GroupBox>

          <GroupBox
            isFetched={isFetched}
            name="Saldo"
            icon="bi-cash-stack"
            color="bg-icon-green"
            value={data?.balance?.contractorBalance}
          />

          <GroupBox
            isFetched={isFetched}
            name="Saldo do wypłaty"
            icon="bi-cash-stack"
            color="bg-icon-green"
            value={data?.balance?.balanceToPay}
          />
        </div>

        <Tabs
          variant="light"
          categories={[
            'Kontakt',
            'Adres',
            'Dane rozliczeniowe',
            'Okresy parametrów',
            'Blokady',
            'Wiadomości',
            'Komentarze',
            'Portal Agenta',
          ]}>
          <Tab.Panel>
            <Contact economyActivities={economyActivity} refetchAgent={refetchAgent} />
          </Tab.Panel>
          <Tab.Panel>
            <Address
              economyActivities={economyActivity}
              companyAddress={getAddress(ADDRESS_TYPE.BILLING_ADDRESS, economyActivity)}
              privateAddress={getAddress(ADDRESS_TYPE.HOME_ADDRESS, economyActivity)}
              correspondenceAddress={getAddress(
                ADDRESS_TYPE.CORRESPONDENCE_ADDRESS,
                economyActivity
              )}
              refetchAgent={refetchAgent}
            />
          </Tab.Panel>
          <Tab.Panel>
            <BillingDetails
              rauNumber={data?.rauNumber}
              agentCode={data?.agentCode}
              deleted={data?.deleted}
              economyActivities={economyActivity}
              refetchAgent={refetchAgent}
              officeDictionaryKey={data?.officeDictionaryKey}
            />
          </Tab.Panel>
          <Tab.Panel>
            <Parameters
              economyActivityId={economyActivity?.id}
              economyActivityValidTo={economyActivity?.economyActivityValidTo}
              refetchAgent={refetchAgent}
            />
          </Tab.Panel>
          <Tab.Panel>
            <Blockades blockadeQueryResult={blockadesQueryResult} />
          </Tab.Panel>
          <Tab.Panel>
            <Communication agentId={agentId} compactView={true} />
          </Tab.Panel>
          <Tab.Panel>
            <Comments />
          </Tab.Panel>
          <Tab.Panel>
            <AgentAccount />
          </Tab.Panel>
        </Tabs>

        <AgentTables agentDetails={data} />
      </div>
    </>
  );
};

export default AgentInfo;
