import { Button, InputField } from '@profitowi/component-library';
import { Form, Formik } from 'formik';
import React from 'react';

interface SearchBoxParams {
  searchCallback: (a: string) => void;
}

export default function Searchbox({ searchCallback }: SearchBoxParams) {
  function onSubmit({ username }: { username: string }) {
    searchCallback(username);
  }

  return (
    <>
      <Formik
        initialValues={{
          username: '',
        }}
        onSubmit={onSubmit}>
        <Form className="">
          <div className="relative flex min-w-300 h-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <InputField className="!w-80 h-full" label="Wyszukaj użytkownika" name="username" />
            <Button variant="outline-primary" type="submit" size="sm" className="px-4 py-1">
              Wyszukaj
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
}
