import { Box, Button, Header, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { usePagination } from 'hooks/usePagination';
import {
  deleteCommissionImport,
  getCommissionImports,
  getCommissionSchemas,
} from 'services/commission';
import { CommissionDataSource, CommissionSchemaList } from 'types/commission';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import CommissionImportSteps from './CommissionImportSteps/CommissionImportSteps';
import { createColumn } from './columns';

const CommissionImports = () => {
  const navigate = useNavigate();

  const pagination = usePagination(10);
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([{ id: 'importDate', desc: true }]);
  const { perPage, currentPage } = pagination;

  const {
    data: commissions,
    isError: commissionsSchemasIsError,
    error: commissionsSchemasError,
  } = useQuery<CommissionSchemaList, AxiosError>([COMMISSION.COMMISSION_SCHEMA], () =>
    getCommissionSchemas(0, 1000)
  );

  const {
    data: commissionImports,
    refetch: refetchImport,
    isFetching,
    error: CommissionImportsError,
    isError: CommissionImportsIsError,
  } = useQuery<Page<CommissionDataSource>, AxiosError>(
    [COMMISSION.COMMISSION_IMPORT, perPage, currentPage, sortBy],
    () => getCommissionImports(currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  const removeCommissionImport = useMutation<AxiosResponse, AxiosError, number>(
    (id: number) => deleteCommissionImport(id),
    {
      onSuccess: () => {
        refetchImport();
      },
    }
  );

  const onDeleteCommissionImport = (id: number) => {
    removeCommissionImport.mutate(id);
  };

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const isError =
    removeCommissionImport.isError || CommissionImportsIsError || commissionsSchemasIsError;
  const error = removeCommissionImport.error || CommissionImportsError || commissionsSchemasError;

  return (
    <>
      <Box className="space-y-6 max-h-xl">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Źródła danych
          </Header>
          <div className="flex space-x-4 items-center">
            <Button
              variant="outline-primary"
              onPress={() => navigate('add')}
              isDisabled={removeCommissionImport.isLoading}>
              <Text weight="semibold">
                Dodaj
                <i className="bi bi-plus-lg ml-2" />
              </Text>
            </Button>
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {commissionImports && (
          <Box.FullWidth>
            <Table
              isLoading={removeCommissionImport.isLoading || isFetching}
              totalPages={commissionImports.totalPages}
              pagination={pagination}
              data={commissionImports.content}
              columns={createColumn(onDeleteCommissionImport, commissions?.content || [])}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path="add" element={<CommissionImportSteps refetchImports={refetchImport} />} />
      </Routes>
    </>
  );
};
export default CommissionImports;
