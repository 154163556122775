import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { FILE } from 'constants/queries/file';
import { getReportFile } from 'services/reports';
import { Reports } from 'types/reports';
import { saveFile } from 'utils/file';

type Props = {
  report?: Reports;
  setReport: (values: Reports | undefined) => void;
  refetchList?: () => void;
};

const useDownloadReport = ({ report, setReport, refetchList }: Props) => {
  const navigate = useNavigate();

  const { refetch, ...rest } = useQuery<Blob, AxiosError>(
    [FILE.REPORT_DOWNLOAD, report?.fileId],
    () => getReportFile(report?.fileId),
    {
      enabled: false,
      onSuccess: (data) => {
        saveFile(data, report?.fileName ?? '', 'application/xls');
        setReport(undefined);
        navigate('..');
        refetchList && refetchList();
      },
    }
  );

  return { refetch, rest };
};

export default useDownloadReport;
