import { Button, Modal, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { FILE } from 'constants/queries/file';
import { getFileStatus } from 'services/file';
import { FileFromBackend } from 'types/file';

type Props = {
  documentId?: number;
  refetch: () => void;
  setDownloadedFile: (value: FileFromBackend | undefined) => void;
};

const DownloadFileModal = ({ documentId, refetch, setDownloadedFile }: Props) => {
  const navigate = useNavigate();

  useQuery<string, AxiosError>([FILE.SETTLEMENTS_FILES], () => getFileStatus(documentId), {
    onSuccess: (data) => {
      if (data === 'FINISHED') {
        refetch();
        navigate('..');
      }
    },
    refetchInterval: 1500,
  });

  const closeModal = () => {
    setDownloadedFile(undefined);
    navigate('..');
  };

  return (
    <Modal visible>
      <Text>
        Plik jest w trakcie generowania. Pozostań na stronie aby pobrać go automatycznie lub pobierz
        plik ręcznie z listy plików po wygenerowaniu.
      </Text>
      <div className="flex justify-end">
        <Button onPress={closeModal}>Zamknij</Button>
      </div>
    </Modal>
  );
};

export default DownloadFileModal;
