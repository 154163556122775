import { Debt, DebtPayload } from 'types/debt';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getDebtsForAgent = async (
  page: number,
  size: number,
  agentId: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<Debt>>(`/agents/${agentId}/debts`, {
    params: { page, size, sort },
  });

  return data;
};

export const createDebtForAgent = (payload: DebtPayload, agentId: number) => {
  return recordmodApi.post(`/agents/${agentId}/debts`, payload);
};

export const updateDebt = (payload: Debt, agentId: number, debtRepaymentId: number) => {
  return recordmodApi.put(`/agents/${agentId}/debts/${debtRepaymentId}`, payload);
};

export const getDebt = async (agentId: number, debtRepaymentId: number) => {
  const { data } = await recordmodApi.get<Debt>(`/agents/${agentId}/debts/${debtRepaymentId}`);

  return data;
};

export const deleteAgentDebt = (agentId: number, debtRepaymentId: number) => {
  return recordmodApi.delete(`/agents/${agentId}/debts/${debtRepaymentId}`, {
    params: { cancelSettled: false },
  });
};
