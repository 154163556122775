import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import CommissionSchemaCopyForm from 'pages/Commissions/components/CommissionSchemas/CommissionSchemaCopyForm/CommissionSchemaCopyForm';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import { COMMISSION } from 'constants/queries/commission';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import {
  copyCommissionSchema,
  createCommissionSchema,
  deleteCommissionSchema,
  getCommissionSchemas,
  updateCommissionSchema,
} from 'services/commission';
import { CommissionSchemaPayload, SchemaCopyPayload } from 'types/commission';
import { decodeUriSortParams } from 'utils/table';

import CommissionSchemaForm from './CommissionSchemaForm/CommissionSchemaForm';
import { createColumn } from './columns';

type UpdateCommission = {
  commissionSchemaPayload: CommissionSchemaPayload;
  commissionSchemaId: number;
};

type CopyCommission = {
  commissionSchemaPayload: SchemaCopyPayload;
  commissionSchemaId: number;
};

const CommissionsSchemas = () => {
  const [visible, setVisible] = useState(false);
  const [displayCopyModal, setDisplayCopyModal] = useState(false);
  const [commissionSchemaId, setCommissionSchemaId] = useState<number>();
  const { activeDictionaries } = useDictionaryContext();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const {
    data: commissions,
    isFetching: commissionsIsFetching,
    refetch,
  } = useQuery(
    [COMMISSION.COMMISSION_SCHEMAS, currentPage, perPage, sortBy],
    () => getCommissionSchemas(currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  const createSchema = useMutation(
    (commissionSchemaPayload: CommissionSchemaPayload) =>
      createCommissionSchema(commissionSchemaPayload),
    {
      onSuccess: () => {
        refetch();
        closeModal();
      },
    }
  );

  const updateSchema = useMutation(
    ({ commissionSchemaPayload, commissionSchemaId }: UpdateCommission) =>
      updateCommissionSchema(commissionSchemaPayload, commissionSchemaId),
    {
      onSuccess: () => {
        refetch();
        closeModal();
      },
    }
  );

  const deleteSchema = useMutation(
    (commissionSchemaId: number) => deleteCommissionSchema(commissionSchemaId),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const copySchema = useMutation(
    ({ commissionSchemaPayload, commissionSchemaId }: CopyCommission) =>
      copyCommissionSchema(commissionSchemaPayload, commissionSchemaId),
    {
      onSuccess: () => {
        refetch();
        closeCopyModal();
      },
    }
  );

  const onDeleteSchema = (commissionSchemaId: number) => {
    deleteSchema.mutate(commissionSchemaId);
  };

  const closeModal = () => {
    setVisible(false);
    setCommissionSchemaId(undefined);
  };

  const closeCopyModal = () => {
    setDisplayCopyModal(false);
    setCommissionSchemaId(undefined);
  };

  const copyCommissionSchemaAction = (commissionSchemaPayload: SchemaCopyPayload) => {
    if (commissionSchemaId) {
      copySchema.mutate({ commissionSchemaPayload, commissionSchemaId });
    }
  };

  const saveCommissionSchema = (commissionSchemaPayload: CommissionSchemaPayload) => {
    commissionSchemaId
      ? updateSchema.mutate({ commissionSchemaPayload, commissionSchemaId })
      : createSchema.mutate(commissionSchemaPayload);
  };

  const setEditedCommissionSchema = (commissionSchemaId: number, isCopyAction: boolean = false) => {
    setCommissionSchemaId(commissionSchemaId);
    if (isCopyAction) {
      return setDisplayCopyModal(true);
    }
    setVisible(true);
  };

  useEffect(() => {
    if (visible === false) {
      closeModal();
    }
  }, [visible]);

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Schematy prowizyjne
          </Header>

          <Button variant="outline-primary" onPress={() => setVisible(true)}>
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2" />
            </Text>
          </Button>
        </div>
        {commissions ? (
          <Box.FullWidth>
            <Table
              totalPages={commissions.totalPages}
              pagination={pagination}
              data={commissions.content}
              columns={createColumn(onDeleteSchema, setEditedCommissionSchema, activeDictionaries)}
              isLoading={commissionsIsFetching}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        ) : (
          <Loader className="h-12 w-12" />
        )}
      </Box>

      <CommissionSchemaForm
        commissionSchemaId={commissionSchemaId}
        visible={visible}
        dictionaryTypes={activeDictionaries}
        closeModal={closeModal}
        isLoading={updateSchema.isLoading || createSchema.isLoading}
        onSave={saveCommissionSchema}
        setVisible={setVisible}
      />
      <CommissionSchemaCopyForm
        commissionSchemaId={commissionSchemaId}
        visible={displayCopyModal}
        dictionaryTypes={activeDictionaries}
        closeModal={closeCopyModal}
        isLoading={copySchema.isLoading}
        onSave={copyCommissionSchemaAction}
        setVisible={setDisplayCopyModal}
      />
    </>
  );
};
export default CommissionsSchemas;
