import useAgentId from 'pages/Agent/useAgentId';
import SalesStructure from 'pages/Records/components/SalesStructure';
import { Outlet, Route, Routes } from 'react-router-dom';

import NavigationToolbar from 'components/NavigationToolbar/NavigationToolbar';
import { AgentDetails } from 'types/agent';

import StructureHistory from '../StructureHistory/StructureHistory';
import ActivityRange from './ActivityRange/ActivityRange';
import AdvancePayments from './AdvancePayments/AdvancePayments';
import AgentBonuses from './AgentBonuses/AgentBonuses';
import Contracts from './Contracts/Contracts';
import Debts from './Debts/Debts';
import InsuranceCompanies from './InsuranceCompanies/InsuranceCompanies';
import PayoutHistory from './PayoutHistory/PayoutHistory';
import Polices from './Polices/Polices';
import Recommendations from './Recommendations/Recommendations';
import Transfers from './Transfers/Transfers';

type Props = {
  agentDetails?: AgentDetails;
};

const AgentTables = ({ agentDetails }: Props) => {
  const agentId = useAgentId();

  return (
    <>
      <NavigationToolbar
        rootPath={`/records/agent/${agentId}/advances`}
        tabs={[
          { displayName: 'Zaliczki', route: 'advances' },
          { displayName: 'Premie', route: 'bonuses' },
          { displayName: 'Kontrakty', route: 'contracts' },
          { displayName: 'Polisy', route: 'policies' },
          { displayName: 'Cesje polis', route: 'policy-transfers' },
          { displayName: 'Zadłużenia', route: 'debt' },
          { displayName: 'Lista TU', route: 'insurance-companies' },
          { displayName: 'Uprawnienia', route: 'activity-range' },
          { displayName: 'Polecenia', route: 'recommendation' },
          { displayName: 'Struktura sprzedaży', route: 'sales-structure' },
          { displayName: 'Historia struktury', route: 'structure-history' },
          { displayName: 'Historia rozliczeń', route: 'billing-history' },
        ]}
      />

      <Routes>
        <Route path="advances/*" element={<AdvancePayments />} />
        <Route path="bonuses/*" element={<AgentBonuses />} />
        <Route path="contracts/*" element={<Contracts />} />
        <Route path="policies/*" element={<Polices />} />
        <Route path="policy-transfers/*" element={<Transfers />} />
        <Route path="debt/*" element={<Debts />} />
        <Route path="insurance-companies/*" element={<InsuranceCompanies />} />
        <Route path="activity-range/*" element={<ActivityRange />} />
        <Route path="recommendation/*" element={<Recommendations />} />
        <Route
          path="sales-structure/*"
          element={
            <SalesStructure
              agentId={agentId}
              agentCode={agentDetails?.agentCode || undefined}
              isAgentView
            />
          }
        />
        <Route path="structure-history/*" element={<StructureHistory />} />
        <Route path="billing-history/*" element={<PayoutHistory agentId={agentId} />} />
      </Routes>

      <Outlet />
    </>
  );
};

export default AgentTables;
