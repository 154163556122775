import {
  CheckboxField,
  DatePickerField,
  InputField,
  MultiSelectField,
  SelectField,
} from '@profitowi/component-library';

import useDictionaryOptions from 'hooks/useDictionaryOptions';
import { InputType, ParamType, Params } from 'types/reports';

type Props = {
  configParams: Params;
};

const Select = ({ configParams }: Props) => {
  const { paramKey, fieldName, paramType, inputType } = configParams;
  const options = useDictionaryOptions(paramType);

  return (
    <>
      {inputType === InputType.DICTIONARY_SELECT && (
        <SelectField name={paramKey} label={fieldName} options={options} />
      )}

      {inputType === InputType.DICTIONARY_MULTISELECT && (
        <MultiSelectField name={paramKey} label={fieldName} options={options} />
      )}
    </>
  );
};

const DynamicField = ({ configParams }: Props) => {
  const { paramKey, fieldName, paramType, mandatory } = configParams;

  switch (paramType) {
    case ParamType.BOOLEAN:
      return (
        <CheckboxField name={paramKey} isRequired={mandatory}>
          {fieldName}
        </CheckboxField>
      );
    case ParamType.DATE:
      return <DatePickerField preventOpenOnFocus label={fieldName} name={paramKey} />;
    case ParamType.STRING:
      return <InputField name={paramKey} label={fieldName} isRequired={mandatory} />;
    default:
      return <Select configParams={configParams} />;
  }
};

export default DynamicField;
