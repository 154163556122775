import { AxiosError } from 'axios';
import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { getUserDetails } from 'services/user';
import { Role, Roles, User } from 'types/user';

type State = {
  isAdminOrCommissionRole?: boolean;
  hasRole: (role: Role) => boolean | undefined;
};

type Props = {
  children: ReactNode;
};

const AgentRoleContext = createContext<State>({ hasRole: () => false });

export const AgentRoleContextProvider = ({ children }: Props) => {
  const { data: user, isSuccess } = useQuery<User, AxiosError>(
    'userRoles',
    () => getUserDetails(),
    {
      staleTime: Infinity,
    }
  );

  const hasRole = (requiredRole: Role) => user?.roles?.includes(requiredRole);

  const isAdminOrCommissionRole = useMemo(
    () => hasRole(Roles.ADMIN) || hasRole(Roles.COMMISSION),
    [user]
  );

  const isOnlyContractorRole = useMemo(
    () => !!(hasRole(Roles.CONTRACTOR) && user?.roles?.length === 1),
    [user]
  );

  useEffect(() => {
    if (isSuccess && isOnlyContractorRole) {
      window.location.href = `${window.location.protocol}//${window.location.host}/portal-agenta`;
    }
  }, [user]);

  const hasAccessToCommissionMode = !isOnlyContractorRole && isSuccess;

  return (
    <AgentRoleContext.Provider value={{ isAdminOrCommissionRole, hasRole }}>
      {hasAccessToCommissionMode && children}
    </AgentRoleContext.Provider>
  );
};

const useAgentRole = () => useContext(AgentRoleContext);

export default useAgentRole;
