import { Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import GroupTransferModalForm from 'pages/Agent/components/GroupTransferModalForm/GroupTransferModalForm';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createGroupTransfer } from 'services/polices';
import { CreateGroupTransferPayload } from 'types/polices';

const validationSchema = Yup.object({
  fromAgentId: Yup.number().required(requiredFieldMessage),
  toAgentId: Yup.number().required(requiredFieldMessage),
  transferDate: Yup.date().required(requiredFieldMessage),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  fromAgentId: 0,
  toAgentId: 0,
  transferDate: '' as unknown as Date,
};

type Props = {
  closeModal: () => void;
  onSuccess?: () => void;
  withDescription?: boolean;
};

const GroupTransferModal = ({ closeModal, onSuccess, withDescription }: Props) => {
  const fromAgentId = useAgentId();

  const initValues = useMemo(() => {
    return {
      ...initialValues,
      fromAgentId,
    };
  }, [fromAgentId]);

  const { isError, error, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    CreateGroupTransferPayload
  >((payload) => createGroupTransfer(payload), {
    onSuccess: () => {
      closeModal();
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const handleSubmit = (values: FormValues) => {
    const payload: CreateGroupTransferPayload = {
      ...values,
      transferDate: format(startOfDay(values.transferDate), 'dd-MM-yyyy HH:mm:ss'),
    };

    mutate(payload);
  };

  return (
    <Modal visible title="Grupowa cesja polis">
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={initValues}
        onSubmit={handleSubmit}>
        <Form className="space-y-2">
          {withDescription && (
            <p>
              Do tego agenta przypisane są polisy, których cesję należy wykonać przed zakończeniem
              współpracy.
            </p>
          )}
          <GroupTransferModalForm />
          <ModalActions onCancel={() => closeModal()} isLoading={isLoading} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default GroupTransferModal;
