import { Box, Header, Loader, Table } from '@profitowi/component-library';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { SortingRule } from 'react-table';

import { AGENT } from 'constants/queries/agent';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { getPeriodParams } from 'services/agent';
import { decodeUriSortParams } from 'utils/table';

import ParametersForm from './ParametersForm/ParametersForm';
import { columns } from './columns';

type Props = {
  economyActivityId?: number;
  economyActivityValidTo?: string;
  refetchAgent: () => void;
};

const Parameters = ({ economyActivityId, economyActivityValidTo, refetchAgent }: Props) => {
  const { activeDictionaries } = useDictionaryContext();
  const agentId = useAgentId();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [AGENT.PERIOD_PARAMETERS, agentId, economyActivityId, currentPage, perPage, sortBy],
    () =>
      getPeriodParams(
        agentId,
        currentPage,
        perPage,
        economyActivityId ?? 0,
        decodeUriSortParams(sortBy)
      ),
    { enabled: !!economyActivityId, keepPreviousData: true }
  );

  return (
    <>
      <Box>
        <div className="flex justify-between my-4">
          <Header as="h4" size="lg" weight="semibold">
            Okresy parametrów
          </Header>
        </div>

        {isLoading && <Loader className="h-12 w-12" />}

        {data?.content && (
          <Box.FullWidth>
            <Table
              data={data.content.length > 0 ? data.content : []}
              columns={columns(activeDictionaries)}
              pagination={pagination}
              totalPages={data.totalPages}
              isLoading={isFetching}
              sortBy={sortBy}
              onSortBy={handleSortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route
          path="updateParameter"
          element={
            <ParametersForm
              economyActivityId={economyActivityId}
              economyActivityValidTo={data && economyActivityValidTo}
              refetch={refetch}
              refetchAgent={refetchAgent}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Parameters;
