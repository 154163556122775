import { Box, Button, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Columns } from 'pages/Settlements/List/columns';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { SETTLEMENT } from 'constants/queries/settlement';
import { getSettlements } from 'services/settlement';
import { Page } from 'types/request';
import { SettlementData } from 'types/settlement';

function SettlementTable() {
  const navigate = useNavigate();
  const { data, isError, isFetching, isLoading, isSuccess, error } = useQuery<
    Page<SettlementData>,
    AxiosError
  >([SETTLEMENT.SETTLEMENT], () => getSettlements(0, 5, true), {
    keepPreviousData: true,
  });

  return (
    <Box>
      <div className="flex justify-between items-center mb-6">
        <Header as="h4" size="lg" weight="semibold">
          Ostatnie rozliczenia
        </Header>
        <Button variant="secondary" onPress={() => navigate('/settlements')}>
          Więcej
        </Button>
      </div>

      {isError && <ErrorMessages error={error} />}
      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && data && (
        <Box.FullWidth>
          <Table
            columns={Columns}
            data={data.content}
            isLoading={isFetching}
            totalPages={data.totalPages}
            manualSortBy={false}
          />
        </Box.FullWidth>
      )}
    </Box>
  );
}

export default SettlementTable;
