import { AutoCompleteField, DatePickerField, Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createAgentsOptions } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';
import { getAgent } from 'services/agent';
import { saveRecommendation, updateRecommendation } from 'services/recommendation';
import { AgentDetails } from 'types/agent';
import {
  Recommendation,
  RecommendationInitialValues,
  RecommendationPayload,
} from 'types/recommendation';

const validationSchema = yup.object({
  referred: yup.number().defined(requiredFieldMessage),
  referee: yup.number().defined(requiredFieldMessage),
  validFrom: yup.date().defined().default(undefined).typeError('Nieprawidłowa data'),
  validTo: yup.date().defined().default(undefined).typeError('Nieprawidłowa data'),
});

type Props = {
  refetch: () => void;
};

const RecommendationForm = ({ refetch }: Props) => {
  const [referredQueryAgent, setReferredQueryAgent] = useState('');
  const agentId = useAgentId();
  const { data: searchAgents } = useAgentQuery({
    queryParam: referredQueryAgent,
    minQueryLength: 3,
  });
  const navigate = useNavigate();
  const { state } = useLocation();

  const recommendation: Recommendation = state?.recommendation;

  const { data: selectedAgent } = useQuery<AgentDetails, AxiosError>(
    [AGENT.AGENT, agentId],
    () => getAgent(agentId),
    { enabled: !!recommendation }
  );

  const initialValues = useMemo(() => {
    if (recommendation) {
      const { validFrom, validTo, referee, referred } = recommendation;

      return {
        referee: referee.id,
        referred: referred.id,
        validFrom: validFrom
          ? parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date())
          : (undefined as unknown as Date),
        validTo: validTo
          ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
          : (undefined as unknown as Date),
      };
    }

    return {
      referred: 0,
      referee: agentId,
      validFrom: undefined as unknown as Date,
      validTo: undefined as unknown as Date,
    };
  }, [recommendation, agentId]);

  const onSuccess = () => {
    refetch();
    navigate('..');
  };

  const {
    isLoading: isSaveLoading,
    error: saveError,
    mutate: save,
  } = useMutation<AxiosResponse, AxiosError, RecommendationPayload>(
    (payload) => saveRecommendation(payload),
    {
      onSuccess,
    }
  );

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate: update,
  } = useMutation<AxiosResponse, AxiosError, RecommendationPayload>(
    (payload) => updateRecommendation(payload),
    {
      onSuccess,
    }
  );

  const onSubmit = (values: RecommendationInitialValues) => {
    const { validFrom, validTo } = values;

    const payload = {
      ...values,
      validFrom: format(new Date(validFrom), 'dd-MM-yyyy 00:00:00'),
      validTo: format(new Date(validTo), 'dd-MM-yyyy 00:00:00'),
    };

    recommendation ? update(payload) : save(payload);
  };

  const initialSearchOptions = useMemo(() => {
    if (recommendation && selectedAgent) {
      return createAgentsOptions([selectedAgent], 'id');
    }
    return [{ key: 0, value: '' }];
  }, [recommendation, selectedAgent]);

  const referredAgentsOption = useMemo(
    () => (searchAgents ? createAgentsOptions(searchAgents.content, 'id') : initialSearchOptions),
    [searchAgents, initialSearchOptions]
  );

  const title = recommendation ? 'Edytuj polecenia' : 'Dodaj polecenie';

  return (
    <Modal title={title} visible>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values: { validFrom, validTo } }) => (
          <Form className="space-y-4">
            <Text className="-mb-3 text-primary">Agent polecony</Text>
            <AutoCompleteField
              placeholder="Wyszukaj kod PR agenta"
              name="referred"
              options={referredAgentsOption}
              query={referredQueryAgent}
              setQuery={setReferredQueryAgent}
            />
            <DatePickerField label="Data od" name="validFrom" maxDate={validFrom} />
            <DatePickerField label="Data do" name="validTo" minDate={validTo} />
            <ModalActions
              isLoading={isSaveLoading || isUpdateLoading}
              onCancel={() => navigate('..')}
            />
            {saveError && <ErrorMessages error={saveError} />}
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RecommendationForm;
