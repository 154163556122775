import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getViceWithDelegations } from 'services/delegations';
import { DelegationVice } from 'types/delegation';

type Props = {
  queryKey: string;
  commissionSchemaId?: number;
  delegatedOnly?: boolean;
  delegateFrom?: boolean;
  term?: string;
};

export const useDelegationViceQuery = ({
  queryKey,
  commissionSchemaId,
  term,
  delegatedOnly = false,
  delegateFrom = false,
}: Props) => {
  const { data, ...rest } = useQuery<DelegationVice[], AxiosError>([queryKey, term], () =>
    getViceWithDelegations({ commissionSchemaId, delegatedOnly, delegateFrom, term })
  );

  return { data, rest };
};
