import { Box, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import BackArrow from 'components/BackArrow/BackArrow';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DEBTS } from 'constants/queries/debts';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getDebt } from 'services/debt';
import { Debt, Installment } from 'types/debt';

import DebtInstallmentForm from './DebtInstallmentForm/DebtInstallmentForm';
import { createColumn } from './columns';

const DebtInstallments = () => {
  const [visible, setVisible] = useState(false);
  const [editedDebtInstallment, setEditedDebtInstallment] = useState<Installment>();
  const { activeDictionaries } = useDictionaryContext();

  const { debtRepaymentId = '', agentId = '' } = useParams();

  const { data, refetch, isError, isLoading, isSuccess, error } = useQuery<Debt, AxiosError>(
    [DEBTS.DEBT, debtRepaymentId],
    () => getDebt(parseInt(agentId), parseInt(debtRepaymentId)),
    {
      select: (debt) => {
        return {
          ...debt,
          installments: debt.installments.sort(
            (a, b) => a.debtRepaymentInstallmentId - b.debtRepaymentInstallmentId
          ),
        };
      },
    }
  );

  const editDebtInstallments = (debtInstallment: Installment) => {
    setEditedDebtInstallment(debtInstallment);
    setVisible(true);
  };

  return (
    <>
      <div className="flex-1 p-8 space-y-6">
        <Header as="h1" size="xl" weight="semibold">
          Raty zadłużenia
        </Header>
        <BackArrow />

        <Box className="space-y-6">
          <Header as="h4" size="lg" weight="semibold">
            Lista rat
          </Header>

          {isError && <ErrorMessages error={error} />}
          {isLoading && <Loader className="h-12 w-12" />}

          {isSuccess && data && (
            <Box.FullWidth>
              <Table
                data={data.installments ?? []}
                manualSortBy={false}
                columns={createColumn(editDebtInstallments, activeDictionaries)}
              />
            </Box.FullWidth>
          )}
        </Box>
      </div>

      {visible && activeDictionaries && data && editedDebtInstallment && (
        <DebtInstallmentForm
          visible={visible}
          debt={data}
          dictionaryTypes={activeDictionaries}
          editedInstallment={editedDebtInstallment}
          setVisible={setVisible}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default DebtInstallments;
