import clsx from 'clsx';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type NavigationTabElement = {
  displayName: string;
  route: string;
  isExternal?: boolean;
};

type NavigationTabProps = {
  tab: NavigationTabElement;
  rootPath: string;
};

type NavigationToolbarProps = {
  rootPath: string;
  tabs: NavigationTabElement[];
};

const NavigationTab = ({ tab, rootPath }: NavigationTabProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const match = useMemo(() => {
    if (tab.route === '/') {
      return rootPath === location.pathname;
    }

    return location.pathname.includes(tab.route);
  }, [location.pathname, tab.route, rootPath]);

  return (
    <button
      type="button"
      onClick={() =>
        !tab.isExternal
          ? navigate(tab.route === '/' ? rootPath : tab.route)
          : window.open(tab.route, '_blank')
      }
      className={clsx(
        'text-sm leading-5 font-medium focus:outline-none hover:text-primary border-light py-1.5',
        match && 'border-b-2'
      )}>
      {tab.displayName}
    </button>
  );
};

const NavigationToolbar = ({ tabs, rootPath }: NavigationToolbarProps) => {
  return (
    <div className="flex space-x-4">
      {tabs.map((tab) => (
        <NavigationTab tab={tab} key={tab.route} rootPath={rootPath} />
      ))}
    </div>
  );
};

export default NavigationToolbar;
