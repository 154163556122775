import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { MessageTemplate } from 'types/template';

export const createColumn = (
  setEditedItem: (template: MessageTemplate) => void
): Column<MessageTemplate>[] => {
  return [
    {
      Header: 'Typ',
      accessor: 'templateType',
      Cell: ({ value }) => {
        const { translate } = useDictionaryContext();

        return value ? translate('MESSAGE_TEMPLATE_TYPE', String(value)) : '';
      },
    },
    {
      Header: 'Tytuł',
      accessor: 'title',
    },
    {
      Header: 'Treść',
      accessor: 'content',
    },
    {
      id: 'edit',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<MessageTemplate>) => (
        <div className="flex justify-end">
          <Button variant="light" onPress={() => setEditedItem(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
