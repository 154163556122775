import { DatePickerField, InputField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { BONUS_STATES } from 'constants/bonusStates';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { generateVatRateOptions } from 'helpers/select';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { saveBonus } from 'services/agent';
import { BonusPayload } from 'types/bonus';

import NetValueInputField from './NetValueInputField/NetValueInputField';
import PaymentsInputField from './PaymentsInputField/PaymentsInputField';

const validationSchema = Yup.object().shape({
  bonusCode: Yup.string().required(requiredFieldMessage),
  bonusType: Yup.string().required(requiredFieldMessage),
  documentPositionName: Yup.string().required(requiredFieldMessage),
  grossValue: Yup.number().required(requiredFieldMessage),
  netValue: Yup.number().required(requiredFieldMessage),
  paymentDayOfMonth: Yup.number()
    .required(requiredFieldMessage)
    .min(1, 'Podana wartość powinna być w przedziale 1-31')
    .max(31, 'Podana wartość powinna być w przedziale 1-31')
    .integer('Wartość musi być liczbą całkowitą'),
  paymentStartDate: Yup.string().required(requiredFieldMessage),
  taxPercent: Yup.string().required(requiredFieldMessage),
  title: Yup.string().required(requiredFieldMessage),
  paymentsCount: Yup.number().required(requiredFieldMessage),
});

type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  refetchAgentBonuses: () => void;
};

const AgentBonusesForm = ({ visible, setVisible, refetchAgentBonuses }: Props) => {
  const { activeDictionaries } = useDictionaryContext();
  const agentId = useAgentId();

  const { isError, isLoading, error, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    BonusPayload
  >((payload) => saveBonus(agentId, payload), {
    onSuccess: () => {
      refetchAgentBonuses();
      setVisible(false);
    },
  });

  const onSubmit = (values: BonusPayload) => {
    const { paymentStartDate = '' } = values;

    mutate({
      ...values,
      paymentStartDate: format(new Date(paymentStartDate), 'dd-MM-yyyy HH:mm:ss'),
      status: BONUS_STATES.WAITING_FOR_ACCRUAL,
    });
  };

  const initialValues = {
    bonusCode: '',
    bonusType: '',
    documentPositionName: '',
    grossValue: undefined,
    netValue: undefined,
    paymentDayOfMonth: undefined,
    paymentStartDate: '',
    taxPercent: '',
    title: '',
    paymentsCount: undefined,
  };

  return (
    <Modal title="Dodaj premię" visible={visible} setVisible={setVisible}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form className="space-y-4">
          <div className="grid grid-cols-2 gap-4 items-center">
            <SelectField
              name="bonusType"
              label="Typ premii"
              options={activeDictionaries ? activeDictionaries[DICTIONARY_TYPES.BONUS_TYPE] : []}
            />
            <InputField name="title" label="Tytuł nadania premii" />
            <PaymentsInputField />
            <DatePickerField label="Data rozpoczęcia naliczenia" name="paymentStartDate" />
            <InputField name="paymentDayOfMonth" label="Dzień miesiąca naliczenia" type="number" />
            <SelectField
              name="bonusCode"
              label="Kod premii"
              options={
                activeDictionaries ? activeDictionaries[DICTIONARY_TYPES.BONUS_CODE_NAME] : []
              }
            />
            <SelectField
              name="documentPositionName"
              label="Nazwa pozycji dokumentu"
              options={
                activeDictionaries
                  ? activeDictionaries[DICTIONARY_TYPES.DOCUMENT_POSITION_NAME]
                  : []
              }
            />
          </div>
          <InputField name="grossValue" label="Kwota brutto" type="number" />
          <SelectField
            name="taxPercent"
            label="Stawka VAT"
            options={generateVatRateOptions(activeDictionaries) ?? []}
          />

          <NetValueInputField />
          <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default AgentBonusesForm;
