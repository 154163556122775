import { Emails } from 'types/emails';
import { Page } from 'types/request';
import {
  Balance,
  BillingItem,
  Bonus,
  Contract,
  Correction,
  Document,
  FileEvent,
  MessageTemplateData,
  Payout,
  SettlementData,
  SettlementMessagePayload,
  SettlementMessageQueryParam,
  SettlementPayload,
  SettlementProcessResult,
  SettlementStepPayload,
} from 'types/settlement';
import { commissionmodApi, recordmodApi } from 'utils/axios';

export const getSettlements = async (
  page: number,
  size: number,
  isTopFive?: boolean,
  sort?: string
) => {
  const { data } = await commissionmodApi.get<Page<SettlementData>>('settlement', {
    params: { page, size, sort: isTopFive === true ? 'createdAt,desc' : sort },
  });

  return data;
};

export const createSettlement = async (payload: SettlementPayload) => {
  const { data } = await commissionmodApi.post<number>('/settlement/create', payload);

  return data;
};

export const processSettlement = async (settlementId: number) => {
  const { data } = await commissionmodApi.post<any>(`/settlement/${settlementId}/process`);

  return data;
};

export const getProcessSettlementStatus = async (settlementId: number) => {
  const { data } = await commissionmodApi.get<any>(`settlement/${settlementId}/status`);

  return data;
};

export const getSettlement = async (settlementId: number) => {
  const { data } = await commissionmodApi.get<SettlementProcessResult>(
    `/settlement/${settlementId}`
  );

  return data;
};

export const cancelSettlement = async (settlementId: number) => {
  const { data } = await commissionmodApi.delete(`/settlement/${settlementId}/process`);

  return data;
};

export const putDecisions = (settlementId: number, payload: SettlementStepPayload[]) =>
  commissionmodApi.put(`/settlement/${settlementId}/process`, payload);

export const getSettlementFileEvents = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<FileEvent>>(
    `/settlement/${settlementId}/process/file-events`,
    {
      params: { page, size, sort },
    }
  );

  return data;
};

export const getSettlementBonuses = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<Bonus>>(
    `/settlement/${settlementId}/process/bonuses`,
    {
      params: { page, size, sort },
    }
  );

  return data;
};

export const getSettlementCorrections = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<Correction>>(
    `/settlement/${settlementId}/process/corrections`,
    { params: { page, size, sort } }
  );

  return data;
};

export const getSettlementContracts = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<Contract>>(
    `/settlement/${settlementId}/process/contracts`,
    { params: { page, size, sort } }
  );

  return data;
};

export const getDocuments = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<Document>>(
    `/settlement/${settlementId}/process/documents`,
    {
      params: { page, size, sort },
    }
  );

  return data;
};

export const getSettlementBillingItems = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<BillingItem>>(
    `/settlement/${settlementId}/process/billing-items`,
    {
      params: { page, size, sort },
    }
  );

  return data;
};

export const getBalances = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<Balance>>(
    `/settlement/${settlementId}/process/balances`,
    {
      params: { page, size, sort },
    }
  );

  return data;
};

export const getPayouts = async (
  settlementId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await commissionmodApi.get<Page<Payout>>(
    `/settlement/${settlementId}/process/payouts`,
    {
      params: { page, size, sort },
    }
  );

  return data;
};

export const getEmailContent = async (settlementId: number) => {
  const { data } = await commissionmodApi.get<MessageTemplateData[]>(
    `/settlement/${settlementId}/process/email`
  );

  return data;
};

export const putEmailContent = async (settlementId: number, payload: MessageTemplateData[]) => {
  const { data } = await commissionmodApi.put(`/settlement/${settlementId}/process/email`, payload);

  return data;
};

export const getSettlementEmails = async (
  settlementId: number,
  page: number,
  size: number,
  queryParams?: SettlementMessageQueryParam,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<Emails>>(`/messages`, {
    params: { settlementId, ...queryParams, page, size, sort },
  });

  return data;
};

export const resend = async (payload: SettlementMessagePayload) => {
  const { data } = await recordmodApi.put(`/messages`, payload);

  return data;
};

export const getSettlementsBillingItemsFile = async (settlementId: number) => {
  const { data } = await commissionmodApi.get(
    `/settlement/${settlementId}/process/billing-items/file`,
    { responseType: 'blob' }
  );

  return data;
};

export const getDocumentsPreview = async (settlementId: number) => {
  const { data } = await commissionmodApi.get(
    `/settlement/${settlementId}/process/documents/preview`,
    { responseType: 'blob' }
  );

  return data;
};
