import {
  Button,
  DatePickerField,
  InputField,
  Modal,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format, parse } from 'date-fns';
import { FieldArray, Form, Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { generateVatRateOptions } from 'helpers/select';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { Program } from 'types/commission';
import { DictionaryTypes } from 'types/dictionary';

const conditionShape = Yup.object({
  condition: Yup.string().required(requiredFieldMessage).default(''),
  vatRate: Yup.string().required(requiredFieldMessage).default(''),
  commissionRate: Yup.number()
    .required(requiredFieldMessage)
    .default(0)
    .min(0, 'Wszystkie wartości muszą znajdować się w przedziale 0 - 100%')
    .max(100, 'Wszystkie wartości muszą znajdować się w przedziale 0 - 100%'),
});

const validationSchema = Yup.object({
  validFrom: Yup.string().default(undefined),
  level: Yup.string().required(requiredFieldMessage).default(''),
  superiorPosition: Yup.string().required(requiredFieldMessage).default(''),
  subordinatePosition: Yup.string().required(requiredFieldMessage).default(''),
  positionType: Yup.string().default(null).nullable(),
  conditions: Yup.array()
    .of(conditionShape)
    .when((_, conditions) => conditions.default([conditionShape.cast({})])),
});

type SchemaType = Yup.InferType<typeof validationSchema>;

type Props = {
  dictionaryTypes?: DictionaryTypes;
  editedConfig?: Program;
  visible: boolean;
  updateSchemaError?: AxiosError | null;
  isLoading: boolean;
  setVisible: (value: boolean) => void;
  cancelEdit: () => void;
  mutate: (configurationLevel: Program) => void;
};

const ConfigurationLevelForm = ({
  visible,
  dictionaryTypes,
  editedConfig,
  updateSchemaError,
  setVisible,
  cancelEdit,
  mutate,
  isLoading,
}: Props) => {
  const { activeDictionaries } = useDictionaryContext();

  const handleSubmit = (program: Program | SchemaType) => {
    const conditions = program.conditions?.map((condition) => ({
      ...condition,
      commissionRate: condition.commissionRate / 100,
    }));

    if (editedConfig) {
      const { commissionSchemaProgramId } = editedConfig;

      const payload = {
        ...program,
        validFrom: program.validFrom && format(new Date(program.validFrom), 'dd-MM-yyyy HH:mm:ss'),
        conditions: conditions || [],
        commissionSchemaProgramId,
      };

      mutate(payload as Program);
    } else {
      const payload = { ...program, conditions } as Program;
      mutate(payload);
    }
  };

  const initialValues = editedConfig
    ? {
        ...editedConfig,
        validFrom: editedConfig.validFrom
          ? parse(editedConfig.validFrom, 'dd-MM-yyyy HH:mm:ss', new Date()).toString()
          : '',
        conditions: editedConfig.conditions.map((condition) => ({
          ...condition,
          commissionRate: parseFloat((condition.commissionRate * 100).toPrecision(4)),
        })),
      }
    : validationSchema.cast({});

  const vatRateOptions =
    useMemo(() => generateVatRateOptions(activeDictionaries), [activeDictionaries]) ?? [];

  useEffect(() => {
    if (visible === false) {
      cancelEdit();
    }
  }, [visible]);

  return (
    <Modal
      title={`${editedConfig ? 'Edytuj' : 'Dodaj'} konfigurację schematu`}
      visible={visible}
      setVisible={setVisible}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize>
        {({ values, errors, touched }) => {
          return (
            <Form>
              <div className="flex flex-col space-y-4">
                {editedConfig && (
                  <DatePickerField
                    preventOpenOnFocus
                    name="validFrom"
                    label="Wprowadź zmiany od"
                    dateFormat={'dd-MM-yyyy'}
                    endDate={new Date()}
                  />
                )}

                <InputField label="Poziom" name="level" />

                <SelectField
                  options={dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.POSITION] : []}
                  name="superiorPosition"
                  label="Stanowisko nadrzędne"
                />

                <SelectField
                  options={dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.POSITION] : []}
                  name="subordinatePosition"
                  label="Stanowisko podrzędne"
                />

                <SelectField
                  options={dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.POSITION_TYPE] : []}
                  name="positionType"
                  label="Typ pozycji"
                />

                <div className="flex flex-col w-full my-2">
                  <p>Warunki prowizyjne</p>
                  <FieldArray name="conditions">
                    {(helpers) => {
                      return (
                        <div className="flex w-full items-center my-8">
                          <div className="flex flex-col gap-4 w-full">
                            <Button
                              onPress={() =>
                                helpers.push({
                                  commissionRate: 0,
                                  condition: '',
                                })
                              }>
                              Dodaj warunek
                            </Button>
                            {values?.conditions?.map((condition, index) => (
                              <div className="flex flex-col my-4" key={index}>
                                <div className="w-full">
                                  <SelectField
                                    options={
                                      dictionaryTypes?.[DICTIONARY_TYPES.COMMISSION_CONDITION] || []
                                    }
                                    name={`conditions[${index}].condition`}
                                  />
                                </div>
                                <div className="flex justify-between my-2 gap-4">
                                  <div className="flex flex-col items-center justify-center w-1/2">
                                    <div className="w-full">
                                      <label className="text-sm font-normal text-secondary">
                                        Prowizja %
                                      </label>
                                      <InputField
                                        id={`conditions[${index}].commissionRate`}
                                        aria-label="Prowizja"
                                        name={`conditions[${index}].commissionRate`}
                                        placeholder="Prowizja"
                                        type="number"
                                        className="!py-3 mt-1"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex items-center justify-center w-1/2">
                                    <div className="w-full">
                                      <SelectField
                                        name={`conditions[${index}].vatRate`}
                                        label="Stawka VAT"
                                        options={vatRateOptions}
                                      />
                                    </div>
                                  </div>
                                  <Button
                                    onPress={() => helpers.remove(index)}
                                    className="self-end h-12 !py-2 mb-0.5">
                                    <i className="bi bi-trash cursor-pointer" />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                </div>
                {errors.conditions &&
                touched.conditions &&
                typeof errors.conditions === 'string' ? (
                  <Text size="sm" className="text-secondary-red">
                    {errors.conditions}
                  </Text>
                ) : null}
              </div>

              {updateSchemaError && <ErrorMessages error={updateSchemaError} />}

              <ModalActions isLoading={isLoading} onCancel={() => cancelEdit()} />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ConfigurationLevelForm;
