import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import ModalActions from 'components/ModalActions';
import { deleteComment } from 'services/comment';

type Props = {
  refetch: () => void;
};

const DeleteCommentModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('..');
    }
  }, [state, navigate]);

  const { id } = state;

  const { isLoading, mutate } = useMutation<AxiosResponse, AxiosError, { id: number }>(
    ({ id }) => deleteComment(id),
    {
      onSuccess: () => {
        navigate('..');
        refetch();
      },
    }
  );

  return (
    <Modal visible title="Usuń komentarz">
      <Text>Czy na pewno chcesz usunąć ten komentarz?</Text>
      <ModalActions
        isLoading={isLoading}
        onConfirm={() => mutate({ id })}
        onCancel={() => navigate('..')}
      />
    </Modal>
  );
};

export default DeleteCommentModal;
