import { Box, Header, Loader, Option, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { DICTIONARY } from 'constants/queries/dictionary';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { getDictionary } from 'services/dictionary';
import { Dictionary, DictionaryPayload } from 'types/dictionary';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import DictionaryForm from '../common/componets/DictionaryForm/DictionaryForm';
import useUpdateDictionary from '../common/hooks/useUpdateDictionary';
import { createColumn } from './columns';

const Parameters = () => {
  const [selectedType, setSelectedType] = useState<Option>();
  const [editedItem, setEditedItem] = useState<Dictionary>();
  const [modalVisible, setModalVisible] = useState(false);
  const pagination = usePagination(10);
  const { activeDictionaries } = useDictionaryContext();
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const {
    data: dictionaries,
    isFetched: dictionaryIsFetched,
    refetch: refetchDictionary,
    isFetching: dictionaryIsFetching,
    error: dictionaryError,
  } = useQuery<Page<Dictionary> | undefined, AxiosError>(
    [DICTIONARY.DICTIONARY, selectedType, currentPage, perPage, sortBy],
    () =>
      selectedType &&
      getDictionary(selectedType?.key, currentPage, perPage, decodeUriSortParams(sortBy)),
    {
      enabled: !!selectedType?.key,
    }
  );

  useEffect(() => {
    if (activeDictionaries && !selectedType) {
      const idx = activeDictionaries.DICTIONARY_TYPE.findIndex(
        (i) => i.key === DICTIONARY_TYPES.APPLICATION_CONFIGURATION
      );
      setSelectedType(activeDictionaries.DICTIONARY_TYPE[idx]);
    }
  }, [activeDictionaries]);

  const closeModal = () => {
    setModalVisible(false);
    setEditedItem(undefined);
  };

  const updateDictionary = useUpdateDictionary(refetchDictionary, () => {}, closeModal);

  const editDictionary = (dictionary: Dictionary) => {
    setEditedItem(dictionary);
    setModalVisible(true);
    updateDictionary.reset();
  };

  const onSave = (values: DictionaryPayload) => {
    if (selectedType) {
      const { key } = selectedType;
      if (editedItem) {
        const { id } = editedItem;

        const payload = {
          ...values,
          id,
          key: editedItem.key,
        };

        updateDictionary.mutate({ dictionary: payload, id, type: key as string });
      }
    }
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Konfiguracja aplikacji
          </Header>
        </div>

        {dictionaryError && <ErrorMessages error={dictionaryError} />}
        {dictionaries && dictionaryIsFetched && activeDictionaries ? (
          <Box.FullWidth>
            <Table
              pagination={pagination}
              totalPages={dictionaries.totalPages}
              data={dictionaries.content}
              columns={createColumn(editDictionary)}
              isLoading={dictionaryIsFetching}
              sortBy={sortBy}
              onSortBy={handleSortBy}
            />
          </Box.FullWidth>
        ) : (
          <Loader className="h-12 w-12" />
        )}
      </Box>

      <DictionaryForm
        title="Edytuj pozycję słownika"
        visible={modalVisible}
        setVisible={setModalVisible}
        canHandleHidden={false}
        onSave={onSave}
        closeModal={closeModal}
        editedItem={editedItem}
        dictionaryError={updateDictionary.error}
      />
    </>
  );
};

export default Parameters;
