import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { DictionaryTypes } from 'types/dictionary';

export const generateVatRateOptions = (activeDictionaries?: DictionaryTypes) =>
  activeDictionaries?.[DICTIONARY_TYPES.VAT_RATE]?.map((item) => {
    return {
      key: item.key,
      value: item.key !== 'VAT_ZW' ? `${parseFloat(item.value) * 100}%` : item.value,
    };
  });
