import qs from 'qs';

import {
  Delegation,
  DelegationVice,
  EndDelegationTimePayload,
  GetDelegationParams,
} from 'types/delegation';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getDelegations = async (
  rawParams: GetDelegationParams,
  page: number,
  size: number,
  sort?: string
) => {
  const params = Object.fromEntries(Object.entries(rawParams).filter(([, value]) => value));

  const { data } = await recordmodApi.get<Page<Delegation>>(`/delegations`, {
    params: {
      ...params,
      size,
      page,
      sort,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

type Params = {
  commissionSchemaId?: number;
  delegatedOnly: boolean;
  delegateFrom: boolean;
  term?: string;
};

export const getViceWithDelegations = async (rawParams: Params) => {
  const params = Object.fromEntries(Object.entries(rawParams).filter(([, value]) => value));

  const { data } = await recordmodApi.get<DelegationVice[]>(`/delegations/vice`, {
    params,
  });

  return data;
};

export const endDelegationTime = async (
  payload: EndDelegationTimePayload,
  validate: boolean = false
) => {
  return await recordmodApi.put<EndDelegationTimePayload>(`/delegations/endTime`, payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });
};
