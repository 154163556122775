import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { addDictionaryEntry } from 'services/dictionary';
import { DictionaryPayload } from 'types/dictionary';

type CreateResponse = {
  dictionary: DictionaryPayload;
  type: string;
};

export const useCreateDictionary = (
  refetchDictionary: Function,
  refetch: Function,
  closeModal: Function
) => {
  return useMutation<AxiosResponse, AxiosError, CreateResponse>(
    ({ dictionary, type }: CreateResponse) => addDictionaryEntry(dictionary, type),
    {
      onSuccess: () => {
        refetchDictionary();
        refetch();
        closeModal();
      },
    }
  );
};

export default useCreateDictionary;
