import { Box, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { usePagination } from 'hooks/usePagination';
import { useTemplatesQuery } from 'hooks/useTemplatesQuery';
import { updateTemplateEntry } from 'services/template';
import { MessageTemplate } from 'types/template';

import TemplateForm from './TemplateForm/TemplateForm';
import { createColumn } from './columns';

type UpdateResponse = {
  template: MessageTemplate;
};

const Templates = () => {
  const [editedItem, setEditedItem] = useState<MessageTemplate>();
  const [modalVisible, setModalVisible] = useState(false);
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { templateList, refetch, isFetching, isError, error } = useTemplatesQuery(
    currentPage,
    perPage,
    sortBy
  );

  const updateTemplate = useMutation<AxiosResponse, AxiosError, UpdateResponse>(
    ({ template }) => updateTemplateEntry(template),
    {
      onSuccess: (response) => {
        try {
          const templatePayload = JSON.parse(response.config.data) as MessageTemplate;
          const currentItem = templateList?.content.find(
            (i: MessageTemplate) => i.templateType === templatePayload.templateType
          );
          if (currentItem) {
            currentItem.title = templatePayload.title;
            currentItem.content = templatePayload.content;
          }
        } catch (e) {
          refetch();
        }
        closeModal();
      },
    }
  );

  const editTemplate = (template: MessageTemplate) => {
    setEditedItem(template);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setEditedItem(undefined);
    updateTemplate.isError && updateTemplate.reset();
  };

  const onSave = (payload: MessageTemplate) => {
    if (editedItem) {
      updateTemplate.mutate({ template: payload });
    }
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Szablony
          </Header>
        </div>

        {isError && <ErrorMessages error={error} />}
        {templateList ? (
          <Box.FullWidth>
            <Table
              pagination={pagination}
              totalPages={templateList.totalPages}
              data={templateList.content}
              columns={createColumn(editTemplate)}
              isLoading={isFetching}
              sortBy={sortBy}
              onSortBy={handleSortBy}
            />
          </Box.FullWidth>
        ) : (
          <Loader className="h-12 w-12" />
        )}
        {!!editedItem && (
          <TemplateForm
            visible={modalVisible}
            setVisible={setModalVisible}
            onSave={onSave}
            closeModal={closeModal}
            editedItem={editedItem}
            updateTemplateError={updateTemplate.error}
          />
        )}
      </Box>
    </>
  );
};

export default Templates;
