import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { Transfer } from 'types/polices';
import { formatBackendDateWithoutTime } from 'utils/date';

export const createColumn = (
  editTransfer: (transfer: Transfer) => void,
  cancelTransfer: (id: number) => void
): Column<Transfer>[] => {
  return [
    {
      Header: 'Sygnatura polisy',
      accessor: 'policySignature',
    },
    {
      Header: 'Identyfikator agenta',
      id: 'agent.id',
      accessor: 'agentCode',
    },
    {
      Header: 'Identyfikator nowego agenta',
      id: 'newAgent.id',
      accessor: 'newAgentCode',
    },
    {
      Header: 'Data transferu',
      accessor: 'transferDate',
      Cell: ({
        row: {
          original: { transferDate },
        },
      }: Cell<Transfer>) => formatBackendDateWithoutTime(transferDate),
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Transfer>) => (
        <div className="flex space-x-6 justify-end">
          <Button variant="light" onPress={() => editTransfer(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>

          <Button variant="light" onPress={() => cancelTransfer(original.id)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
