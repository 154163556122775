import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import { AGENT } from 'constants/queries/agent';
import { getAgents } from 'services/agent';
import { AgentList } from 'types/agent';
import { Page } from 'types/request';

type Props = {
  queryParam: string;
  minQueryLength?: number;
  currentPage?: number;
  perPage?: number;
  sortBy?: string[];
};

export const useAgentQuery = ({
  queryParam = '',
  minQueryLength,
  currentPage = 0,
  perPage = 10,
  sortBy,
}: Props) => {
  const [debouncedQueryParam] = useDebounce(queryParam.toUpperCase(), 500);

  const { data, ...rest } = useQuery<Page<AgentList>, AxiosError>(
    [AGENT.AGENTS, debouncedQueryParam, currentPage, perPage, sortBy],
    () => getAgents(debouncedQueryParam, currentPage, perPage, sortBy),
    {
      enabled: minQueryLength ? queryParam?.length >= minQueryLength : true,
      keepPreviousData: true,
    }
  );

  return {
    data,
    ...rest,
  };
};
