import { Alert, Box, Button, Header, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import { ElementType, Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { SETTLEMENT } from 'constants/queries/settlement';
import useDictionaryContext from 'hooks/useDictionaryContext';
import {
  cancelSettlement,
  getProcessSettlementStatus,
  getSettlement,
  processSettlement,
} from 'services/settlement';
import { SettlementProcessResult, SettlementStage, SettlementStatus } from 'types/settlement';

import useSettlement from '../../useSettlement';
import AcceptanceStage from './AcceptanceStage/AcceptanceStage';
import BalanceGeneratedStage from './BalanceGeneratedStage/BalanceGeneratedStage';
import BonusesStage from './BonusesStage/BonusesStage';
import ContractsStage from './ContractsStage/ContractsStage';
import CorrectionsStage from './CorrectionsStage/CorrectionsStage';
import DocumentsStage from './DocumentsStage/DocumentsStage';
import FileEventsStage from './FileEventsStage/FileEventsStage';
import FinalAcceptationStage from './FinalAcceptationStage/FinalAcceptationStage';
import PayoutsStage from './PayoutsStage/PayoutsStage';

const stages: Record<SettlementStage, ElementType> = {
  ACCEPTED: () => <Alert type="success">Rozliczenie zostało zaakceptowane.</Alert>,
  BALANCE_GENERATED: BalanceGeneratedStage,
  BONUSES: BonusesStage,
  CANCELLED: () => <Alert type="error">Rozliczenie zostało anulowane.</Alert>,
  CONTRACTS: ContractsStage,
  CREATED: () => <Fragment />,
  DOCUMENTS_GENERATED: DocumentsStage,
  FILE_EVENTS: FileEventsStage,
  FINAL_ACCEPTATION: FinalAcceptationStage,
  PAYOUTS_CALCULATED: PayoutsStage,
  SYSTEM_CORRECTION_EVENTS: CorrectionsStage,
  TO_ACCEPTATION: AcceptanceStage,
};

const stageNames: Record<SettlementStage, string> = {
  ACCEPTED: 'Zaakceptowane',
  BALANCE_GENERATED: 'Saldo wygenerowane',
  BONUSES: 'Premie naliczone',
  CANCELLED: 'Anulowane',
  CONTRACTS: 'Kontrakty naliczone',
  CREATED: 'Utworzone',
  DOCUMENTS_GENERATED: 'Dokumenty księgowe wygenerowane',
  FILE_EVENTS: 'Prowizje z pliku naliczone',
  FINAL_ACCEPTATION: 'Do ostatecznej akceptacji',
  PAYOUTS_CALCULATED: 'Płatności obliczone',
  SYSTEM_CORRECTION_EVENTS: 'Zdarzenia korygujące naliczone',
  TO_ACCEPTATION: 'Naliczenie do akceptacji',
};

const isStageCancellable = (stage: SettlementStage) =>
  ![
    'ACCEPTED',
    'BALANCE_GENERATED',
    'CANCELLED',
    'DOCUMENTS_GENERATED',
    'FINAL_ACCEPTATION',
    'PAYOUTS_CALCULATED',
  ].includes(stage);

const Stages = () => {
  const [selectedStage, setSelectedStage] = useState<SettlementStage>();
  const { translate } = useDictionaryContext();
  const { settlementId: paramSettlementId = '' } = useParams();
  const settlementId = useSettlement().settlementId ?? parseInt(paramSettlementId);
  const { setIsEditable } = useSettlement();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isContinued, setIsContinued] = useState(state?.isContinued);
  const [isProcessSettlement, setIsProcessSettlement] = useState<boolean>(false);
  const [isStatusBlocked, setIsStatusBlocked] = useState<boolean>(true);

  const { data, error, isError, isLoading, isRefetching, refetch } = useQuery<
    SettlementProcessResult,
    AxiosError
  >([SETTLEMENT.SETTLEMENT_STAGE, settlementId], () => getSettlement(settlementId), {
    enabled: !!settlementId,
    onSuccess: (data) => {
      if (isContinued === true) setIsContinued(false);
      setIsEditable(data.editable);
      setSelectedStage(data.currentStage);
    },
  });

  const {
    mutate: mutateProcessSettlement,
    error: processSettlementError,
    isError: isProcessSettlementError,
    isLoading: isLoadingProcessSettlement,
  } = useMutation<AxiosResponse, AxiosError>(() => processSettlement(settlementId), {
    onSuccess: () => {
      setIsProcessSettlement(true);
    },
  });

  const {
    data: settlementProcessStatus,
    isError: isStatusError,
    error: statusError,
  } = useQuery<any, AxiosError>(
    ['processSettlement', settlementId, isProcessSettlement, isStatusBlocked],
    () => getProcessSettlementStatus(settlementId),
    {
      enabled: !!isProcessSettlement || isStatusBlocked,
      refetchInterval: 3000, //TODO
      keepPreviousData: true,
      onSuccess: (status) => {
        if (status === SettlementStatus.BLOCKED) {
          setIsStatusBlocked(true);
        }
        if (status === SettlementStatus.UNBLOCKED) {
          setIsProcessSettlement(false);
          setIsStatusBlocked(false);
          refetch();
        }
      },
    }
  );

  const { currentStage, documentCreationDate, nextStages, period } = data ?? {};

  const {
    error: cancelError,
    isError: isCancelError,
    isLoading: isCancelLoading,
    mutate,
  } = useMutation<AxiosResponse, AxiosError>(() => cancelSettlement(settlementId), {
    onSuccess: () => navigate('..'),
  });

  const isProcessSettlementStatusError = settlementProcessStatus === SettlementStatus.ERROR;

  useEffect(() => {
    // if stuck at the CREATED stage, move on to the next stage
    if (currentStage === 'CREATED') {
      mutateProcessSettlement();
    }

    if (isContinued) refetch();
  }, [mutateProcessSettlement, refetch, currentStage]);

  useEffect(() => {
    if (isProcessSettlementStatusError) setIsProcessSettlement(false);
  }, [isProcessSettlementStatusError]);

  if (isLoading && !data) {
    return <Loader className="h-12 w-12" />;
  }

  if (isProcessSettlementStatusError) {
    const error = {
      message:
        'Wystąpił błąd podczas rozliczenia. Skontaktuj się z administratorem lub spróbuj ponownie',
    };

    return <ErrorMessages error={error} />;
  }

  if (isError) {
    return <ErrorMessages error={error} />;
  }

  if (isProcessSettlementError) return <ErrorMessages error={processSettlementError} />;

  if (isStatusError) return <ErrorMessages error={statusError} />;

  const StageComponent = selectedStage && stages[selectedStage];
  const { previousStages } = data ?? {};

  const isSettlementLoading =
    isRefetching || isProcessSettlement || isLoadingProcessSettlement || isStatusBlocked;

  if (StageComponent) {
    return (
      <>
        <Box className="flex space-x-8">
          <div>
            <Text className="text-secondary">Identyfikator rozliczenia</Text>
            <Text weight="semibold">{settlementId}</Text>
          </div>

          <div>
            <Text className="text-secondary">Okres rozliczeniowy</Text>
            <Text weight="semibold">{period}</Text>
          </div>

          <div>
            <Text className="text-secondary">Data wystawienia dokumentów księgowych</Text>
            <Text weight="semibold">{documentCreationDate}</Text>
          </div>
        </Box>

        <Box>
          <Header size="lg" weight="bold">
            Poprzednie kroki naliczenia
          </Header>

          <div className="flex space-x-4">
            {previousStages &&
              [...previousStages].reverse().map((item) => {
                return (
                  item !== 'CREATED' && (
                    <button
                      onClick={() => setSelectedStage(item)}
                      className={clsx(
                        'text-secondary cursor-pointer text-sm ',
                        item === selectedStage && 'border-b-2 border-light py-1.5'
                      )}>
                      {translate(DICTIONARY_TYPES.SETTLEMENT_STAGE, item)}
                    </button>
                  )
                );
              })}
          </div>
        </Box>

        <Box className="flex items-center justify-between">
          <div>
            <Header size="lg" weight="bold">
              {stageNames[selectedStage]}
            </Header>
            {Boolean(nextStages?.length) && (
              <Text className="text-secondary">Pozostało kroków: {nextStages?.length}</Text>
            )}
          </div>

          <div className="flex space-x-4">
            {isStageCancellable(selectedStage) && (
              <Button
                isDisabled={isCancelLoading}
                onPress={() => mutate()}
                variant="outline-primary">
                Anuluj rozliczenie
              </Button>
            )}

            {currentStage !== 'ACCEPTED' && (
              <Button
                className="flex space-x-2"
                isDisabled={isSettlementLoading}
                onPress={() =>
                  currentStage === selectedStage
                    ? mutateProcessSettlement()
                    : setSelectedStage(currentStage)
                }>
                {isSettlementLoading && <Loader className="h-4 w-4 !border-white" />}
                <span>
                  {selectedStage === 'FINAL_ACCEPTATION'
                    ? 'Zakończ'
                    : currentStage === selectedStage
                    ? 'Kontynuuj'
                    : 'Wróć do aktualnego kroku'}
                </span>
              </Button>
            )}
          </div>
        </Box>

        {isCancelError && <ErrorMessages error={cancelError} />}

        <StageComponent />
      </>
    );
  }
  if (isError)
    return <Alert type="error">Nieoczekiwany krok. Skontaktuj się z administratorem.</Alert>;

  return <></>;
};

export default Stages;
