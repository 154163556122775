import { InputField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { transactionTypeOptions } from 'constants/payment';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { addAgentPayout } from 'services/agent';
import { TransactionType } from 'types/advancePayment';
import { AddAgentPayoutPayload } from 'types/agent';

type Props = {
  visible: boolean;
  closeModal: () => void;
  agentId: number;
  refetch: () => void;
};

const validationSchema = Yup.object({
  transactionType: Yup.mixed().oneOf(Object.values(TransactionType)).required(requiredFieldMessage),
  grossValue: Yup.number().min(0).required(requiredFieldMessage),
  description: Yup.string().required(requiredFieldMessage),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const AddPayoutHistoryRecordModal = ({ visible, closeModal, agentId, refetch }: Props) => {
  const { activeDictionaries } = useDictionaryContext();

  const initialValues = {
    transactionType: TransactionType.PAYMENT,
    grossValue: 0,
    description: '',
  };

  const handleSubmit = (formValues: FormValues) => {
    mutate({
      agentId,
      payload: {
        grossValue:
          formValues.transactionType === TransactionType.PAYOUT
            ? -formValues.grossValue
            : formValues.grossValue,
        description: formValues.description,
      },
    });
  };

  const { isLoading, isError, error, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { agentId: number; payload: AddAgentPayoutPayload }
  >(({ agentId, payload }) => addAgentPayout(agentId, payload), {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });

  const descriptionOptions = useMemo(
    () =>
      activeDictionaries?.[DICTIONARY_TYPES.OTHER_PAYMENT_DESCRIPTION]?.map(({ value }) => {
        return { key: value, value };
      }) ?? [{ key: '', value: '' }],
    [activeDictionaries]
  );

  return (
    <Modal visible={visible} title="Dodaj wpłatę/wypłatę zewn.">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        <Form className="space-y-4">
          <SelectField
            options={transactionTypeOptions}
            name="transactionType"
            label="Typ transakcji"
          />
          <InputField type="number" name="grossValue" label="Kwota" placeholder="Kwota" />
          <SelectField options={descriptionOptions ?? []} name="description" label="Opis" />

          <ModalActions onCancel={() => closeModal()} isLoading={isLoading} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddPayoutHistoryRecordModal;
