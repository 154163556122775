import { Balance } from 'types/agent';
import { commissionmodApi } from 'utils/axios';

export const getBalance = async (period: string) => {
  const {
    data: { balance },
  } = await commissionmodApi.get<Balance>('/balance', {
    params: {
      period,
    },
  });

  return balance;
};
