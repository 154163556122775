import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { deleteInsuranceCompany } from 'services/agent';
import { InsuranceCompany } from 'types/agent';

import useAgentId from '../../../useAgentId';

type Props = {
  refetch: () => void;
};

const DeleteInsuranceCompanyModal = ({ refetch }: Props) => {
  const agentId = useAgentId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const insuranceCompany: InsuranceCompany = state?.insuranceCompany;

  const { error, isError, isLoading, mutate } = useMutation<AxiosResponse, AxiosError>(
    () => deleteInsuranceCompany(agentId, insuranceCompany?.id),
    {
      onSuccess: () => {
        refetch();
        navigate('..');
      },
    }
  );

  useEffect(() => {
    if (!insuranceCompany) {
      navigate('..');
    }
  }, [insuranceCompany, navigate]);

  return (
    <Modal title="Potwierdź usunięcie" visible>
      <Text>Czy na pewno chcesz usunąć?</Text>
      <ModalActions
        confirmLabel="Zatwierdź"
        isLoading={isLoading}
        onCancel={() => navigate('..')}
        onConfirm={() => mutate()}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default DeleteInsuranceCompanyModal;
