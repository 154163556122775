import { Button, Icon } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { getAgentName } from 'helpers/agents';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { AgentRelationStructureRecord } from 'types/structure';

export const columns: Array<Column<AgentRelationStructureRecord>> = [
  {
    id: 'expander',
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
      </span>
    ),
    Cell: ({ row }: Cell<AgentRelationStructureRecord>) =>
      row.original.child?.positions?.length && row.original.level <= 2 ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      ) : null,
  },
  {
    accessor: 'level',
    Header: 'Relacja bezpośrednia',
    Cell: ({ value }) => (value > 2 ? 'Nie' : 'Tak'),
  },
  {
    id: 'commission_schema',
    accessor: 'commissionSchema',
    Header: 'Schemat prowizyjny',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_SCHEMA_NAME', String(value)) : '';
    },
  },
  {
    id: 'child.id',
    accessor: (row) => row.child?.agentCode,
    Header: 'Kod podrzędnego',
  },
  {
    id: 'child',
    accessor: (row) => getAgentName(row?.child?.economyActivities),
    Header: 'Kontrahent podrzędny',
  },
  {
    id: 'child.position',
    accessor: (row) =>
      row?.child?.positions.map(({ positionDictionaryValue }) => (
        <div>{positionDictionaryValue}</div>
      )),
    Header: 'Stanowisko podrzędnego',
    disableSortBy: true,
  },
  {
    id: 'parent.id',
    accessor: (row) => row?.parent?.agentCode,
    Header: 'Kod nadrzędnego',
  },
  {
    id: 'parent',
    accessor: (row) => getAgentName(row?.parent?.economyActivities),
    Header: 'Kontrahent nadrzędny',
  },
  {
    accessor: (row) =>
      row?.parent?.positions.map(({ positionDictionaryValue }) => (
        <div>{positionDictionaryValue}</div>
      )),
    Header: 'Stanowisko nadrzędnego',
    disableSortBy: true,
  },
  {
    accessor: 'delegated',
    Header: 'Delegacja',
    Cell: ({ value }) => (value ? 'Tak' : 'Nie'),
  },
  {
    id: 'valid_from',
    accessor: 'validFrom',
    Header: 'Data od',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0]}</span>,
  },
  {
    id: 'valid_to',
    accessor: 'validTo',
    Header: 'Data do',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0]}</span>,
  },

  {
    id: 'operations',
    Header: 'Akcje',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<AgentRelationStructureRecord>) => {
      const navigate = useNavigate();

      if (original?.level <= 2 && !original?.delegated)
        return (
          <div className="flex space-x-2">
            <Button
              onPress={() =>
                navigate('changeSubordinationPeriod', {
                  state: original,
                })
              }
              size="sm"
              variant="light">
              Edytuj daty
            </Button>

            <Button
              onPress={() =>
                navigate('addPosition', {
                  state: original,
                })
              }
              size="sm"
              variant="light">
              Dodaj stanowisko
            </Button>

            <Button
              onPress={() =>
                navigate('removeAgentFromSchema', {
                  state: original,
                })
              }
              size="sm"
              variant="light">
              Zakończ
            </Button>
          </div>
        );
      else return <div></div>;
    },
  },
];
