import { Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { groupBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Row } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { getAgentRates } from 'services/commission';
import { AgentCommissionConditionRate } from 'types/commission';
import { AgentRelationStructureRecord } from 'types/structure';

import CommissionTable from '../CommissionTable';
import { createColumns } from './columns';

type Props = {
  row: Row<AgentRelationStructureRecord>;
  filterDate: Date;
  runCommissionsRefetch: boolean;
  setRunCommissionsRefetch: (runCommissionsRefetch: boolean) => void;
};

const PositionTable = ({
  row,
  filterDate,
  runCommissionsRefetch,
  setRunCommissionsRefetch,
}: Props) => {
  const agent = useMemo(() => row.original.child || {}, [row.original.child]);
  const positions = agent?.positions || [];

  const {
    data = [],
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery<Array<AgentCommissionConditionRate>, AxiosError>(
    [
      COMMISSION.COMMISSION_AGENT_RATES,
      row.original.commissionSchema,
      agent?.id,
      format(filterDate, 'dd-MM-yyyy 00:00:00'),
    ],
    () =>
      getAgentRates(
        row.original.commissionSchema,
        agent?.id,
        format(filterDate, 'dd-MM-yyyy 00:00:00')
      )
  );

  useEffect(() => {
    if (runCommissionsRefetch) {
      refetch();
      setRunCommissionsRefetch(false);
    }
  }, [runCommissionsRefetch, setRunCommissionsRefetch, refetch]);

  const map = useMemo(() => groupBy(data, 'employeePosition') || {}, [data]);
  const columns = useMemo(() => createColumns(row.original), [row.original]);

  return (
    <Table
      columns={columns}
      data={positions}
      isExpandable
      renderRowSubComponent={({ row }) => {
        const conditions = map[row.original.positionDictionaryKey] || [];

        return (
          <div className="pl-20">
            {isError && <ErrorMessages error={error} />}
            {isLoading && <Loader className="h-12 w-12" />}
            {isSuccess && <CommissionTable data={conditions} />}
          </div>
        );
      }}
    />
  );
};

export default PositionTable;
