import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { DICTIONARY } from 'constants/queries/dictionary';
import { getDictionaries } from 'services/dictionary';
import { DictionaryTypes } from 'types/dictionary';
import { Roles } from 'types/user';

import useAgentRoleContext from './useAgentRoleContext';
import { useDictionariesHashQuery } from './useDictionariesHashQuery';

export type Options = Partial<{
  fallback: string;
}>;

type State = {
  translate: (dictionaryName: string, key: string, options?: Options) => string;
  refetch: () => void;

  dictionaries?: DictionaryTypes;
  activeDictionaries?: DictionaryTypes;
};

const DictionaryContext = createContext<State>({
  translate: () => '',
  refetch: () => void 0,
});

type Props = {
  children: ReactNode;
};

const processDictionaries = (dictionaries: DictionaryTypes) =>
  Object.fromEntries(
    Object.entries(dictionaries).map(([dictionaryName, items]) => [
      dictionaryName,
      Object.fromEntries(items.map(({ key, value }) => [key, value])),
    ])
  );

export const DictionaryContextProvider = ({ children }: Props) => {
  const [currentDictionariesHash, setCurrentDictionariesHash] = useState<number>();
  const dictionariesHash = useDictionariesHashQuery();
  const { hasRole } = useAgentRoleContext();

  const hasRequiredRoles = useMemo(
    () => hasRole(Roles.ADMIN) || hasRole(Roles.COMMISSION || hasRole(Roles.RECORDS_MANAGEMENT)),
    []
  );

  const { data = {}, refetch } = useQuery(DICTIONARY.DICTIONARIES, getDictionaries, {
    enabled: hasRequiredRoles,
  });

  useMemo(() => {
    if (dictionariesHash && dictionariesHash !== currentDictionariesHash) {
      setCurrentDictionariesHash(dictionariesHash);
      refetch();
    }
  }, [dictionariesHash, currentDictionariesHash, setCurrentDictionariesHash, refetch]);

  const activeDictionaries = useMemo(() => {
    if (data) {
      const dictionaryTypes = { ...data };

      Object.keys(dictionaryTypes)?.forEach((key) => {
        if (Array.isArray(dictionaryTypes[key])) {
          dictionaryTypes[key] = dictionaryTypes[key].filter((dict) => !dict.hidden);
        }
      });

      return dictionaryTypes;
    }
  }, [data]);

  const map = useMemo(() => processDictionaries(data), [data]);

  const translate = (
    dictionaryName: string,
    key: string,
    { fallback = `${key} (Missing translation)` }: Options = {}
  ) => map[dictionaryName]?.[key] ?? fallback;

  return (
    <DictionaryContext.Provider
      value={{
        dictionaries: data,
        activeDictionaries,
        refetch,
        translate,
      }}>
      {children}
    </DictionaryContext.Provider>
  );
};

const useDictionaryContext = () => useContext(DictionaryContext);

export default useDictionaryContext;
