import { Button } from '@profitowi/component-library';
import clsx from 'clsx';
import { parse } from 'date-fns';
import { Cell, Column } from 'react-table';
import ReactTooltip from 'react-tooltip';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { FileFromBackend, FileStatus } from 'types/file';

export const createColumns: (
  onFileDownload: (file: FileFromBackend) => void
) => Array<Column<FileFromBackend>> = (onFileDownload) => {
  return [
    {
      Header: 'Data wygenerowania',
      accessor: 'createdAt',
    },
    {
      Header: 'Typ',
      Cell: ({
        row: {
          original: { fileType },
        },
      }: Cell<FileFromBackend>) => {
        const { translate } = useDictionaryContext();

        return fileType ? translate(DICTIONARY_TYPES.FILE_TYPE, fileType) : '';
      },
    },
    {
      Header: 'Typ rozliczenia',
      Cell: ({
        row: {
          original: { fileParams },
        },
      }: Cell<FileFromBackend>) => {
        return fileParams?.SETTLEMENT_TYPE || '';
      },
    },
    {
      Header: 'Rozliczenie',
      Cell: ({
        row: {
          original: { fileParams },
        },
      }: Cell<FileFromBackend>) => {
        return fileParams?.SETTLEMENT_SIGNATURE || '';
      },
    },
    {
      Header: 'Status',
      Cell: ({
        row: {
          original: { status },
        },
      }: Cell<FileFromBackend>) => {
        const { translate } = useDictionaryContext();

        return status ? translate(DICTIONARY_TYPES.FILE_STATUS, status) : '';
      },
    },
    {
      Header: 'Komentarz',
      accessor: 'description',
      Cell: ({
        row: {
          original: { description },
        },
      }: Cell<FileFromBackend>) => {
        return (
          <>
            <ReactTooltip />
            <p
              className="text-ellipsis overflow-hidden w-28 whitespace-nowrap"
              data-tip={description}>
              {description}
            </p>
          </>
        );
      },
    },
    {
      id: 'operations',
      Cell: ({ row: { original } }: Cell<FileFromBackend>) => {
        const isFinished = original.status === FileStatus.FINISHED;

        return (
          <Button
            variant="light"
            onPress={() => onFileDownload(original)}
            className="flex gap-4 w-full items-center">
            <i className={clsx('bi cursor-pointer', isFinished ? 'bi-download' : 'bi-gear')} />
            <span>{isFinished ? 'Pobierz' : 'Wygeneruj'}</span>
          </Button>
        );
      },
    },
  ];
};
