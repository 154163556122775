import { Alert, InputField, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { ModalActions } from 'components';
import { uploadClientDataFile } from 'services/file';
import { CommissionOtherImportPayload } from 'types/commission';

const validationSchema = yup.object({
  file: yup.mixed().required('Plik jest wymagany'),
  sheetName: yup.string(),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  refetch: () => void;
};

const CommissionOtherImportForm = ({ setVisible, refetch }: Props) => {
  const onChange = (form: any, e: any) => {
    form.setFieldValue('file', e.target.files[0]);
  };

  const { mutate, isLoading, isError, error } = useMutation<
    AxiosResponse,
    AxiosError,
    CommissionOtherImportPayload
  >((payload) => uploadClientDataFile(payload), {
    onSuccess: () => {
      refetch();
      setVisible(false);
    },
  });

  const onSubmit = (values: FormValues) => {
    mutate({ ...values });
  };

  return (
    <Formik
      initialValues={{
        file: {},
        sheetName: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ errors, touched }) => (
        <Form className="space-y-4">
          <InputField label="Nazwa arkusza" name="sheetName" />

          <div>
            <Field name="file">
              {({ form }: any) => (
                <input type="file" name="file" onChange={(e) => onChange(form, e)} />
              )}
            </Field>

            {errors.file && touched.file ? (
              <Text size="sm" className="text-red-600">
                {errors.file}
              </Text>
            ) : null}
          </div>

          <ModalActions
            confirmLabel="Załaduj Plik"
            onCancel={() => setVisible(false)}
            isLoading={isLoading}
          />
          {isError ? (
            Array.isArray(error?.response?.data?.errors) ? (
              <Alert type="error">
                {error?.response?.data?.errors.map((error: string) => {
                  const regex = /validation.(.*).duplicate/;

                  return (
                    <p key={error}>
                      Wystąpił duplikat dla rekordu: {regex.exec(error)?.[1] || error}
                    </p>
                  );
                })}
              </Alert>
            ) : (
              <Alert type="error">{error?.message}</Alert>
            )
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default CommissionOtherImportForm;
