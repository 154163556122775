import { SelectField, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { ImportValidation, getCommissionSchemas, importCommission } from 'services/commission';
import { CommissionImportPayload, CommissionSchemaList } from 'types/commission';

const validationSchema = yup.object({
  commissionSchemaId: yup.string().required(requiredFieldMessage),
  file: yup.mixed().required(requiredFieldMessage),
  insuranceCompany: yup.string().required(requiredFieldMessage),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  visible?: boolean;
  setCommissionSchemaId: (value: number) => void;
  setStep: (step: number) => void;
  onSave?: (payload: CommissionImportPayload) => void;
  setVisible?: (value: boolean) => void;
};

const CommissionImportForm = ({ setCommissionSchemaId, setStep }: Props) => {
  const navigate = useNavigate();
  const { activeDictionaries } = useDictionaryContext();

  const {
    data: commissions,
    error: commissionsError,
    isError: commissionsIsError,
  } = useQuery<CommissionSchemaList, AxiosError>([COMMISSION.COMMISSION_SCHEMA], () =>
    getCommissionSchemas(0, 1000)
  );

  const createCommissionImports = useMutation<
    AxiosResponse<ImportValidation>,
    AxiosError,
    CommissionImportPayload
  >(
    ({ file, commissionSchemaId, insuranceCompany }) =>
      importCommission(file, commissionSchemaId, insuranceCompany),
    {
      onSuccess: ({ data }) => {
        setCommissionSchemaId(data.id);
        setStep(2);
      },
    }
  );

  const commissionOptions = useMemo(
    () =>
      commissions?.content.map((item) => ({
        value: item.schemaName,
        key: item.commissionSchemaId.toString(),
      })),
    [commissions]
  );

  const insuranceCompanies = useMemo(
    () => activeDictionaries?.INSURANCE_COMPANY || [],
    [activeDictionaries?.INSURANCE_COMPANY]
  );

  const onChange = (form: any, e: any) => {
    form.setFieldValue('file', e.target.files[0]);
  };

  const onSubmit = (values: FormValues) => {
    const formData = new FormData();
    formData.append('file', values.file);

    const payload = {
      ...values,
      commissionSchemaId: parseInt(values.commissionSchemaId),
      file: formData,
    };

    createCommissionImports.mutate(payload);
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        commissionSchemaId: '',
        file: null,
        insuranceCompany: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ errors, touched }) => (
        <Form className="space-y-4">
          <SelectField
            label="Schemat prowizyjny"
            name="commissionSchemaId"
            options={commissionOptions ? commissionOptions : []}
          />

          <SelectField
            label="Towarzystwo ubezpieczeniowe"
            name="insuranceCompany"
            options={insuranceCompanies}
          />

          <div>
            <Field name="file">
              {({ form }: any) => (
                <input type="file" name="file" onChange={(e) => onChange(form, e)} />
              )}
            </Field>

            {errors.file && touched.file ? (
              <Text size="sm" className="text-secondary-red">
                {errors.file}
              </Text>
            ) : null}
          </div>

          <ModalActions
            isLoading={createCommissionImports.isLoading}
            confirmLabel="Załaduj Plik"
            disableSubmit={!!Object.values(errors).length}
            onCancel={() => navigate('..')}
          />

          {commissionsIsError && <ErrorMessages error={commissionsError} />}
          {createCommissionImports.isError && (
            <ErrorMessages error={createCommissionImports.error} />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CommissionImportForm;
