import {
  Alert,
  DatePickerField,
  InputField,
  Modal,
  Option,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { generateVatRateOptions } from 'helpers/select';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { AssignCommissionRatePayload, assignCommissionRate } from 'services/commission';
import { Agent } from 'types/agent';

const validationSchema = Yup.object({
  validFrom: Yup.date().required(requiredFieldMessage).default(undefined).defined(),
  validTo: Yup.date().nullable(),
  employeePosition: Yup.string().required(requiredFieldMessage),
  commissionRate: Yup.number()
    .min(0, 'Minimalna wartość prowizji wynosi 0%.')
    .max(100, 'Maksymalna wartość prowizji wynosi 100%.')
    .required(requiredFieldMessage),
  condition: Yup.string().required(requiredFieldMessage),
  vatRate: Yup.string().required(requiredFieldMessage),
});

type FormValues = Yup.InferType<typeof validationSchema>;

type Props = {
  refetch: () => void;
};

const AddCommissionConditionModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { activeDictionaries, translate } = useDictionaryContext();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('..');
      refetch();
    }
  }, [state, navigate, refetch]);

  const {
    commissionSchemaId,
    subordinate,
    positionKey,
  }: { commissionSchemaId: number; subordinate: Agent; positionKey: string } = state;

  const initialValues: FormValues = {
    validFrom: undefined as unknown as Date,
    validTo: null,
    employeePosition: positionKey,
    commissionRate: 1,
    condition: '',
    vatRate: 'VAT_ZW',
  };

  const positions: Option[] = subordinate?.positions?.map((position) => {
    return {
      key: position.positionDictionaryKey,
      value: position.positionDictionaryValue,
    };
  });

  const vatRateOptions = useMemo(() => generateVatRateOptions(activeDictionaries) ?? [], []);

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    AssignCommissionRatePayload
  >((payload) => assignCommissionRate(commissionSchemaId, subordinate.id, payload), {
    onSuccess: () => {
      navigate('..');
      refetch();
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      ...values,
      vatRate:
        values.vatRate !== 'VAT_ZW'
          ? parseFloat(translate(DICTIONARY_TYPES.VAT_RATE, values.vatRate))
          : null,
      commissionRate: values.commissionRate / 100,
      validFrom: format(new Date(values.validFrom), 'dd-MM-yyyy HH:mm:ss'),
      validTo: values.validTo && format(new Date(values.validTo), 'dd-MM-yyyy HH:mm:ss'),
    });
  };

  return (
    <Modal title="Dodaj warunek" visible>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}>
        {({ values: { validFrom, validTo } }) => (
          <Form>
            <div className="flex justify-center items-center my-2">
              <p className="w-1/3">Data od - do</p>
              <div className="flex justify-center items-center w-2/3">
                <DatePickerField
                  preventOpenOnFocus
                  name="validFrom"
                  placeholder="Data od"
                  maxDate={validTo}
                />
                <p className="mx-2">-</p>
                <DatePickerField name="validTo" placeholder="Data do" minDate={validFrom} />
              </div>
            </div>
            <div className="flex justify-center items-center my-2">
              <p className="w-1/3">Stanowisko</p>
              <div className="w-2/3">
                <SelectField options={positions} name="employeePosition" placeholder="Stanowisko" />
              </div>
            </div>
            <div className="flex justify-center items-center my-2">
              <p className="w-1/3">Warunek prowizyjny</p>
              <div className="w-2/3 flex justify-center items-center gap-2">
                <div className="w-2/3">
                  <SelectField
                    options={activeDictionaries?.[DICTIONARY_TYPES.COMMISSION_CONDITION] || []}
                    name="condition"
                    placeholder="Program"
                  />
                </div>
                <div className="w-1/3 flex items-center justify-center">
                  <InputField
                    name="commissionRate"
                    placeholder="Prowizja"
                    className="w-3/4"
                    aria-label="Prowizja"
                    type="number"
                  />
                  <p className="w-1/4 ml-4">%</p>
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center my-2">
              <p className="w-1/3">Stawka Vat</p>
              <div className="w-2/3">
                <SelectField options={vatRateOptions} name="vatRate" placeholder="Stawka Vat" />
              </div>
            </div>

            <ModalActions
              confirmLabel="Zatwierdź"
              isLoading={isLoading}
              onCancel={() => navigate('..')}
            />
            {isError &&
              error?.response?.data.errors.map((err: string) => (
                <Alert className="mt-4" type="error">
                  {err === 'validation.exists_condition_in_period'
                    ? 'W wybranym okresie warunek już istnieje'
                    : err}
                </Alert>
              ))}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCommissionConditionModal;
