import { CheckboxGroup, CheckboxGroupField, Modal } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { EditRolesPayload, User } from 'types/user';

type Props = {
  editedItem: User;
  visible: boolean;
  editRoles?: AxiosError | null;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (payload: EditRolesPayload) => void;
  closeModal: () => void;
};

const AddUserForm = ({ editedItem, visible, editRoles, setVisible, onSave, closeModal }: Props) => {
  const { activeDictionaries } = useDictionaryContext();

  return (
    <Modal title={'Edytuj role'} visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={{
          roles: editedItem.roles || [],
        }}
        onSubmit={onSave}>
        <Form className="space-y-4">
          <CheckboxGroupField orientation="vertical" name="roles" label="Role">
            {activeDictionaries?.[DICTIONARY_TYPES.KEYCLOAK_ROLES]?.map((item, index) => (
              <CheckboxGroup.Checkbox key={index} value={item.key}>
                {item.value}
              </CheckboxGroup.Checkbox>
            ))}
          </CheckboxGroupField>
          <ModalActions onCancel={closeModal} />

          {editRoles && <ErrorMessages error={editRoles} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddUserForm;
