import { Icon } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { ChangeEvent } from 'react';
import { useMutation } from 'react-query';

import { putDecisions } from 'services/settlement';
import { BillingItemStatus, SettlementStepPayload } from 'types/settlement';

import useSettlement from '../../../useSettlement';

type Option = {
  key: BillingItemStatus;
  value: string;
};

const options: Option[] = [
  { key: 'ACCEPTED', value: 'Zaakceptowana' },
  { key: 'BLOCKED', value: 'Zablokowana' },
  { key: 'CANCELED', value: 'Anulowana' },
  { key: 'IGNORED', value: 'Pominięta' },
];

type Props = {
  items: number[];
  status: BillingItemStatus;
};

const StatusSelect = ({ items, status }: Props) => {
  const { settlementId = NaN } = useSettlement();
  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { payload: SettlementStepPayload[]; settlementId: number }
  >(({ settlementId, payload }) => putDecisions(settlementId, payload));

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const status = event.target.value as BillingItemStatus;
    const payload = items.map((itemId) => ({
      itemId,
      itemStatus: status,
    }));

    mutate({ payload, settlementId });
  };

  return (
    <div className="flex items-center space-x-2">
      <select defaultValue={status} disabled={isLoading} onChange={handleChange}>
        {options.map(({ key, value }) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
      {isError && (
        <div title={error?.message}>
          <Icon className="text-red-500" name="exclamation-triangle-fill" />
        </div>
      )}
    </div>
  );
};

export default StatusSelect;
