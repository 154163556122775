import {
  CreateGroupTransferPayload,
  Policy,
  PolicyWallet,
  Transfer,
  TransferPayload,
} from 'types/polices';
import { Page, Request } from 'types/request';
import { recordmodApi } from 'utils/axios';

interface Polices extends Request {
  content: Policy[];
}

interface Transfers extends Request {
  content: Transfer[];
}

export const getSplits = async (page: number, size: number, agentId: number, sort?: string) => {
  const { data } = await recordmodApi.get<Polices>(`/agents/policies/splits`, {
    params: { agentId, page, size, sort },
  });

  return data;
};

export const createPolicySplit = (payload: Policy) => {
  return recordmodApi.post('/agents/policies/splits', payload);
};

export const updatePolicySplit = (payload: Policy) => {
  const { id } = payload;

  return recordmodApi.put(`/agents/policies/splits/${id}`, payload);
};

export const cancelPolicySplit = (id: number) => {
  return recordmodApi.delete(`/agents/policies/splits/${id}`);
};

export const getTransfers = async (page: number, size: number, agentId: number, sort?: string) => {
  const { data } = await recordmodApi.get<Transfers>(`/agents/policies/transfers`, {
    params: { agentId, page, size, sort },
  });

  return data;
};

export const createPolicyTransfer = (payload: TransferPayload) => {
  return recordmodApi.post('/agents/policies/transfers', payload);
};

export const updatePolicyTransfer = (payload: Transfer) => {
  const { id } = payload;

  return recordmodApi.put(`/agents/policies/transfers/${id}`, payload);
};

export const cancelPolicyTransfer = (id: number) => {
  return recordmodApi.delete(`/agents/policies/transfers/${id}`);
};

export const getAgentWallet = async (agentId: number) => {
  const { data } = await recordmodApi.get<Page<PolicyWallet>>(
    `/agents/policies/wallets/${agentId}`
  );

  return data;
};

export const createGroupTransfer = (payload: CreateGroupTransferPayload) => {
  return recordmodApi.post('/agents/policies/transfers/wallet', payload);
};
