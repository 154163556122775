import { DatePickerField, InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { updateDebt } from 'services/debt';
import { Debt, Installment } from 'types/debt';
import { DictionaryTypes } from 'types/dictionary';

const ValidationSchema = Yup.object().shape({
  grossValue: Yup.number().required(requiredFieldMessage).max(0, 'Wartość nie możę być dodatnia'),
  paymentDate: Yup.string().required(requiredFieldMessage),
});

type Props = {
  debt: Debt;
  visible: boolean;
  dictionaryTypes: DictionaryTypes;
  editedInstallment: Installment;
  setVisible: (value: boolean) => void;
  refetch: () => void;
};

const DebtInstallmentForm = ({ visible, debt, editedInstallment, setVisible, refetch }: Props) => {
  const { agentId = '' } = useParams();

  const { error, isError, isLoading, mutate } = useMutation<AxiosResponse, AxiosError, Debt>(
    (payload) => updateDebt(payload, parseInt(agentId), payload.debtRepaymentId),
    {
      onSuccess: () => {
        setVisible(false);
        refetch();
      },
    }
  );

  const onSubmit = (installment: Installment) => {
    const { installments } = debt;
    const { debtRepaymentInstallmentId, paymentDate } = installment;
    const installmentPayments = [...installments];

    const index = installmentPayments.findIndex(
      (item) => item.debtRepaymentInstallmentId === debtRepaymentInstallmentId
    );

    if (index >= 0) {
      installmentPayments.splice(index, 1, {
        ...installment,
        paymentDate: format(startOfDay(new Date(paymentDate)), 'dd-MM-yyyy HH:mm:ss'),
      });

      const payload = {
        ...debt,
        installments: installmentPayments,
      };

      mutate(payload);
    }
  };

  const initialValues = {
    ...editedInstallment,
    paymentDate: parse(editedInstallment.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).toString(),
  };

  return (
    <Modal title="Edytuj ratę zadłużenia" visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        enableReinitialize>
        <Form className="space-y-4">
          <InputField name="grossValue" label="Wartość brutto" type="number" />

          <DatePickerField label="Planowana data spłaty" name="paymentDate" />

          <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default DebtInstallmentForm;
