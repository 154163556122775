import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { usePagination } from 'hooks/usePagination';
import { getRecommendation } from 'services/recommendation';
import { Recommendation } from 'types/recommendation';
import { Page } from 'types/request';
import { parseSortParams } from 'utils/sort';

import RecommendationDeleteModal from './RecommendationDeleteModal/RecommendationDeleteModal';
import RecommendationForm from './RecommendationForm/RecommendationForm';
import { columns } from './columns';

const Recommendations = () => {
  const agentId = useAgentId();
  const navigate = useNavigate();
  const pagination = usePagination(10);
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const {
    data: recommendations,
    isError: recommendationsIsError,
    isSuccess: recommendationsIsSuccess,
    error: recommendationsError,
    isLoading: recommendationsIsLoading,
    isFetching: recommendationsIsFetching,
    refetch,
  } = useQuery<Page<Recommendation>, AxiosError>(
    ['RECOMMENDATION', sortBy],
    () =>
      getRecommendation(
        pagination.currentPage,
        pagination.perPage,
        agentId,
        parseSortParams(sortBy)
      ),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Polecenia
          </Header>

          <Button onPress={() => navigate('addUpdate')} size="sm" variant="outline-primary">
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {recommendationsIsSuccess && recommendations && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={recommendations.content}
              pagination={pagination}
              totalPages={recommendations.totalPages}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              isLoading={recommendationsIsFetching}
            />
          </Box.FullWidth>
        )}
        {recommendationsIsError && <ErrorMessages error={recommendationsError} />}
        {recommendationsIsLoading && <Loader className="h-12 w-12" />}
      </Box>

      <Routes>
        <Route path="addUpdate" element={<RecommendationForm refetch={refetch} />} />
        <Route path="delete" element={<RecommendationDeleteModal refetch={refetch} />} />
      </Routes>
    </>
  );
};

export default Recommendations;
