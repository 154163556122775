import { Box, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import BackArrow from 'components/BackArrow/BackArrow';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getBonus } from 'services/agent';
import { Bonus, Payments } from 'types/bonus';

import PaymentForm from './PaymentsForm/PaymentForm';
import { CreateColumn } from './columns';

const AgentBonusPayments = () => {
  const [visible, setVisible] = useState(false);
  const [editedPayment, setEditedPayment] = useState<Payments>();
  const { activeDictionaries } = useDictionaryContext();
  const { bonusId = '', agentId = '' } = useParams();

  const { data, refetch, isError, isLoading, isSuccess, error, isFetching } = useQuery<
    Bonus & {
      payments: Payments[];
    },
    AxiosError
  >([AGENT.AGENT_BONUS, bonusId], () => getBonus(parseInt(agentId), parseInt(bonusId)), {
    keepPreviousData: true,
  });

  const editPayment = (payment: Payments) => {
    setEditedPayment(payment);
    setVisible(true);
  };

  return (
    <>
      <div className="flex-1 p-8 space-y-6 overflow-hidden">
        <Header as="h1" size="xl" weight="semibold">
          Premie
        </Header>
        <BackArrow />

        <Box className="space-y-6">
          <Header as="h4" size="lg" weight="semibold">
            Lista Płatności
          </Header>

          {isError && <ErrorMessages error={error} />}
          {isLoading && <Loader className="h-12 w-12" />}
          {isSuccess && (
            <Box.FullWidth>
              <Table
                data={data ? data.payments : []}
                columns={CreateColumn(editPayment)}
                isLoading={isFetching}
                manualSortBy={false}
              />
            </Box.FullWidth>
          )}
        </Box>
      </div>

      {visible && editedPayment && (
        <PaymentForm
          visible={visible}
          dictionaryTypes={activeDictionaries}
          editedPayment={editedPayment}
          setVisible={setVisible}
          refetch={refetch}
          payments={data ? data.payments : []}
        />
      )}
    </>
  );
};

export default AgentBonusPayments;
