import { GenerateReportPayload, ReportConfig, ReportType, Reports } from 'types/reports';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getReports = async (page: number, size: number, sort?: string) => {
  const { data } = await recordmodApi.get<Page<Reports>>('/reports', {
    params: { page, size, sort },
  });

  return data;
};

export const getReportsConfig = async (reportType?: ReportType) => {
  const { data } = await recordmodApi.get<ReportConfig[]>('/reports/config', {
    params: { reportType },
  });

  return data;
};

export const getReportFileStatus = async (id?: number) => {
  const { data } = await recordmodApi.get<string>(`/reports/${id}/file/status`);

  return data;
};

export const getReportFile = async (id?: number) => {
  const { data } = await recordmodApi.get<Blob>(`/reports/${id}/file`, { responseType: 'blob' });

  return data;
};

export const generateReport = async (payload: GenerateReportPayload[], reportType?: string) => {
  const data = await recordmodApi.post<Reports>(`/reports`, payload, { params: { reportType } });

  return data;
};
