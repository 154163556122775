import { Dictionary, DictionaryPayload, DictionaryTypes } from 'types/dictionary';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getDictionary = async (
  type: string | number,
  page: number,
  size: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<Dictionary>>(`/dictionaries/${type}`, {
    params: { page, size, sort },
  });

  return data;
};

export const getDictionaries = async () => {
  const { data } = await recordmodApi.get<DictionaryTypes>('/dictionaries');

  return data;
};

export const getDictionariesHash = async () => {
  const { data } = await recordmodApi.get<number>('/dictionaries/hash');

  return data;
};

export const addDictionaryEntry = (payload: DictionaryPayload, dictionaryType: string) => {
  return recordmodApi.post(`/dictionaries/${dictionaryType}`, payload);
};

export const updateDictionaryEntry = (
  payload: DictionaryPayload,
  id: number,
  dictionaryType: string
) => {
  return recordmodApi.put(`/dictionaries/${dictionaryType}/${id}`, payload);
};
