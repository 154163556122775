import { DatePickerField, Modal } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createSettlement } from 'services/settlement';
import { SettlementPayload } from 'types/settlement';

const initialValues = {
  documentCreationDate: undefined as unknown as Date,
  period: undefined as unknown as Date,
};

const validationSchema = yup.object({
  documentCreationDate: yup.date().defined().typeError(requiredFieldMessage),
  period: yup.date().defined().typeError(requiredFieldMessage),
});

type FormValues = yup.InferType<typeof validationSchema>;

const CreateSettlementModal = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const title = state?.title;
  const settlementType = state?.settlementType;

  useEffect(() => {
    if (!title || !settlementType) {
      navigate('..');
    }
  }, [navigate, settlementType, title]);

  const { error, isError, isLoading, mutate } = useMutation<number, AxiosError, SettlementPayload>(
    (payload) => createSettlement(payload),
    {
      onSuccess: (settlementId) => {
        navigate(`../${settlementId}`, { state: { isContinued: false } });
      },
    }
  );

  const onSubmit = ({ period, documentCreationDate }: FormValues) =>
    mutate({
      period: format(new Date(period), 'LLLL yyyy', { locale: pl }),
      documentCreationDate: format(new Date(documentCreationDate), 'dd-MM-yyyy'),
      settlementType,
    });

  return (
    <Modal title={title} visible>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form className="space-y-4">
          <DatePickerField
            preventOpenOnFocus
            dateFormat="LLLL yyyy"
            label="Okres rozliczeniowy"
            name="period"
            showFullMonthYearPicker
            showMonthYearPicker
          />

          <DatePickerField
            name="documentCreationDate"
            label="Data wystawienia dokumentów księgowych"
          />

          <ModalActions isLoading={isLoading} onCancel={() => navigate('..')} />

          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};
export default CreateSettlementModal;
