import { SortingRule } from 'react-table';

export const getSortParam = (sortBy: Array<SortingRule<any>>) => {
  if (sortBy.length === 1) {
    switch (sortBy[0].id) {
      case 'referred':
        return [
          {
            id: 'rdea.lastName',
            desc: sortBy[0].desc,
          },
          {
            id: 'rdea.name',
            desc: sortBy[0].desc,
          },
        ];
      case 'Kontrahent':
        return [
          {
            id: 'ea.lastName',
            desc: sortBy[0].desc,
          },
          {
            id: 'ea.name',
            desc: sortBy[0].desc,
          },
        ];
      case 'agentName':
        return [
          {
            id: 'caea.lastName',
            desc: sortBy[0].desc,
          },
          {
            id: 'caea.name',
            desc: sortBy[0].desc,
          },
        ];
    }
  }
  return sortBy;
};

export const parseSortParams = (sortBy: Array<SortingRule<any>>) => {
  const params = getSortParam(sortBy);
  return params.map((sortObj) => `${sortObj.id},${sortObj.desc ? 'desc' : 'asc'}`);
};
