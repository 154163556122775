import { Header } from '@profitowi/component-library';
import { Route, Routes } from 'react-router-dom';

import NavigationToolbar from 'components/NavigationToolbar/NavigationToolbar';

import { Agents } from './components/Agents/Agents';
import Delegations from './components/Delegations/Delegations';
import SalesStructure from './components/SalesStructure';

const Records = () => {
  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <Header as="h1" size="xl" weight="semibold">
        Ewidencje
      </Header>

      <NavigationToolbar
        rootPath="/records"
        tabs={[
          { displayName: 'Kontrahenci', route: '/' },
          { displayName: 'Struktura sprzedaży', route: 'sales-structure' },
          { displayName: 'Delegacje', route: 'delegations' },
        ]}
      />

      <Routes>
        <Route path="/*" element={<Agents />} />
        <Route path="sales-structure/*" element={<SalesStructure />} />
        <Route path="delegations/*" element={<Delegations />} />
      </Routes>
    </div>
  );
};

export default Records;
