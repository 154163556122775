import {
  Box,
  Button,
  Header,
  Loader,
  Option,
  Select,
  Table,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { DICTIONARY } from 'constants/queries/dictionary';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { getDictionary } from 'services/dictionary';
import { Dictionary, DictionaryPayload } from 'types/dictionary';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import DictionaryForm from '../common/componets/DictionaryForm/DictionaryForm';
import useCreateDictionary from '../common/hooks/useCreateDictionary';
import useUpdateDictionary from '../common/hooks/useUpdateDictionary';
import { createColumn } from './columns';

const Dictionaries = () => {
  const [selectedType, setSelectedType] = useState<Option>();
  const [editedItem, setEditedItem] = useState<Dictionary>();
  const [modalVisible, setModalVisible] = useState(false);
  const [dictionaryTypeOptions, setOptions] = useState<Option[]>([]);
  const pagination = usePagination(10);
  const { dictionaries: dictionaryTypes, refetch } = useDictionaryContext();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { currentPage, perPage } = pagination;

  const {
    data: dictionaries,
    isFetched: dictionaryIsFetched,
    refetch: refetchDictionary,
    isFetching: dictionaryIsFetching,
    error: dictionariesError,
  } = useQuery<Page<Dictionary> | undefined, AxiosError>(
    [DICTIONARY.DICTIONARY, selectedType, currentPage, perPage, sortBy],
    () => {
      if (selectedType && pagination.perPage && typeof selectedType.key === 'string')
        return getDictionary(selectedType.key, currentPage, perPage, decodeUriSortParams(sortBy));
    }
  );

  useEffect(() => {
    if (dictionaryTypes) {
      !selectedType && setSelectedType(dictionaryTypes.DICTIONARY_TYPE[0]);
      setOptions(
        dictionaryTypes.DICTIONARY_TYPE.filter(
          (i) => i.key !== DICTIONARY_TYPES.APPLICATION_CONFIGURATION
        )
      );
    }
  }, [dictionaryTypes]);

  const closeModal = () => {
    const isError = createDictionary.error || updateDictionary.error;

    isError && editedItem ? updateDictionary.reset() : createDictionary.reset();
    setModalVisible(false);
    setEditedItem(undefined);
  };

  const createDictionary = useCreateDictionary(refetchDictionary, refetch, closeModal);
  const updateDictionary = useUpdateDictionary(refetchDictionary, refetch, closeModal);

  const editDictionary = (dictionary: Dictionary) => {
    setEditedItem(dictionary);
    setModalVisible(true);
  };

  const onSave = (payload: DictionaryPayload, officeId?: number) => {
    if (selectedType) {
      const { key } = selectedType;
      const data = officeId ? { ...payload, parentDictionaryId: officeId } : payload;

      if (editedItem) {
        const { id } = editedItem;
        updateDictionary.mutate({ dictionary: data, id, type: key as string });
      } else {
        createDictionary.mutate({ dictionary: data, type: key as string });
      }
    }
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <div className="space-y-4">
            <Header as="h4" size="lg" weight="semibold">
              Słowniki
            </Header>

            <Select
              options={dictionaryTypeOptions}
              selected={selectedType}
              setSelected={setSelectedType}
            />
          </div>
          <div className="flex space-x-4 items-center">
            <Button
              variant="outline-primary"
              className="px-4 py-1"
              onPress={() => setModalVisible(true)}>
              <Text weight="semibold">
                Dodaj
                <i className="bi bi-plus-lg ml-2"></i>
              </Text>
            </Button>
          </div>
        </div>

        {dictionariesError && <ErrorMessages error={dictionariesError} />}
        {dictionaries && dictionaryIsFetched && dictionaryTypes ? (
          <Box.FullWidth>
            <Table
              pagination={pagination}
              totalPages={dictionaries.totalPages}
              data={dictionaries.content}
              columns={createColumn(dictionaryTypes.DICTIONARY_TYPE, editDictionary)}
              isLoading={dictionaryIsFetching}
              sortBy={sortBy}
              onSortBy={handleSortBy}
            />
          </Box.FullWidth>
        ) : (
          <Loader className="h-12 w-12" />
        )}
      </Box>

      <DictionaryForm
        title={editedItem ? 'Edytuj pozycję słownika' : 'Dodaj pozycję słownika'}
        visible={modalVisible}
        setVisible={setModalVisible}
        onSave={onSave}
        closeModal={closeModal}
        editedItem={editedItem}
        dictionaryError={createDictionary.error ?? updateDictionary.error}
        selectedType={selectedType?.key}
      />
    </>
  );
};

export default Dictionaries;
