import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { Subordinations } from 'types/structureHistory';

export const columns: Column<Subordinations>[] = [
  {
    id: 'id',
    Cell: ({ row: { id } }: Cell) => Number.parseInt(id) + 1,
  },
  {
    accessor: 'commissionSchema',
    Header: 'Schemat prowizyjny',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_SCHEMA_NAME', String(value)) : '';
    },
  },
  {
    id: 'child.id',
    Header: 'Kod podrzędnego',
    accessor: 'child',
    Cell: ({ value: { agentCode } }) => {
      if (!agentCode) return <></>;
      return <>{agentCode}</>;
    },
  },
  {
    Header: 'Kontrahent podrzędny',
    accessor: 'child',
    Cell: ({ value: { economyActivities } }) => {
      if (!economyActivities[0]) return <></>;
      const { name = '', middleName = '', lastName = '' } = economyActivities[0];
      return (
        <>
          {name} {middleName} {lastName}
        </>
      );
    },
  },
  {
    id: 'parent.id',
    Header: 'Kod nadrzędnego',
    accessor: 'parent',
    Cell: ({ value: { agentCode } }) => {
      if (!agentCode) return <></>;
      return <>{agentCode}</>;
    },
  },
  {
    Header: 'Kontrahent nadrzędny',
    accessor: 'parent',
    Cell: ({ value: { economyActivities } }) => {
      if (!economyActivities[0]) return <></>;
      const { name = '', middleName = '', lastName = '' } = economyActivities[0];
      return (
        <>
          {name} {middleName} {lastName}
        </>
      );
    },
  },
  {
    Header: 'Data od',
    accessor: 'validFrom',
  },
  {
    Header: 'Data do',
    accessor: 'validTo',
  },
  {
    Header: 'Aktualne',
    disableSortBy: true,
    accessor: 'valid',
    Cell: ({ value }) => <p>{value === true ? 'TAK' : 'NIE'}</p>,
  },
];
