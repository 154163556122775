import * as Yup from 'yup';

import { requiredFieldMessage } from './../../../../../constants/requiredFieldMessage';

export const addresses = Yup.object({
  street: Yup.string().required(requiredFieldMessage).default(''),
  houseNumber: Yup.string().required(requiredFieldMessage).default(''),
  apartmentNumber: Yup.string().default('').nullable(),
  postalCode: Yup.string().required(requiredFieldMessage).default(''),
  city: Yup.string().required(requiredFieldMessage).default(''),
  type: Yup.string().required(requiredFieldMessage).default(''),
});

export const IndividualAgentSchema = Yup.object({
  homeAddress: addresses,
  correspondenceAddress: addresses,
});

export const CompanySchema = Yup.object({
  homeAddress: addresses,
  correspondenceAddress: addresses,
  billingAddress: addresses,
});
