import { Alert } from '@profitowi/component-library';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AgentInfo from './AgentInfo';
import { AgentContext } from './useAgentId';

const Agent = () => {
  const { id: paramId = '' } = useParams();
  const agentId = useMemo(() => {
    const id = Number.parseInt(paramId, 10);

    if (Number.isNaN(id) || Math.sign(id) !== 1) {
      return NaN;
    }

    return id;
  }, [paramId]);

  if (agentId) {
    return (
      <AgentContext.Provider value={agentId}>
        <AgentInfo />
      </AgentContext.Provider>
    );
  }

  return <Alert type="error">Nieprawidłowy identyfikator kontrahenta.</Alert>;
};

export default Agent;
