import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { editUser } from 'services/user';
import { EditUserPayload } from 'types/user';

export const useEditUser = (userId: string, refetch: Function, closeModal: Function) => {
  return useMutation<AxiosResponse, AxiosError, EditUserPayload>(
    (payload) => editUser(payload, userId),
    {
      onSuccess: () => {
        closeModal();
        setTimeout(refetch, 500);
      },
    }
  );
};

export default useEditUser;
