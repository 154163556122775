export const isValidAgentCode = (agentCode: any, optional: boolean = false): boolean => {
  if (!agentCode && optional) {
    return true;
  }

  if (typeof agentCode !== 'string') {
    return false;
  }

  return /^PR[0-9]{4}$/.test(agentCode);
};
