export const PAYMENT_STATUS_TYPES = {
  CANCELLED: 'CANCELLED',
  CANCELLED_PAYED: 'CANCELLED_PAYED',
  TO_PAY: 'TO_PAY',
  ON_HOLD: 'ON_HOLD',
  PAYED: 'PAYED',
  SETTLED: 'SETTLED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  TO_REPAY: 'TO_REPAY',
};
