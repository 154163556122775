import { useQuery } from 'react-query';

import { DICTIONARY } from 'constants/queries/dictionary';
import { getDictionariesHash } from 'services/dictionary';

export const useDictionariesHashQuery = () => {
  const { data: dictionariesHash } = useQuery(
    [DICTIONARY.DICTIONARIES_HASH],
    () => getDictionariesHash(),
    { refetchInterval: 3600000 }
  );

  return dictionariesHash;
};
