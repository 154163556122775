import { Alert } from '@profitowi/component-library';
import React from 'react';

export default function SchemaPropagationAlerts({
  validationWarnings,
  validationErrors,
}: {
  validationWarnings: string[];
  validationErrors: string[];
}) {
  return (
    <>
      {!!validationWarnings?.length && (
        <Alert className="my-4" type="info">
          {validationWarnings.map((warning) => (
            <p className="my-2">{warning}</p>
          ))}
        </Alert>
      )}
      {!!validationErrors?.length && (
        <Alert className="my-4" type="error">
          {validationErrors.map((error) => (
            <p className="my-2">{error}</p>
          ))}
        </Alert>
      )}
    </>
  );
}
