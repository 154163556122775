import { DatePickerField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMutation } from 'react-query';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { endCooperation } from 'services/agent';

type Payload = {
  endDate: Date;
};

const initValues = {
  endDate: undefined as unknown as Date,
};

type Props = {
  visible: boolean;
  setVisible: (values: boolean) => void;
  refetchAgent: () => void;
};

const EndCooperationForm = ({ visible, setVisible, refetchAgent }: Props) => {
  const agentId = useAgentId();

  const {
    error,
    isError,
    isLoading,
    mutate: mutateEndCooperation,
  } = useMutation<AxiosResponse, AxiosError, { endDate: string }>(
    ({ endDate }) => endCooperation(agentId, endDate),
    {
      onSuccess: () => {
        refetchAgent();
        setVisible(false);
      },
    }
  );

  const handleSubmit = (values: Payload) => {
    const payload = {
      endDate: format(startOfDay(values.endDate), 'dd-MM-yyyy HH:mm:ss'),
    };

    mutateEndCooperation(payload);
  };

  return (
    <Modal visible={visible} title="Zakończenie współpracy">
      <Formik enableReinitialize={true} initialValues={initValues} onSubmit={handleSubmit}>
        <Form className="space-y-2">
          <DatePickerField
            preventOpenOnFocus
            dateFormat={'dd-MM-yyyy'}
            label="Planowany dzień zakończenia współpracy"
            name="endDate"
          />

          <ModalActions onCancel={() => setVisible(false)} isLoading={isLoading} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default EndCooperationForm;
