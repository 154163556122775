import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { UseQueryResult } from 'react-query/types/react/types';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AgentDetailsBlockade } from 'types/agent';

import { createColumns } from './columns';
import AddAgentBlockadeModal from './components/AddAgentBlockadeModal/AddAgentBlockadeModal';
import DisableBlockadeModal from './components/DisableBlockadeModal/DisableBlockadeModal';

type Props = {
  blockadeQueryResult: UseQueryResult<AgentDetailsBlockade[], AxiosError>;
};

const Blockades = ({
  blockadeQueryResult: { data, isSuccess, isError, isLoading, isFetching, error, refetch },
}: Props) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isDeleteBlockadeModalVisible, setIsDeleteBlockadeModalVisible] = useState<boolean>(false);
  const [blockadeToDelete, setBlockadeToDelete] = useState<AgentDetailsBlockade | null>(null);

  return (
    <Box>
      <div className="flex justify-between my-4">
        <Header as="h4" size="lg" weight="semibold">
          Blokady
        </Header>
        <Button
          variant="outline-primary"
          className="px-4 py-1"
          onPress={() => setIsAddModalVisible(true)}>
          <Text weight="semibold">
            Dodaj
            <i className="bi bi-plus-lg ml-2" />
          </Text>
        </Button>
      </div>

      {isError && <ErrorMessages error={error} />}

      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && data && (
        <Box.FullWidth>
          <Table
            data={data || []}
            isLoading={isFetching}
            columns={createColumns(setBlockadeToDelete, () =>
              setIsDeleteBlockadeModalVisible(true)
            )}
            manualSortBy={false}
          />
        </Box.FullWidth>
      )}
      <AddAgentBlockadeModal
        closeModal={() => setIsAddModalVisible(false)}
        visible={isAddModalVisible}
        refetch={refetch}
      />
      <DisableBlockadeModal
        blockadeToDelete={blockadeToDelete}
        setBlockadeToDelete={setBlockadeToDelete}
        closeModal={() => setIsDeleteBlockadeModalVisible(false)}
        visible={isDeleteBlockadeModalVisible}
        refetch={refetch}
      />
    </Box>
  );
};

export default Blockades;
