import { MultiSelectField, RadioGroup, RadioGroupField } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import InsetLoader from 'components/InsetLoader/InsetLoader';
import { COMMISSION } from 'constants/queries/commission';
import { getCommissionSchema, getCommissionSchemas } from 'services/commission';
import { PropagationOptions } from 'services/structure';
import { CommissionSchema, CommissionSchemaList } from 'types/commission';

interface CommissionSchemaPropagationInterface {
  commissionSchemaId: number;
  propagateToSelectedSchemas: PropagationOptions | string;
  selectedCommissionSchemaIds: string[];
}

type SchemaPropagationFieldsProps = {
  isLoading: boolean;
  productGroup?: string;
  commissionSchemaId?: number;
  actionLabel?: ActionLabelOptions;
};

export enum ActionLabelOptions {
  ADD = 'Dodaj',
  EDIT = 'Edytuj',
  FINISH = 'Zakończ',
  DELETE = 'Usuń',
  CHANGE = 'Zmień',
  DELEGATE = 'Deleguj',
}

export function getSchemaPropagationPayload(formValues: CommissionSchemaPropagationInterface) {
  let commissionSchemaIdList: string[] = [];
  if (formValues.propagateToSelectedSchemas === PropagationOptions.PROPAGATE_TO_SELECTED_SCHEMAS) {
    commissionSchemaIdList = formValues.selectedCommissionSchemaIds;
  } else {
    commissionSchemaIdList = [formValues.commissionSchemaId.toString()];
  }

  return {
    propagateToAllSchemasInGroup:
      formValues.propagateToSelectedSchemas ===
      PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
    commissionSchemaIdList,
  };
}

export default function SchemaPropagationFields({
  isLoading,
  productGroup,
  commissionSchemaId,
  actionLabel = ActionLabelOptions.ADD,
}: SchemaPropagationFieldsProps) {
  const [currentProductGroup, setCurrentProductGroup] = useState<string>('');
  const { values } = useFormikContext<CommissionSchemaPropagationInterface>();

  const { data, isFetching, isError, error } = useQuery<CommissionSchemaList, AxiosError>(
    [COMMISSION.COMMISSION_SCHEMAS],
    () => {
      return getCommissionSchemas(0, 1000);
    }
  );

  const {
    data: commissionSchema,
    isFetching: isFetchingCommissionSchema,
    isError: isErrorCommissionSchema,
    error: errorCommissionSchema,
  } = useQuery<CommissionSchema | null, AxiosError>([commissionSchemaId], () => {
    if (commissionSchemaId && !productGroup) {
      return getCommissionSchema(parseInt(commissionSchemaId.toString()));
    }
    return null;
  });

  useEffect(() => {
    if (productGroup) {
      setCurrentProductGroup(productGroup);
    }
    if (commissionSchema?.productGroup) {
      setCurrentProductGroup(commissionSchema?.productGroup);
    }
  }, [commissionSchema, productGroup]);

  useEffect(() => {
    values.selectedCommissionSchemaIds = [];
  }, []);

  const options = useMemo(
    () =>
      values.commissionSchemaId || commissionSchemaId
        ? [
            ...(
              data?.content?.filter((schema) => schema.productGroup === currentProductGroup) || []
            ).map((schema) => ({
              key: schema.commissionSchemaId.toString(),
              value: schema.schemaName,
            })),
          ]
        : [],
    [commissionSchemaId, currentProductGroup, data?.content, values]
  );

  useEffect(() => {
    if (
      values.propagateToSelectedSchemas === PropagationOptions.PROPAGATE_TO_SELECTED_SCHEMAS &&
      commissionSchemaId &&
      !values.selectedCommissionSchemaIds.find(
        (schemaId) => schemaId === commissionSchemaId.toString()
      )
    ) {
      values.selectedCommissionSchemaIds.unshift(commissionSchemaId.toString());
    }
  }, [commissionSchemaId, values.propagateToSelectedSchemas, values.selectedCommissionSchemaIds]);

  return (
    <div className="relative">
      {(isLoading || isFetching || isFetchingCommissionSchema) && <InsetLoader />}

      <div className="flex items-center justify-between">
        <RadioGroupField
          name="propagateToSelectedSchemas"
          orientation="vertical"
          isDisabled={isLoading || isFetching || isFetchingCommissionSchema}>
          <RadioGroup.Radio value={PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP}>
            {actionLabel} we wszystkich schematach z powiązanej grupy produktowej
          </RadioGroup.Radio>
          <RadioGroup.Radio value={PropagationOptions.PROPAGATE_TO_SELECTED_SCHEMA}>
            {actionLabel} tylko w ramach wyżej wybranego schematu
          </RadioGroup.Radio>
          <RadioGroup.Radio value={PropagationOptions.PROPAGATE_TO_SELECTED_SCHEMAS}>
            {actionLabel === ActionLabelOptions.DELEGATE ? ActionLabelOptions.DELETE : actionLabel}{' '}
            {actionLabel === ActionLabelOptions.ADD
              ? 'do wybranych schematów'
              : 'w wybranych schematach'}
          </RadioGroup.Radio>
        </RadioGroupField>
      </div>
      {values.propagateToSelectedSchemas === PropagationOptions.PROPAGATE_TO_SELECTED_SCHEMAS && (
        <div className="my-4">
          <MultiSelectField
            label="Schematy prowizyjne"
            name="selectedCommissionSchemaIds"
            options={values.selectedCommissionSchemaIds ? options : []}
          />
        </div>
      )}
      {isError ||
        (isErrorCommissionSchema && <ErrorMessages error={error || errorCommissionSchema} />)}
    </div>
  );
}
