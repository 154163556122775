import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { deleteCommissionRate } from 'services/commission';

type Props = {
  refetch: () => void;
};

const DeleteCommissionModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('..');
      refetch();
    }
  }, [state, navigate]);

  const { agentId, id, schemaId } = state;

  const { error, isError, isLoading, mutate } = useMutation<AxiosResponse, AxiosError>(
    () => deleteCommissionRate(schemaId, agentId, id),
    {
      onSuccess: () => {
        navigate('..');
        refetch();
      },
    }
  );

  return (
    <Modal title="Usuń warunek" visible>
      <Text>Czy na pewno chcesz usunąć warunek?</Text>
      <ModalActions
        confirmLabel="Zatwierdź"
        isLoading={isLoading}
        onCancel={() => navigate('..')}
        onConfirm={() => mutate()}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default DeleteCommissionModal;
