import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { createUser } from 'services/user';
import { AddUserPayload } from 'types/user';

export const useAddUser = (refetch: Function, closeModal: Function) => {
  return useMutation<AxiosResponse, AxiosError, AddUserPayload>((payload) => createUser(payload), {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });
};

export default useAddUser;
