import { InputField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { BlockadeType } from 'constants/blockadeType';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { updateAgentBlockade } from 'services/agent';
import { AgentDetailsBlockade } from 'types/agent';
import { cleanObject } from 'utils/object';

const validationSchema = yup.object({
  type: yup.mixed().oneOf(Object.values(BlockadeType)).required(requiredFieldMessage),
  accountingDocumentNumber: yup.string(),
});

type FormValues = yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  type: '',
  accountingDocumentNumber: '',
};

type Props = {
  closeModal: () => void;
  refetch: () => void;
  visible: boolean;
};

const AddAgentBlockadeModal = ({ closeModal, refetch, visible }: Props) => {
  const agentId = useAgentId();
  const { activeDictionaries } = useDictionaryContext();

  const { isError, error, mutate, isLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    { agentId: number; payload: AgentDetailsBlockade }
  >(({ payload, agentId }) => updateAgentBlockade(agentId, payload), {
    onSuccess: () => {
      closeModal();
      refetch();
    },
  });

  const handleSubmit = (values: FormValues) => {
    const submitValues: AgentDetailsBlockade = { ...values, state: true };

    mutate({ agentId, payload: cleanObject(submitValues) });
  };

  return (
    <Modal visible={visible} title="Dodaj blokadę">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}>
        {({ values }) => {
          const isDocBlocking = values.type === 'ACCOUNTING_DOC_PAYOUT_BLOCKER';

          return (
            <Form>
              <SelectField
                options={activeDictionaries?.[DICTIONARY_TYPES.BLOCKER_TYPE] || []}
                label="Typ blokady"
                placeholder="Typ blokady"
                name="type"
              />
              <div className="my-2" hidden={!isDocBlocking}>
                <InputField
                  name="accountingDocumentNumber"
                  label="Numer dokumentu księgowego"
                  placeholder="Numer dokumentu księgowego"
                  isDisabled={!isDocBlocking}
                />
              </div>
              <div className="my-8">
                <ModalActions onCancel={() => closeModal()} isLoading={isLoading} />
                {isError && <ErrorMessages error={error} />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddAgentBlockadeModal;
