import { Header } from '@profitowi/component-library';
import { Route, Routes } from 'react-router-dom';

import NavigationToolbar from '../../components/NavigationToolbar/NavigationToolbar';
import Blockades from './Blockades/Blockades';
import CreateSettlementModal from './CreateSettlementModal';
import Files from './Files';
import List from './List';
import Settlement from './Settlement';

const Settlements = () => (
  <div className="flex-1 p-8 space-y-6 overflow-hidden">
    <>
      <Header as="h1" size="xl" weight="semibold">
        Rozliczenia
      </Header>

      <NavigationToolbar
        rootPath="/settlements"
        tabs={[
          { displayName: 'Rozliczenia', route: '/' },
          { displayName: 'Pliki', route: 'files' },
          { displayName: 'Blokady', route: 'blockades' },
        ]}
      />
    </>

    <Routes>
      <Route path="/" element={<List />}>
        <Route path="create" element={<CreateSettlementModal />} />
      </Route>
      <Route path="/:settlementId/*" element={<Settlement />} />
      <Route path="files/*" element={<Files />} />
      <Route path="blockades/*" element={<Blockades />} />
    </Routes>
  </div>
);

export default Settlements;
