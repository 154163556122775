import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { usePagination } from 'hooks/usePagination';
import { getAllInsuranceCompanies } from 'services/agent';
import { InsuranceCompany } from 'types/agent';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import useAgentId from '../../../useAgentId';
import AddUpdateInsuranceCompanyModal from './AddUpdateInsuranceCompanyModal';
import DeleteInsuranceCompanyModal from './DeleteInsuranceCompanyModal';
import { columns } from './columns';

const InsuranceCompanies = () => {
  const agentId = useAgentId();
  const navigate = useNavigate();
  const pagination = usePagination(10);
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { currentPage, perPage } = pagination;

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useQuery<
    Page<InsuranceCompany>,
    AxiosError
  >(
    [AGENT.AGENT_INSURANCE_COMPANIES, agentId, currentPage, perPage, sortBy],
    () => getAllInsuranceCompanies(agentId, currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Lista towarzystw ubezpieczeniowych kontrahenta
          </Header>

          <Button onPress={() => navigate('addUpdate')} size="sm" variant="outline-primary">
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={data.content ?? []}
              isLoading={isFetching}
              pagination={pagination}
              totalPages={data.totalPages}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path="addUpdate" element={<AddUpdateInsuranceCompanyModal refetch={refetch} />} />
        <Route path="delete" element={<DeleteInsuranceCompanyModal refetch={refetch} />} />
      </Routes>
    </>
  );
};

export default InsuranceCompanies;
