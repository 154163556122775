import {
  DatePickerField,
  Input,
  InputField,
  Modal,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { endOfDay, format, parse, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { generateVatRateOptions } from 'helpers/select';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { EditCommissionRatePayload, editCommissionRate } from 'services/commission';
import { AgentCommissionConditionRate } from 'types/commission';

const validationSchema = yup.object({
  commissionRate: yup
    .number()
    .min(0, 'Minimalna wartość prowizji to 0%.')
    .max(100, 'Maksymalna wartość prowizji to 100%')
    .required(requiredFieldMessage),
  validFrom: yup.date().required(requiredFieldMessage),
  validTo: yup.date().nullable(),
  vatRate: yup.string().required(requiredFieldMessage),
});

type Props = {
  refetch: () => void;
};

type FormValues = yup.InferType<typeof validationSchema>;

const EditCommissionModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { activeDictionaries, translate } = useDictionaryContext();

  useEffect(() => {
    if (!state) {
      navigate('..');
      refetch();
    }
  }, [state, navigate]);

  const {
    agentId,
    commissionRate = 0,
    commissionSchema,
    vatRate,
    id,
    validFrom,
    validTo = null,
  } = (state || {}) as AgentCommissionConditionRate;

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    EditCommissionRatePayload
  >((payload) => editCommissionRate(commissionSchema.commissionSchemaId, agentId, id, payload), {
    onSuccess: () => {
      navigate('..');
      refetch();
    },
  });

  const vatRateOptions = useMemo(() => generateVatRateOptions(activeDictionaries) ?? [], []);

  const findVatRate = (vatRate: string) =>
    activeDictionaries?.[DICTIONARY_TYPES.VAT_RATE].find((item) => item.value.includes(vatRate))
      ?.key;

  const initialValues = useMemo(() => {
    return {
      commissionRate: parseFloat((commissionRate * 100).toFixed(2)),
      validFrom: validFrom ? parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date()) : new Date(),
      validTo: validTo ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date()) : null,
      vatRate: findVatRate(vatRate?.toString()) ?? 'VAT_ZW',
    };
  }, [commissionRate, validFrom, validTo, vatRate]);

  const handleSubmit = (values: FormValues) => {
    mutate({
      commissionRate: values.commissionRate / 100,
      validFrom: format(startOfDay(new Date(values.validFrom)), 'dd-MM-yyyy HH:mm:ss'),
      validTo: values.validTo && format(endOfDay(new Date(values.validTo)), 'dd-MM-yyyy HH:mm:ss'),
      vatRate:
        values.vatRate !== 'VAT_ZW'
          ? parseFloat(translate(DICTIONARY_TYPES.VAT_RATE, values.vatRate))
          : null,
    });
  };

  return (
    <Modal title="Edytuj warunek" visible>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({ values: { validFrom, validTo } }) => (
          <Form className="space-y-4">
            <Input label="Schemat prowizyjny" isReadOnly value={commissionSchema?.schemaName} />
            <InputField name="commissionRate" label="%" />
            <DatePickerField name="validFrom" label="Data od" maxDate={validTo} />
            <DatePickerField name="validTo" label="Data do" minDate={validFrom} />
            <SelectField options={vatRateOptions} name="vatRate" placeholder="Stawka Vat" />

            <ModalActions
              confirmLabel="Zatwierdź"
              isLoading={isLoading}
              onCancel={() => navigate('..')}
            />
            {isError && <ErrorMessages error={error} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditCommissionModal;
