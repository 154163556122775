import { AutoCompleteField, DatePickerField } from '@profitowi/component-library';
import { getIn, useFormikContext } from 'formik';
import { useMemo, useState } from 'react';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { createAgentsOptions } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';

const GroupTransferModalForm = () => {
  const [referredQueryAgent, setReferredQueryAgent] = useState('');
  const {
    data: searchAgents,
    isError: isAgentError,
    error: agentError,
  } = useAgentQuery({
    queryParam: referredQueryAgent,
    minQueryLength: 3,
  });

  const agentOptions = useMemo(
    () => createAgentsOptions(searchAgents?.content || [], 'id'),
    [searchAgents]
  );

  return (
    <>
      <DatePickerField
        preventOpenOnFocus
        name="transferDate"
        placeholder="Data cesji"
        label="Data cesji"
      />
      <AutoCompleteField
        label="Przypisz do agenta"
        placeholder="Przypisz do agenta"
        name="toAgentId"
        options={agentOptions}
        query={referredQueryAgent}
        setQuery={setReferredQueryAgent}
      />
      {isAgentError && <ErrorMessages error={agentError} />}
    </>
  );
};

export default GroupTransferModalForm;
