import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { importBonusFile } from 'services/agent';
import { FileTypes } from 'types/file';

const BonusesImportSchema = Yup.object().shape({
  file: Yup.mixed().required('Plik jest wymagany'),
});

type Payload = {
  file: FormData;
};

type Props = {
  refetch: () => void;
};

const BonusesFileForm = ({ refetch }: Props) => {
  const navigate = useNavigate();

  const createBonusesFile = useMutation<AxiosResponse, AxiosError, Payload>(
    ({ file }: any) => importBonusFile(file, FileTypes.DISCRETIONARY_BONUSES),
    {
      onSuccess: () => {
        refetch();
        navigate('..');
      },
    }
  );

  const onSubmit = ({ file }: any) => {
    const formData = new FormData();
    formData.append('file', file);

    createBonusesFile.mutate({ file: formData });
  };

  const onChange = (form: any, e: any) => {
    form.setFieldValue('file', e.target.files[0]);
  };

  return (
    <Modal title="Import pliku premii uznaniowej" visible>
      <Formik
        initialValues={{
          file: undefined,
        }}
        onSubmit={onSubmit}
        validationSchema={BonusesImportSchema}>
        {({ errors, touched }) => (
          <Form className="space-y-4">
            <Text className="text-secondary">Plik</Text>

            <Field name="file">
              {({ form }: any) => (
                <input type="file" name="file" onChange={(e) => onChange(form, e)} />
              )}
            </Field>

            <div>
              {errors.file && touched.file ? (
                <Text size="sm" className="text-red-600">
                  {errors.file}
                </Text>
              ) : null}
            </div>

            <ModalActions
              isLoading={createBonusesFile.isLoading}
              confirmLabel="Załaduj Plik"
              onCancel={() => navigate('..')}
            />

            {createBonusesFile.isError && <ErrorMessages error={createBonusesFile.error} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default BonusesFileForm;
