import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { usePagination } from 'hooks/usePagination';
import { getActivityRange } from 'services/agent';
import { ActivityRangeRecord } from 'types/agent';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import useAgentId from '../../../useAgentId';
import AddUpdateActivityRangeModal from './AddUpdateActivityRangeModal';
import DeleteActivityRangeModal from './DeleteActivityRangeModal';
import { columns } from './columns';

const ActivityRange = () => {
  const agentId = useAgentId();
  const navigate = useNavigate();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useQuery<
    Page<ActivityRangeRecord>,
    AxiosError
  >(
    [AGENT.AGENT_INSURANCE_COMPANIES, agentId, currentPage, perPage, sortBy],
    () => getActivityRange(agentId, currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Zakres uprawnień
          </Header>

          <Button onPress={() => navigate('addUpdate')} size="sm" variant="outline-primary">
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={data.content ?? []}
              isLoading={isFetching}
              pagination={pagination}
              totalPages={data.totalPages}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path="addUpdate" element={<AddUpdateActivityRangeModal refetch={refetch} />} />
        <Route path="delete" element={<DeleteActivityRangeModal refetch={refetch} />} />
      </Routes>
    </>
  );
};

export default ActivityRange;
