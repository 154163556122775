import { DatePickerField, InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createDebtForAgent } from 'services/debt';
import { Debt, DebtPayload } from 'types/debt';

const validationSchema = Yup.object().shape({
  debtValue: Yup.number()
    .required(requiredFieldMessage)
    .min(0, 'Wartość zadłużenia musi być dodatnia'),
  installmentsCount: Yup.number()
    .moreThan(0, 'Liczba miesięcy musi być dodatnia')
    .required(requiredFieldMessage),
  repaymentDayOfMonth: Yup.number()
    .required(requiredFieldMessage)
    .min(1, 'Podana wartość powinna być w przedziale 1-31')
    .max(31, 'Podana wartość powinna być w przedziale 1-31')
    .integer('Wartość musi być liczbą całkowitą'),
  repaymentStartDate: Yup.string().required(requiredFieldMessage),
});

type Props = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
};

const DebtForm = ({ visible, setVisible, refetch }: Props) => {
  const agentId = useAgentId();

  const { isError, isLoading, error, mutate } = useMutation<AxiosResponse, AxiosError, DebtPayload>(
    (payload) => createDebtForAgent(payload, agentId),
    {
      onSuccess: () => {
        refetch();
        setVisible(false);
      },
    }
  );

  const onSubmit = (debt: Partial<Debt>) => {
    const { repaymentStartDate = '' } = debt;

    const payload = {
      ...debt,
      repaymentStartDate: format(new Date(repaymentStartDate), 'dd-MM-yyyy HH:mm:ss'),
    };

    mutate(payload as DebtPayload);
  };

  return (
    <Modal title="Dodaj Zadłużenie" visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        <Form className="space-y-4">
          <InputField name="debtValue" label="Wartość zadłużenia" type="number" />
          <InputField name="installmentsCount" label="Liczba miesięcy" type="number" />
          <InputField name="repaymentDayOfMonth" label="Dzień spłaty raty" type="number" />
          <DatePickerField name="repaymentStartDate" label="Data rozpoczęcia spłat" />

          <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />

          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default DebtForm;
