import { DatePickerField } from '@profitowi/component-library';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type Props = {
  handleBalancePeriod: (period: string) => void;
};

type FormikValues = {
  period: string;
};

const PeriodDatePicker = ({ handleBalancePeriod }: Props) => {
  const { values } = useFormikContext<FormikValues>();

  useEffect(() => {
    if (values && values.period) handleBalancePeriod(values.period);
  }, [values]);

  return (
    <DatePickerField
      dateFormat="LLLL yyyy"
      label="Okres rozliczeniowy"
      name="period"
      showFullMonthYearPicker
      showMonthYearPicker
    />
  );
};

export default PeriodDatePicker;
