import { Header } from '@profitowi/component-library';
import CommissionImport from 'pages/Commissions/components/CommissionImports/CommissionImports';
import { Outlet, Route, Routes } from 'react-router-dom';

import NavigationToolbar from 'components/NavigationToolbar/NavigationToolbar';

import CommissionOtherImports from './components/CommissionOtherImports/CommissionOtherImports';
import CommissionSchemas from './components/CommissionSchemas/CommissionSchemas';

const Commissions = () => {
  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <Header as="h1" size="xl" weight="semibold">
        Prowizje
      </Header>

      <NavigationToolbar
        rootPath="/commissions"
        tabs={[
          { displayName: 'Źródła danych', route: '/' },
          { displayName: 'Schematy prowizyjne', route: 'commission-schemas' },
          { displayName: 'Inne źródła danych', route: 'other-dataSources' },
        ]}
      />

      <Routes>
        <Route path="/*" element={<CommissionImport />} />
        <Route path="commission-schemas/*" element={<CommissionSchemas />} />
        <Route path="other-dataSources/*" element={<CommissionOtherImports />} />
      </Routes>

      <Outlet />
    </div>
  );
};

export default Commissions;
