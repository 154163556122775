import { Column } from 'react-table';

import { AgentPaymentHistoryRecord } from 'types/agent';

export const columns: Column<AgentPaymentHistoryRecord>[] = [
  {
    Header: 'Miesiąc rozliczeniowy',
    accessor: 'salePeriod',
    id: 'salePeriodDate',
  },
  {
    Header: 'Data wystawienia',
    accessor: 'issueDate',
  },
  {
    Header: 'Typ',
    accessor: 'type',
  },
  {
    Header: 'Numer/tytuł',
    accessor: 'documentNumber',
  },
  {
    id: 'amount',
    Header: 'Należności [PLN]',
    accessor: (row) => <div className="text-right">{row?.amount}</div>,
  },
  {
    id: 'paidBalance',
    Header: 'Saldo do wypłaty [PLN]',
    accessor: (row) => <div className="text-right">{row?.paidBalance}</div>,
  },
  {
    id: 'payout',
    Header: 'Wypłata [PLN]',
    accessor: (row) => <div className="text-right">{row?.payout}</div>,
  },
  {
    id: 'payment',
    Header: 'Wpłata [PLN]',
    accessor: (row) => <div className="text-right">{row?.payment}</div>,
  },
  {
    id: 'balance',
    Header: 'Saldo [PLN]',
    accessor: (row) => <div className="text-right">{row?.balance}</div>,
  },
];
