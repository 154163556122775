import { Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { BillingItem } from 'types/settlement';
import { toPercent } from 'utils/number';

export const columns: Column<BillingItem>[] = [
  {
    Header: 'Data naliczenia',
    accessor: 'settlementDate',
  },
  {
    Header: 'Id pozycji naliczeniowej',
    accessor: 'id',
  },
  {
    Header: 'Kod Agenta do wypłaty',
    accessor: 'payoutAgentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: 'payoutAgentDisplayName',
  },
  {
    Header: 'Stanowisko',
    accessor: 'employeePositionDictionaryValue',
  },
  {
    Header: 'Sygnatura polisy',
    accessor: 'policySignature',
  },
  {
    Header: 'Sygnatura produktu',
    accessor: 'productSignature',
  },
  {
    Header: 'Warunek prowizyjny',
    accessor: 'condition',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_CONDITION', value) : '';
    },
  },
  {
    Header: 'Stawka VAT',
    accessor: 'vatRate',
    Cell: ({ value }) => {
      if (!value) return 'zw';

      return toPercent(value);
    },
  },
  {
    Header: 'Podstawa prowizji',
    accessor: (row) => <div className="text-right">{row?.baseCommission}</div>,
  },
  {
    Header: 'Stawka prowizyjna',
    accessor: 'commissionRate',
  },
  {
    Header: 'Podstawa brutto',
    accessor: (row) => <div className="text-right">{row?.baseValueGross}</div>,
  },
  {
    Header: 'Składka brutto',
    accessor: (row) => <div className="text-right">{row?.contribution}</div>,
  },
  {
    Header: 'Kwota prowizji netto',
    accessor: (row) => <div className="text-right">{row?.net}</div>,
  },
  {
    Header: 'Kwota VAT',
    accessor: (row) => <div className="text-right">{row?.vat}</div>,
  },
  {
    Header: 'Kwota brutto',
    accessor: (row) => <div className="text-right">{row?.gross}</div>,
  },
];
