import {
  CheckboxField,
  DatePickerField,
  Header,
  InputField,
  Modal,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import ModalActions from 'components/ModalActions';
import { ADDRESS_TYPE } from 'constants/addressType';
import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { changeCooperation } from 'services/agent';
import {
  CooperationEconomyActivity,
  CooperationNaturalPerson,
  EconomyActivities,
} from 'types/agent';
import { cleanObject } from 'utils/object';

import CooperationTypeSelect from './components/CooperationTypeSelect';
import { ValidationSchemaShape, generateDefaultAddresses, validationSchema } from './validation';

type Props = {
  economyActivity?: EconomyActivities;
  refetchAgent: () => void;
  setVisible: (values: boolean) => void;
};

const CooperationForm = ({ economyActivity, refetchAgent, setVisible }: Props) => {
  const [type, setType] = useState<string>();

  const { translate } = useDictionaryContext();
  const { activeDictionaries } = useDictionaryContext();
  const agentId = useAgentId();

  const handleSetType = useCallback((type: string) => setType(type), []);

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { payload: CooperationEconomyActivity | CooperationNaturalPerson }
  >(({ payload }) => changeCooperation(payload, agentId), {
    onSuccess: () => {
      setVisible(false);
      refetchAgent();
    },
  });

  const handleSubmit = (payload: ValidationSchemaShape) => {
    const isBusinessActivity =
      payload.type === COOPERATION_FORMS.COMPANY ||
      payload.type === COOPERATION_FORMS.SOLE_PROPRIETORSHIP;

    const validFrom = payload.validFrom
      ? format(startOfDay(new Date(payload.validFrom)), 'dd-MM-yyyy HH:mm:ss')
      : '';
    const validTo = payload.validTo
      ? format(startOfDay(new Date(payload.validTo)), 'dd-MM-yyyy HH:mm:ss')
      : '';

    if (payload.type === COOPERATION_FORMS.INDIVIDUAL) {
      let { economyActivityParams, ...newPayload } = payload;

      const personPayload = {
        ...newPayload,
        validFrom,
        validTo,
        addresses: payload.addresses ? payload.addresses.filter((address) => address !== null) : [],
        birthDate: payload.birthDate ? format(new Date(payload.birthDate), 'dd-MM-yyyy') : '',
      };
      mutate({ payload: cleanObject(personPayload) });
    } else if (isBusinessActivity) {
      if (payload) {
        const { economyActivityParams } = payload;

        const economyActivityPayload = {
          ...payload,
          validFrom,
          validTo,
          economyActivityParams: economyActivityParams?.map((item) => {
            return { ...item, validFrom, validTo };
          }),
        };

        mutate({ payload: cleanObject(economyActivityPayload) });
      }
    }
  };

  const findAddress = useCallback(
    (addressType: string) => economyActivity?.addresses.find(({ type }) => type === addressType),
    []
  );

  const initialValues = useMemo(() => {
    const correspondenceAddress = findAddress(ADDRESS_TYPE.CORRESPONDENCE_ADDRESS);
    const billingAddress = findAddress(ADDRESS_TYPE.BILLING_ADDRESS);
    const homeAddress = findAddress(ADDRESS_TYPE.HOME_ADDRESS);

    const defaultValues = {
      ...validationSchema.cast({
        type: '',
        name: economyActivity?.name ?? '',
        lastName: economyActivity?.lastName ?? '',
        businessEmail: economyActivity?.businessEmail ?? '',
        privateEmail: economyActivity?.privateEmail ?? '',
        phoneNumber: economyActivity?.phoneNumber ?? '',
        addresses: generateDefaultAddresses(
          type,
          billingAddress,
          homeAddress,
          correspondenceAddress
        ),
      }),
    };

    return defaultValues as ValidationSchemaShape;
  }, []);

  return (
    <Modal title="Zmień formę współpracy" visible setVisible={() => {}}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({ values: { type, addresses, validFrom, validTo } }) => {
          const isBusinessActivity =
            type === COOPERATION_FORMS.COMPANY || type === COOPERATION_FORMS.SOLE_PROPRIETORSHIP;
          const isIndividual = type === COOPERATION_FORMS.INDIVIDUAL;

          return (
            <Form className="space-y-4">
              <Header as="h3" size="lg">
                {isBusinessActivity ? 'Dane firmy:' : 'Dane Agenta:'}
              </Header>

              <CooperationTypeSelect
                dictionaryTypes={activeDictionaries}
                economyActivity={economyActivity}
                setType={handleSetType}
              />

              <DatePickerField
                name="validFrom"
                label="Data od"
                required
                maxDate={new Date(validTo)}
              />
              <DatePickerField name="validTo" label="Data do" minDate={new Date(validFrom)} />

              {isBusinessActivity && (
                <>
                  <InputField name="companyName" label="Nazwa firmy" isRequired />
                  <InputField name="nip" label="Nip firmy" isRequired />
                  <InputField name="regon" label="Regon firmy" isRequired />
                  <InputField
                    name="krs"
                    label="Krs firmy"
                    isRequired={type === COOPERATION_FORMS.COMPANY}
                  />
                  <InputField name="bankAccount" label="Konto bankowe" />
                  <InputField name="primaryBillingEmailAddress" label="Adres email do rozliczeń" />
                  <InputField name="secondBillingEmailAddress" label="Email pomocniczy" />
                </>
              )}

              <InputField name="name" label="Imię" isRequired={isIndividual} />
              <InputField name="lastName" label="Nazwisko" isRequired={isIndividual} />
              <InputField name="businessEmail" label="Email służbowy" />
              <InputField name="privateEmail" label="Email prywatny" />
              <InputField name="phoneNumber" label="Numer telefonu" />

              {isIndividual && (
                <>
                  <InputField name="pesel" label="Pesel" />
                  <DatePickerField name="birthDate" label="Data urodzenia" />
                  <InputField name="birthPlace" label="Miejsce urodzenia" isRequired />
                  <SelectField
                    name="education"
                    label="Wykształcenie"
                    options={
                      activeDictionaries ? activeDictionaries[DICTIONARY_TYPES.EDUCATION] : []
                    }
                  />
                </>
              )}

              {addresses?.map((address, key) => (
                <div className="space-y-4" key={key}>
                  <Header as="h3" size="lg">
                    {address.type ? translate(DICTIONARY_TYPES.ADDRESS_TYPE, address.type) : ''}
                  </Header>
                  <InputField name={`addresses[${key}].street`} label="Ulica" />
                  <InputField name={`addresses[${key}].houseNumber`} label="Numer domu" />
                  <InputField name={`addresses[${key}].apartmentNumber`} label="Numer mieszkania" />
                  <InputField name={`addresses[${key}].postalCode`} label="Kod pocztowy" />
                  <InputField name={`addresses[${key}].city`} label="Miasto" />
                </div>
              ))}

              {isBusinessActivity && (
                <>
                  <Header as="h3" size="lg">
                    Stawka VAT:
                  </Header>
                  <SelectField
                    name="economyActivityParams[0].value"
                    label="Stawka VAT"
                    options={
                      activeDictionaries ? activeDictionaries[DICTIONARY_TYPES.VAT_RATE] : []
                    }
                  />
                  <InputField name="economyActivityParams[0].description" label="Komentarz" />
                  <Header as="h3" size="lg">
                    Status działalności:
                  </Header>
                  <SelectField
                    name="economyActivityParams[1].value"
                    label="Status działalności"
                    options={
                      activeDictionaries
                        ? activeDictionaries[DICTIONARY_TYPES.COOPERATION_STATUS]
                        : []
                    }
                  />
                  <InputField name="economyActivityParams[1].description" label="Komentarz" />

                  <Header as="h3" size="lg">
                    Samofakturowanie:
                  </Header>
                  <div className="my-2">
                    <CheckboxField name="economyActivityParams[2].value">
                      <span className="text-sm">Znacznik samofakturowania</span>
                    </CheckboxField>
                  </div>
                  <InputField name="economyActivityParams[2].description" label="Komentarz" />
                </>
              )}

              <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
              {isError && <ErrorMessages error={error} />}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CooperationForm;
