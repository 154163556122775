import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { updateAgentBlockade } from 'services/agent';
import { AgentDetailsBlockade } from 'types/agent';

import useAgentId from '../../../../../useAgentId';

type Props = {
  closeModal: () => void;
  refetch: () => void;
  visible: boolean;
  blockadeToDelete: AgentDetailsBlockade | null;
  setBlockadeToDelete: (blockadeToDelete: AgentDetailsBlockade | null) => void;
};

const DisableBlockadeModal = ({
  visible,
  refetch,
  closeModal,
  blockadeToDelete,
  setBlockadeToDelete,
}: Props) => {
  const agentId = useAgentId();
  const { isError, error, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { agentId: number; payload: AgentDetailsBlockade }
  >(({ payload, agentId }) => updateAgentBlockade(agentId, payload), {
    onSuccess: () => {
      closeModal();
      refetch();
    },
  });

  return (
    <Modal title="Dezaktywuj blokadę" visible={visible}>
      <Text>Czy na pewno chcesz dezaktywować blokadę? Ta operacja jest nieodwracalna.</Text>
      <ModalActions
        onCancel={() => {
          closeModal();
          setBlockadeToDelete(null);
        }}
        onConfirm={() => {
          if (blockadeToDelete) {
            mutate({ agentId, payload: { ...blockadeToDelete, state: false } });
          }
        }}
        isLoading={isLoading}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default DisableBlockadeModal;
