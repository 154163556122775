import { isValidNip, isValidPesel, isValidRegon } from 'polish-identification-numbers';
import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    isDigitsOnly(): this;
    isValidPesel(): this;
    isValidNip(): this;
    isValidRegon(): this;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'isDigitsOnly', function () {
  return this.matches(/^(\s*|\d+)$/, 'Podana wartość powinna składać się z samych cyfr');
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidPesel', function () {
  return this.isDigitsOnly().test({
    message: 'Nieprawidłowy numer PESEL',
    name: 'isValidPesel',
    test: (value = '') => value === '' || isValidPesel(value),
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidRegon', function () {
  return this.isDigitsOnly().test({
    message: 'Nieprawidłowy numer REGON',
    name: 'isValidRegon',
    test: (value = '') => value === '' || isValidRegon(value),
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidNip', function () {
  return this.isDigitsOnly()
    .length(10, 'Pole powinno zawierać tylko 10 cyfr')
    .test({
      message: 'Nieprawidłowy numer NIP',
      name: 'isValidNip',
      test: (value = '') => value === '' || isValidNip(value),
    });
});
