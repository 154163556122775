import {
  AutoCompleteField,
  Button,
  DatePickerField,
  Input,
  MultiSelectField,
  Option,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { getIn, useFormikContext } from 'formik';
import { useDelegationViceQuery } from 'pages/Records/components/Delegations/hooks/useDelegationViceQuery';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import { DELEGATION } from 'constants/queries/delegation';
import { STRUCTURE } from 'constants/queries/structure';
import { createAgentsOptions, getAgentName } from 'helpers/agents';
import { getSubordinationStructure } from 'services/structure';
import { AgentRelationStructureRecord, AgentStructureRecord } from 'types/structure';

type Props = {
  fromVice: AgentStructureRecord;
  commissionSchemaId?: number;
};

const DelegateViceModalForm = ({ fromVice, commissionSchemaId }: Props) => {
  const { values, setFieldValue } = useFormikContext();
  const [queryParam, setQueryParam] = useState('');
  const [displayAutocomplete, setDisplayAutocomplete] = useState(true);
  const validFrom = getIn(values, 'validFrom');
  const [debouncedQueryParam] = useDebounce(queryParam, 500);

  const { data: viceAgents } = useDelegationViceQuery({
    queryKey: DELEGATION.DELEGATION_VICE_ALL,
    commissionSchemaId,
    term: debouncedQueryParam,
  });

  const { data: subordinatedAgents } = useQuery<AgentRelationStructureRecord[], AxiosError>(
    [STRUCTURE.STRUCTURE_SUBORDINATION, commissionSchemaId, validFrom],
    () =>
      getSubordinationStructure({
        time: format(validFrom, 'dd-MM-yyyy 00:00:00'),
        commissionSchema: commissionSchemaId ?? -1,
        parentCode: fromVice.agentCode,
      })
  );

  const viceOptions: Option[] = useMemo(
    () =>
      viceAgents?.map(({ id, name, agentCode }) => ({
        key: id,
        value: `${agentCode} - ${name}`,
      })) || [],
    [viceAgents]
  );

  const childAgentOptions = useMemo(() => {
    const agents = subordinatedAgents?.map((item) => {
      return {
        agentCode: item.child.agentCode,
        id: item.child.id,
        economyActivities: item.child.economyActivities,
      };
    });

    return createAgentsOptions(agents ?? [], 'id');
  }, [subordinatedAgents]);

  const handleClearViceField = () => {
    setDisplayAutocomplete(false);
    setFieldValue('toVice', '');
    setDisplayAutocomplete(true);
  };

  return (
    <>
      <DatePickerField preventOpenOnFocus name="validFrom" label="Data od" className="my-2" />
      <Input
        value={fromVice ? getAgentName(fromVice.economyActivities) : ''}
        placeholder="Od wicedyrektora"
        label="Od wicedyrektora"
        className="my-6"
      />

      {displayAutocomplete && (
        <div className="flex space-x-4 w-full ">
          <div className=" w-full">
            <AutoCompleteField
              name="toVice"
              label="Do wicedyrektora"
              placeholder="Do wicedyrektora"
              options={viceOptions}
              query={queryParam}
              setQuery={setQueryParam}
            />
          </div>
          <Button variant="secondary" onPress={() => handleClearViceField()}>
            <Text>
              <i className="bi bi-trash"></i>
            </Text>
          </Button>
        </div>
      )}

      <MultiSelectField
        options={childAgentOptions}
        name="delegatedAgents"
        label="Agenci do oddelegowania"
      />
    </>
  );
};

export default DelegateViceModalForm;
