import { Button, Icon } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Positions } from 'types/agent';
import { AgentRelationStructureRecord } from 'types/structure';

export const createColumns = (record: AgentRelationStructureRecord): Array<Column<Positions>> => {
  return [
    {
      id: 'expander',
      Cell: ({ row }: Cell<Positions>) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      ),
    },
    {
      accessor: 'positionDictionaryValue',
      Header: 'Stanowisko',
    },
    {
      accessor: 'validFrom',
      Header: 'Data od',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0]}</span>,
    },
    {
      accessor: 'validTo',
      Header: 'Data do',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0]}</span>,
    },

    {
      id: 'operations',
      Header: 'Akcje',
      disableSortBy: true,
      Cell: ({ row: { original: position } }: Cell<Positions>) => {
        const navigate = useNavigate();

        return (
          <div className="flex space-x-2">
            {position.positionDictionaryKey === 'ZCA_DR' && (
              <Button
                onPress={() =>
                  navigate('delegateVice', {
                    state: {
                      fromVice: record.child,
                      commissionSchemaId: record.commissionSchema,
                    },
                  })
                }
                size="sm"
                variant="light">
                Deleguj wicedyrektora
              </Button>
            )}
            <Button
              onPress={() =>
                navigate('editPosition', {
                  state: {
                    record,
                    position,
                  },
                })
              }
              size="sm"
              variant="light">
              Edytuj
            </Button>
            <Button
              onPress={() =>
                navigate('deletePosition', {
                  state: {
                    agentId: record.child.id,
                    positionId: position.id,
                    record,
                    position,
                  },
                })
              }
              size="sm"
              variant="light">
              Usuń stanowisko
            </Button>
            <Button
              onPress={() =>
                navigate('addCommissionCondition', {
                  state: {
                    commissionSchemaId: record.commissionSchema,
                    subordinate: record.child,
                    positionKey: position.positionDictionaryKey,
                  },
                })
              }
              size="sm"
              variant="light">
              Dodaj warunek
            </Button>
          </div>
        );
      },
    },
  ];
};
