import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { Dictionary, DictionaryType } from 'types/dictionary';

export const createColumn = (
  dictionaryTypes: DictionaryType[],
  setEditedItem: (dictionary: Dictionary) => void
): Column<Dictionary>[] => {
  return [
    {
      Header: 'Nazwa słownika',
      disableSortBy: true,
      accessor: (row: Dictionary) => {
        const dictionaryType = dictionaryTypes.find(({ key }) => key === row.type);

        if (dictionaryType) return dictionaryType.value;
      },
    },
    {
      Header: 'Wartość Pozycji',
      accessor: 'value',
    },
    {
      Header: 'kod Pozycji',
      accessor: 'key',
    },
    {
      id: 'edit',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Dictionary>) => (
        <div className="flex justify-end">
          <Button variant="light" onPress={() => setEditedItem(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
