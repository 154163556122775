import { Comment } from 'types/comment';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getComments = async (agentId: number, page: number, size: number, sort: string) => {
  const { data } = await recordmodApi.get<Page<Comment>>(`/agents/${agentId}/comment`, {
    params: {
      page,
      size,
      sort,
    },
  });

  return data;
};

export const addNewComment = async (agentId: number, comment: string) => {
  return recordmodApi.post(`/agents/${agentId}/comment`, comment, {
    headers: { 'Content-Type': 'text/plain' },
  });
};

export const deleteComment = async (commentId: number) => {
  return recordmodApi.delete(`/agents/agents/comment/${commentId}`);
};
