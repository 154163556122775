import { Option } from '@profitowi/component-library';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { DictionaryTypes } from 'types/dictionary';

export const findDictionaryByKey = (row: string, options: Option[]) =>
  options?.find(({ key }) => key === row);

export const filterDictionaryByAddressType = (
  addressType: string,
  dictionaryTypes?: DictionaryTypes
) => {
  if (dictionaryTypes) {
    return dictionaryTypes[DICTIONARY_TYPES.ADDRESS_TYPE].filter(({ key }) => key === addressType);
  }

  return [];
};
