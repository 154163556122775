import { Box, Button, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import ReportModal from 'pages/Reports/components/ReportModal/ReportModal';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DELEGATION } from 'constants/queries/delegation';
import useAgentRoleContext from 'hooks/useAgentRoleContext';
import { usePagination } from 'hooks/usePagination';
import { getDelegations } from 'services/delegations';
import { ChildList, Delegation } from 'types/delegation';
import { ReportType } from 'types/reports';
import { Page } from 'types/request';
import { Roles } from 'types/user';

import DelegationDetails from './DelegationDetails/DelegationDetails';
import DelegationFilterForm, {
  DelegationFilterFormValues,
  initialValues,
} from './DelegationFilterForm/DelegationFilterForm';
import EndDelegationForm from './EndDelegationForm/EndDelegationForm';
import { columns } from './columns';

type Roww = {
  row: Row<Delegation>;
};

const Delegations = () => {
  const [payload, setPayload] = useState<DelegationFilterFormValues>(initialValues);
  const [selectedAgentRelationIdList, setSelectedAgentRelationIdList] = useState<ChildList[]>([]);
  const { hasRole } = useAgentRoleContext();

  const navigate = useNavigate();
  const pagination = usePagination(10);

  const { perPage, currentPage } = pagination;

  const { error, data, isError, isLoading, isSuccess, refetch } = useQuery<
    Page<Delegation>,
    AxiosError
  >([DELEGATION.DELEGATIONS, payload, currentPage, perPage], () =>
    getDelegations(
      { ...payload, time: format(payload.time as Date, 'dd-MM-yyyy 00:00:00') },
      currentPage,
      perPage
    )
  );

  const handleSelectionChange = useCallback((rows: ChildList[]) => {
    setSelectedAgentRelationIdList(rows);
  }, []);

  const handleGenerateReport = () => {
    const initialValues = {
      reportType: ReportType.DELEGATIONS,
      TIME: new Date(payload.time) ?? '',
    };

    navigate('generateReport', { state: { defaultInitialValues: initialValues } });
  };

  const isAdminOrReportRole = hasRole(Roles.ADMIN) || hasRole(Roles.REPORTS);

  return (
    <>
      <Box className="space-y-6 w-full">
        <div className="flex justify-between">
          <div className="flex justify-between">
            <DelegationFilterForm payload={payload} setPayload={setPayload} />
          </div>

          <div className="flex flex-col justify-end space-y-4">
            <Button
              variant="outline-primary"
              onPress={() => navigate('edit')}
              isDisabled={!selectedAgentRelationIdList.length}>
              Edytuj datę końcową delegacji
            </Button>
            {isAdminOrReportRole && (
              <Button variant="outline-primary" onPress={() => handleGenerateReport()}>
                Generuj raport .csv
              </Button>
            )}
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Table
            columns={columns}
            data={data.content}
            pagination={pagination}
            totalPages={data.totalPages}
            isExpandable={true}
            renderRowSubComponent={({ row }: Roww) => (
              <div className="pl-20">
                <DelegationDetails
                  childList={row.original.childList}
                  handleSelectionChange={handleSelectionChange}
                />
              </div>
            )}
          />
        )}
      </Box>

      <Routes>
        <Route
          path="edit"
          element={
            <EndDelegationForm
              commissionSchemaId={selectedAgentRelationIdList[0]?.commissionSchema}
              selectedAgentRelationIdList={selectedAgentRelationIdList}
              refetch={refetch}
            />
          }
        />
        <Route
          path="generateReport/*"
          element={<ReportModal initialReportType={ReportType.DELEGATIONS} />}
        />
      </Routes>
    </>
  );
};

export default Delegations;
