import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { POLICES } from 'constants/queries/polices';
import { usePagination } from 'hooks/usePagination';
import { cancelPolicySplit, getSplits } from 'services/polices';
import { Policy } from 'types/polices';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import PolicyForm from './PolicesForm/PolicyForm';
import { createColumn } from './columns';

const Polices = () => {
  const [visible, setVisible] = useState(false);
  const [editedPolicy, setEditedPolicy] = useState<Policy>();
  const pagination = usePagination(10);
  const agentId = useAgentId();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  useEffect(() => {
    if (!visible) setEditedPolicy(undefined);
  }, [visible, editedPolicy]);

  const { data, error, isError, isLoading, isSuccess, refetch, isFetching } = useQuery<
    Page<Policy>,
    AxiosError
  >(
    [POLICES.POLICES, pagination.currentPage, pagination.perPage, sortBy],
    () =>
      getSplits(pagination.currentPage, pagination.perPage, agentId, decodeUriSortParams(sortBy)),
    {
      keepPreviousData: true,
    }
  );

  const { mutate: deleteMutate } = useMutation<AxiosResponse, AxiosError, number>(
    (id) => cancelPolicySplit(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const editPolicy = (policy: Policy) => {
    setVisible(true);
    setEditedPolicy(policy);
  };

  const closeModal = () => {
    setVisible(false);
    if (editedPolicy) setEditedPolicy(undefined);
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Podziały polisy
          </Header>

          <Button variant="outline-primary" onPress={() => setVisible(true)}>
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}

        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={createColumn(deleteMutate, editPolicy)}
              data={data.content}
              pagination={pagination}
              totalPages={data.totalPages}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              isLoading={isFetching}
            />
          </Box.FullWidth>
        )}
      </Box>

      {visible && (
        <PolicyForm
          visible={visible}
          editedPolicy={editedPolicy}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default Polices;
