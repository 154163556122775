import { Box, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { SETTLEMENT } from 'constants/queries/settlement';
import { usePagination } from 'hooks/usePagination';
import { getDocuments } from 'services/settlement';
import { Page } from 'types/request';
import { Document } from 'types/settlement';
import { decodeUriSortParams } from 'utils/table';

import useSettlement from '../../../useSettlement';
import { columns } from './columns';

const DocumentsStage = () => {
  const { settlementId = NaN } = useSettlement();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, error, isError, isLoading, isSuccess, isFetching } = useQuery<
    Page<Document>,
    AxiosError
  >(
    [SETTLEMENT.SETTLEMENT_BONUSES, settlementId, currentPage, perPage, sortBy],
    () => getDocuments(settlementId, currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Wygenerowane dokumenty
          </Header>
        </div>
        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={data.content}
              pagination={pagination}
              totalPages={data.totalPages}
              isLoading={isFetching}
              sortBy={sortBy}
              onSortBy={handleSortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Outlet />
    </>
  );
};

export default DocumentsStage;
