import { InputField, Modal, SelectField } from '@profitowi/component-library';
import { Form, Formik } from 'formik';
import { useQuery, useQueryClient } from 'react-query';

import { ModalActions } from 'components';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { COMMISSION } from 'constants/queries/commission';
import { getCommissionSchema } from 'services/commission';
import { CommissionSchemaPayload, SchemaPayload } from 'types/commission';
import { DictionaryTypes } from 'types/dictionary';

type Props = {
  visible: boolean;
  dictionaryTypes?: DictionaryTypes;
  commissionSchemaId?: number;
  closeModal: () => void;
  isLoading: boolean;
  onSave: (payload: CommissionSchemaPayload) => void;
  setVisible: (visible: boolean) => void;
};

const CommissionSchemaForm = ({
  dictionaryTypes,
  visible,
  commissionSchemaId,
  closeModal,
  isLoading,
  onSave,
  setVisible,
}: Props) => {
  const queryClient = useQueryClient();
  const { data: editedCommissionSchema, isFetching } = useQuery(
    [COMMISSION.COMMISSION_SCHEMA, commissionSchemaId],
    () => {
      if (commissionSchemaId) return getCommissionSchema(commissionSchemaId);
    }
  );

  const onSubmit = (schema: SchemaPayload) => {
    const payload = {
      ...schema,
      programs: editedCommissionSchema ? editedCommissionSchema.programs : [],
    };

    onSave(payload);
  };

  const onCloseModal = () => {
    queryClient.removeQueries(COMMISSION.COMMISSION_SCHEMA);
    closeModal();
  };

  if (!visible) {
    return null;
  } else
    return (
      <Modal
        title={`${commissionSchemaId ? 'Edytuj' : 'Dodaj'} schemat prowizyjny`}
        visible={visible}
        setVisible={setVisible}>
        <Formik
          onSubmit={onSubmit}
          initialValues={
            editedCommissionSchema
              ? editedCommissionSchema
              : {
                  documentPositionName: '',
                  productGroup: '',
                  schemaName: '',
                  structure: '',
                  description: '',
                }
          }
          enableReinitialize>
          <Form className="space-y-4">
            <InputField
              label="Nazwa schematu"
              name="schemaName"
              isReadOnly={isFetching || isLoading}
            />

            <SelectField
              options={dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.PRODUCT_GROUP] : []}
              name="productGroup"
              label="Grupa produktowa"
            />
            <SelectField
              options={
                dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.DOCUMENT_POSITION_NAME] : []
              }
              name="documentPositionName"
              label="Nazwa pozycji na DK"
            />
            <InputField name="description" label="Opis" />
            <ModalActions isLoading={isFetching || isLoading} onCancel={onCloseModal} />
          </Form>
        </Formik>
      </Modal>
    );
};
export default CommissionSchemaForm;
