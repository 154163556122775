import { DatePickerField, InputField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { updateBonusSchedule } from 'services/agent';
import { Payments } from 'types/bonus';
import { DictionaryTypes } from 'types/dictionary';

const validationSchema = Yup.object().shape({
  grossValue: Yup.number().required(requiredFieldMessage),
  netValue: Yup.number().required(requiredFieldMessage),
  paymentDate: Yup.string().required(requiredFieldMessage),
  taxPercent: Yup.string().required(requiredFieldMessage),
});

type PaymentsPayload = {
  bonusId: number;
  payload: Payments[];
};

type Props = {
  visible: boolean;
  dictionaryTypes?: DictionaryTypes;
  editedPayment: Payments;
  payments: Payments[];
  setVisible: (value: boolean) => void;
  refetch: () => void;
};

const PaymentForm = ({
  visible,
  editedPayment: loadedPayment,
  dictionaryTypes,
  payments,
  setVisible,
  refetch,
}: Props) => {
  const editedPayment: Payments = loadedPayment && {
    ...loadedPayment,
    paymentDate: parse(loadedPayment.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).toString(),
  };
  const { bonusId, agentId = '' } = useParams();

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    PaymentsPayload
  >(({ payload, bonusId }) => updateBonusSchedule(parseInt(agentId), bonusId, payload), {
    onSuccess: () => {
      refetch();
      setVisible(false);
    },
  });

  const onSubmit = (payment: Payments) => {
    if (editedPayment && bonusId) {
      const { bonusPaymentId: paymentId } = editedPayment;
      const newPaymentsList = [...payments];

      const index = payments.findIndex(({ bonusPaymentId }) => bonusPaymentId === paymentId);

      if (payment)
        newPaymentsList.splice(index, 1, {
          ...payment,
          paymentDate: format(new Date(payment.paymentDate), 'dd-MM-yyyy HH:mm:ss'),
        } as Payments);

      mutate({ payload: newPaymentsList, bonusId: parseInt(bonusId) });
    }
  };

  const defaultValues: Payments = {
    grossValue: 0,
    netValue: 0,
    paymentDate: '',
  };

  const initialValues = useMemo(() => {
    if (loadedPayment) {
      const { paymentDate } = loadedPayment;

      return { ...loadedPayment, paymentDate: paymentDate ?? '' };
    }
    return defaultValues;
  }, [loadedPayment]);

  return (
    <Modal title="Edytuj płatność" visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        <Form className="space-y-4">
          <InputField name="grossValue" label="Wartość brutto" type="number" />
          <InputField name="netValue" label="Wartość netto" type="number" />
          <DatePickerField name="paymentDate" label="Data płatności" required />
          <SelectField
            name="taxPercent"
            label="Podatek VAT"
            options={dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.VAT_RATE] : []}
          />

          <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default PaymentForm;
