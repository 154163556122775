import { CheckboxField, InputField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { ADDRESS_TYPE } from 'constants/addressType';
import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { modifyAgentsBillingInfo } from 'services/agent';
import { AgentBillingDetailsPayload, EconomyActivities } from 'types/agent';

const isCompany = (type: COOPERATION_FORMS) =>
  type === COOPERATION_FORMS.COMPANY || type === COOPERATION_FORMS.SOLE_PROPRIETORSHIP;

const validationSchema = yup.lazy(() => {
  return yup.object({
    bankAccount: yup
      .string()
      .when('type', {
        is: (type: COOPERATION_FORMS) => isCompany(type),
        then: yup.string().required(requiredFieldMessage),
      })
      .default(''),
    primaryBillingEmailAddress: yup.string().email('Nieprawidłowy adres E-mail'),
    secondBillingEmailAddress: yup.string().email('Nieprawidłowy adres E-mail'),
  });
});

type Props = {
  economyActivities?: EconomyActivities;
  agentCode?: string;
  rauNumber?: string;
  officeDictionaryKey?: string;
  refetchAgent: () => void;
};

type AgentBillingDetailsParam = {
  payload: AgentBillingDetailsPayload;
  cooperationId: number;
};

const BillingDetailsForm = ({
  economyActivities,
  agentCode,
  rauNumber,
  officeDictionaryKey,
  refetchAgent,
}: Props) => {
  const agentId = useAgentId();
  const { activeDictionaries } = useDictionaryContext();
  const navigate = useNavigate();

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, AgentBillingDetailsParam>(
    ({ payload, cooperationId }) => modifyAgentsBillingInfo(agentId, cooperationId, payload),
    {
      onSuccess: () => {
        refetchAgent();
        navigate('..');
      },
    }
  );

  const initialValues = useMemo(() => {
    if (economyActivities) {
      const {
        name,
        lastName,
        companyName,
        bankAccount,
        invoiceAddressType,
        primaryBillingEmailAddress,
        secondBillingEmailAddress,
        sendInvoiceByEmail,
        pesel,
        type,
      } = economyActivities;

      return {
        type: type ?? '',
        name: name ?? '',
        lastName: lastName ?? '',
        companyName: companyName ?? '',
        agentCode: agentCode ?? '',
        bankAccount: bankAccount ?? '',
        rauNumber: rauNumber ?? undefined,
        invoiceAddressType: invoiceAddressType ?? ADDRESS_TYPE.BILLING_ADDRESS,
        primaryBillingEmailAddress: primaryBillingEmailAddress ?? '',
        secondBillingEmailAddress: secondBillingEmailAddress ?? '',
        sendInvoiceByEmail: sendInvoiceByEmail ?? false,
        officeDictionaryKey: officeDictionaryKey ?? '',
        pesel: pesel ?? '',
      };
    }

    return {
      type: '',
      name: '',
      lastName: '',
      companyName: '',
      agentCode: '',
      bankAccount: '',
      rauNumber: '',
      invoiceAddressType: ADDRESS_TYPE.BILLING_ADDRESS,
      primaryBillingEmailAddress: '',
      secondBillingEmailAddress: '',
      sendInvoiceByEmail: false,
      officeDictionaryKey: '',
      pesel: '',
    };
  }, [economyActivities, agentCode, rauNumber]);

  const filteredAddressTypes = () =>
    activeDictionaries?.[DICTIONARY_TYPES.ADDRESS_TYPE].filter(({ key }) =>
      economyActivities?.addresses.some(({ type }) => type === key)
    );

  const onSubmit = (
    values: AgentBillingDetailsPayload & {
      type: string;
    }
  ) => {
    const { type, ...payload } = values;

    if (economyActivities) mutate({ payload: payload, cooperationId: economyActivities.id });
  };

  const isIndividual = economyActivities?.type === COOPERATION_FORMS.INDIVIDUAL;

  return (
    <Modal title={'Edytuj dane rozliczeniowe Kontrahenta'} visible>
      {economyActivities && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          <Form className="space-y-4">
            <InputField name="agentCode" label="Kod agenta" isDisabled />
            <InputField name="name" label="Imię" />
            <InputField name="lastName" label="Nazwisko " />
            <InputField name="companyName" label="Nazwa firmy" />
            <InputField
              name="bankAccount"
              label="Numer rachunku bankowego"
              isRequired={!isIndividual}
            />

            <SelectField
              name="invoiceAddressType"
              label="Adres do faktury"
              options={filteredAddressTypes() ?? []}
            />
            <InputField
              name="primaryBillingEmailAddress"
              label="Adres e-mail do wysyłki rozliczeń"
            />
            <InputField
              name="secondBillingEmailAddress"
              label="Drugi adres e-mail do wysyłki rozliczeń"
            />
            <InputField name="pesel" label="Pesel" />
            <InputField name="rauNumber" label="Numer RAU" />
            <SelectField
              name="officeDictionaryKey"
              label="Biuro"
              options={activeDictionaries ? activeDictionaries[DICTIONARY_TYPES.OFFICE] : []}
            />

            <CheckboxField name="sendInvoiceByEmail">
              Zgoda na wysyłkę dokumentów drogą elektroniczną
            </CheckboxField>

            <ModalActions isLoading={isUpdateLoading} onCancel={() => navigate('..')} />
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        </Formik>
      )}
    </Modal>
  );
};
export default BillingDetailsForm;
