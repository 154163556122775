import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { getAgentName } from 'helpers/agents';
import { EconomyActivities } from 'types/agent';
import { Recommendation } from 'types/recommendation';

const formatDate = ({ value = '' }: Cell<Recommendation>) => (value ? value.split(' ')[0] : '');

export const columns: Column<Recommendation>[] = [
  {
    Header: 'Data od',
    accessor: 'validFrom',
    Cell: formatDate,
  },
  {
    Header: 'Data do',
    accessor: 'validTo',
    Cell: formatDate,
  },
  {
    Header: 'Numer agenta',
    id: 'referred.agentCode',
    accessor: 'referred',
    Cell: ({ value }) => value.agentCode,
  },
  {
    Header: 'Polecony',
    accessor: 'referred',
    Cell: ({ value }) => {
      return getAgentName(value.economyActivities as EconomyActivities[]);
    },
  },
  {
    id: 'operations',
    disableSortBy: true,
    Cell: ({ row: { original: recommendation } }: Cell<Recommendation>) => {
      const navigate = useNavigate();

      return (
        <div className="flex space-x-2">
          <Button
            onPress={() => navigate('delete', { state: { recommendation } })}
            size="sm"
            variant="light">
            Usuń
          </Button>
        </div>
      );
    },
  },
];
