import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { Policy } from 'types/polices';

export const createColumn = (
  cancelPolicy: (id: number) => void,
  editPolicy: (policy: Policy) => void
): Column<Policy>[] => {
  return [
    {
      Header: 'Sygnatura polisy',
      accessor: 'policySignature',
    },
    {
      Header: 'Sygnatury produktów',
      accessor: 'productSignatures',
      Cell: ({ value }) => value.join(',\n'),
    },
    {
      Header: 'Identyfikator agenta',
      id: 'agent.id',
      accessor: 'agentCode',
    },
    {
      Header: 'Procent dla agenta',
      accessor: 'percentageForSecondAgent',
      Cell: ({ value }) => `${value * 100}%`,
    },
    {
      Header: 'Identyfikator drugiego agenta',
      id: 'secondAgent.id',
      accessor: 'secondAgentCode',
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Policy>) => (
        <div className="flex space-x-6 justify-end">
          <Button variant="light" onPress={() => editPolicy(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>

          <Button variant="light" onPress={() => cancelPolicy(original.id)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
