export const AGENT = {
  AGENTS: 'AGENTS',
  AGENTS_POLICIES_BLOCKADES: 'AGENTS_POLICIES_BLOCKADES',
  AGENT: 'AGENT',
  AGENT_BLOCKADES: 'AGENT_BLOCKADES',
  AGENT_BONUSES: 'AGENT_BONUSES',
  AGENT_BONUS: 'AGENT_BONUS',
  AGENT_PAYOUT_HISTORY: 'AGENT_PAYOUT_HISTORY',
  AGENT_POSITIONS: 'AGENT_POSITIONS',
  AGENT_INSURANCE_COMPANIES: 'AGENT_INSURANCE_COMPANIES',
  AGENT_REFERRED_AGENTS: 'AGENT_REFERRED_AGENTS',
  FILE_TYPES: 'FILE_TYPES',
  PERIOD_PARAMETERS: 'PERIOD_PARAMETERS',
};
