import {
  AutoCompleteField,
  Button,
  DatePickerField,
  InputField,
  Option,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { parse } from 'date-fns';
import { FieldArray, getIn, useFormikContext } from 'formik';
import SchemaPropagationFields from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { COMMISSION } from 'constants/queries/commission';
import { createAgentPositionsOptions, createAgentsOptions } from 'helpers/agents';
import { generateVatRateOptions } from 'helpers/select';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getCommissionSchema } from 'services/commission';
import { CommissionSchema, Program } from 'types/commission';

import { useAgentQuery } from '../../../../../../hooks/useAgentQuery';
import { AddAgentModalFormValues } from '../AddAgentToStructureModal/AddAgentToStructureModal';

type Props = {
  schemas: Option[];
  isSubmitError: boolean;
  submitError: AxiosError | null;
  isSubmitting: boolean;
  resetView: () => void;
};

const AddAgentToStructureModalForm = ({
  schemas,
  isSubmitError,
  submitError,
  isSubmitting,
  resetView,
}: Props) => {
  const [isCurrentlyLoading, setIsCurrentlyLoading] = useState<boolean>(false);
  const [superiorPositionOptions, setSuperiorPositionOptions] = useState<Option[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [defaultProgramsAssigned, setDefaultProgramsAssigned] = useState<boolean>(false);
  const { activeDictionaries } = useDictionaryContext();
  const { values } = useFormikContext<AddAgentModalFormValues>();
  const commissionSchemaId = getIn(values, 'commissionSchemaId');
  const childAgentId = getIn(values, 'childAgentId');
  const positionDictionaryKey = getIn(values, 'positionDictionaryKey');
  const parentAgentId = getIn(values, 'parentAgentId');
  const superiorPositionDictionaryKey = getIn(values, 'superiorPositionDictionaryKey');

  const [queryParentAgent, setQueryParentAgent] = useState<string>('');
  const [queryChildAgent, setQueryChildAgent] = useState<string>('');

  const {
    data: commissionSchema,
    isFetching,
    isError,
    error,
  } = useQuery<CommissionSchema | null, AxiosError>(
    [COMMISSION.COMMISSION_SCHEMA, commissionSchemaId, values.subordinationValidFrom],
    () => {
      if (commissionSchemaId) {
        return getCommissionSchema(
          parseInt(commissionSchemaId),
          undefined,
          values.subordinationValidFrom > new Date() && false
        );
      }

      return null;
    }
  );

  const { data: searchChildAgents } = useAgentQuery({
    queryParam: queryChildAgent,
    minQueryLength: 3,
  });

  const { data: searchParentAgents } = useAgentQuery({
    queryParam: queryParentAgent,
    minQueryLength: 3,
  });

  const childAgentsOptions = useMemo(
    () => createAgentsOptions(searchChildAgents?.content || [], 'id'),
    [searchChildAgents]
  );
  const parentAgentsOptions = useMemo(
    () => createAgentsOptions(searchParentAgents?.content || [], 'id'),
    [searchParentAgents]
  );

  const vatRateOptions = useMemo(() => generateVatRateOptions(activeDictionaries) ?? [], []);

  useMemo(() => {
    const selectedSubordinate = searchParentAgents?.content?.find(
      (agent) => agent.id === parentAgentId
    );

    if (selectedSubordinate) {
      setSuperiorPositionOptions(
        createAgentPositionsOptions(selectedSubordinate, commissionSchemaId)
      );
    }
  }, [searchParentAgents, parentAgentId, commissionSchemaId]);

  useMemo(() => {
    const programs = commissionSchema?.programs || [];

    setPrograms([
      ...programs.filter(
        (program) =>
          program.superiorPosition === superiorPositionDictionaryKey &&
          program.subordinatePosition === positionDictionaryKey &&
          (!!values.subordinationValidFrom && program.validFrom
            ? parse(program.validFrom, 'dd-MM-yyyy HH:mm:ss', new Date()) <=
              new Date(values.subordinationValidFrom)
            : true) &&
          (!!values.subordinationValidFrom && program.validTo
            ? new Date(values.subordinationValidFrom) <=
              parse(program.validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
            : true)
      ),
    ]);
  }, [
    commissionSchema?.programs,
    positionDictionaryKey,
    superiorPositionDictionaryKey,
    values.subordinationValidFrom,
  ]);

  useEffect(() => {
    resetView();
  }, [values]);

  useEffect(() => {
    const loadingIndicator = isFetching || isSubmitting;
    setIsCurrentlyLoading(loadingIndicator);
  }, [isFetching, isSubmitting]);

  return (
    <>
      <div className="pb-4">
        <div className="flex w-full justify-between items-center my-2">
          <p>Data od</p>
          <div className="w-1/2">
            <DatePickerField
              disabled={isCurrentlyLoading}
              preventOpenOnFocus
              dateFormat="dd-MM-yyyy"
              placeholder="Data od"
              name="subordinationValidFrom"
              autoFocus={false}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center my-2">
          <p>Schemat prowizyjny</p>
          <div className="w-1/2">
            <SelectField
              name="commissionSchemaId"
              placeholder="Schemat prowizyjny"
              options={schemas}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center my-2">
          <p>Kontrahent</p>
          <div className="w-1/2">
            <AutoCompleteField
              label="Kontrahent"
              name="childAgentId"
              options={childAgentsOptions}
              placeholder="Kontrahent"
              query={queryChildAgent}
              setQuery={setQueryChildAgent}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center my-2">
          <p>Stanowisko kontrahenta</p>
          <div className="w-1/2">
            <SelectField
              name="positionDictionaryKey"
              placeholder="Stanowisko kontrahenta"
              options={activeDictionaries?.[DICTIONARY_TYPES.POSITION] || []}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center my-2">
          <p>Nadrzędny</p>
          <div className="w-1/2">
            <AutoCompleteField
              label="Nadrzędny"
              name="parentAgentId"
              options={parentAgentsOptions}
              placeholder="Nadrzędny"
              query={queryParentAgent}
              setQuery={setQueryParentAgent}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center my-2">
          <p>Stanowisko nadrzędnego</p>
          <div className="w-1/2">
            <SelectField
              name="superiorPositionDictionaryKey"
              placeholder="Stanowisko nadrzędnego"
              options={superiorPositionOptions}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center my-2">
          <p>Warunki prowizyjne</p>
          <div className="w-1/2">
            <FieldArray name="commissionRatesData">
              {(helpers) => {
                return (
                  <div>
                    <div hidden={defaultProgramsAssigned}>
                      <Button
                        className="w-full my-8"
                        onPress={() => {
                          setDefaultProgramsAssigned(true);
                          programs?.[0].conditions.forEach((condition) => {
                            helpers.unshift({
                              condition: condition.condition,
                              vatRate: condition.vatRate,
                              commissionRate: (condition.commissionRate * 100).toFixed(2),
                            });
                          });
                        }}
                        isDisabled={
                          !(
                            !!commissionSchemaId &&
                            !!parentAgentId &&
                            !!superiorPositionDictionaryKey &&
                            !!childAgentId &&
                            !!positionDictionaryKey
                          ) ||
                          !programs.length ||
                          isCurrentlyLoading ||
                          defaultProgramsAssigned
                        }>
                        Przypisz na podstawie konfiguracji schematu
                      </Button>
                    </div>
                    <div className="flex w-full items-center my-8">
                      <div className="flex flex-col gap-4 w-full">
                        <p>Lub dodaj pojedynczo</p>
                        <Button
                          isDisabled={isCurrentlyLoading}
                          onPress={() =>
                            helpers.push({
                              commissionRate: 0.01,
                              condition: '',
                              vatRate: 'VAT_ZW',
                            })
                          }>
                          Dodaj warunek
                        </Button>
                        {values?.commissionRatesData?.map((rateData, index) => {
                          return (
                            <div className="flex flex-col space-y-4" key={index}>
                              <SelectField
                                options={
                                  activeDictionaries?.[DICTIONARY_TYPES.COMMISSION_CONDITION] || []
                                }
                                name={`commissionRatesData[${index}].condition`}
                                placeholder="Program"
                              />

                              <SelectField
                                options={vatRateOptions}
                                name={`commissionRatesData[${index}].vatRate`}
                                placeholder="Stawka Vat"
                              />

                              <div className="flex justify-between gap-4 my-2">
                                <div className="flex items-center justify-center gap-4">
                                  <InputField
                                    isDisabled={isCurrentlyLoading}
                                    aria-label="Prowizja"
                                    name={`commissionRatesData[${index}].commissionRate`}
                                    type="number"
                                    className="!py-3"
                                  />
                                  <span className="block">%</span>
                                </div>
                                <Button
                                  onPress={() => helpers.remove(index)}
                                  isDisabled={isCurrentlyLoading}>
                                  <i className="bi bi-trash cursor-pointer" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              }}
            </FieldArray>
          </div>
        </div>
        <SchemaPropagationFields
          commissionSchemaId={commissionSchema?.commissionSchemaId}
          productGroup={commissionSchema?.productGroup}
          isLoading={isCurrentlyLoading}
        />
      </div>

      {(isError || isSubmitError) && <ErrorMessages error={error || submitError} />}
    </>
  );
};

export default AddAgentToStructureModalForm;
