import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type ChartData = {
  name: string;
  value: number;
};

type Props = {
  data?: ChartData[];
};

const Chart = ({ data }: Props) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 24,
          right: 30,
          bottom: 5,
          left: 30,
        }}
        barSize={20}>
        <defs>
          <linearGradient
            id="colorUv"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#00E5C9" />
            <stop offset="0.7" stopColor="#00E5C9" />
            <stop offset="1" stopColor="#011983" />
          </linearGradient>
        </defs>
        <XAxis axisLine={false} dataKey="name" scale="point" padding={{ left: 25, right: 25 }} />
        <YAxis
          axisLine={false}
          padding={{ bottom: 20 }}
          domain={data?.some(({ value }) => value < 0) ? ['auto', 0] : [0, 'auto']}
        />
        <Tooltip />
        <CartesianGrid vertical={false} strokeDasharray="1 4" />
        <Bar fill="url(#colorUv)" radius={20} dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
