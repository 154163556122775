import { Button, Modal, Table, Text } from '@profitowi/component-library';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { columns } from './columns';

const ItemDetailsModal = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.billingItems.length) {
      navigate('..');
    }
  }, [navigate, state?.billingItems.length]);

  return (
    <Modal title={state?.title} visible size="7xl">
      <div className="space-y-4">
        <Table columns={columns} data={state?.billingItems} />

        <div className="flex justify-center">
          <Button onPress={() => navigate('..')} variant="outline-primary">
            <Text>Zamknij</Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ItemDetailsModal;
