import { Button } from '@profitowi/component-library';
import { NavigateFunction } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { Debt } from 'types/debt';
import { formatBackendDateWithoutTime } from 'utils/date';

export const createColumn = (
  agentId: number,
  navigate: NavigateFunction,
  mutate: (debtRepaymentId: number) => void
): Column<Debt>[] => {
  return [
    {
      Header: 'kwota zadłużenia ',
      accessor: (row) => <div className="text-right">{row?.debtValue}</div>,
    },
    {
      Header: 'Liczba miesięcy',
      accessor: 'installmentsCount',
    },

    {
      Header: 'Dzień spłaty raty',
      accessor: 'repaymentDayOfMonth',
    },

    {
      Header: 'Data rozpoczęcia spłat',
      accessor: 'repaymentStartDate',
      Cell: ({
        row: {
          original: { repaymentStartDate },
        },
      }: Cell<Debt>) => formatBackendDateWithoutTime(repaymentStartDate),
    },
    {
      Header: 'Status',
      Cell: ({
        row: {
          original: { paymentStatus },
        },
      }: Cell<Debt>) => {
        const { translate } = useDictionaryContext();

        return paymentStatus ? translate(DICTIONARY_TYPES.PAYMENT_STATUS, paymentStatus) : '';
      },
    },

    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { debtRepaymentId },
        },
      }: Cell<Debt>) => (
        <div className="flex justify-end ">
          <Button
            variant="light"
            onPress={() => navigate(`/records/agent/${agentId}/debt/${debtRepaymentId}`)}>
            <i className="bi bi-gear cursor-pointer" />
          </Button>

          <Button variant="light" onPress={() => mutate(debtRepaymentId)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
