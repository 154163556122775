import { Header, Option, Select } from '@profitowi/component-library';
import { Box } from '@profitowi/component-library';

import { summaryPeriod } from 'helpers/months';
import { months } from 'helpers/months';
import { CommissionByPeriod } from 'types/commission';

import Chart from './Chart';

type ChartData = {
  name: string;
  value: number;
};

type Props = {
  commissionPeriod: Option;
  commission?: CommissionByPeriod;
  isFetched: boolean;
  setCommissionPeriod: (value: Option) => void;
};

const CommissionChart = ({
  commissionPeriod,
  commission,
  isFetched,
  setCommissionPeriod,
}: Props) => {
  const createDataForChart = () => {
    if (commission) {
      const { periods } = commission;
      const data: ChartData[] = [];

      const sortedPeriod = Object.keys(periods).sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);

        return aDate.getTime() - bDate.getTime();
      });

      sortedPeriod.forEach((period) => {
        const month = parseInt(period.substring(5));

        data.push({
          name: months[month - 1],
          value: periods[`${period}`],
        });
      });

      return data;
    }
  };

  return (
    <Box className="col-span-1 2xl:col-span-3 xl:col-span-2">
      <div className="flex flex-wrap justify-between mx-6 pt-2 items-center">
        <Header as="h4" size="lg" weight="semibold">
          Prowizje
        </Header>
        <Select
          selected={commissionPeriod}
          setSelected={setCommissionPeriod}
          options={summaryPeriod}
        />
      </div>
      {commission && isFetched && <Chart data={createDataForChart()} />}
    </Box>
  );
};

export default CommissionChart;
