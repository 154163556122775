import { Button, Icon } from '@profitowi/component-library';
import useSettlement from 'pages/Settlements/useSettlement';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Correction } from 'types/settlement';

import StatusSelect from '../StatusSelect';

export const columns: Column<Correction>[] = [
  {
    Header: 'Data dodania zdarzenia',
    accessor: 'eventCreateTime',
    id: 'createdAt',
  },
  {
    Header: 'Kod Agenta',
    accessor: 'payoutAgentCode',
    id: 'payoutAgentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: 'payoutAgentDisplayName',
    id: 'payoutAgentDisplayName',
  },
  {
    Header: 'Źródło zdarzenia',
    accessor: 'eventType',
    id: 'billingEventId',
  },
  {
    Header: 'Korygowane DK',
    accessor: 'correctedDocumentsNo',
    id: 'correction',
  },
  {
    Header: 'Poprawione pozycje rozliczeniowe',
    Cell: ({
      row: {
        original: { correctedBillingItems = [] },
      },
    }: Cell<Correction>) => {
      const navigate = useNavigate();

      return (
        <Button
          className="space-x-2"
          onPress={() =>
            navigate('details', {
              state: {
                billingItems: correctedBillingItems,
                title: 'Poprawione pozycje rozliczeniowe',
              },
            })
          }
          size="sm"
          variant="light">
          <Icon name="search" />
          <span>{correctedBillingItems.length}</span>
        </Button>
      );
    },
  },
  {
    Header: 'Wygenerowane pozycje rozliczeniowe',
    Cell: ({
      row: {
        original: { generatedBillingItems = [] },
      },
    }: Cell<Correction>) => {
      const navigate = useNavigate();

      return (
        <Button
          className="space-x-2"
          onPress={() =>
            navigate('details', {
              state: {
                billingItems: generatedBillingItems,
                title: 'Wygenerowane pozycje rozliczeniowe',
              },
            })
          }
          size="sm"
          variant="light">
          <Icon name="search" />
          <span>{generatedBillingItems.length}</span>
        </Button>
      );
    },
  },
  {
    Header: 'Status',
    Cell: ({ row: { original } }: Cell<Correction>) => {
      const { isEditable } = useSettlement();
      const { correctedBillingItems = [], generatedBillingItems = [] } = original;
      const items = [
        ...correctedBillingItems.map(({ id }) => id),
        ...generatedBillingItems.map(({ id }) => id),
      ];

      return items.length && isEditable ? (
        <StatusSelect
          items={items}
          status={generatedBillingItems[0]?.status || correctedBillingItems[0]?.status}
        />
      ) : null;
    },
  },
];
