import { Box, Button, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMUNICATION } from 'constants/queries/communication';
import { useAllAgentsQuery } from 'hooks/useAllAgentsQuery';
import { usePagination } from 'hooks/usePagination';
import { getMessages } from 'services/communication';
import { AgentList } from 'types/agent';
import { CommunicationMessage } from 'types/communication';
import { Page } from 'types/request';
import { parseSortParams } from 'utils/sort';

import { createColumns } from '../../columns';
import SendMessageModal from '../SendMessageModal/SendMessageModal';

type Props = {
  agentQueryResult: ReturnType<typeof useAllAgentsQuery>;
  agentId?: number;
  agent?: AgentList;
};

const CommunicationList = ({
  agentQueryResult: {
    data: agents,
    isLoading: isAgentListLoading,
    isError: isAgentListError,
    error: agentListError,
  },
  agent,
  agentId,
}: Props) => {
  const [agentToRespond, setAgentToRespond] = useState<AgentList>();
  const [messageToRespond, setMessageToRespond] = useState<CommunicationMessage | undefined>();
  const pagination = usePagination(10);
  const [isNewMessageModalVisible, setIsNewMessageModalVisible] = useState<boolean>(false);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([{ id: 'createdAt', desc: true }]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useQuery<
    Page<CommunicationMessage>,
    AxiosError
  >(
    [COMMUNICATION.MESSAGES, currentPage, perPage, sortBy],
    () => getMessages(agent?.id, currentPage, perPage, parseSortParams(sortBy)),
    { enabled: agentId ? !!agent : true, keepPreviousData: true }
  );

  return (
    <>
      <Box className="space-y-6 flex flex-col">
        <div className="flex justify-between items-center">
          <h4 className="text-lg font-semibold">Lista wiadomości</h4>
          <Button
            variant="outline-primary"
            isDisabled={isLoading || isFetching || (!!agentId && !agent)}
            onPress={() => setIsNewMessageModalVisible(true)}>
            <Text weight="semibold">
              Nowa wiadomość
              <i className="bi bi-plus-lg ml-2" />
            </Text>
          </Button>
        </div>

        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              pagination={pagination}
              totalPages={data.totalPages}
              data={data.content}
              columns={createColumns(agents?.content || [], (agentToModal: AgentList, message) => {
                setIsNewMessageModalVisible(true);
                setAgentToRespond(agentToModal);
                setMessageToRespond(message);
              })}
              isLoading={isFetching || isAgentListLoading}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
        {(isError || isAgentListError) && <ErrorMessages error={error ?? agentListError} />}
        {(isLoading || (!!agentId && !agent)) && <Loader className="w-12 h-12" />}
      </Box>

      {isNewMessageModalVisible && (
        <SendMessageModal
          visible={isNewMessageModalVisible}
          closeModal={() => {
            setIsNewMessageModalVisible(false);
            setMessageToRespond(undefined);
          }}
          refetch={refetch}
          agent={agent || agentToRespond}
          messageToRespond={messageToRespond}
        />
      )}
    </>
  );
};

export default CommunicationList;
