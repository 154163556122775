type Genus = 'HE' | 'SHE' | 'IT';

export const parseActiveStatus = (status: boolean, genus: Genus) => {
  switch (genus) {
    case 'HE':
      return status ? 'Aktywny' : 'Nieaktywny';
    case 'SHE':
    default:
      return status ? 'Aktywna' : 'Nieaktywna';
  }
};

export const parseVatRate = (vatRate: string) => {
  if (vatRate !== 'zw' && vatRate) {
    return `${parseFloat(vatRate) * 100}%`;
  }

  if (vatRate === 'zw') {
    return 'Zwolniony';
  }

  return vatRate;
};
