import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { AgentBlockade } from 'types/agent';

export const columns: Column<AgentBlockade>[] = [
  {
    Header: 'Sygnatura polisy',
    accessor: 'signature',
  },
  {
    Header: 'Data utworzenia blokady',
    accessor: 'blockadeCreateDate',
  },
  {
    id: 'operations',
    disableSortBy: true,
    Cell: ({
      row: {
        original: { signature },
      },
    }: Cell<AgentBlockade>) => {
      const navigate = useNavigate();

      return (
        <div className="flex space-x-6 justify-end">
          <Button
            variant="light"
            onPress={() =>
              navigate('deleteBlockade', {
                state: {
                  signature,
                },
              })
            }>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      );
    },
  },
];
