import { Button, Loader } from '@profitowi/component-library';

type Props = {
  cancelLabel?: string;
  confirmLabel?: string;
  isLoading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  disableSubmit?: boolean;
};

const ModalActions = ({
  cancelLabel = 'Anuluj',
  confirmLabel = 'Zapisz',
  isLoading = false,
  onCancel = () => {},
  onConfirm = () => {},
  disableSubmit = false,
}: Props) => {
  return (
    <div className="flex space-x-8 items-center justify-center w-full">
      <Button isDisabled={isLoading} onPress={onCancel} type="reset" variant="outline-primary">
        {cancelLabel}
      </Button>
      <Button
        className="flex space-x-2"
        isDisabled={isLoading || disableSubmit}
        onPress={onConfirm}
        type="submit"
        variant="primary">
        {isLoading && <Loader className="h-4 w-4 !border-white" />}
        <span>{confirmLabel}</span>
      </Button>
    </div>
  );
};

export default ModalActions;
