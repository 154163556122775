import { Button, Icon } from '@profitowi/component-library';
import useSettlement from 'pages/Settlements/useSettlement';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Contract } from 'types/settlement';

import StatusSelect from '../StatusSelect';

export const columns: Column<Contract>[] = [
  {
    Header: 'Data zdarzenia',
    accessor: 'eventCreateTime',
    id: 'eventCreationDate',
  },
  {
    Header: 'Id zdarzenia',
    accessor: 'eventId',
    id: 'billingEventId',
  },
  {
    Header: 'Nazwa zdarzenia',
    accessor: 'eventName',
  },
  {
    Header: 'Kontrahent',
    accessor: 'contractAgentDisplayName',
    id: 'contractAgentDisplayName',
  },
  {
    Header: 'Szczegóły zdarzenia',
    accessor: 'contractNumber',
    id: 'eventDetails',
  },
  {
    Header: 'Podstawa naliczenia',
    accessor: 'baseValueDescription',
    id: 'baseValueDescription',
  },
  {
    Header: 'Zawarte pozycje rozliczeniowe',
    Cell: ({
      row: {
        original: { includedBillingItems },
      },
    }: Cell<Contract>) => {
      const navigate = useNavigate();

      return (
        <Button
          className="space-x-2"
          onPress={() =>
            navigate('details', {
              state: {
                billingItems: includedBillingItems,
                title: 'Zawarte pozycje rozliczeniowe',
              },
            })
          }
          size="sm"
          variant="light">
          <Icon name="search" />
          <span>{includedBillingItems.length}</span>
        </Button>
      );
    },
  },
  {
    Header: 'Wygenerowane pozycje rozliczeniowe',
    Cell: ({
      row: {
        original: { generatedBillingItems },
      },
    }: Cell<Contract>) => {
      const navigate = useNavigate();

      return (
        <Button
          className="space-x-2"
          onPress={() =>
            navigate('details', {
              state: {
                billingItems: generatedBillingItems,
                title: 'Wygenerowane pozycje rozliczeniowe',
              },
            })
          }
          size="sm"
          variant="light">
          <Icon name="search" />
          <span>{generatedBillingItems.length}</span>
        </Button>
      );
    },
  },
  {
    Header: 'Status',
    Cell: ({ row: { original } }: Cell<Contract>) => {
      const { isEditable } = useSettlement();
      const { includedBillingItems = [], generatedBillingItems = [] } = original;
      const items = [
        ...includedBillingItems.map(({ id }) => id),
        ...generatedBillingItems.map(({ id }) => id),
      ];

      return items.length && isEditable ? (
        <StatusSelect
          items={items}
          status={generatedBillingItems[0]?.status || includedBillingItems[0]?.status}
        />
      ) : null;
    },
  },
];
