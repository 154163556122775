import { DatePickerField, InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { updateAdvance } from 'services/advancePayment';
import { AdvancePayment, Installment } from 'types/advancePayment';
import { DictionaryTypes } from 'types/dictionary';

const validationSchema = Yup.object().shape({
  grossValue: Yup.number().required(requiredFieldMessage),
  paymentDate: Yup.string().required(requiredFieldMessage),
});

type Props = {
  advancePayment: AdvancePayment;
  visible: boolean;
  dictionaryTypes: DictionaryTypes;
  editedInstallment: Installment;
  setVisible: (value: boolean) => void;
  refetch: () => void;
};

const AdvanceInstallmentForm = ({
  visible,
  advancePayment,
  editedInstallment,
  setVisible,
  refetch,
}: Props) => {
  const { agentId = '' } = useParams();

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { payload: AdvancePayment } & { advancePaymentId: number }
  >(
    ({ payload, advancePaymentId }) => updateAdvance(parseInt(agentId), payload, advancePaymentId),
    {
      onSuccess: () => {
        setVisible(false);
        refetch();
      },
    }
  );

  const onSubmit = (installment: Partial<Installment>) => {
    const { installments, advancePaymentId } = advancePayment;
    const { advancePaymentInstallmentId, paymentDate } = installment;
    const installmentPayments = [...installments];

    const index = installmentPayments.findIndex(
      (item) => item.advancePaymentInstallmentId === advancePaymentInstallmentId
    );
    if (index >= 0 && installment && paymentDate) {
      installmentPayments.splice(index, 1, {
        ...installment,
        paymentDate: format(startOfDay(new Date(paymentDate)), 'dd-MM-yyyy HH:mm:ss'),
      } as Installment);

      const payload = {
        ...advancePayment,
        installments: installmentPayments,
      };

      mutate({ payload, advancePaymentId });
    }
  };

  const initialValues = {
    ...editedInstallment,
    paymentDate: parse(editedInstallment.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).toString(),
  };

  return (
    <Modal title="Edytuj ratę zaliczki" visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        <Form className="space-y-4">
          <InputField name="grossValue" label="Wartość brutto" type="number" />

          <DatePickerField
            dateFormat={'dd-MM-yyyy'}
            label="Planowana data spłaty"
            name="paymentDate"
          />

          <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default AdvanceInstallmentForm;
