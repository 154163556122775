import { Box, Button, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { getBlockades } from 'services/agent';
import { AgentBlockade } from 'types/agent';
import { decodeUriSortParams } from 'utils/table';

import { columns } from './columns';
import AddBlockadeModal from './components/AddBlockadeModal/AddBlockadeModal';
import { DeleteBlockadeModal } from './components/DeleteBlockadeModal/DeleteBlockadeModal';

const Blockades = () => {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, isLoading, isSuccess, isError, error, refetch, isFetching } = useQuery<
    AgentBlockade[],
    AxiosError
  >([AGENT.AGENTS_POLICIES_BLOCKADES, sortBy], () => getBlockades(decodeUriSortParams(sortBy)), {
    keepPreviousData: true,
  });

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Blokady
          </Header>

          <Button onPress={() => navigate('addBlockade')}>Dodaj blokadę</Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={data}
              isLoading={isFetching}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path="addBlockade" element={<AddBlockadeModal refetch={refetch} />} />
        <Route path="deleteBlockade" element={<DeleteBlockadeModal refetch={refetch} />} />
      </Routes>
    </>
  );
};

export default Blockades;
