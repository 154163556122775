import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { Dictionary } from 'types/dictionary';

const positionCodeMapper: { [key: string]: string } = {
  MinKwotaDoWyplaty: 'Minimalna kwota do wypłaty',
  DOCUMENT_PROFITOWI_NAME: 'Dane nabywcy - nabywca',
  DOCUMENT_PROFITOWI_ADRESS: 'Dane nabywcy - adres',
  DOCUMENT_PROFITOWI_NIP: 'Dane nabywcy - nip',
  DOCUMENT_FORMA_PLATNOSCI: 'Forma płatności',
  DOCUMENT_TERMIN_PLATNOSCI: 'Termin płatności',
  ZmianaHaslaCoIleDni: 'Wymuszanie zmiany hasła (dni)',
  AutomatyczneWylogowanieCoIleMin: 'Automatyczne wylogowanie (minuty)',
  DniSynchronizacjiCEIDG: 'Reguła synchronizacji z CEIDG (numer dnia)',
  WyswietlanyMiesiacNaDashboardzieOdDnia: 'Miesiąc wyświetlany na dashboardzie (od dnia)',
  ProfitowiNumerKontaBankowego: 'Numer konta ("ProfitowiNumerKontaBankowego")',
  ProfitowiPrzelewAdres: 'Przelew - adres ("ProfitowiPrzelewAdres")',
};

export const createColumn = (
  setEditedItem: (dictionary: Dictionary) => void
): Column<Dictionary>[] => {
  return [
    {
      Header: 'Wartość Pozycji',
      accessor: 'value',
    },
    {
      Header: 'kod Pozycji',
      id: 'key',
      accessor: (row: Dictionary) => {
        return positionCodeMapper[row.key] || row.key;
      },
    },
    {
      id: 'edit',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Dictionary>) => (
        <div className="flex justify-end">
          <Button variant="light" onPress={() => setEditedItem(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
