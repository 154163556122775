import { Button, DatePickerField, Option, SelectField } from '@profitowi/component-library';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import * as yup from 'yup';

import { DELEGATION } from 'constants/queries/delegation';
import useDictionaryContext from 'hooks/useDictionaryContext';

import { useDelegationViceQuery } from '../hooks/useDelegationViceQuery';
import { validationSchema } from './validationSchema';

export const initialValues = {
  commissionSchema: null,
  originalParentCode: '',
  parentCode: '',
  time: new Date(),
};

export type DelegationFilterFormValues = yup.InferType<typeof validationSchema>;

type Props = {
  payload: DelegationFilterFormValues;
  setPayload: (values: DelegationFilterFormValues) => void;
};

const DelegationFilterForm = ({ payload, setPayload }: Props) => {
  const { activeDictionaries } = useDictionaryContext();

  const { data: delegationViceFrom } = useDelegationViceQuery({
    queryKey: DELEGATION.DELEGATION_VICE_FROM,
    delegateFrom: true,
    delegatedOnly: true,
  });

  const { data: delegationViceTo } = useDelegationViceQuery({
    queryKey: DELEGATION.DELEGATION_VICE_TO,
    delegatedOnly: true,
  });

  const commissionSchemaOptions: Option[] = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(activeDictionaries?.COMMISSION_SCHEMA_NAME?.map(({ key, value }) => ({
        key,
        value,
      })) || []),
    ],
    [activeDictionaries?.COMMISSION_SCHEMA_NAME]
  );

  const delegationViceFromOptions: Option[] = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(delegationViceFrom?.map(({ agentCode, name }) => ({
        key: agentCode,
        value: `${agentCode} - ${name}`,
      })) || []),
    ],
    [delegationViceFrom]
  );

  const delegationViceToOptions: Option[] = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(delegationViceTo?.map(({ agentCode, name }) => ({
        key: agentCode,
        value: `${agentCode} - ${name}`,
      })) || []),
    ],
    [delegationViceTo]
  );

  const handleSubmit = (values: DelegationFilterFormValues) => setPayload(values);
  const handleReset = () => setPayload(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ values }) => (
        <Form className="space-y-4 flex flex-col">
          <SelectField label="Schemat" name="commissionSchema" options={commissionSchemaOptions} />
          <SelectField
            label="Kod wicedyrektora od którego przeniesiono podrzędnych"
            name="originalParentCode"
            options={delegationViceFromOptions}
          />
          <SelectField
            label="Kod wycedyrektora do którego przeniesiono podrzędnych"
            name="parentCode"
            options={delegationViceToOptions}
          />
          <DatePickerField label="Delegacja na dzień" name="time" />

          <div className="flex space-x-4">
            <Button isDisabled={isEqual(values, payload)} size="sm" variant="primary" type="submit">
              Szukaj
            </Button>

            <Button size="sm" variant="primary" type="reset" onPress={handleReset}>
              Wyczyść filtry
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DelegationFilterForm;
