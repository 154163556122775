import { Page } from 'types/request';
import { AddUserPayload, EditRolesPayload, EditUserPayload, User } from 'types/user';
import { recordmodApi } from 'utils/axios';

export const getUser = async (userId: string) => {
  const { data } = await recordmodApi.get<User>(`/keycloak/users/${userId}`);
  return data;
};

export const getUsers = async (page: number, size: number, search?: string) => {
  const { data } = await recordmodApi.get<Page<User>>('/keycloak/users', {
    params: { page, size, search },
  });
  return data;
};

export const getUserDetails = async () => {
  const { data } = await recordmodApi.get<User>('/keycloak/users/me');
  return data;
};

export const getUserByAgentId = async (agentId: number) => {
  const { data } = await recordmodApi.get<User>('/keycloak/users/search', {
    params: { agentId },
  });
  return data;
};

export const createUser = (payload: AddUserPayload) => {
  return recordmodApi.post(`/keycloak/users`, payload);
};

export const editUser = (payload: EditUserPayload, userId: string) => {
  return recordmodApi.put(`/keycloak/users/${userId}`, payload);
};

export const editRoles = (payload: EditRolesPayload, userId: string) => {
  return recordmodApi.put(`/keycloak/users/${userId}/roles`, payload);
};

export const enableUser = (userId?: string) => {
  return recordmodApi.patch<User>(`/keycloak/users/${userId}/enable`);
};

export const disableUser = (userId?: string) => {
  return recordmodApi.patch<User>(`/keycloak/users/${userId}/disable`);
};

export const resetPassword = (userId?: string) => {
  return recordmodApi.patch<User>(`/keycloak/users/${userId}/reset`);
};

export const changePassword = (userId: string, password: string, temporary: boolean) => {
  return recordmodApi.put<User>(`/keycloak/users/${userId}/changePassword`, {
    password,
    temporary,
  });
};

export const updateLastLoginDate = () => {
  return recordmodApi.put('/keycloak/users/me/login');
};
