import useSettlement from 'pages/Settlements/useSettlement';
import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { Bonus } from 'types/settlement';

import StatusSelect from '../StatusSelect';

export const columns: Column<Bonus>[] = [
  {
    Header: 'Data dodania zdarzenia',
    accessor: 'eventCreateTime',
    id: 'eventCreationDate',
  },
  {
    Header: 'Kod Agenta',
    accessor: 'payoutAgentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: 'payoutAgentDisplayName',
  },
  {
    Header: 'Źródło zdarzenia',
    accessor: 'eventId',
    id: 'billingEventId',
  },
  {
    Header: 'Tytułem',
    accessor: 'title',
    id: 'invoiceItemName',
  },
  {
    Header: 'Kwota brutto',
    accessor: (row) => <div className="text-right">{row?.grossValue}</div>,
    id: 'gross',
  },
  {
    Header: 'Waluta',
    accessor: 'currency',
    disableSortBy: true,
  },
  {
    Header: 'Korygowany dokument księgowy',
    accessor: 'correctedDocument',
    id: 'correctingDocumentNumber',
  },
  {
    Header: 'Status',
    Cell: ({ row: { original } }: Cell<Bonus>) => {
      const { isEditable } = useSettlement();
      const { translate } = useDictionaryContext();

      return isEditable ? (
        <StatusSelect items={[original.eventId]} status={original?.status ?? 'ACCEPTED'} />
      ) : (
        original?.status && translate('BILLING_ITEM_STATUS', String(original?.status))
      );
    },
  },
];
