import { Box, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import BackArrow from 'components/BackArrow/BackArrow';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { ADVANCE } from 'constants/queries/advance';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getAdvancePayment } from 'services/advancePayment';
import { AdvancePayment, Installment } from 'types/advancePayment';

import AdvanceInstallmentForm from './AdvanceInstallmentForm/AdvanceInstallmentForm';
import { createColumn } from './columns';

const AdvanceInstallments = () => {
  const [visible, setVisible] = useState(false);
  const [editedInstallment, setEditedInstallment] = useState<Installment>();
  const { activeDictionaries } = useDictionaryContext();
  const { advanceId = '', agentId = '' } = useParams();

  const { data, refetch, isError, isLoading, isFetching, isSuccess, error } = useQuery<
    AdvancePayment,
    AxiosError
  >(
    [ADVANCE.ADVANCE_INSTALLMENT, advanceId],
    () => getAdvancePayment(parseInt(agentId), parseInt(advanceId)),
    {
      select: (advance) => {
        return {
          ...advance,
          installments: advance.installments.sort(
            (a, b) => a.advancePaymentInstallmentId - b.advancePaymentInstallmentId
          ),
        };
      },
      keepPreviousData: true,
    }
  );

  const installments = useMemo(() => {
    if (!data) return;
    const [, ...second] = data.installments;
    return second;
  }, [data]);

  const editInstallment = (installment: Installment) => {
    setEditedInstallment(installment);
    setVisible(true);
  };

  return (
    <>
      <div className="flex-1 p-8 space-y-6">
        <Header as="h1" size="xl" weight="semibold">
          Zaliczki
        </Header>
        <BackArrow />

        <Box className="space-y-6">
          <Header as="h4" size="lg" weight="semibold">
            Lista rat
          </Header>

          {isError && <ErrorMessages error={error} />}
          {isLoading && <Loader className="h-12 w-12" />}
          {isSuccess && data && (
            <Box.FullWidth>
              <Table
                data={installments ?? []}
                columns={createColumn(editInstallment, activeDictionaries)}
                isLoading={isFetching}
                manualSortBy={false}
              />
            </Box.FullWidth>
          )}
        </Box>
      </div>

      {editedInstallment && activeDictionaries && data && (
        <AdvanceInstallmentForm
          visible={visible}
          advancePayment={data}
          dictionaryTypes={activeDictionaries}
          editedInstallment={editedInstallment}
          setVisible={setVisible}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default AdvanceInstallments;
