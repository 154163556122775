import { Icon } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { Delegation } from 'types/delegation';

export const columns: Column<Delegation>[] = [
  {
    id: 'expander',
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
      </span>
    ),
    Cell: ({ row }: Cell<Delegation>) =>
      row.original.childList.length ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      ) : null,
  },
  {
    accessor: 'commissionSchemaName',
    Header: 'Schemat prowizyjny',
  },

  {
    accessor: 'originalParent',
    Header: 'Kod wicedyrektora od którego przeniesiono podrzędnych',
    Cell: ({ value }) => value.agentCode,
  },
  {
    id: 'originalParent.name',
    Header: 'kontrahent',
    Cell: ({ row: { original } }: Cell<Delegation>) => original?.originalParent?.name ?? '',
  },
  {
    accessor: 'parent',
    Header: 'Kod wicedyrektora do którego przeniesiono podrzędnych',
    Cell: ({ value }) => value.agentCode,
  },
  {
    Header: 'kontrahent',
    Cell: ({ row: { original } }: Cell<Delegation>) => original?.parent?.name ?? '',
  },
  {
    accessor: 'validFrom',
    Header: 'Data od',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0]}</span>,
  },
  {
    accessor: 'validTo',
    Header: 'Data do',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0]}</span>,
  },
];
