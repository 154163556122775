import * as yup from 'yup';

import { requiredFieldMessage } from 'constants/requiredFieldMessage';

export const validationSchema = yup.object({
  newParentId: yup.string().required(requiredFieldMessage),
  propagateToSelectedSchemas: yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: yup.array().of(yup.string()).optional(),
  validTo: yup.mixed().test('dateValidation', 'Date Validation', (date, { createError }) => {
    if (!date) {
      return createError({
        message: requiredFieldMessage,
      });
    }

    return true;
  }),
});
