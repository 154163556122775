import { Column } from 'react-table';

import { Payout } from 'types/settlement';

export const columns: Column<Payout>[] = [
  {
    Header: 'Data obliczenia',
    accessor: 'calculationTime',
  },
  {
    Header: 'Kod agenta',
    accessor: 'agentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: 'agentDisplayName',
  },
  {
    Header: 'Kwota płatności',
    accessor: (row) => <div className="text-right">{row?.balanceToPay}</div>,
  },
];
