import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { deleteBlockade } from 'services/agent';

type Props = {
  refetch: () => void;
};

export const DeleteBlockadeModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { signature } = state;

  useEffect(() => {
    if (!signature) {
      navigate('..');
    }
  }, [signature, navigate]);

  const { isError, error, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { signature: string }
  >(({ signature }) => deleteBlockade(signature), {
    onSuccess: () => {
      refetch();
      navigate('..');
    },
  });

  return (
    <Modal visible>
      <Text>Czy na pewno chcesz usunąć tę blokadę?</Text>
      <ModalActions
        isLoading={isLoading}
        onConfirm={() => mutate({ signature })}
        onCancel={() => navigate('..')}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};
