import qs from 'qs';

import { Page } from 'types/request';
import { AgentRelationStructureRecord, DelegateAgentsBetweenVicePayload } from 'types/structure';
import { StructureHistoryPositions, Subordinations } from 'types/structureHistory';
import { recordmodApi } from 'utils/axios';

export type GetSubordinationStructurePayload = {
  childCode?: string;
  commissionSchema?: number | null;
  parentCode?: string;
  position?: string;
  term?: string;
  time: string;
};

export const getSubordinationStructure = async (rawParams: GetSubordinationStructurePayload) => {
  const params = Object.fromEntries(Object.entries(rawParams).filter(([, value]) => value));

  const { data } = await recordmodApi.get<AgentRelationStructureRecord[]>(
    '/structure/subordination',
    {
      params: { ...params },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    }
  );

  return data;
};

export type GetSubordinationStructurePayloadView = {
  agentCode?: string;
  commissionSchema?: number | null;
  childPositions?: string[];
  parentPositions?: string[];
  term?: string;
  directOnly?: boolean;
  time: string | Date;
};

export const getSubordinationStructureView = async (
  rawParams: GetSubordinationStructurePayloadView,
  page: number,
  size: number,
  sort?: string
) => {
  const params = Object.fromEntries(Object.entries(rawParams).filter(([, value]) => value));

  const { data } = await recordmodApi.get('/structure/subordination/view', {
    params: { ...params, page, size, sort },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export enum PropagationOptions {
  PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP = 'propagateToAllSchemasInGroup',
  PROPAGATE_TO_SELECTED_SCHEMAS = 'propagateToSelectedSchemas',
  PROPAGATE_TO_SELECTED_SCHEMA = 'propagateToSelectedSchema',
}

export type CreateSubordinationPayload = {
  childAgentId: number;
  commissionSchemaId: number;
  mainPosition?: boolean;
  parentAgentId: number;
  positionDictionaryKey: string;
  subordinationValidFrom: string;
  superiorPositionDictionaryKey: string;
  commissionSchemaIdList: string[];
  commissionRatesData: {
    condition: string;
    commissionRate: number;
    vatRate: number | null;
  }[];
  propagateToAllSchemasInGroup?: boolean;
};

export const createSubordinationRelation = async (
  payload: CreateSubordinationPayload,
  validate: boolean = false
) =>
  recordmodApi.post(`/structure/subordination`, payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });

export type MoveAgentsPayload = {
  agentsToMove: number[];
  newParentId: number;
  oldParentId: number;
  validFrom: string;
  commissionSchemaId?: number;
  commissionSchemaIdList?: string[];
  propagateToAllSchemasInGroup?: boolean;
};

export const moveAgents = async (payload: MoveAgentsPayload, validate: boolean = false) =>
  recordmodApi.put('/structure/subordination/move', payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });

export type ChangeSubordinationPayload = {
  relationId: number;
  validFrom: string;
  validTo: string | null;
  propagateToAllSchemasInGroup: boolean;
  commissionSchemaIdList: string[];
};

export const changeSubordinationPeriod = async (
  payload: ChangeSubordinationPayload,
  validate: boolean = false
) =>
  recordmodApi.put('/structure/subordination', payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });

export type RemoveAgentFromSchemaPayload = {
  commissionSchemaId: number;
  subordinateAgentId: number;
  validTo: string;
  propagateToAllSchemasInGroup: boolean;
  commissionSchemaIdList: string[];
};
export const removeAgentFromSchema = async (
  payload: RemoveAgentFromSchemaPayload,
  validate: boolean = false
) =>
  recordmodApi.delete('/structure/subordination', {
    data: payload,
    params: {
      validate: validate ? 'true' : undefined,
    },
  });

export const delegateAgentsBetweenVices = async (
  payload: DelegateAgentsBetweenVicePayload,
  validate: boolean = false
) => {
  return recordmodApi.put('/delegations', payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });
};

export const getSubordinationHistory = async (
  page: number,
  size: number,
  commissionSchema?: number,
  agentCode?: string,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<Subordinations>>(
    '/structure/subordination/history',
    {
      params: { commissionSchema, agentCode, page, size, sort },
    }
  );

  return data;
};

export const getPositions = async (
  page: number,
  size: number,
  agentId: number,
  commissionSchema?: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<StructureHistoryPositions>>(
    `/agents/${agentId}/positions`,
    {
      params: { page, size, commissionSchema, sort },
    }
  );

  return data;
};
