import { Table } from '@profitowi/component-library';

import { ChildList } from 'types/delegation';

import { columns } from './columns';

type Props = {
  childList: ChildList[];
  handleSelectionChange: (rows: ChildList[]) => void;
};

const DelegationDetails = ({ childList, handleSelectionChange }: Props) => {
  return (
    <>
      <Table
        columns={columns}
        data={childList}
        isSelectable={true}
        onSelectionChange={handleSelectionChange}
      />
    </>
  );
};

export default DelegationDetails;
