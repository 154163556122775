import { Alert, InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { modifyAgentsBusinessIdentifiers } from 'services/agent';
import { AgentsBusinessIdentifiersPayload, EconomyActivities } from 'types/agent';

const validationSchema = Yup.object().shape({
  nip: Yup.string().required(requiredFieldMessage).isValidNip(),
  regon: Yup.string().required(requiredFieldMessage).isValidRegon(),
});

type Props = {
  visible: boolean;
  economyActivity?: EconomyActivities;
  refetch: () => void;
  setVisible: (values: boolean) => void;
};

const AgentBusinessIdentifiersForm = ({ refetch, visible, setVisible, economyActivity }: Props) => {
  const agentId = useAgentId();

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { payload: AgentsBusinessIdentifiersPayload; economyActivityId: number }
  >(
    ({ payload, economyActivityId }) =>
      modifyAgentsBusinessIdentifiers(agentId, economyActivityId, payload),
    {
      onSuccess: () => {
        refetch();
        setVisible(false);
      },
    }
  );

  const initialValues = useMemo(() => {
    if (economyActivity) {
      return { nip: economyActivity.nip, regon: economyActivity.regon };
    }
    return { nip: '', regon: '' };
  }, [economyActivity]);

  return (
    <Modal title="Edytuj NIP/REGON" visible={visible}>
      {!economyActivity ? (
        <Alert type="error">Wystąpił błąd podczas pobierania danych</Alert>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) =>
            economyActivity && mutate({ payload: values, economyActivityId: economyActivity.id })
          }
          validationSchema={validationSchema}
          enableReinitialize>
          <Form className="space-y-4">
            <InputField name="nip" label="NIP" type="string" />
            <InputField name="regon" label="REGON" type="string" />

            <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
            {isError && <ErrorMessages error={error} />}
          </Form>
        </Formik>
      )}
    </Modal>
  );
};

export default AgentBusinessIdentifiersForm;
