import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { chooseEconomyActivity, chooseEconomyActivityParam, getAgentName } from 'helpers/agents';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { Agent } from 'types/agent';
import { DictionaryTypes } from 'types/dictionary';

export const createColumns = (dictionaries: DictionaryTypes): Column<Agent>[] => [
  {
    Header: 'Kod',
    accessor: 'agentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: ({ economyActivities }: Agent): string => getAgentName(economyActivities),
  },
  {
    Header: 'Typ',
    id: 'ea.type',
    accessor: 'economyActivities',
    Cell: ({
      row: {
        original: { economyActivities },
      },
    }: Cell<Agent>) => {
      const { translate } = useDictionaryContext();
      const chosenActivity = chooseEconomyActivity(economyActivities);

      if (chosenActivity) {
        return chosenActivity.type
          ? translate(DICTIONARY_TYPES.ECONOMY_ACTIVITY_TYPE, chosenActivity.type)
          : 'Brak typu na działalności kontrahenta';
      }

      return 'Brak działalności na kontrahencie';
    },
  },
  {
    Header: 'Status działalności',
    id: 'eap_state.value',
    accessor: 'economyActivities',
    Cell: ({
      row: {
        original: { economyActivities },
      },
    }: Cell<Agent>) => {
      const { translate } = useDictionaryContext();
      const chosenActivity = chooseEconomyActivity(economyActivities);

      if (chosenActivity) {
        const { economyActivityParams } = chosenActivity;

        const chosenParam = chooseEconomyActivityParam(
          economyActivityParams,
          'ECONOMY_ACTIVITY_STATE'
        );

        if (chosenParam) {
          return translate(DICTIONARY_TYPES.ECONOMY_ACTIVITY_STATE, chosenParam?.value);
        } else {
          return 'Brak statusu na działalności kontrahenta';
        }
      }
      return 'Brak działalności na kontrahencie';
    },
  },
  {
    id: 'cooperationTo',
    Header: 'Status współpracy',
    accessor: ({ cooperationStatus }: Agent) =>
      dictionaries[DICTIONARY_TYPES.COOPERATION_STATUS]?.find(
        (dictionary) => dictionary.key === cooperationStatus
      )?.value,
  },
  {
    accessor: 'debtRecovery',
    Header: 'Windykacja',
    Cell: ({
      row: {
        original: { debtRecovery },
      },
    }: Cell<Agent>) => (debtRecovery ? 'TAK' : 'NIE'),
  },
  {
    id: 'edit',
    Cell: ({
      row: {
        original: { id },
      },
    }: Cell<Agent>) => {
      const navigate = useNavigate();

      return (
        <Button onPress={() => navigate(`/records/agent/${id}/advances`)} size="sm" variant="light">
          <i className="bi bi-three-dots-vertical cursor-pointer" />
        </Button>
      );
    },
  },
];
