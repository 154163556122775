import * as yup from 'yup';

import { requiredFieldMessage } from 'constants/requiredFieldMessage';

export const validationSchema = yup.object({
  commissionSchema: yup.number().nullable(),
  originalParentCode: yup.string(),
  parentCode: yup.string(),
  time: yup.mixed().test('dateValidation', 'Date Validation', (date, { createError }) => {
    if (!date) {
      return createError({
        message: requiredFieldMessage,
      });
    }

    return true;
  }),
});
