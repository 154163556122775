import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { Contract } from 'types/contract';

export const createColumn = (
  editContract: (contract: Contract) => void,
  deleteAgentBonus: (contractId: number, agentId: number) => void
): Column<Contract>[] => {
  return [
    {
      Header: 'Numer kontraktu',
      accessor: 'number',
    },
    {
      Header: 'Kod agenta',
      accessor: 'agentCode',
    },
    {
      Header: 'Schematy prowizyjne',
      accessor: 'commissionSchemas',
      Cell: ({ value }) => {
        const { translate } = useDictionaryContext();
        return value
          ?.map((schema) => translate('COMMISSION_SCHEMA_NAME', String(schema)))
          .join(',\n');
      },
    },
    {
      Header: 'Data podpisania kontraktu',
      accessor: 'signatureDate',
    },
    {
      Header: 'Data rozpoczęcia kontraktu',
      accessor: 'startDate',
    },
    {
      Header: 'Data zakończenia',
      accessor: 'endDate',
    },
    {
      Header: 'Zakładana wartość',
      accessor: (row) => <div className="text-right">{row?.assumedValue}</div>,
    },
    {
      Header: 'Wartość końcowa',
      accessor: (row) => <div className="text-right">{row?.finalValue}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => {
        const { translate } = useDictionaryContext();
        if (!value) return '';
        return value ? translate('CONTRACT_STATUS', String(value)) : '';
      },
    },
    {
      Header: 'Czy spełniony',
      accessor: 'fulfilled',
      Cell: ({ value }) => {
        if (value === null) return '';
        return value ? 'TAK' : 'NIE';
      },
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Contract>) => (
        <div className="flex space-x-6 justify-end">
          <Button variant="light" onPress={() => editContract(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>

          <Button
            variant="light"
            onPress={() => deleteAgentBonus(original.contractId, original.agentId)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
