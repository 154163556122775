export type Reports = {
  reportType: string;
  status: string;
  fileName: string;
  createdBy: string;
  createdAt: string;
  size: number;
  fileId: number;
  params: Record<string, string>;
};

export type GenerateReportPayload = {
  paramKey: string;
  value: string | boolean;
};

export type ReportConfig = {
  reportType: string;
  reportName: string;
  params: Params[];
};

export type Params = {
  paramKey: string;
  paramName: string;
  fieldName: string;
  paramType: ParamType;
  mandatory: boolean;
  inputType: InputType;
  dictionaryValue: boolean;
};

export enum InputType {
  DICTIONARY_SELECT = 'DICTIONARY_SELECT',
  DICTIONARY_MULTISELECT = 'DICTIONARY_MULTISELECT',
}

export enum ParamType {
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
}

export enum YupParamType {
  DATE = 'date',
  BOOLEAN = 'boolean',
  STRING = 'string',
}

export enum ReportType {
  SUBORDINATION_STRUCTURE = 'SUBORDINATION_STRUCTURE',
  DELEGATIONS = 'DELEGATIONS',
  CONTRACTORS_LIST = 'CONTRACTORS_LIST',
  ADVANCE_PAYMENT = 'ADVANCE_PAYMENT',
  SETTLEMENT_DATA_IMPORT = 'SETTLEMENT_DATA_IMPORT',
  BILLING_ITEMS = 'BILLING_ITEMS',
  COMMISSION_SETTLEMENT_PROCESSES = 'COMMISSION_SETTLEMENT_PROCESSES',
  BONUSES = 'BONUSES',
  POLICY_WALLET = 'POLICY_WALLET',
  POLICY_SPLIT = 'POLICY_SPLIT',
  SETTLEMENT_SOURCE_FILES = 'SETTLEMENT_SOURCE_FILES',
  ACCOUNTING_DOCUMENTS = 'ACCOUNTING_DOCUMENTS',
  POLICY_TRANSFERS = 'POLICY_TRANSFERS',
  BLOCKERS = 'BLOCKERS',
  BLOCKERS_HISTORY = 'BLOCKERS_HISTORY',
  CEIDG = 'CEIDG',
  INDIVIDUAL_COMMISSION_RATES = 'INDIVIDUAL_COMMISSION_RATES',
  AGENT_DOCUMENTS = 'AGENT_DOCUMENTS',
  USERS_LIST = 'USERS_LIST',
}

export enum GenerateReportStatus {
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
}
