import { Button, InputField, Modal, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { ChangePasswordPayload, User } from 'types/user';

const validationMessage = 'Wprowadzone hasło nie spełnia wymogów zabezpieczeń';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Hasło jest wymagane')
    .min(6, validationMessage)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{6,})/, validationMessage),
  confirmPassword: Yup.string()
    .required(requiredFieldMessage)
    .oneOf([Yup.ref('password'), null], 'Sprawdź poprawność wprowadzonych danych'),
});

type Props = {
  visible: boolean;
  editedItem: User;
  changePasswordError?: AxiosError | null;
  isSuccess?: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (payload: ChangePasswordPayload) => void;
  closeModal: () => void;
};

const ChangePasswordForm = ({
  visible,
  changePasswordError,
  isSuccess,
  setVisible,
  onSave,
  closeModal,
}: Props) => {
  return (
    <Modal title="Zmień hasło" visible={visible} setVisible={setVisible}>
      {isSuccess ? (
        <>
          <Text className="text-center">Twoje hasło zostało zmienione</Text>
          <div className="w-full flex justify-center">
            <Button className="flex space-x-2" onPress={closeModal} type="submit" variant="primary">
              Zamknij
            </Button>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          onSubmit={onSave}
          validationSchema={validationSchema}>
          <Form className="space-y-4">
            <InputField type="password" name="password" label="Nowe hasło" />
            <InputField type="password" name="confirmPassword" label="Powtórz nowe hasło" />
            <ModalActions onCancel={closeModal} />

            {changePasswordError && <ErrorMessages error={changePasswordError} />}
          </Form>
        </Formik>
      )}

      <div className="space-y-2">
        <div className="mb-8">
          <Text>Wymagania dotyczące hasła:</Text>
        </div>
        <Text className="space-y-2">- co najmniej 6 znaków</Text>
        <Text className="space-y-2">- co najmniej 1 mała litera</Text>
        <Text className="space-y-2">- co najmniej 1 duża litera</Text>
        <Text className="space-y-2">- co najmniej 1 znak specjalny</Text>
      </div>
    </Modal>
  );
};

export default ChangePasswordForm;
