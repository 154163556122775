import {
  CheckboxField,
  DatePickerField,
  Header,
  Modal,
  SelectField,
  TextareaField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse, startOfMonth } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { generateVatRateOptions } from 'helpers/select';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { changeAgentsParam } from 'services/agent';
import { ACTIVITY_PARAMS, AgentParamPayload, EconomyActivityParams } from 'types/agent';

type Props = {
  economyActivityId?: number;
  economyActivityValidTo?: string;
  refetch: () => void;
  refetchAgent: () => void;
};

const EconomyActivitiesParamForm = ({
  refetch,
  refetchAgent,
  economyActivityId,
  economyActivityValidTo,
}: Props) => {
  const agentId = useAgentId();
  const { activeDictionaries } = useDictionaryContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  const economyActivityParam: EconomyActivityParams = state?.economyActivityParam;

  const initialValues = useMemo(() => {
    if (economyActivityParam) {
      const { value, description, type, validFrom, validTo } = economyActivityParam;

      return {
        type,
        value: type === 'SELF_BILLING' ? (value === 'true' ? true : false) : value,
        comment: description ?? '',
        dateFrom: validFrom
          ? parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date())
          : startOfMonth(new Date()),
        dateTo: validTo ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date()) : null,
      };
    }

    return {
      type: '',
      dateFrom: startOfMonth(new Date()),
      dateTo: null,
      value: '',
      comment: '',
    };
  }, [economyActivityParam]);

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, AgentParamPayload>(
    (values) => {
      const { type, dateFrom, dateTo, value, comment } = values;

      const payload = {
        dateFrom: format(new Date(dateFrom), 'dd-MM-yyyy 00:00:00'),
        dateTo: dateTo ? dateTo && format(new Date(dateTo), 'dd-MM-yyyy 00:00:00') : null,
        value: value.toString(),
        comment,
      };
      return changeAgentsParam(agentId, payload, type, economyActivityId);
    },
    {
      onSuccess: () => {
        navigate('..');
        refetch();
        refetchAgent();
      },
    }
  );

  return (
    <Modal title={'Edytuj dane rozliczeniowe Kontrahenta'} visible>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => mutate(values)}
        enableReinitialize>
        {({ values: { type, dateFrom, dateTo } }) => (
          <Form className="space-y-4">
            <SelectField
              name="type"
              label="Typ parametru"
              options={
                activeDictionaries?.[DICTIONARY_TYPES.PARAM_TYPE].filter(
                  (item) => item.key === type
                ) ?? []
              }
            />

            <DatePickerField label="Data od" name="dateFrom" maxDate={dateTo} />
            <DatePickerField
              label="Data do"
              name="dateTo"
              minDate={dateFrom}
              maxDate={
                economyActivityValidTo
                  ? parse(economyActivityValidTo, 'dd-MM-yyyy HH:mm:ss', new Date())
                  : undefined
              }
            />

            {type === ACTIVITY_PARAMS.VAT_RATE && (
              <SelectField
                label="Stawka VAT"
                name="value"
                options={generateVatRateOptions(activeDictionaries) ?? []}
              />
            )}

            {type === ACTIVITY_PARAMS.ECONOMY_ACTIVITY_STATE && (
              <SelectField
                label="Status działalności"
                name="value"
                options={activeDictionaries?.[DICTIONARY_TYPES.ECONOMY_ACTIVITY_STATE] ?? []}
              />
            )}

            {type === ACTIVITY_PARAMS.SELF_BILLING && (
              <>
                <Header as="h3" size="lg">
                  Samofakturowanie:
                </Header>
                <div className="my-2">
                  <CheckboxField name="value">
                    <span className="text-sm">Znacznik samofakturowania</span>
                  </CheckboxField>
                </div>
              </>
            )}

            <TextareaField name="comment" label="komentarz" />

            <ModalActions isLoading={isUpdateLoading} onCancel={() => navigate('..')} />
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default EconomyActivitiesParamForm;
