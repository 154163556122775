export const SETTLEMENT = {
  SETTLEMENT_BALANCES: 'SETTLEMENT_BALANCES',
  SETTLEMENT_BILLING_ITEMS: 'SETTLEMENT_BILLING_ITEMS',
  SETTLEMENT_BONUSES: 'SETTLEMENT_BONUSES',
  SETTLEMENT_CONTRACTS: 'SETTLEMENT_CONTRACTS',
  SETTLEMENT_CORRECTIONS: 'SETTLEMENT_CORRECTIONS',
  SETTLEMENT_EMAIL_CONTENT: 'SETTLEMENT_EMAIL_CONTENT',
  SETTLEMENT_FILE_EVENTS: 'SETTLEMENT_FILE_EVENTS',
  SETTLEMENT_STAGE: 'SETTLEMENT_STAGE',
  SETTLEMENT: 'SETTLEMENT',
  SETTLEMENT_EMAILS: 'SETTLEMENT_EMAILS',
};
