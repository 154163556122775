const Forbidden = () => {
  return (
    <div className="flex flex-col items-center justify-center max-h-full w-full">
      <p className="text-6xl">Brak uprawnień</p>
      <p className="mt-2">Nie masz wystarczających uprawnień do wykonania tej akcji</p>
    </div>
  );
};

export default Forbidden;
