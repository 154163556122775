import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { StructureHistoryPositions } from 'types/structureHistory';

export const columns: Column<StructureHistoryPositions>[] = [
  {
    id: 'id',
    Cell: ({ row: { id } }: Cell) => Number.parseInt(id) + 1,
  },
  {
    Header: 'Schemat prowizyjny',
    id: 'commissionSchema.schemaName',
    accessor: 'commissionSchemaId',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_SCHEMA_NAME', String(value)) : '';
    },
  },
  {
    Header: 'Stanowisko',
    id: 'positionDictionary.value',
    accessor: 'positionDictionaryValue',
  },
  {
    Header: 'Data od',
    accessor: 'validFrom',
  },
  {
    Header: 'Data do',
    accessor: 'validTo',
  },
  {
    Header: 'Aktualne',
    disableSortBy: true,
    accessor: 'valid',
    Cell: ({ value }) => <p>{value === true ? 'TAK' : 'NIE'}</p>,
  },
  {
    Header: 'Główne',
    accessor: 'mainPosition',
    Cell: ({ value }) => <p>{value === true ? 'TAK' : 'NIE'}</p>,
  },
];
