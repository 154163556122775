import { InputField, Option } from '@profitowi/component-library';
import React, { useEffect } from 'react';

interface Props {
  currentAgentCode: string;
  referredAgentsOption: Option[];
  setFieldValue: (field: string, value: any) => void;
}

const UsernameField = ({ currentAgentCode, referredAgentsOption, setFieldValue }: Props) => {
  useEffect(() => {
    if (!!currentAgentCode) {
      const item = referredAgentsOption.find((i) => i.key === currentAgentCode);
      if (item) {
        setFieldValue('username', item.key);
      } else {
        setFieldValue('username', currentAgentCode);
      }
    }
  }, [currentAgentCode]);

  return (
    <InputField
      name="username"
      label="Nazwa użytkownika"
      autoComplete="off"
      isDisabled={!!currentAgentCode}
    />
  );
};

export default UsernameField;
