import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { editRoles } from 'services/user';
import { EditRolesPayload } from 'types/user';

export const useEditRoles = (userId: string, refetch: Function, closeModal: Function) => {
  return useMutation<AxiosResponse, AxiosError, EditRolesPayload>(
    (payload) => editRoles(payload, userId),
    {
      onSuccess: () => {
        refetch();
        closeModal();
      },
    }
  );
};

export default useEditRoles;
