import { DatePickerField, Input, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { endOfDay, format } from 'date-fns';
import { Form, Formik } from 'formik';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { AGENT } from 'constants/queries/agent';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { getAgentName } from 'helpers/agents';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { changePosition, getAgentPositions } from 'services/agent';
import { PropagationOptions } from 'services/structure';
import { ChangePositionPayload, Positions } from 'types/agent';
import { AgentRelationStructureRecord } from 'types/structure';

const validationSchema = yup.object({
  mainPosition: yup.mixed().oneOf(['false', 'true']),
  positionDictionaryKey: yup.string().required(requiredFieldMessage),
  superiorPositionKey: yup.string().required(requiredFieldMessage),
  validFrom: yup.date().default(undefined).required(requiredFieldMessage).defined(),
  validTo: yup.date().nullable().default(undefined),
  propagateToSelectedSchemas: yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: yup.array().of(yup.string()).optional(),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetch: () => void;
};

const AddPositionModal = ({ refetch }: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<ChangePositionPayload>();
  const { translate } = useDictionaryContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('..');
      refetch();
    }
  }, [state, navigate, refetch]);

  const { commissionSchema, child, parent } = (state || {}) as AgentRelationStructureRecord;

  const { activeDictionaries } = useDictionaryContext();

  const { data: superiorAgentPositions, isSuccess } = useQuery<Positions[], AxiosError>(
    [AGENT.AGENT_POSITIONS, parent.id],
    () => getAgentPositions(parent.id, format(new Date(), 'dd-MM-yyyy HH:mm:ss'), commissionSchema)
  );

  const positions = useMemo(() => {
    if (isSuccess && superiorAgentPositions?.length) {
      return superiorAgentPositions.map((position) => ({
        key: position.positionDictionaryKey,
        value: position.positionDictionaryValue,
      }));
    }

    return [];
  }, [isSuccess, superiorAgentPositions]);

  const initialValues = useMemo(() => {
    return {
      mainPosition: 'true',
      positionDictionaryKey: '',
      superiorPositionKey: '',
      validFrom: undefined as unknown as Date,
      validTo: null,
      propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
      selectedCommissionSchemaIds: [],
    };
  }, []);

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    ChangePositionPayload
  >((payload) => changePosition(child.id, payload), {
    onSuccess: () => {
      navigate('..');
      refetch();
    },
  });

  const {
    isLoading: validationIsloading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, ChangePositionPayload>(
    (payload) => changePosition(child.id, payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const handleSubmit = (values: FormValues) => {
    const propagationOptions = getSchemaPropagationPayload({
      commissionSchemaId: commissionSchema,
      ...values,
    });

    const payload = {
      commissionSchemaId: commissionSchema,
      mainPosition: values.mainPosition === 'true',
      positionDictionaryKey: values.positionDictionaryKey,
      superiorPositionKey: values.superiorPositionKey,
      validFrom: format(values.validFrom, 'dd-MM-yyyy 00:00:00'),
      validTo: values.validTo && format(endOfDay(values.validTo), 'dd-MM-yyyy HH:mm:ss'),
      ...propagationOptions,
    };
    setPayload(payload);

    if (!validationErrors?.length && !!validationWarnings?.length && payload) {
      mutate(payload);
    } else {
      validateForm(payload);
    }
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  return (
    <Modal title="Dodaj stanowisko" visible>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({ values: { validFrom, validTo } }) => (
          <Form className="space-y-4" onBlur={onChange}>
            <Input
              label="Schemat prowizyjny"
              isReadOnly
              value={translate('COMMISSION_SCHEMA_NAME', String(commissionSchema))}
            />
            <Input label="Kontrahent" isReadOnly value={getAgentName(child?.economyActivities)} />
            <Input label="Nadrzędny" isReadOnly value={getAgentName(parent?.economyActivities)} />
            <DatePickerField label="Data od" name="validFrom" maxDate={validTo} />
            <DatePickerField label="Data do" name="validTo" minDate={validFrom} />
            <SelectField
              options={positions}
              name="superiorPositionKey"
              label="Stanowisko kontrahenta nadrzędnego"
            />
            <SelectField
              options={activeDictionaries?.[DICTIONARY_TYPES.POSITION] || []}
              name="positionDictionaryKey"
              label="Nowe stanowisko"
            />
            {/* FIXME: fix Input#checkbox in component-library */}
            <SelectField
              label="Główne stanowisko"
              name="mainPosition"
              options={[
                { key: 'true', value: 'Tak' },
                { key: 'false', value: 'Nie' },
              ]}
            />
            {(isError || validationIsError) && <ErrorMessages error={error || validationError} />}

            <SchemaPropagationFields
              commissionSchemaId={commissionSchema}
              isLoading={isLoading || validationIsloading}
            />

            <SchemaPropagationAlerts
              validationWarnings={validationWarnings}
              validationErrors={validationErrors}
            />
            <ModalActions
              onCancel={() => navigate('..')}
              disableSubmit={!!validationErrors?.length}
              confirmLabel={
                !validationErrors?.length && !!validationWarnings?.length
                  ? 'Zapisz pomimo ostrzeżeń'
                  : 'Zapisz'
              }
              isLoading={isLoading || validationIsloading}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPositionModal;
