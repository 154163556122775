import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  navigateTo?: string;
};

const BackArrow = ({ navigateTo }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <p
      className="cursor-pointer"
      onClick={() =>
        navigateTo
          ? navigate(navigateTo)
          : navigate(pathname.substring(0, pathname.lastIndexOf('/')))
      }>
      <i className="bi bi-arrow-left pr-2" />
      <span>Powrót</span>
    </p>
  );
};

export default BackArrow;
