import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { AgentDetailsBlockade } from 'types/agent';
import { parseActiveStatus } from 'utils/lingual';

export const createColumns: (
  setBlockadeToDelete: (blockade: AgentDetailsBlockade) => void,
  openDeleteBlockadeModal: () => void
) => Column<AgentDetailsBlockade>[] = (setBlockadeToDelete, openDeleteBlockadeModal) => {
  return [
    {
      Header: 'Data dodania',
      accessor: 'blockadeCreateDate',
      sortType: (a, b) =>
        parse(a.values.blockadeCreateDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
        parse(b.values.blockadeCreateDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
    },
    {
      Header: 'Typ',
      id: 'type',
      Cell: ({
        row: {
          original: { type },
        },
      }: Cell<AgentDetailsBlockade>) => {
        const { translate } = useDictionaryContext();

        return type ? translate(DICTIONARY_TYPES.BLOCKER_TYPE, type) : '';
      },
    },
    {
      Header: 'Numer dokumentu księgowego',
      id: 'accountingDocumentNumber',
      accessor: (row: AgentDetailsBlockade) => row?.accountingDocumentNumber || '',
    },
    {
      Header: 'Status blokady',
      accessor: (row: AgentDetailsBlockade) => parseActiveStatus(!!row.valid, 'SHE'),
    },
    {
      Header: 'Data wyłączenia blokady',
      accessor: 'blockadeDisableDate',
      sortType: (a, b) =>
        parse(a.values.blockadeDisableDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
        parse(b.values.blockadeDisableDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<AgentDetailsBlockade>) => {
        return (
          !!original.valid && (
            <div className="flex justify-end">
              <Button
                variant="light"
                onPress={() => {
                  setBlockadeToDelete(original);
                  openDeleteBlockadeModal();
                }}>
                <i className="bi bi-trash cursor-pointer" />
              </Button>
            </div>
          )
        );
      },
    },
  ];
};
