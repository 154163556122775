export const months = [
  'Sty',
  'Lut',
  'Mar',
  'Kwi',
  'Maj',
  'Cze',
  'Lip',
  'Sie',
  'Wrz',
  'Paz',
  'Lis',
  'Gru',
];

export const summaryPeriod = [
  { value: 'Naliczenie ostatni miesiąc', key: 'M1' },
  { value: 'Naliczenie ostatnie 3 miesiące', key: 'M3' },
  { value: 'Naliczenie ostatnie 6 miesiący', key: 'M6' },
  { value: 'Naliczenie ostatnie 12 miesiący', key: 'M12' },
];
