import qs from 'qs';

import {
  Recommendation,
  RecommendationDeletePayload,
  RecommendationPayload,
} from 'types/recommendation';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getRecommendation = async (
  page: number,
  size: number,
  refereeId: number,
  sort?: string[]
) => {
  const { data } = await recordmodApi.get<Page<Recommendation>>(`/structure/recommendation`, {
    params: { refereeId, page, size, sort },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

export const saveRecommendation = (payload: RecommendationPayload) => {
  return recordmodApi.post(`/structure/recommendation`, payload);
};

export const updateRecommendation = (payload: RecommendationPayload) => {
  return recordmodApi.put(`/structure/recommendation`, payload);
};

export const deleteRecommendation = (payload: RecommendationDeletePayload) => {
  return recordmodApi.delete(`/structure/recommendation`, { data: payload });
};
