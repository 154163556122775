import { DatePickerField, InputField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { ADVANCE_PAYMENT_TYPE } from 'constants/PaymentFormTypes';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { createAdvanceForAgent } from 'services/advancePayment';
import { AdvancePaymentPayload } from 'types/advancePayment';

const validationSchema = Yup.object().shape({
  advancePaymentType: Yup.string().required(requiredFieldMessage),
  title: Yup.string().required(requiredFieldMessage),
  advanceGrossValue: Yup.number()
    .required(requiredFieldMessage)
    .min(0, 'Kwota zaliczki nie może być ujemna'),
  paymentStartDate: Yup.string().required(requiredFieldMessage),

  paymentsCount: Yup.number().when('advancePaymentType', {
    is: (val: string) => val === ADVANCE_PAYMENT_TYPE.INSTALLMENT_ADVANCE,
    then: Yup.number()
      .required(requiredFieldMessage)
      .min(1, 'Wartość musi być dodatnia')
      .integer('Wartość musi być liczbą całkowitą'),
  }),

  paymentDayOfMonth: Yup.number().when('advancePaymentType', {
    is: (val: string) => val === ADVANCE_PAYMENT_TYPE.INSTALLMENT_ADVANCE,
    then: Yup.number()
      .required(requiredFieldMessage)
      .min(1, 'Wprowadzono nieprawidłowy dzień miesiąca')
      .max(31, 'Wprowadzono nieprawidłowy dzień miesiąca')
      .integer('Wartość musi być liczbą całkowitą'),
  }),
});

type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  refetch: () => void;
};

const AdvancePaymentForm = ({ visible, setVisible, refetch }: Props) => {
  const { activeDictionaries } = useDictionaryContext();
  const agentId = useAgentId();

  const { isError, isLoading, error, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    AdvancePaymentPayload
  >((payload: AdvancePaymentPayload) => createAdvanceForAgent(agentId, payload), {
    onSuccess: () => {
      setVisible(false);
      refetch();
    },
  });

  const onSubmit = (payment: Partial<AdvancePaymentPayload>) => {
    const { paymentStartDate = '', advancePaymentType = '' } = payment;

    const payload: Partial<AdvancePaymentPayload> = {
      ...payment,
      agentId,
      paymentStartDate: format(startOfDay(new Date(paymentStartDate)), 'dd-MM-yyyy HH:mm:ss'),
    };

    if (advancePaymentType === ADVANCE_PAYMENT_TYPE.FOR_FUTURE_SALARY) {
      delete payload.paymentsCount;
      delete payload.paymentDayOfMonth;
    }

    mutate(payload as AdvancePaymentPayload);
  };

  const initialValues = {
    advancePaymentType: '',
    title: '',
    advanceGrossValue: undefined,
    paymentStartDate: '',
    paymentsCount: undefined,
    paymentDayOfMonth: undefined,
  };

  return (
    <Modal title="Dodaj zaliczkę" visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({ values }) => (
          <Form className="space-y-4">
            <SelectField
              name="advancePaymentType"
              label="Typ Zaliczki"
              options={
                activeDictionaries ? activeDictionaries[DICTIONARY_TYPES.ADVANCE_PAYMENT_TYPE] : []
              }
            />
            <InputField name="title" label="Tytuł" />
            <InputField name="advanceGrossValue" label="Kwota zaliczki" type="number" />
            <DatePickerField
              name="paymentStartDate"
              label={
                values.advancePaymentType === ADVANCE_PAYMENT_TYPE.INSTALLMENT_ADVANCE
                  ? 'Data rozpoczęcia spłaty'
                  : 'Data spłaty'
              }
            />

            {values.advancePaymentType === ADVANCE_PAYMENT_TYPE.INSTALLMENT_ADVANCE && (
              <>
                <InputField name="paymentsCount" label="Liczba miesięcy" />
                <InputField
                  name="paymentDayOfMonth"
                  label="Dzień spłaty w miesiącu"
                  type="number"
                />
              </>
            )}

            <ModalActions isLoading={isLoading} onCancel={() => setVisible(false)} />
            {isError && <ErrorMessages error={error} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AdvancePaymentForm;
