import { Header } from '@profitowi/component-library';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAllAgentsQuery } from 'hooks/useAllAgentsQuery';

import CommunicationList from './components/CommunicationList/CommunicationList';
import CommunicationThread from './components/CommunicationThread/CommunicationThread';

type Props = {
  agentId?: number;
  compactView?: boolean;
};

const Communication = ({ agentId, compactView }: Props) => {
  const queryResult = useAllAgentsQuery();
  const agent = useMemo(
    () => queryResult?.data?.content?.find((agent) => agent.id === agentId),
    [queryResult, agentId]
  );

  return (
    <div className={clsx('flex-1 space-y-6 overflow-hidden', !compactView && 'p-8')}>
      {!compactView && (
        <Header as="h1" size="xl" weight="semibold">
          Wiadomości
        </Header>
      )}

      <Routes>
        <Route
          path="*"
          element={
            <CommunicationList
              agentQueryResult={{ ...queryResult }}
              agentId={agentId}
              agent={agent}
            />
          }
        />
        <Route
          path="thread/:id"
          element={<CommunicationThread agentQueryResult={{ ...queryResult }} />}
        />
      </Routes>
    </div>
  );
};

export default Communication;
