import { Alert, Button, Text } from '@profitowi/component-library';
import React, { ReactNode } from 'react';
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Alert className="space-y-6" type="error">
      <Text size="xl" weight="bold">
        Wystąpił nieoczekiwany wyjątek. Skontaktuj się z administratorem.
      </Text>

      <div className="space-y-2">
        <Text>
          <strong>Wyjątek:</strong> {error.message}
        </Text>
        <Text>
          <strong>Stacktrace:</strong>
          <pre>{error.stack}</pre>
        </Text>
      </div>

      <Button onPress={resetErrorBoundary} variant="outline-primary">
        Spróbuj ponownie
      </Button>
    </Alert>
  );
};

type Props = {
  children: ReactNode;
};

const ErrorBoundary = ({ children }: Props) => (
  <ReactErrorBoundary FallbackComponent={FallbackComponent}>{children}</ReactErrorBoundary>
);

export default ErrorBoundary;
