import {
  DatePickerField,
  InputField,
  Modal,
  MultiSelectField,
  Option,
  SelectField,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { parse } from 'date-fns';
import { Form, Formik } from 'formik';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { getCommissionSchemas } from 'services/commission';
import { Contract, ContractPayload } from 'types/contract';

import SchemaField from './SchemaField/SchemaField';

const BonusesImportSchema = Yup.object().shape({
  startDate: Yup.date().required(requiredFieldMessage),
  endDate: Yup.date().required(requiredFieldMessage),
  signatureDate: Yup.date().required(requiredFieldMessage),
  commissionSchemas: Yup.array().required(requiredFieldMessage),
  assumedValue: Yup.number()
    .required(requiredFieldMessage)
    .min(0, 'Kwota nie możę być wartości ujemną'),
  settleCommissionSchemaId: Yup.number().required(requiredFieldMessage),
  finalValue: Yup.number().min(0, 'Kwota nie możę być wartości ujemną').nullable(),
  fulfilled: Yup.string(),
});

type Props = {
  visible: boolean;
  editedContract?: Contract;
  error?: AxiosError | null;
  closeModal: () => void;
  onSave: (payload: ContractPayload) => void;
};

const options = [
  {
    key: 'null',
    value: 'W trakcie',
  },
  {
    key: 'true',
    value: 'Spełniony',
  },
  {
    key: 'false',
    value: 'Niespełniony',
  },
];

const ContractForm = ({ visible, editedContract, error, closeModal, onSave }: Props) => {
  const { data: schemas, isFetched: schemasIsFetched } = useQuery(
    [COMMISSION.COMMISSIONS, 0, 10000],
    () => getCommissionSchemas(0, 10000)
  );

  const createSchemaOptions = () => {
    let schemaOptions: Option[] = [];

    if (schemas && schemasIsFetched)
      schemas.content.map(({ schemaName, commissionSchemaId }) =>
        schemaOptions.push({ value: schemaName, key: commissionSchemaId })
      );

    return schemaOptions;
  };

  return (
    <Modal title={`${editedContract ? 'Edytuj' : 'Dodaj'} kontrakt`} visible={visible}>
      <Formik
        initialValues={
          editedContract
            ? {
                ...editedContract,
                fulfilled: editedContract.fulfilled?.toString() ?? 'null',
                endDate: parse(editedContract.endDate, 'dd-MM-yyyy', new Date()),
                startDate: parse(editedContract.startDate, 'dd-MM-yyyy', new Date()),
                signatureDate: parse(editedContract.signatureDate, 'dd-MM-yyyy', new Date()),
              }
            : {
                startDate: undefined,
                endDate: undefined,
                signatureDate: undefined,
                commissionSchemas: [],
                assumedValue: undefined,
                finalValue: undefined,
                fulfilled: '',
              }
        }
        onSubmit={(values: Partial<ContractPayload>) => {
          return onSave(values as ContractPayload);
        }}
        validationSchema={BonusesImportSchema}
        enableReinitialize>
        {({ values: { startDate, endDate } }) => (
          <Form className="space-y-4">
            <DatePickerField
              preventOpenOnFocus
              label="Data rozpoczęcia kontraktu"
              name="startDate"
              maxDate={endDate}
            />
            <DatePickerField
              label="Data zakończenia kontraktu"
              name="endDate"
              minDate={startDate}
            />
            <DatePickerField label="Data podpisania kontraktu" name="signatureDate" />

            {!editedContract && (
              <>
                <MultiSelectField
                  options={createSchemaOptions()}
                  name="commissionSchemas"
                  label="Schematy prowizyjne"
                />
                <SchemaField schemaOptions={createSchemaOptions()} />
              </>
            )}

            <InputField label="Zakładana kwota" name="assumedValue" type="number" />

            {editedContract && (
              <>
                <InputField label="Kwota zrealizowana" name="finalValue" type="number" />
                <SelectField label="Status" options={options} name="fulfilled" />
              </>
            )}

            <ModalActions onCancel={() => closeModal()} />

            {error && <ErrorMessages error={error} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ContractForm;
