import { Contract, NewContract } from 'types/contract';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getContracts = async (page: number, size: number, agentId: number, sort?: string) => {
  const { data } = await recordmodApi.get<Page<Contract>>(`/agents/contracts`, {
    params: { agentId, page, size, sort },
  });

  return data;
};

export const getContract = async (agentId: number, contractId: number) => {
  const { data } = await recordmodApi.get<Contract>(`/agents/${agentId}/contracts/${contractId}`);

  return data;
};

export const saveContract = (payload: NewContract, agentId: number) => {
  return recordmodApi.post(`/agents/${agentId}/contracts`, payload);
};

export const updateContract = (agentId: number, contractId: number, payload: Contract) => {
  return recordmodApi.put(`/agents/${agentId}/contracts/${contractId}`, payload);
};

export const removeContract = (agentId: number, contractId: number) => {
  return recordmodApi.delete(`/agents/${agentId}/contracts/${contractId}`);
};
