import * as yup from 'yup';

import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { InputType, ParamType, ReportConfig, YupParamType } from 'types/reports';

export const createValidationSchema = (config: ReportConfig) => {
  const { params } = config;
  const schema: { [key: string]: any } = {};

  params.map(({ paramType, paramKey, mandatory: required, inputType }) => {
    if (paramType in ParamType) {
      const type = paramType.toLowerCase() as YupParamType;
      const validator = yup[type]();

      schema[paramKey] = required ? validator['required'](requiredFieldMessage) : validator;
    } else {
      const isMultiSelect = inputType === InputType.DICTIONARY_MULTISELECT;

      const validator = isMultiSelect
        ? yup.array().of(yup[YupParamType.STRING]())
        : yup[YupParamType.STRING]();

      schema[paramKey] = required ? validator['required'](requiredFieldMessage) : validator;
    }
  });

  return yup.object().shape(schema);
};
