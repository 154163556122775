import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { CONTRACT } from 'constants/queries/contract';
import { usePagination } from 'hooks/usePagination';
import { getContracts, removeContract, saveContract, updateContract } from 'services/contract';
import { Contract, ContractPayload, NewContract } from 'types/contract';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import ContractForm from './ContractForm/ContractForm';
import { createColumn } from './columns';

type SaveContractParams = {
  contract: NewContract;
  agentId: number;
};

type DeleteContractParams = {
  agentId: number;
  contractId: number;
};

type UpdateContractParams = {
  contract: Contract;
  agentId: number;
  contractId: number;
};

const Contracts = () => {
  const [visible, setVisible] = useState(false);
  const [editedContract, setEditedContract] = useState<Contract>();
  const pagination = usePagination(10);
  const agentId = useAgentId();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const {
    data: contracts,
    isLoading: contractIsLoading,
    isFetching,
    refetch: refetchContracts,
    isError,
    error: errorContract,
  } = useQuery<Page<Contract>, AxiosError>(
    [CONTRACT.CONTRACTS, pagination.currentPage, pagination.perPage, sortBy],
    () =>
      getContracts(
        pagination.currentPage,
        pagination.perPage,
        agentId,
        decodeUriSortParams(sortBy)
      ),
    { keepPreviousData: true }
  );

  const useSaveContract = useMutation<AxiosResponse, AxiosError, SaveContractParams>(
    ({ contract, agentId }) => saveContract(contract, agentId),
    {
      onSuccess: () => {
        refetchContracts();
        setVisible(false);
      },
    }
  );

  const useDeleteContract = useMutation(
    ({ agentId, contractId }: DeleteContractParams) => removeContract(agentId, contractId),
    {
      onSuccess: () => {
        refetchContracts();
        setVisible(false);
      },
    }
  );

  const useUpdateContract = useMutation<AxiosResponse, AxiosError, UpdateContractParams>(
    ({ agentId, contractId, contract }) => updateContract(agentId, contractId, contract),
    {
      onSuccess: () => {
        refetchContracts();
        setVisible(false);
        setEditedContract(undefined);
      },
    }
  );

  const closeModal = () => {
    useUpdateContract.error && useUpdateContract.reset();
    useSaveContract.error && useSaveContract.reset();

    setEditedContract(undefined);
    setVisible(false);
  };

  const editContract = (contract: Contract) => {
    setEditedContract(contract);
    setVisible(true);
  };

  const deleteContract = (contractId: number, agentId: number) => {
    useDeleteContract.mutate({ agentId, contractId });
  };

  const onSaveContract = (contract: ContractPayload) => {
    const { endDate = '', startDate = '', signatureDate = '' } = contract;

    const payload = {
      ...contract,
      agentId,
      endDate: format(new Date(endDate), 'dd-MM-yyyy'),
      startDate: format(new Date(startDate), 'dd-MM-yyyy'),
      signatureDate: format(new Date(signatureDate), 'dd-MM-yyyy'),
    };

    if (editedContract) {
      const { contractId } = editedContract;

      const editedPayload = {
        ...payload,
        contractId,
      };

      useUpdateContract.mutate({ agentId, contractId, contract: editedPayload });
    } else {
      useSaveContract.mutate({ contract: payload, agentId });
    }
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Kontrakty
          </Header>

          <Button variant="outline-primary" className="px-4 py-1" onPress={() => setVisible(true)}>
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={errorContract} />}
        {contractIsLoading && <Loader className="h-12 w-12" />}
        {contracts && (
          <Box.FullWidth>
            <Table
              pagination={pagination}
              totalPages={contracts.totalPages}
              data={contracts.content}
              columns={createColumn(editContract, deleteContract)}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              isLoading={isFetching}
            />
          </Box.FullWidth>
        )}
      </Box>

      <ContractForm
        visible={visible}
        editedContract={editedContract}
        closeModal={closeModal}
        onSave={onSaveContract}
        error={useSaveContract.error ?? useUpdateContract.error}
      />
    </>
  );
};

export default Contracts;
