import { DatePickerField, Modal, SelectField } from '@profitowi/component-library';
import { Option } from '@profitowi/component-library/dist/types/Select';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  ActionLabelOptions,
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useState } from 'react';
import { useMutation, useQueries, useQuery } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { AGENT } from 'constants/queries/agent';
import { COMMISSION } from 'constants/queries/commission';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { PromoteAgentPayload, getAgentPositions, promoteAgent } from 'services/agent';
import { getCommissionSchema } from 'services/commission';
import { PropagationOptions } from 'services/structure';
import { Positions } from 'types/agent';
import { CommissionSchema } from 'types/commission';
import { AgentRelationStructureRecord } from 'types/structure';

type Props = {
  isVisible: boolean;
  closeModal: () => void;
  commissionSchemaId?: string | null;
  selectedRows: AgentRelationStructureRecord[];
};

const validationSchema = Yup.object({
  validFrom: Yup.date().required(requiredFieldMessage).default(undefined).defined(),
  positionDictionaryKey: Yup.string().required(requiredFieldMessage),
  superiorPositionKey: Yup.string().required(requiredFieldMessage),
  propagateToSelectedSchemas: Yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: Yup.array().of(Yup.string()).optional(),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  validFrom: undefined as unknown as Date,
  positionDictionaryKey: '',
  superiorPositionKey: '',
  propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
  selectedCommissionSchemaIds: [],
};

const PromoteAgentsModal = ({ isVisible, closeModal, commissionSchemaId, selectedRows }: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<PromoteAgentPayload>();
  const { activeDictionaries } = useDictionaryContext();
  const { translate } = useDictionaryContext();

  const {
    data: commissionSchema,
    isError: isCommissionSchemaError,
    error: commissionSchemaError,
  } = useQuery<CommissionSchema | undefined, AxiosError>(
    [COMMISSION.COMMISSION_SCHEMA, commissionSchemaId],
    () => {
      if (commissionSchemaId) {
        return getCommissionSchema(parseInt(commissionSchemaId));
      }
    }
  );

  const superiorAgentIds = selectedRows?.map((row) => row.parent?.id);

  const results = useQueries(
    superiorAgentIds.map((superiorAgentId) => {
      return {
        queryKey: [AGENT.AGENT_POSITIONS, superiorAgentId],
        queryFn: () => {
          if (superiorAgentId && commissionSchemaId) {
            return getAgentPositions(
              superiorAgentId,
              format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
              parseInt(commissionSchemaId as string)
            );
          }
        },
      };
    })
  );

  const isSuperiorAgentPositionsError = results.some((res) => res?.isError);
  const superiorAgentPositionsError = results.filter((res) => !!res?.error)[0]?.error as AxiosError;

  const positions = results?.map((res) => res?.data);

  const commonPositions = (positionCollections: Array<Array<Positions> | undefined>): Option[] => {
    if (!positionCollections.length) {
      return [];
    }

    if (positionCollections.length === 1) {
      return (
        positionCollections?.[0]?.map(
          (position: Positions): Option => ({
            key: position.positionDictionaryKey,
            value: position.positionDictionaryValue,
          })
        ) || []
      );
    }

    const positionKeyAndCountMap = new Map<string, number>([]);

    positionCollections.forEach((positions) => {
      positions?.forEach((pos) => {
        if (!positionKeyAndCountMap.has(pos.positionDictionaryKey)) {
          positionKeyAndCountMap.set(pos.positionDictionaryKey, 1);
        } else {
          positionKeyAndCountMap.set(
            pos.positionDictionaryKey,
            (positionKeyAndCountMap.get(pos.positionDictionaryKey) as number) + 1
          );
        }
      });
    });

    const commonPositions: string[] = [];

    positionKeyAndCountMap.forEach((value, key) => {
      if (value === positionCollections.length) {
        commonPositions.push(key);
      }
    });

    return commonPositions.map((positionKey) => ({
      key: positionKey,
      value: translate(DICTIONARY_TYPES.POSITION, positionKey),
    }));
  };

  const {
    isLoading: validationIsloading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, PromoteAgentPayload>(
    (payload) => promoteAgent(payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const {
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, PromoteAgentPayload>(
    (payload) => promoteAgent(payload),
    {
      onSuccess: closeModal,
    }
  );

  const onSubmit = (values: FormValues) => {
    if (commissionSchemaId) {
      const propagationOptions = getSchemaPropagationPayload({
        commissionSchemaId: Number.parseInt(commissionSchemaId),
        ...values,
      });

      const payload: PromoteAgentPayload = {
        agentsIds: [...selectedRows.map((row) => row.child.id)].filter(
          (value, index, self) => self.indexOf(value) === index
        ),
        commissionSchemaId,
        mainPosition: true,
        positionDictionaryKey: values.positionDictionaryKey,
        superiorPositionKey: values.superiorPositionKey,
        validFrom: format(values.validFrom, 'dd-MM-yyyy HH:mm:ss'),
        ...propagationOptions,
      };
      setPayload(payload);

      if (!validationErrors?.length && !!validationWarnings?.length && payload) {
        mutate(payload);
      } else {
        validateForm(payload);
      }
    }
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  return (
    <Modal visible={isVisible} title="Zmień stanowisko">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form className="space-y-4" onBlur={onChange}>
          <div className="flex w-full justify-between items-center my-2">
            <p>Schemat prowizyjny</p>
            <div className="w-1/2">{(commissionSchema as CommissionSchema)?.schemaName}</div>
          </div>
          <div className="flex w-full justify-between items-center my-2">
            <p>Data od</p>
            <div className="w-1/2">
              <DatePickerField
                dateFormat="dd-MM-yyyy"
                placeholder="Data od"
                name="validFrom"
                preventOpenOnFocus
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center my-2">
            <p>Nowe stanowisko</p>
            <div className="w-1/2">
              <SelectField
                options={activeDictionaries?.[DICTIONARY_TYPES.POSITION] || []}
                name="positionDictionaryKey"
                placeholder="Nowe stanowisko"
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center my-2">
            <p>Stanowisko kontrahenta nadrzędnego</p>
            <div className="w-1/2">
              <SelectField
                options={commonPositions(positions)}
                name="superiorPositionKey"
                placeholder="Stanowisko kontrahenta nadrzędnego"
              />
            </div>
          </div>
          {isCommissionSchemaError && <ErrorMessages error={commissionSchemaError} />}
          {isSuperiorAgentPositionsError && <ErrorMessages error={superiorAgentPositionsError} />}
          {isUpdateError && <ErrorMessages error={updateError} />}
          {validationIsError && <ErrorMessages error={validationError} />}

          <SchemaPropagationFields
            commissionSchemaId={
              commissionSchemaId ? Number.parseInt(commissionSchemaId) : undefined
            }
            isLoading={isUpdateLoading || validationIsloading}
            actionLabel={ActionLabelOptions.CHANGE}
          />

          <SchemaPropagationAlerts
            validationWarnings={validationWarnings}
            validationErrors={validationErrors}
          />
          <ModalActions
            onCancel={closeModal}
            disableSubmit={!!validationErrors?.length}
            confirmLabel={
              !validationErrors?.length && !!validationWarnings?.length
                ? 'Zapisz pomimo ostrzeżeń'
                : 'Zapisz'
            }
            isLoading={isUpdateLoading || validationIsloading}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default PromoteAgentsModal;
