import {
  BusinessActivityShape,
  GeneralCooperationShape,
  NaturalPersonShape,
} from 'pages/Agent/components/Cooperation/validation';

import { BlockadeType } from 'constants/blockadeType';
import { COOPERATION_FORMS } from 'constants/cooperationForms';

import { Optional } from './common';

export type Agent = {
  id: number;
  balance?: AgentBalance;
  agentCode: string;
  coworker: boolean;
  deleted: boolean;
  economyActivities: EconomyActivities[];
  positions: Positions[];
  referees: Referees[];
  referred: Referees[];
  parents: Parents[];
  children: Parents[];
  debtRecovery: boolean;
  cooperationSince: any;
  cooperationStatus: any;
  cooperationTo: any;
  rauNumber: string;
  officeDictionaryKey: string;
};

export type EconomyActivities = {
  id: number;
  businessEmail: string;
  companyName: string;
  bankAccount: string;
  from: string;
  lastName: string;
  name: string;
  nip: string;
  regon: string;
  to: string;
  type: COOPERATION_FORMS;
  valid: boolean;
  validFrom: string;
  validTo: string;
  validNowOrInFuture: boolean;
  krs: string;
  middleName: string;
  education: string;
  pesel: string;
  birthdate: string;
  birthPlace: string;
  privateEmail: string;
  phoneNumber: string;
  invoiceAddressType: string;
  sendInvoiceByEmail: boolean;
  primaryBillingEmailAddress: string;
  secondBillingEmailAddress: string;
  economyActivityParams: EconomyActivityParams[];
  addresses: Addresses[];
  economyActivityValidTo: string;
};

export type EconomyActivityParams = {
  type: string;
  value: string;
  description: string;
  validFrom: string;
  validTo: string;
  valid: boolean;
  validNowOrInFuture: boolean;
};

export type Positions = {
  commissionSchemaId: number;
  from: string;
  id: number;
  macroRegionDictionaryId: number;
  macroRegionDictionaryKey: string;
  macroRegionDictionaryValue: string;
  mainPosition: boolean;
  officeDictionaryId: number;
  officeDictionaryKey: string;
  officeDictionaryValue: string;
  positionDictionaryId: number;
  positionDictionaryKey: string;
  positionDictionaryValue: string;
  regionDictionaryId: number;
  regionDictionaryKey: string;
  regionDictionaryValue: string;
  to: number;
  valid: boolean;
  validFrom: string;
  validTo: string;
  validNowOrInFuture: boolean;
};

type AgentBalance = {
  balanceToPay: number;
  contractorBalance: number;
};

export type Referees = {
  referee: string;
  referred: string;
  validFrom: string;
  validTo: string;
  from: string;
  to: string;
  valid: boolean;
  '@id': number;
  validNowOrInFuture: boolean;
};

export type Addresses = {
  id?: number;
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  type: string;
};

export type Parents = {
  parent: string;
  child: string;
  level: number;
  validFrom: string;
  validTo: string;
  commissionSchema: number;
  from: string;
  to: string;
  valid: boolean;
  '@id': number;
};

export type Balance = {
  balance: number;
};

export type AgentList = Pick<
  Agent,
  | 'id'
  | 'parents'
  | 'positions'
  | 'agentCode'
  | 'children'
  | 'coworker'
  | 'deleted'
  | 'referees'
  | 'referred'
> & {
  economyActivities: Omit<EconomyActivities, 'addresses'>[];
};

export type AgentDetails = Pick<
  Agent,
  | 'id'
  | 'agentCode'
  | 'balance'
  | 'cooperationSince'
  | 'cooperationStatus'
  | 'cooperationTo'
  | 'coworker'
  | 'debtRecovery'
  | 'deleted'
  | 'economyActivities'
  | 'rauNumber'
  | 'officeDictionaryKey'
>;

export type CooperationEconomyActivity = Omit<GeneralCooperationShape, 'type'> & {
  type: COOPERATION_FORMS.COMPANY | COOPERATION_FORMS.SOLE_PROPRIETORSHIP;
} & BusinessActivityShape;

export type CooperationNaturalPerson = Omit<GeneralCooperationShape, 'type'> & {
  type: COOPERATION_FORMS.INDIVIDUAL;
} & NaturalPersonShape;

export type InsuranceCompany = {
  agentId: number;
  id: number;
  insuranceCompanyDictionaryKey: string;
  valid: boolean;
  validFrom: string;
  validTo: string;
};

export type InsuranceCompanyPayload = Pick<
  InsuranceCompany,
  'insuranceCompanyDictionaryKey' | 'validFrom' | 'validTo'
>;

export type ActivityRangeRecord = {
  activityRangeDictionaryKey: string;
  agentId: number;
  id: number;
  valid: boolean;
  validFrom: string;
  validTo: string;
};

export type ActivityRangePayload = Pick<
  ActivityRangeRecord,
  'activityRangeDictionaryKey' | 'validFrom' | 'validTo'
>;

export type ModifyAgentsContactPayload = {
  businessEmail: string;
  privateEmail: string;
  phoneNumber: string;
};

export type ModifyAgentsAddressPayload = {
  homeAddress: Addresses;
  correspondenceAddress: Addresses;
  billingAddress?: Addresses;
};

export type AgentsBusinessIdentifiersPayload = {
  nip: string;
  regon: string;
};

export type AgentBillingDetailsPayload = {
  name: string;
  lastName: string;
  companyName: string;
  rauNumber?: string;
  agentCode: string;
  bankAccount: string;
  invoiceAddressType?: string;
  primaryBillingEmailAddress: string;
  secondBillingEmailAddress: string;
  sendInvoiceByEmail: boolean;
};

export type AgentParamPayload = {
  type?: string;
  dateFrom: string | Date;
  dateTo: string | Date | null;
  value: string | boolean;
  comment: string;
};

export type ChangePositionPayload = {
  commissionSchemaId: number | string;
  mainPosition: boolean;
  positionDictionaryKey: string;
  superiorPositionKey: string;
  validFrom: string;
  validTo?: string | null;
  propagateToAllSchemasInGroup: boolean;
  commissionSchemaIdList: string[];
};

export type AddAgentPayload = Pick<Agent, 'agentCode' | 'referees' | 'economyActivities'> & {
  rauNumber?: string;
  cooperationStatus: string;
  officeDictionaryKey: string;
  insuranceCompanies: {
    insuranceCompanyDictionaryKey: string;
    validFrom: string;
    validTo?: string;
  }[];
  activityRanges: {
    activityRangeDictionaryKey: string;
    validFrom: string;
    validTo?: string;
  }[];
};

export enum ACTIVITY_PARAMS {
  VAT_RATE = 'VAT_RATE',
  ECONOMY_ACTIVITY_STATE = 'ECONOMY_ACTIVITY_STATE',
  SELF_BILLING = 'SELF_BILLING',
}

export type AgentBlockade = {
  blockadeCreateDate: string;
  signature: string;
};

export type AgentDetailsBlockade = {
  type: BlockadeType;
  state: boolean;
  blockadeCreateDate?: string;
  blockadeDisableDate?: string | null;
  accountingDocumentNumber?: string;
  valid?: boolean;
};

export type AgentPaymentHistoryRecord = {
  salePeriod: string;
  issueDate: string;
  payoutPositionCreationDate: string;
  settlementDate: string;
  type: string;
  documentNumber: string;
  amount: number;
  payment: number;
  payout: number;
  balance: number;
  paidBalance: number;
  toSettlement: number;
  agentId: number;
};

export type AddAgentPayoutPayload = {
  grossValue: number;
  description: string;
};

export enum DocumentType {
  ACCOUNTING_NOTE = 'ACCOUNTING_NOTE',
  CORRECTING_ACCOUNTING_NOTE = 'CORRECTING_ACCOUNTING_NOTE',
  SELF_BILLING_INVOICE = 'SELF_BILLING_INVOICE',
  SELF_BILLING_CORRECTING_INVOICE = 'SELF_BILLING_CORRECTING_INVOICE',
  EXTERNAL_INVOICE = 'EXTERNAL_INVOICE',
  INVOICE = 'INVOICE',
  ACCOUNTING_NOTE_STATEMENT = 'ACCOUNTING_NOTE_STATEMENT',
}

export type AddAgentPayoutDocumentAllowedType =
  | DocumentType.ACCOUNTING_NOTE_STATEMENT
  | DocumentType.EXTERNAL_INVOICE
  | DocumentType.INVOICE;

export type AddAgentPayoutDocumentPayload = {
  documentNumber?: string;
  grossValue?: number;
  issueDate: string;
  documentType: AddAgentPayoutDocumentAllowedType;
  documentFileId: number;
  agentId: number;
  settlingDocuments: {
    documentNumber: string;
    grossValue: number;
  }[];
};

export type BasicAgent = {
  id: number;
  agentCode: string;
  economyActivities: Optional<EconomyActivities, 'addresses'>[];
};
