import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import GroupTransferModal from 'pages/Agent/components/GroupTransferModal/GroupTransferModal';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { TRANSFERS } from 'constants/queries/polices';
import { usePagination } from 'hooks/usePagination';
import { cancelPolicyTransfer, getTransfers } from 'services/polices';
import { Transfer } from 'types/polices';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import TransferForm from './TransferForm/TransferForm';
import { createColumn } from './columns';

const Transfers = () => {
  const [visible, setVisible] = useState(false);
  const [groupTransferModalVisible, setGroupTransferModalVisible] = useState(false);
  const [editedTransfer, setEditedTransfer] = useState<Transfer>();
  const pagination = usePagination(10);
  const agentId = useAgentId();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  useEffect(() => {
    if (!visible) setEditedTransfer(undefined);
  }, [visible, editedTransfer]);

  const {
    data: transfers,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    refetch: refetchTransfers,
  } = useQuery<Page<Transfer>, AxiosError>(
    [TRANSFERS.TRANSFERS, pagination.currentPage, pagination.perPage],
    () =>
      getTransfers(
        pagination.currentPage,
        pagination.perPage,
        agentId,
        decodeUriSortParams(sortBy)
      ),
    { keepPreviousData: true }
  );

  useEffect(() => {
    refetchTransfers();
  }, [sortBy]);

  const { mutate: deleteMutate } = useMutation<AxiosResponse, AxiosError, number>(
    (id) => cancelPolicyTransfer(id),
    {
      onSuccess: () => {
        refetchTransfers();
      },
    }
  );

  const closeModal = () => {
    setVisible(false);
    if (editedTransfer) setEditedTransfer(undefined);
  };

  const editTransfer = (policy: Transfer) => {
    setVisible(true);
    setEditedTransfer(policy);
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Cesje polis
          </Header>

          <div className="flex flex-col space-y-2">
            <Button variant="outline-primary" onPress={() => setVisible(true)}>
              <Text weight="semibold">
                Dodaj
                <i className="ml-2 bi bi-plus-lg"></i>
              </Text>
            </Button>
            <Button variant="outline-primary" onPress={() => setGroupTransferModalVisible(true)}>
              <Text weight="semibold">
                Grupowa cesja
                <i className="ml-2 bi bi-plus-lg"></i>
              </Text>
            </Button>
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}

        {isSuccess && transfers && (
          <Box.FullWidth>
            <Table
              columns={createColumn(editTransfer, deleteMutate)}
              data={transfers.content}
              isLoading={isFetching}
              pagination={pagination}
              totalPages={transfers.totalPages}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      {visible && (
        <TransferForm
          visible={visible}
          editedTransfer={editedTransfer}
          closeModal={closeModal}
          refetch={refetchTransfers}
        />
      )}

      {groupTransferModalVisible && (
        <GroupTransferModal
          closeModal={() => setGroupTransferModalVisible(false)}
          onSuccess={() => {
            refetchTransfers();
          }}
        />
      )}
    </>
  );
};

export default Transfers;
