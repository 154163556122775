import { Loader } from '@profitowi/component-library';
import React from 'react';

function InsetLoader() {
  return (
    <div className="absolute inset-0 bg-gray-light opacity-70 z-[1]">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader className="h-12 w-12" />
      </div>
    </div>
  );
}

export default InsetLoader;
