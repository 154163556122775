import { Box, Button, CheckBox, Text } from '@profitowi/component-library';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { ECONOMY_ACTIVITY_PARAM_TYPES } from 'constants/economyActivityParamTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { EconomyActivities } from 'types/agent';

import BillingDetailsForm from './BillingDetailsForm/BillingDetailsForm';

type Props = {
  economyActivities?: EconomyActivities;
  rauNumber?: string;
  agentCode?: string;
  deleted?: boolean;
  refetchAgent: () => void;
  officeDictionaryKey?: string;
};

const BillingDetails = ({
  economyActivities,
  rauNumber,
  agentCode,
  deleted,
  officeDictionaryKey,
  refetchAgent,
}: Props) => {
  const { activeDictionaries, translate } = useDictionaryContext();
  const navigate = useNavigate();

  const getVatRateValue = () => {
    const vatRate = economyActivities?.economyActivityParams.find(
      (value) => value.type === DICTIONARY_TYPES.VAT_RATE && value.valid
    );

    if (vatRate) {
      return translate(DICTIONARY_TYPES.VAT_RATE, vatRate.value);
    }

    return 'Brak';
  };

  return (
    <>
      <Box className="flex w-full">
        <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 space-x-2">
          {economyActivities && activeDictionaries && (
            <>
              <div className="cols-span-1 space-y-4 ">
                <div className="flex flex-col pr-14">
                  <Text className="text-secondary">Kod Agenta</Text>
                  <Text weight="semibold">{agentCode?.toUpperCase() ?? 'Brak'}</Text>
                </div>
                <div className="flex flex-col pr-14">
                  <Text className="text-secondary">Imię</Text>
                  <Text weight="semibold">{economyActivities?.name ?? 'Brak'}</Text>
                </div>
                <div className="flex flex-col pr-14">
                  <Text className="text-secondary">Nazwisko</Text>
                  <Text weight="semibold">{economyActivities?.lastName ?? 'Brak'}</Text>
                </div>

                <div className="flex flex-col pr-14">
                  <Text className="text-secondary">Pesel</Text>
                  <Text weight="semibold">{economyActivities?.pesel ?? 'Brak'}</Text>
                </div>

                <div className="flex flex-col">
                  <Text className="text-secondary">Biuro</Text>
                  <Text weight="semibold">
                    {translate(DICTIONARY_TYPES.OFFICE, officeDictionaryKey ?? '') ?? 'Brak'}
                  </Text>
                </div>
              </div>

              <div className="cols-span-1 space-y-4 ">
                <div className="flex flex-col">
                  <Text className="text-secondary">Nazwa firmy</Text>
                  <Text weight="semibold">{economyActivities?.companyName ?? 'Brak'}</Text>
                </div>

                <div className="flex flex-col">
                  <Text className="text-secondary">Numer rachunku bankowego</Text>
                  <Text weight="semibold">{economyActivities?.bankAccount ?? 'Brak'}</Text>
                </div>

                <div className="flex flex-col">
                  <Text className="text-secondary">Typ</Text>
                  <Text weight="semibold">
                    {economyActivities?.type
                      ? translate(DICTIONARY_TYPES.ECONOMY_ACTIVITY_TYPE, economyActivities?.type)
                      : 'Brak'}
                  </Text>
                </div>

                {(economyActivities.type === COOPERATION_FORMS.COMPANY ||
                  economyActivities.type === COOPERATION_FORMS.SOLE_PROPRIETORSHIP) && (
                  <div className="flex flex-col">
                    <Text className="text-secondary">Adres na fakturze</Text>
                    <Text weight="semibold">Taki sam jak działalności</Text>
                  </div>
                )}

                <div className="flex flex-col">
                  <Text className="text-secondary">E-mail do wysyłki rozliczeń</Text>
                  <Text weight="semibold">
                    {economyActivities?.primaryBillingEmailAddress ?? 'Brak'}
                  </Text>
                </div>
              </div>

              <div className="cols-span-1 space-y-4">
                <div className="flex flex-col">
                  <Text className="text-secondary">Drugi adres e-mail do wysyłki rozliczeń</Text>
                  <Text weight="semibold">
                    {economyActivities?.secondBillingEmailAddress ?? 'Brak'}
                  </Text>
                </div>
                <div className="flex flex-col">
                  <Text className="text-secondary">Stawka VAT</Text>
                  <Text weight="semibold">{getVatRateValue() ?? 'Brak'}</Text>
                </div>
                {deleted && (
                  <div className="flex flex-col pr-14">
                    <Text className="text-secondary">Status</Text>
                    <Text weight="semibold">{deleted && 'Kontrahent usunięty'}</Text>
                  </div>
                )}
                <div className="flex flex-col">
                  <Text className="text-secondary">Numer RAU</Text>
                  <Text weight="semibold">{rauNumber ?? 'Brak'}</Text>
                </div>
                <div className="flex flex-col">
                  <CheckBox
                    name="sendInvoiceByEmail"
                    isDisabled
                    isSelected={economyActivities?.sendInvoiceByEmail}>
                    <Text weight="semibold">Zgoda na wysyłkę dokumentów drogą elektroniczną</Text>
                  </CheckBox>
                </div>

                <div className="flex">
                  <CheckBox
                    isDisabled
                    name="selfBilling"
                    isSelected={
                      !!economyActivities?.economyActivityParams.find(
                        (activity) =>
                          activity.valid &&
                          activity.type === ECONOMY_ACTIVITY_PARAM_TYPES.SELF_BILLING &&
                          activity.value === 'true'
                      )
                    }>
                    <Text weight="semibold">Samofakturowanie</Text>
                  </CheckBox>
                </div>
              </div>
            </>
          )}

          <div className="col-span-1 sm:space-y-8 md:space-y-8 sm:flex-wrap md:col-span-2 lg:col-span-2 xl:col-span-1 w-full justify-end items-end flex mr-4">
            <Button variant="outline-primary" onPress={() => navigate('billing/update')}>
              Edycja
            </Button>
          </div>
        </div>
      </Box>

      <Routes>
        <Route
          path="billing/update"
          element={
            <BillingDetailsForm
              economyActivities={economyActivities}
              refetchAgent={refetchAgent}
              rauNumber={rauNumber}
              agentCode={agentCode}
              officeDictionaryKey={officeDictionaryKey}
            />
          }
        />
      </Routes>
    </>
  );
};

export default BillingDetails;
