import { Button, Icon } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Document } from 'types/settlement';

export const columns: Column<Document>[] = [
  {
    Header: 'Data Wystawienia',
    accessor: 'issueDate',
  },
  {
    Header: 'Kod Agenta',
    accessor: (row) => row.billingItems?.[0]?.payoutAgentCode,
    id: 'agent.agentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: (row) => row.billingItems?.[0]?.payoutAgentDisplayName,
    id: 'agentDisplayName',
  },
  {
    Header: 'Numer dokumentu',
    accessor: 'documentNumber',
  },
  {
    Header: 'Kwota brutto',
    accessor: (row) => <div className="text-right">{row?.grossValue}</div>,
  },
  {
    Header: 'Wygenerowane pozycje rozliczeniowe',
    Cell: ({
      row: {
        original: { billingItems = [] },
      },
    }: Cell<Document>) => {
      const navigate = useNavigate();

      return (
        <Button
          className="space-x-2"
          onPress={() =>
            navigate('details', {
              state: {
                billingItems,
                title: 'Wygenerowane pozycje rozliczeniowe',
              },
            })
          }
          size="sm"
          variant="light">
          <Icon name="search" />
          <span>{billingItems.length}</span>
        </Button>
      );
    },
  },
];
