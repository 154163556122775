import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { FileFromBackend } from 'types/file';

import { DICTIONARY_TYPES } from '../../../../constants/dictionaryTypes';
import useDictionaryContext from '../../../../hooks/useDictionaryContext';

export const columns = (deleteFile: (fileId: number) => void): Column<FileFromBackend>[] => [
  {
    Header: 'Data importu',
    accessor: 'createdAt',
  },
  {
    Header: 'Nazwa pliku',
    accessor: 'fileName',
  },
  {
    Header: 'Typ pliku',
    accessor: 'fileType',
    Cell: ({
      row: {
        original: { fileType },
      },
    }: Cell<FileFromBackend>) => {
      const { translate } = useDictionaryContext();

      return fileType ? translate(DICTIONARY_TYPES.FILE_TYPE, fileType) : '';
    },
  },
  {
    id: 'operations',
    disableSortBy: true,
    Cell: ({
      row: {
        original: { fileId, deletable },
      },
    }: Cell<FileFromBackend>) => {
      if (deletable)
        return (
          <div className="flex space-x-2">
            <Button onPress={() => deleteFile(fileId)} size="sm" variant="light">
              Usuń
            </Button>
          </div>
        );

      return <></>;
    },
  },
];
