import { Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import { getSchemaPropagationPayload } from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { COMMISSION } from 'constants/queries/commission';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createSchemaOptions } from 'helpers/schemas';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getCommissionSchemas } from 'services/commission';
import {
  CreateSubordinationPayload,
  PropagationOptions,
  createSubordinationRelation,
} from 'services/structure';
import { PropagateCommissionSchemaValidationResult } from 'types/commission';

import AddAgentToStructureModalForm from '../AddAgentToStructureModalForm/AddAgentToStructureModalForm';

type Props = {
  visible: boolean;
  closeModal: () => void;
  refetch: () => void;
};

type Payload = {
  payload: CreateSubordinationPayload;
};

const validationSchema = Yup.object({
  parentAgentId: Yup.string().required(requiredFieldMessage),
  childAgentId: Yup.string().required(requiredFieldMessage),
  commissionSchemaId: Yup.mixed().required(requiredFieldMessage),
  positionDictionaryKey: Yup.string().required(requiredFieldMessage),
  superiorPositionDictionaryKey: Yup.string().required(requiredFieldMessage),
  subordinationValidFrom: Yup.date().required(requiredFieldMessage),
  commissionRatesData: Yup.array().of(
    Yup.object({
      condition: Yup.string().required(requiredFieldMessage),
      vatRate: Yup.string().required(requiredFieldMessage).default(''),
      commissionRate: Yup.number().required(requiredFieldMessage),
    })
  ),
  propagateToSelectedSchemas: Yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: Yup.array().of(Yup.string()).optional(),
});

export type AddAgentModalFormValues = Yup.InferType<typeof validationSchema>;

const initialValues: AddAgentModalFormValues = {
  parentAgentId: '',
  childAgentId: '',
  commissionSchemaId: '',
  subordinationValidFrom: '' as unknown as Date,
  superiorPositionDictionaryKey: '',
  positionDictionaryKey: '',
  commissionRatesData: [],
  propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
  selectedCommissionSchemaIds: [],
};

export const AddAgentToStructureModal = ({ visible, closeModal, refetch }: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<Payload>();
  const { translate } = useDictionaryContext();

  const { data: schemaList, isFetched: schemasIsFetched } = useQuery(
    [COMMISSION.COMMISSION_SCHEMAS, 0, 10000],
    () => getCommissionSchemas(0, 10000)
  );

  const {
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, Payload>(
    ({ payload }) => createSubordinationRelation(payload),
    {
      onSuccess: () => {
        closeModal();
        refetch();
        resetView();
      },
    }
  );

  const {
    isLoading: isValidationLoading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse<PropagateCommissionSchemaValidationResult>, AxiosError, Payload>(
    ({ payload }) => createSubordinationRelation(payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const onSubmit = (formValues: AddAgentModalFormValues) => {
    const propagationOptions = getSchemaPropagationPayload(formValues);

    const payload: Payload = {
      payload: {
        ...formValues,
        subordinationValidFrom: format(
          formValues.subordinationValidFrom as Date,
          'dd-MM-yyyy 00:00:00'
        ),
        childAgentId: Number.parseInt(formValues.childAgentId),
        parentAgentId: Number.parseInt(formValues.parentAgentId),
        commissionRatesData:
          formValues?.commissionRatesData?.map((rateData) => ({
            ...rateData,
            commissionRate: rateData.commissionRate / 100,
            vatRate:
              rateData.vatRate !== 'VAT_ZW'
                ? parseFloat(translate(DICTIONARY_TYPES.VAT_RATE, rateData.vatRate))
                : null,
          })) || [],
        ...propagationOptions,
      },
    };
    setPayload(payload);

    if (!validationErrors?.length && !!validationWarnings?.length && payload) {
      mutate(payload);
    } else {
      validateForm(payload);
    }
  };

  function resetView() {
    setValidationWarnings([]);
    setValidationErrors([]);
    setPayload(undefined);
  }

  useEffect(() => {
    resetView();
  }, []);

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  return (
    <Modal title="Dodaj kontrahenta w strukturze sprzedaży" visible={visible}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form onBlur={onChange}>
          <AddAgentToStructureModalForm
            schemas={schemaList && schemasIsFetched ? createSchemaOptions(schemaList) : []}
            isSubmitting={isUpdateLoading || isValidationLoading}
            submitError={updateError || validationError}
            isSubmitError={isUpdateError || validationIsError}
            resetView={resetView}
          />
          <SchemaPropagationAlerts
            validationWarnings={validationWarnings}
            validationErrors={validationErrors}
          />
          <ModalActions
            onCancel={closeModal}
            disableSubmit={!!validationErrors?.length}
            confirmLabel={
              !validationErrors?.length && !!validationWarnings?.length
                ? 'Zapisz pomimo ostrzeżeń'
                : 'Zapisz'
            }
            isLoading={isUpdateLoading || isValidationLoading}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddAgentToStructureModal;
