import {
  CheckboxField,
  DatePickerField,
  InputField,
  Modal,
  SelectField,
} from '@profitowi/component-library';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useQuery, useQueryClient } from 'react-query';

import { ModalActions } from 'components';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { COMMISSION } from 'constants/queries/commission';
import { getCommissionSchema } from 'services/commission';
import { SchemaCopyPayload } from 'types/commission';
import { DictionaryTypes } from 'types/dictionary';

type Props = {
  visible: boolean;
  dictionaryTypes?: DictionaryTypes;
  commissionSchemaId?: number;
  closeModal: () => void;
  isLoading: boolean;
  onSave: (payload: SchemaCopyPayload) => void;
  setVisible: (visible: boolean) => void;
};

const CommissionSchemaCopyForm = ({
  dictionaryTypes,
  visible,
  commissionSchemaId,
  closeModal,
  isLoading,
  onSave,
  setVisible,
}: Props) => {
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery([COMMISSION.COMMISSION_SCHEMA, commissionSchemaId], () => {
    if (commissionSchemaId) return getCommissionSchema(commissionSchemaId);
  });

  const onSubmit = (schema: SchemaCopyPayload) => {
    const payload = {
      ...schema,
      copyDate: format(new Date(schema.copyDate), 'dd-MM-yyyy 00:00:00'),
    };

    onSave(payload);
  };

  const onCloseModal = () => {
    queryClient.removeQueries(COMMISSION.COMMISSION_SCHEMA);
    closeModal();
  };

  if (!visible) {
    return null;
  } else
    return (
      <Modal title={`Kopiuj schemat prowizyjny`} visible={visible} setVisible={setVisible}>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            productGroup: data?.productGroup || '',
            schemaName: data?.schemaName || '',
            copyDate: new Date().toString(),
            keepIndividualCommissionRates: false,
          }}
          enableReinitialize>
          <Form className="space-y-4">
            <InputField
              label="Nazwa schematu"
              name="schemaName"
              isReadOnly={isFetching || isLoading}
            />

            <SelectField
              options={dictionaryTypes ? dictionaryTypes[DICTIONARY_TYPES.PRODUCT_GROUP] : []}
              name="productGroup"
              label="Grupa produktowa"
            />
            <DatePickerField
              readOnly={isFetching || isLoading}
              name="copyDate"
              label="Kopia struktury na dzień"
              required
              dateFormat={'dd-MM-yyyy'}
              endDate={new Date()}
            />
            <CheckboxField
              name="keepIndividualCommissionRates"
              isReadOnly={isFetching || isLoading}>
              <span className="text-sm">Zachowaj stawki indywidualne</span>
            </CheckboxField>

            <ModalActions isLoading={isFetching || isLoading} onCancel={onCloseModal} />
          </Form>
        </Formik>
      </Modal>
    );
};
export default CommissionSchemaCopyForm;
