import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { USER } from 'constants/queries/user';
import { getUsers } from 'services/user';
import { Page } from 'types/request';
import { User } from 'types/user';

export const useUsersQuery = (page: number, perPage: number, search?: string) => {
  const { data: userList, ...rest } = useQuery<Page<User>, AxiosError>(
    [USER.USER_LIST, page, perPage, search],
    () => getUsers(page, perPage, search),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  return { userList, ...rest };
};
