import {
  AutoCompleteField,
  DatePickerField,
  InputField,
  Modal,
  Text,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createAgentsOptions } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';
import { getAgent } from 'services/agent';
import { createPolicyTransfer, updatePolicyTransfer } from 'services/polices';
import { AgentDetails } from 'types/agent';
import { Transfer, TransferPayload } from 'types/polices';
import { Recommendation } from 'types/recommendation';

const validationSchema = Yup.object().shape({
  policySignature: Yup.string().required(requiredFieldMessage),
  newAgentId: Yup.string().required(requiredFieldMessage),
  transferDate: Yup.date().default(undefined).required(requiredFieldMessage),
});

type Props = {
  visible: boolean;
  editedTransfer?: Transfer;
  refetch: () => void;
  closeModal: () => void;
};

const TransferForm = ({ visible, editedTransfer, refetch, closeModal }: Props) => {
  const [referredQueryAgent, setReferredQueryAgent] = useState('');
  const agentId = useAgentId();
  const { data: searchAgents } = useAgentQuery({
    queryParam: referredQueryAgent,
    minQueryLength: 3,
  });

  useEffect(() => {
    if (editedTransfer) setReferredQueryAgent(editedTransfer.newAgentCode);
  }, [editedTransfer]);

  const { state } = useLocation();
  const recommendation: Recommendation = state?.recommendation;

  const { data: selectedAgent } = useQuery<AgentDetails, AxiosError>(
    [AGENT.AGENT, agentId],
    () => getAgent(agentId),
    { enabled: !!recommendation }
  );

  const {
    mutate: saveMutate,
    isError: saveIsError,
    error: saveError,
    isLoading: saveIsLoading,
  } = useMutation<AxiosResponse, AxiosError, TransferPayload>(
    (payload) => createPolicyTransfer(payload),
    {
      onSuccess: () => {
        refetch();
        closeModal();
      },
    }
  );

  const {
    mutate: updateMutate,
    isError: updateIsError,
    error: updateError,
    isLoading: updateIsLoading,
  } = useMutation<AxiosResponse, AxiosError, Transfer>((payload) => updatePolicyTransfer(payload), {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });

  const initialSearchOptions = useMemo(() => {
    if (recommendation && selectedAgent) {
      return createAgentsOptions([selectedAgent]);
    }
    return [{ key: 0, value: '' }];
  }, [recommendation, selectedAgent]);

  const referredAgentsOption = useMemo(
    () => (searchAgents ? createAgentsOptions(searchAgents.content, 'id') : initialSearchOptions),
    [searchAgents, initialSearchOptions]
  );

  const onSubmit = (values: Partial<Transfer>) => {
    const { transferDate = '' } = values;

    const payload = {
      ...values,
      agentId,
      transferDate: format(startOfDay(new Date(transferDate)), 'dd-MM-yyyy HH:mm:ss'),
    };

    editedTransfer ? updateMutate(payload as Transfer) : saveMutate(payload as TransferPayload);
  };

  const initialValues = {
    policySignature: '',
    transferDate: undefined as unknown as string,
    newAgentId: undefined,
  };

  return (
    <Modal
      title={`${editedTransfer ? 'Edytuj' : 'Dodaj'} cesję polisy`}
      visible={visible}
      setVisible={closeModal}>
      <Formik
        initialValues={
          editedTransfer
            ? {
                ...editedTransfer,
                transferDate: parse(
                  editedTransfer.transferDate,
                  'dd-MM-yyyy HH:mm:ss',
                  new Date()
                ).toString(),
              }
            : initialValues
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        <Form className="space-y-4">
          <div>
            <Text className="text-secondary">Kod nowego agenta</Text>
            <AutoCompleteField
              name="newAgentId"
              options={referredAgentsOption}
              query={referredQueryAgent}
              setQuery={setReferredQueryAgent}
              placeholder="Wprowadź PR nowego agenta"
            />
          </div>

          <InputField name="policySignature" label="Sygnatura polisy" />

          <DatePickerField name="transferDate" label="Data przejęcia" />

          <ModalActions isLoading={saveIsLoading || updateIsLoading} onCancel={closeModal} />
          {(saveIsError || updateIsError) && <ErrorMessages error={saveError ?? updateError} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default TransferForm;
