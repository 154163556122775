import { ReactNode, createContext, useContext, useState } from 'react';

type Props = {
  children: ReactNode;
  settlementId: number;
  isEditable?: boolean;
};

type State = {
  settlementId?: number;
  isEditable?: boolean;
  setIsEditable: (value: boolean) => void;
};

const SettlementContext = createContext<State>({ setIsEditable: () => {} });

export const SettlementContextProvider = ({
  children,
  settlementId,
  isEditable: isPreview,
}: Props) => {
  const [isEditable, setIsEditable] = useState(isPreview);

  return (
    <SettlementContext.Provider value={{ settlementId, isEditable, setIsEditable }}>
      {children}
    </SettlementContext.Provider>
  );
};

const useSettlementContext = () => useContext(SettlementContext);

export default useSettlementContext;
