export enum FileTypes {
  DISCRETIONARY_BONUSES = 'DISCRETIONARY_BONUSES',
  PAYMENTS = 'PAYMENTS',
  DOCUMENT_SCAN = 'DOCUMENT_SCAN',
  SNAPSHOT = 'SNAPSHOT',
  COMMISSION_IMPORT = 'COMMISSION_IMPORT',
  GENERATED_DOCUMENT = 'GENERATED_DOCUMENT',
  ING_PAYMENTS_FILE = 'ING_PAYMENTS_FILE',
  ACCOUNTING_FILE = 'ACCOUNTING_FILE',
  CLIENT_DATA = 'CLIENT_DATA',
  SETTLEMENT_DOCUMENTS_ARCHIVE = 'SETTLEMENT_DOCUMENTS_ARCHIVE',
}

export enum FileStatus {
  NEW = 'NEW',
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
}

export type FileFromBackend = {
  fileId: number;
  fileName: string;
  fileType: FileTypes;
  status: FileStatus;
  downloaded: boolean;
  description: string;
  createdAt: string;
  deletable: boolean;
  fileParams: {
    [propertyName: string]: string;
  };
};
