import { Box, Header, Loader } from '@profitowi/component-library';

type Props = {
  total?: number;

  isSuccess: boolean;
  isLoading: boolean;
};

const SummaryBox = ({ total, isSuccess, isLoading }: Props) => {
  return (
    <Box className="space-y-8">
      <Header as="h3" size="lg" weight="semibold">
        Suma naliczeń
      </Header>

      <div className="space-y-2">
        <Header as="h2" size="xl" weight="semibold">
          {isSuccess && total && `${total ?? 0}  PLN`}
          {isLoading && <Loader className="h-6 w-6 mt-2" />}
        </Header>
      </div>
    </Box>
  );
};

export default SummaryBox;
