import { Option } from '@profitowi/component-library';

import { CommissionSchemaList } from 'types/commission';

export const createSchemaOptions = (schemas: CommissionSchemaList): Option[] => {
  if (schemas)
    return schemas.content.map(({ schemaName, commissionSchemaId }) => ({
      value: schemaName,
      key: commissionSchemaId,
    }));

  return [];
};
