import { Box, Button, CheckBox, Header, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import DeleteConfigurationLevel from 'pages/ConfigurationLevels/DeleteConfigurationLevel/DeleteConfigurationLevel';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import BackArrow from 'components/BackArrow/BackArrow';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import {
  createCommissionSchemaProgram,
  deleteCommissionSchemaProgram,
  getCommissionSchema,
  updateCommissionSchemaProgram,
} from 'services/commission';
import { CommissionSchemaPayload, Program } from 'types/commission';
import { CommissionSchema } from 'types/commission';

import ConfigurationLevelForm from './ConfigurationLevelForm/ConfigurationLevelForm';
import { createColumn } from './columns';

type UpdateCommission = {
  commissionSchemaProgramPayload: Program;
  commissionSchemaId: number;
};

type CreateCommission = {
  commissionSchemaPayload: CommissionSchemaPayload;
  commissionSchemaId: number;
};

const ConfigurationLevels = () => {
  const [visible, setVisible] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [isCurrentSelected, setIsCurrentSelected] = useState(true);
  const [editedSchemaProgram, setEditedSchemaProgram] = useState<Program>();
  const pagination = usePagination(10);
  const { commissionSchemaId = '' } = useParams();
  const { activeDictionaries } = useDictionaryContext();

  const {
    data: commissionSchema,
    refetch,
    error: commissionSchemaError,
    isFetching,
  } = useQuery<CommissionSchema, AxiosError>(
    [COMMISSION.COMMISSION_SCHEMA, commissionSchemaId],
    () => getCommissionSchema(parseInt(commissionSchemaId), undefined, isCurrentSelected),
    { keepPreviousData: true }
  );

  const deleteProgram = useMutation<
    AxiosResponse,
    AxiosError,
    { endDateTime: string; programId: number }
  >(
    ({ endDateTime, programId }) =>
      deleteCommissionSchemaProgram(parseInt(commissionSchemaId), programId, endDateTime),
    {
      onSuccess: () => {
        refetch();
        setDisplayDeleteModal(false);
        setEditedSchemaProgram(undefined);
      },
    }
  );

  const updateSchema = useMutation<AxiosResponse, AxiosError, UpdateCommission>(
    ({ commissionSchemaProgramPayload, commissionSchemaId }) =>
      updateCommissionSchemaProgram(commissionSchemaProgramPayload, commissionSchemaId),
    {
      onSuccess: () => {
        refetch();
        setVisible(false);
        setEditedSchemaProgram(undefined);
      },
    }
  );

  const createSchema = useMutation<AxiosResponse, AxiosError, UpdateCommission>(
    ({ commissionSchemaProgramPayload, commissionSchemaId }) =>
      createCommissionSchemaProgram(commissionSchemaProgramPayload, commissionSchemaId),
    {
      onSuccess: () => {
        refetch();
        setVisible(false);
        setEditedSchemaProgram(undefined);
      },
    }
  );

  const onSave = (program: Program) => {
    if (commissionSchema && program) {
      const { commissionSchemaId } = commissionSchema;

      if (editedSchemaProgram?.commissionSchemaProgramId) {
        updateSchema.mutate({ commissionSchemaProgramPayload: program, commissionSchemaId });
      } else {
        createSchema.mutate({ commissionSchemaProgramPayload: program, commissionSchemaId });
      }
    }
  };

  const cancelEdit = () => {
    setVisible(false);
    setEditedSchemaProgram(undefined);
    updateSchema.isError && updateSchema.reset();
  };

  const cancelDelete = () => {
    setDisplayDeleteModal(false);
    setEditedSchemaProgram(undefined);
    deleteProgram.isError && deleteProgram.reset();
  };

  const deleteConfiguration = (programId: number) => {
    if (commissionSchema) {
      const { programs } = commissionSchema;

      const program = programs.find(
        ({ commissionSchemaProgramId }) => commissionSchemaProgramId === programId
      );

      setDisplayDeleteModal(true);
      setEditedSchemaProgram(program);
    }
  };

  const setEditedProgram = (programId: number) => {
    if (commissionSchema) {
      const { programs } = commissionSchema;

      const program = programs.find(
        ({ commissionSchemaProgramId }) => commissionSchemaProgramId === programId
      );

      setVisible(true);
      setEditedSchemaProgram(program);
    }
  };

  useEffect(() => {
    refetch();
  }, [isCurrentSelected]);

  return (
    <>
      <div className="flex-1 p-8 space-y-6 overflow-hidden">
        <div className="flex flex-col justify-between lg:flex-row lg:space-y-0 space-y-7">
          <Header as="h1" size="xl" weight="semibold">
            Prowizje
          </Header>
        </div>

        <BackArrow />

        <Box className="space-y-6">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-col gap-2">
                <Header as="h4" size="lg" weight="semibold">
                  Lista konfiguracji
                </Header>
              </div>

              <Button
                variant="outline-primary"
                className="px-4 py-1"
                onPress={() => setVisible(true)}>
                <Text weight="semibold">
                  Dodaj
                  <i className="bi bi-plus-lg ml-2" />
                </Text>
              </Button>
            </div>
            <CheckBox
              isDisabled={isFetching}
              name="isCurrentSelected"
              isSelected={isCurrentSelected}
              onChange={() => setIsCurrentSelected((value) => !value)}>
              <Text weight="semibold">Wyświetl aktualne</Text>
            </CheckBox>
          </div>

          <Box.FullWidth>
            <Table
              isLoading={isFetching || updateSchema.isLoading}
              manualSortBy={false}
              totalPages={
                commissionSchema ? commissionSchema.programs?.length / pagination.perPage : 0
              }
              data={commissionSchema ? commissionSchema.programs : []}
              columns={createColumn(deleteConfiguration, setEditedProgram, activeDictionaries)}
            />
          </Box.FullWidth>
          {commissionSchemaError && <ErrorMessages error={commissionSchemaError} />}
        </Box>
      </div>

      <ConfigurationLevelForm
        cancelEdit={cancelEdit}
        editedConfig={editedSchemaProgram}
        dictionaryTypes={activeDictionaries}
        visible={visible}
        isLoading={updateSchema.isLoading || createSchema.isLoading}
        setVisible={setVisible}
        updateSchemaError={updateSchema.error || createSchema.error}
        mutate={onSave}
      />

      <DeleteConfigurationLevel
        cancelDelete={cancelDelete}
        editedConfig={editedSchemaProgram}
        displayDeleteModal={displayDeleteModal}
        isLoading={deleteProgram.isLoading}
        setDisplayDeleteModal={setDisplayDeleteModal}
        mutate={deleteProgram.mutate}
        deleteSchemaError={deleteProgram.error}
      />
    </>
  );
};

export default ConfigurationLevels;
