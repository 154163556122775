import { Button, LogoIcon, Sidebar } from '@profitowi/component-library';
import { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useAgentRoleContext from 'hooks/useAgentRoleContext';
import { Roles } from 'types/user';

type Props = {
  className: string;
  icon: string;
  to: string;
  name: string;
  external: boolean;
  isDisabled?: boolean;
};

const NavbarButton = ({ className, icon, to, name, external, isDisabled }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const match = useMemo(() => {
    if (to === '/') {
      return pathname === to;
    }

    return to === '' ? false : pathname.includes(to);
  }, [pathname, to]);

  return (
    <Button
      className={className}
      isActive={match}
      onPress={() => {
        if (external) {
          window.open(to, '_blank');
        } else {
          navigate(to);
        }
      }}
      isDisabled={isDisabled}
      variant="light">
      <i className={`bi ${icon}`} />
      <span>{name}</span>
    </Button>
  );
};

const Navbar = () => {
  const { hasRole, isAdminOrCommissionRole } = useAgentRoleContext();

  return (
    <Sidebar>
      <div className="space-y-6">
        <Link className="flex justify-center" to="/">
          <LogoIcon />
        </Link>

        <nav>
          <NavbarButton
            className="w-full text-left space-x-2 outline-none"
            icon="bi-grid"
            key="Dashboard"
            name="Dashboard"
            to="/"
            external={false}
            isDisabled={!isAdminOrCommissionRole}
          />

          <NavbarButton
            className="w-full text-left space-x-2 outline-none"
            icon="bi-wallet"
            key="Ewidencje"
            name="Ewidencje"
            to="/records"
            external={false}
            isDisabled={!isAdminOrCommissionRole && !hasRole(Roles.RECORDS_MANAGEMENT)}
          />

          <NavbarButton
            className="w-full text-left space-x-2 outline-none"
            icon="bi-bar-chart"
            key="Prowizje"
            name="Prowizje"
            to="/commissions"
            external={false}
            isDisabled={!isAdminOrCommissionRole}
          />
          <NavbarButton
            className="w-full text-left space-x-2 outline-none"
            icon="bi-stickies"
            key="Rozliczenia"
            name="Rozliczenia"
            to="/settlements"
            external={false}
            isDisabled={!isAdminOrCommissionRole}
          />

          <NavbarButton
            className="w-full text-left space-x-2 outline-none"
            icon="bi-chat-left-text"
            key="Wiadomości"
            name="Wiadomości"
            to="/communication"
            external={false}
            isDisabled={!isAdminOrCommissionRole && !hasRole(Roles.RECORDS_MANAGEMENT)}
          />

          <NavbarButton
            className="w-full text-left space-x-2 outline-none"
            icon="bi-collection"
            key="Raporty"
            name="Raporty"
            to="/reports"
            external={false}
            isDisabled={!hasRole(Roles.REPORTS) && !hasRole(Roles.ADMIN)}
          />
        </nav>
      </div>

      <nav>
        <NavbarButton
          className="w-full text-left space-x-2 outline-none"
          icon="bi-bell"
          key="Ustawienia"
          name="Ustawienia"
          to="/ustawienia"
          external={false}
          isDisabled={!isAdminOrCommissionRole && !hasRole(Roles.RECORDS_MANAGEMENT)}
        />
        <NavbarButton
          className="w-full text-left space-x-2 outline-none"
          icon="bi-person"
          key="Moje konto"
          name="Moje konto"
          to="/konto"
          external={false}
        />
        <NavbarButton
          className="w-full text-left space-x-2 outline-none"
          icon="bi-box-arrow-left"
          key="Wyloguj"
          name="Wyloguj"
          to="/wyloguj"
          external={false}
        />
      </nav>
    </Sidebar>
  );
};

export default Navbar;
