import { InputField } from '@profitowi/component-library';
import { getIn, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';

import { BonusType } from 'constants/BonusType';

const PaymentsInputField = () => {
  const { values, setFieldValue } = useFormikContext();
  const bonusType = getIn(values, 'bonusType');

  useEffect(() => {
    if (bonusType === BonusType.DISCRETIONARY_BONUS) setFieldValue('paymentsCount', 1);
  }, [bonusType]);

  const isDisable = useMemo(() => bonusType === BonusType.DISCRETIONARY_BONUS, [bonusType]);

  return (
    <InputField name="paymentsCount" label="Liczba miesięcy" type="number" isDisabled={isDisable} />
  );
};

export default PaymentsInputField;
