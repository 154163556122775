import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import AuthService from '../services/auth';

const commissionmodApi = axios.create({
  baseURL: '/commissionmod',
});

const recordmodApi = axios.create({
  baseURL: '/recordmod',
});

function authInterceptor(config: AxiosRequestConfig) {
  return AuthService.refreshToken().then(() => {
    if (AuthService.isLoggedIn()) {
      if (!config.headers) {
        config.headers = {};
      }

      config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
    }

    return config;
  });
}

const forbiddenInterceptor = (error: AxiosError) => {
  if (error?.response?.status === 403) {
    window.location.href = `${window.location.protocol}//${window.location.host}/403`;
  }

  return Promise.reject(error);
};

commissionmodApi.interceptors.request.use(authInterceptor);
commissionmodApi.interceptors.response.use((response) => response, forbiddenInterceptor);

recordmodApi.interceptors.request.use(authInterceptor);
recordmodApi.interceptors.response.use((response) => response, forbiddenInterceptor);

export { commissionmodApi, recordmodApi };
