import { SelectField } from '@profitowi/component-library';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';

import { GeneralCooperationShape } from '../validation';

type SelectProps = {
  dictionaryTypes: any;
  economyActivity: any;
  setType: (type: string) => void;
};

const CooperationTypeSelect = ({ dictionaryTypes, economyActivity, setType }: SelectProps) => {
  const { values, setFieldValue } = useFormikContext<GeneralCooperationShape>();

  useEffect(() => {
    setType(values.type);

    if (values.type === COOPERATION_FORMS.INDIVIDUAL)
      setFieldValue('pesel', economyActivity?.pesel);
  }, [values.type, setType]);

  const initialCooperationTypeOptions = () => {
    const selectedTypeValues =
      dictionaryTypes?.[DICTIONARY_TYPES.ECONOMY_ACTIVITY_TYPE]?.filter(({ key }: any) =>
        Object.values(COOPERATION_FORMS).some(
          (value) => value === key && value !== economyActivity?.type
        )
      ) || [];
    return selectedTypeValues;
  };

  return (
    <SelectField
      name="type"
      label="Typ współpracy"
      options={initialCooperationTypeOptions()}
      placeholder="Wybierz nowy typ współpracy"
    />
  );
};

export default CooperationTypeSelect;
