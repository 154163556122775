import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { updateDictionaryEntry } from 'services/dictionary';
import { DictionaryPayload } from 'types/dictionary';

type UpdateResponse = {
  dictionary: DictionaryPayload;
  id: number;
  type: string;
};

export const useUpdateDictionary = (
  refetchDictionary: Function,
  refetch: Function,
  closeModal: Function
) => {
  return useMutation<AxiosResponse, AxiosError, UpdateResponse>(
    ({ dictionary, id, type }) => updateDictionaryEntry(dictionary, id, type),
    {
      onSuccess: () => {
        refetchDictionary();
        refetch();
        closeModal();
      },
    }
  );
};

export default useUpdateDictionary;
