import {
  Button,
  DatePickerField,
  InputField,
  Modal,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { ADD_AGENT_PAYOUT_DOCUMENT_ALLOWED_TYPES } from 'constants/agent';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { transactionTypeOptions } from 'constants/payment';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { addAgentPayoutDocument } from 'services/agent';
import { uploadFileScan } from 'services/file';
import { TransactionType } from 'types/advancePayment';
import {
  AddAgentPayoutDocumentAllowedType,
  AddAgentPayoutDocumentPayload,
  DocumentType,
} from 'types/agent';
import { FileTypes } from 'types/file';

type Props = {
  visible: boolean;
  closeModal: () => void;
  agentId: number;
  refetch: () => void;
};

const isExternalInvoice = (data: any): data is DocumentType.EXTERNAL_INVOICE => {
  return data === DocumentType.EXTERNAL_INVOICE;
};

const isInvoice = (data: any): data is DocumentType.INVOICE => {
  return data === DocumentType.INVOICE;
};
const isAccountingNoteStatement = (data: any): data is DocumentType.ACCOUNTING_NOTE_STATEMENT => {
  return data === DocumentType.ACCOUNTING_NOTE_STATEMENT;
};

const settlingDocumentsValidationSchema = Yup.array().of(
  Yup.object({
    transactionType: Yup.number(),
    documentNumber: Yup.string().required(requiredFieldMessage),
    grossValue: Yup.mixed().required(requiredFieldMessage),
  })
);
const validationSchema = Yup.object({
  documentNumber: Yup.string().when('documentType', (type) =>
    !isAccountingNoteStatement(type) ? Yup.string().required(requiredFieldMessage) : Yup.string()
  ),
  grossValue: Yup.number(),
  issueDate: Yup.date().default(undefined).required(requiredFieldMessage).defined(),
  documentType: Yup.mixed()
    .oneOf(ADD_AGENT_PAYOUT_DOCUMENT_ALLOWED_TYPES)
    .required(requiredFieldMessage),
  file: Yup.mixed().required(requiredFieldMessage),
  documentFileId: Yup.number().min(1).required(requiredFieldMessage),
  settlingDocuments: settlingDocumentsValidationSchema.when(['documentType'], (documentType) => {
    return isInvoice(documentType) || isAccountingNoteStatement(documentType)
      ? settlingDocumentsValidationSchema.test(
          'settlingDocuments',
          'Dodatkowe dokumenty są wymagane',
          (value: any[] | undefined) => !!(value && value.length)
        )
      : settlingDocumentsValidationSchema;
  }),
});

type FormValues = Yup.InferType<typeof validationSchema>;

type AllowedTypesDescriptionDictionary = {
  [K in AddAgentPayoutDocumentAllowedType]: string;
};

const fieldsDescription: {
  [P in keyof FormValues]?: Exclude<FormValues[P], undefined> extends Array<infer E>
    ? { [T in keyof E]: AllowedTypesDescriptionDictionary }
    : { [K in AddAgentPayoutDocumentAllowedType]: string };
} & {
  settlingDocumentsTitle: AllowedTypesDescriptionDictionary;
  settlingDocumentsAddButton: AllowedTypesDescriptionDictionary;
} = {
  documentNumber: {
    [DocumentType.EXTERNAL_INVOICE]: 'Numer faktury',
    [DocumentType.INVOICE]: 'Numer otrzymanej faktury',
    [DocumentType.ACCOUNTING_NOTE_STATEMENT]: '',
  },
  grossValue: {
    [DocumentType.EXTERNAL_INVOICE]: 'Kwota brutto',
    [DocumentType.INVOICE]: 'Kwota brutto',
    [DocumentType.ACCOUNTING_NOTE_STATEMENT]: '',
  },
  issueDate: {
    [DocumentType.EXTERNAL_INVOICE]: 'Data wystawienia faktury',
    [DocumentType.INVOICE]: 'Data wystawienia faktury',
    [DocumentType.ACCOUNTING_NOTE_STATEMENT]: 'Data oświadczenia',
  },
  settlingDocuments: {
    transactionType: {
      [DocumentType.EXTERNAL_INVOICE]: 'Typ transakcji',
      [DocumentType.INVOICE]: 'Typ transakcji',
      [DocumentType.ACCOUNTING_NOTE_STATEMENT]: 'Typ transakcji',
    },
    documentNumber: {
      [DocumentType.EXTERNAL_INVOICE]: 'Data wystawienia faktury',
      [DocumentType.INVOICE]: 'Data wystawienia faktury',
      [DocumentType.ACCOUNTING_NOTE_STATEMENT]: 'Data oświadczenia',
    },
    grossValue: {
      [DocumentType.EXTERNAL_INVOICE]: 'Data wystawienia faktury',
      [DocumentType.INVOICE]: 'Data wystawienia faktury',
      [DocumentType.ACCOUNTING_NOTE_STATEMENT]: 'Data oświadczenia',
    },
  },
  settlingDocumentsTitle: {
    [DocumentType.EXTERNAL_INVOICE]: 'Wpłaty/wypłaty',
    [DocumentType.INVOICE]: 'Rozliczane NK/NKK',
    [DocumentType.ACCOUNTING_NOTE_STATEMENT]: 'Rozliczane NK/NKK',
  },
  settlingDocumentsAddButton: {
    [DocumentType.EXTERNAL_INVOICE]: 'Dodaj wpłatę/wypłatę',
    [DocumentType.INVOICE]: 'Dodaj dokument NK/NKK',
    [DocumentType.ACCOUNTING_NOTE_STATEMENT]: 'Dodaj dokument NK/NKK',
  },
};

const initialValues: FormValues = {
  documentNumber: '',
  grossValue: '' as unknown as number,
  issueDate: undefined as unknown as Date,
  documentType: DocumentType.INVOICE,
  file: null,
  documentFileId: 0,
  settlingDocuments: [],
};

const AddPayoutHistoryDocumentModal = ({ visible, closeModal, agentId, refetch }: Props) => {
  const { translate } = useDictionaryContext();

  let formSetFieldValue: ((field: string, value: any, shouldValidate?: boolean) => void) | null =
    null;

  const handleSubmit = (formValues: FormValues) => {
    const payload: AddAgentPayoutDocumentPayload = {
      agentId,
      documentFileId: formValues.documentFileId,
      documentType: formValues.documentType,
      settlingDocuments:
        formValues?.settlingDocuments?.map((document) => ({
          documentNumber: document.documentNumber,
          grossValue:
            formValues.documentNumber === DocumentType.EXTERNAL_INVOICE
              ? document.transactionType === TransactionType.PAYOUT
                ? document.grossValue
                : -document.grossValue
              : document.grossValue,
        })) || [],
      issueDate: format(
        startOfDay(formValues.issueDate instanceof Date ? formValues.issueDate : new Date()),
        'dd-MM-yyyy HH:mm:ss'
      ),
    };

    if ([DocumentType.EXTERNAL_INVOICE, DocumentType.INVOICE].includes(formValues.documentType)) {
      payload.documentNumber = formValues.documentNumber as string;

      if (DocumentType.EXTERNAL_INVOICE === formValues.documentType) {
        payload.grossValue = formValues.grossValue;
      }
    }

    if (
      [DocumentType.ACCOUNTING_NOTE_STATEMENT, DocumentType.INVOICE].includes(
        formValues.documentType
      )
    ) {
      payload.grossValue = formValues?.settlingDocuments?.length
        ? formValues.settlingDocuments
            .map((doc) => parseFloat(doc.grossValue))
            .reduce((a, b) => a + b) || 0
        : 0;
    }

    submitMutate({ agentId, payload });
  };

  const {
    isLoading: isSubmitLoading,
    isError: isSubmitError,
    error: submitError,
    mutate: submitMutate,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    { agentId: number; payload: AddAgentPayoutDocumentPayload }
  >(({ agentId, payload }) => addAgentPayoutDocument(agentId, payload), {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });

  const {
    isLoading: isUploadLoading,
    isError: isUploadError,
    error: fileUploadError,
    mutate: mutateFile,
  } = useMutation<
    number,
    AxiosError,
    {
      type: string;
      file: File;
    }
  >(({ file }) => uploadFileScan(file), {
    onSuccess: (uploadedFileId) => {
      if (formSetFieldValue) {
        formSetFieldValue('documentFileId', uploadedFileId);
      }
    },
  });

  const documentTypeOptions = Object.values(ADD_AGENT_PAYOUT_DOCUMENT_ALLOWED_TYPES).map(
    (type) => ({
      key: type,
      value: translate(DICTIONARY_TYPES.ACCOUNTING_DOCUMENT_TYPE, type),
    })
  );

  const onChange = (form: any, e: any) => {
    const file = e.target.files[0];

    form.setFieldValue('file', file);
    mutateFile({ type: FileTypes.DOCUMENT_SCAN, file });
  };

  return (
    <Modal visible={visible} title="Dodaj dokument">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, setFieldValue }) => {
          formSetFieldValue = setFieldValue;

          return (
            <Form>
              <div className="my-2">
                <SelectField
                  options={documentTypeOptions}
                  name="documentType"
                  label="Typ dokumentu"
                />
              </div>

              {!isAccountingNoteStatement(values.documentType) && (
                <InputField
                  label={
                    fieldsDescription.documentNumber?.[
                      values.documentType as AddAgentPayoutDocumentAllowedType
                    ] || ''
                  }
                  name="documentNumber"
                  className="my-2"
                />
              )}
              {isExternalInvoice(values.documentType) && (
                <InputField
                  type="number"
                  name="grossValue"
                  label={
                    fieldsDescription.grossValue?.[
                      values.documentType as AddAgentPayoutDocumentAllowedType
                    ]
                  }
                />
              )}
              <div className="my-2">
                <DatePickerField
                  name="issueDate"
                  label={
                    fieldsDescription.issueDate?.[
                      values.documentType as AddAgentPayoutDocumentAllowedType
                    ]
                  }
                  placeholder={
                    fieldsDescription.issueDate?.[
                      values.documentType as AddAgentPayoutDocumentAllowedType
                    ]
                  }
                />
              </div>
              <div className="my-8">
                <p className="text-sm font-normal text-secondary">Skan dokumentu</p>
                <Field name="file">
                  {({ form }: any) => (
                    <input type="file" name="file" onChange={(e) => onChange(form, e)} />
                  )}
                </Field>
                {touched?.file && errors?.file && (
                  <p className="text-xs font-normal text-red-700">Skan dokumentu jest wymagany</p>
                )}
              </div>

              {errors.file && touched.file && (
                <div>
                  <Text size="sm" className="text-secondary-red">
                    {errors.file}
                  </Text>
                </div>
              )}

              {!isExternalInvoice(values.documentType) && (
                <FieldArray name="settlingDocuments">
                  {(helpers) => (
                    <div>
                      <h2>
                        {
                          fieldsDescription.settlingDocumentsTitle?.[
                            values.documentType as AddAgentPayoutDocumentAllowedType
                          ]
                        }
                      </h2>
                      <div className="my-8">
                        <Button
                          className="w-full"
                          onPress={() =>
                            helpers.push({
                              transactionType: TransactionType.PAYMENT,
                              documentNumber: '',
                              grossValue: '',
                            })
                          }>
                          Dodaj dokument
                        </Button>
                        {errors?.settlingDocuments && (
                          <p className="text-xs font-normal text-red-700">
                            {typeof errors?.settlingDocuments === 'string'
                              ? errors?.settlingDocuments
                              : ''}
                          </p>
                        )}
                      </div>
                      <div>
                        {values?.settlingDocuments?.map((document, index) => (
                          <div className="flex flex-col gap-4 my-8" key={index}>
                            {isExternalInvoice(values.documentType) && (
                              <div className="my-2">
                                <SelectField
                                  options={transactionTypeOptions}
                                  name={`settlingDocuments[${index}].transactionType`}
                                  label="Typ transakcji"
                                />
                              </div>
                            )}

                            <InputField
                              name={`settlingDocuments[${index}].documentNumber`}
                              label="Numer dokumentu"
                            />

                            <div className="flex gap-4 w-full">
                              <div className="w-full">
                                <InputField
                                  name={`settlingDocuments[${index}].grossValue`}
                                  type="number"
                                  label="Kwota brutto"
                                />
                              </div>
                              <div>
                                <Button
                                  onPress={() => helpers.remove(index)}
                                  className="h-12 !py-2">
                                  <i className="bi bi-trash cursor-pointer" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </FieldArray>
              )}

              <div className="my-2">
                <ModalActions
                  onCancel={() => closeModal()}
                  isLoading={isSubmitLoading || isUploadLoading}
                />
                {isSubmitError && <ErrorMessages error={submitError} />}
                {isUploadError && <ErrorMessages error={fileUploadError} />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddPayoutHistoryDocumentModal;
