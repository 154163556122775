import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import { TEMPLATE } from 'constants/queries/template';
import { getTemplates } from 'services/template';
import { Page } from 'types/request';
import { MessageTemplate } from 'types/template';
import { decodeUriSortParams } from 'utils/table';

export const useTemplatesQuery = (page: number, size: number, sortBy: SortingRule<any>[]) => {
  const { data: templateList, ...rest } = useQuery<Page<MessageTemplate>, AxiosError>(
    [TEMPLATE.TEMPLATE_LIST, sortBy],
    () => getTemplates(page, size, decodeUriSortParams(sortBy)),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  return { templateList, ...rest };
};
