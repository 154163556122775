import { Box, Loader, Option, Select, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import useAgentId from 'pages/Agent/useAgentId';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import { AGENT } from 'constants/queries/agent';
import { COMMISSION } from 'constants/queries/commission';
import { usePagination } from 'hooks/usePagination';
import { getAgent } from 'services/agent';
import { getCommissionSchemas } from 'services/commission';
import { getPositions, getSubordinationHistory } from 'services/structure';
import { AgentDetails } from 'types/agent';
import { CommissionSchemas } from 'types/commission';
import { Page } from 'types/request';
import { StructureHistoryPositions, Subordinations } from 'types/structureHistory';
import { decodeUriSortParams } from 'utils/table';

import { columns as positionColumns } from './positionColumns';
import { columns as subordinationColumns } from './subordinationColumns';

function StructureHistory() {
  const subordinationPagination = usePagination(10);
  const positionsPagination = usePagination(10);
  const agentId = useAgentId();
  const [sortByPositions, setSortByPositions] = useState<Array<SortingRule<any>>>([]);
  const [sortBySubordination, setSortBySubordination] = useState<Array<SortingRule<any>>>([]);

  const handleSortByPositions = useCallback(
    (sortBy: SortingRule<any>[]) => setSortByPositions(sortBy),
    []
  );

  const handleSortBySubordinations = useCallback(
    (sortBy: SortingRule<any>[]) => setSortBySubordination(sortBy),
    []
  );

  const { currentPage: subordinationCurrentPage, perPage: subordinationPerPage } =
    subordinationPagination;
  const { currentPage: positionsCurrentPage, perPage: positionsPerPage } = positionsPagination;

  const { data: commissions } = useQuery([COMMISSION.COMMISSION_SCHEMA], () =>
    getCommissionSchemas(0, 1000)
  );

  const commissionOptions: Option[] = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(commissions?.content.map((commission: CommissionSchemas) => {
        return { key: commission.schemaName, value: commission.schemaName };
      }) || []),
    ],
    [commissions]
  );

  const [selected, setSelected] = useState<Option>(commissionOptions[0]);

  const { data } = useQuery<AgentDetails, AxiosError>([AGENT.AGENT, agentId], () =>
    getAgent(agentId)
  );

  const {
    data: subordinationHistory,
    isLoading: subordinationIsLoading,
    isFetching: isFetchingSubordinations,
  } = useQuery<Page<Subordinations>, AxiosError>(
    [
      'subordinationHistory',
      selected,
      subordinationCurrentPage,
      subordinationPerPage,
      sortBySubordination,
    ],
    () =>
      getSubordinationHistory(
        subordinationCurrentPage,
        subordinationPerPage,
        commissions?.content.find(
          (commission: CommissionSchemas) => commission.schemaName === selected.value
        )?.commissionSchemaId,
        data?.agentCode,
        decodeUriSortParams(sortBySubordination)
      ),
    { keepPreviousData: true }
  );

  const {
    data: positions,
    isSuccess: isPositionsSuccess,
    isLoading: positionsIsLoading,
    isFetching: isFetchingPositions,
  } = useQuery<Page<StructureHistoryPositions>, AxiosError>(
    ['positions', selected, positionsCurrentPage, positionsPerPage, sortByPositions],
    () =>
      getPositions(
        positionsCurrentPage,
        positionsPerPage,
        agentId,
        commissions?.content.find(
          (commission: CommissionSchemas) => commission.schemaName === selected.value
        )?.commissionSchemaId,
        decodeUriSortParams(sortByPositions)
      ),
    { keepPreviousData: true }
  );

  return (
    <Box className="space-y-6">
      <div className="flex flex-col space-y-2 w-2/5">
        <Text className="text-secondary">Schemat</Text>
        <Select options={commissionOptions} selected={selected} setSelected={setSelected} />
      </div>

      <Text weight="bold">Podległości</Text>
      <Box.FullWidth className="px-2">
        {subordinationIsLoading && <Loader className="h-12 w-12" />}
        {subordinationHistory && (
          <Table
            columns={subordinationColumns}
            data={subordinationHistory.content}
            pagination={subordinationPagination}
            totalPages={subordinationHistory.totalPages}
            sortBy={sortBySubordination}
            onSortBy={handleSortBySubordinations}
            isLoading={isFetchingSubordinations}
          />
        )}
      </Box.FullWidth>

      <Text weight="bold">Stanowiska</Text>
      <Box.FullWidth className="px-2">
        {positionsIsLoading && <Loader className="h-12 w-12" />}
        {isPositionsSuccess && positions && (
          <Table
            isLoading={isFetchingPositions}
            columns={positionColumns}
            data={positions.content}
            pagination={positionsPagination}
            totalPages={positions.totalPages}
            sortBy={sortByPositions}
            onSortBy={handleSortByPositions}
          />
        )}
      </Box.FullWidth>
    </Box>
  );
}

export default StructureHistory;
