import { Box, Button, Text } from '@profitowi/component-library';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Addresses, EconomyActivities } from 'types/agent';

import AgentAddressForm from './AddressForm/AddressForm';

type Props = {
  privateAddress?: Addresses;
  companyAddress?: Addresses;
  correspondenceAddress?: Addresses;
  economyActivities?: EconomyActivities;
  refetchAgent: () => void;
};

const Address = ({
  privateAddress,
  companyAddress,
  correspondenceAddress,
  economyActivities,
  refetchAgent,
}: Props) => {
  const navigate = useNavigate();

  const generateAddress = (address?: Addresses) => {
    if (address) {
      const { houseNumber, apartmentNumber, street } = address;
      const displayedAddress = apartmentNumber
        ? houseNumber?.concat('/', apartmentNumber)
        : houseNumber;

      return street?.concat(' ', displayedAddress) ?? 'Brak';
    }
    return 'Brak';
  };

  return (
    <>
      <Box>
        <div className="flex flex-col">
          <div className="flex space-x-16 ">
            <div className="flex flex-col">
              <Text className="text-secondary">Adres Zamieszkania</Text>
              <Text weight="semibold">{generateAddress(privateAddress)}</Text>
              <Text weight="semibold">
                {privateAddress?.postalCode.concat(' ', privateAddress?.city)}
              </Text>
            </div>
            <div className="flex flex-col">
              <Text className="text-secondary">Adres Korespondencyjny</Text>
              <Text weight="semibold">{generateAddress(correspondenceAddress)}</Text>
              <Text weight="semibold">
                {correspondenceAddress?.postalCode?.concat(' ', correspondenceAddress?.city)}
              </Text>
            </div>
            <div className="flex flex-col">
              <Text className="text-secondary">Adres działalności</Text>
              <Text weight="semibold">{generateAddress(companyAddress)}</Text>
              <Text weight="semibold">
                {companyAddress?.postalCode?.concat(' ', companyAddress?.city)}
              </Text>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Button onPress={() => navigate('update')} variant="outline-primary">
            Edytuj
          </Button>
        </div>
      </Box>

      <Routes>
        <Route
          path="update"
          element={
            <AgentAddressForm economyActivities={economyActivities} refetchAgent={refetchAgent} />
          }
        />
      </Routes>
    </>
  );
};

export default Address;
