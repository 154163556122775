import '@fontsource/sora';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'utils/yupMethods';
import AuthService from 'services/auth';

import App from './App';

/**
 * NOTE: this file is ignored by prettier in order to preserve the import order of the CSS files.
 * The index.css file of the @profitowi/component-library must be at the end.
 */
import './index.css';
import '@profitowi/component-library/dist/index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function app(): void {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <App />
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

AuthService.initKeycloak(app);
