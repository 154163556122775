import { Box, Button, Loader } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { COMMUNICATION } from 'constants/queries/communication';
import { FILE } from 'constants/queries/file';
import { getAgentName } from 'helpers/agents';
import { useAllAgentsQuery } from 'hooks/useAllAgentsQuery';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getCommunicationThread } from 'services/communication';
import { downloadFile } from 'services/file';
import { CommunicationMessageDetails } from 'types/communication';
import { FileFromBackend, FileTypes } from 'types/file';
import { saveFile } from 'utils/file';

import SendMessageModal from '../SendMessageModal/SendMessageModal';

type Props = {
  agentQueryResult: ReturnType<typeof useAllAgentsQuery>;
};

const CommunicationThread = ({
  agentQueryResult: { data: agents, isError: isAgentListError, error: agentListError },
}: Props) => {
  const { id: paramId = '' } = useParams();
  const { translate } = useDictionaryContext();
  const [isNewMessageModalVisible, setIsNewMessageModalVisible] = useState<boolean>(false);
  const [downloadedFile, setDownloadedFile] = useState<FileFromBackend>();
  const navigate = useNavigate();

  const threadId = useMemo(() => {
    const id = Number.parseInt(paramId, 10);

    if (Number.isNaN(id) || Math.sign(id) !== 1) {
      return NaN;
    }

    return id;
  }, [paramId]);

  const { data, error, isError, isSuccess } = useQuery<
    CommunicationMessageDetails<FileFromBackend>,
    AxiosError
  >([COMMUNICATION.THREAD, threadId], () => getCommunicationThread(threadId));

  const agent = useMemo(
    () => agents?.content?.find((agent) => agent.agentCode === data?.agentCode),
    [agents, data]
  );

  const { refetch, isFetching: isFetchingFile } = useQuery<AxiosResponse<Blob>, AxiosResponse>(
    [FILE.DOWNLOAD, downloadedFile],
    () =>
      downloadFile(
        downloadedFile?.fileId as NonNullable<number>,
        downloadedFile?.fileType as NonNullable<FileTypes>
      ),
    {
      enabled: !!downloadedFile,
      onSuccess: ({ data }) => {
        if (data instanceof Blob) {
          saveFile(data, downloadedFile?.fileName as NonNullable<string>);
        }
      },
    }
  );

  const onFileDownload = (file: FileFromBackend) => {
    if (!downloadedFile) {
      setDownloadedFile(file);
    } else {
      if (downloadedFile.fileId !== file.fileId) {
        setDownloadedFile(file);
      } else {
        refetch();
      }
    }
  };

  return (
    <>
      <p className="cursor-pointer" onClick={() => navigate('/communication')}>
        <i className="bi bi-arrow-left pr-2" />
        <span>Powrót</span>
      </p>
      {isSuccess && agents && data ? (
        (() => {
          const fieldsConfig: { title: string; content: any }[] = [
            {
              title: 'Data wysłania',
              content: data.createdAt,
            },
            {
              title: 'Użytkownik',
              content: getAgentName(agent?.economyActivities),
            },
            {
              title: 'Typ',
              content: data.reason
                ? translate(DICTIONARY_TYPES.COMMUNICATION_REASON, data.reason)
                : '',
            },
            {
              title: 'Status',
              content: data.status
                ? translate(DICTIONARY_TYPES.COMMUNICATION_STATUS, data.status)
                : '',
            },
            {
              title: 'Stworzona przez',
              content: data.createdBy,
            },
            {
              title: 'Tytuł',
              content: data.title,
            },
            {
              title: 'Treść',
              content: data.content,
            },
            {
              title: 'Załączniki',
              content: data.attachments.length ? (
                <>
                  {!isFetchingFile ? (
                    data.attachments.map((att) => (
                      <p
                        className="cursor-pointer"
                        onClick={() => onFileDownload(att)}
                        key={att.fileId}>
                        <i className="bi bi-paperclip cursor-pointer" />
                        <span>{att.fileName}</span>
                      </p>
                    ))
                  ) : (
                    <Loader className="h-5 w-5" />
                  )}
                </>
              ) : (
                'Brak'
              ),
            },
          ];

          return (
            <>
              <Box>
                {fieldsConfig.map((config) => (
                  <div className="flex my-4" key={config.title}>
                    <p className="w-1/3 text-secondary">{config.title}</p>
                    <div className="w-2/3 font-light">{config.content}</div>
                  </div>
                ))}
                {['PENDING_FOR_SERVICE', 'READ'].includes(data.status) && (
                  <div className="flex justify-end items-center">
                    <Button onPress={() => setIsNewMessageModalVisible(true)}>Odpowiedz</Button>
                  </div>
                )}
              </Box>

              <SendMessageModal
                visible={isNewMessageModalVisible}
                closeModal={() => setIsNewMessageModalVisible(false)}
                agent={agent}
                messageToRespond={data}
              />
            </>
          );
        })()
      ) : (
        <Loader className="h-12 w-12" />
      )}
      {(isError || isAgentListError) && <ErrorMessages error={error ?? agentListError} />}
    </>
  );
};

export default CommunicationThread;
