import { InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { addNewComment } from 'services/comment';

const validationSchema = Yup.object({
  agentId: Yup.number().required(),
  comment: Yup.string().required(requiredFieldMessage),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  agentId: 0,
  comment: '',
};

type Props = {
  refetch: () => void;
};

const AddCommentModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const agentId = useAgentId();

  const { mutate, isLoading, isError, error } = useMutation<
    AxiosResponse,
    AxiosError,
    { agentId: number; comment: string }
  >(({ agentId, comment }) => addNewComment(agentId, comment), {
    onSuccess: () => {
      navigate('..');
      refetch();
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({ ...values });
  };

  return (
    <Modal visible title="Dodaj komentarz">
      <Formik
        initialValues={{ ...initialValues, agentId }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}>
        <Form>
          <InputField
            type="text"
            name="comment"
            label="Treść komentarza"
            placeholder="Treść komentarza"
          />
          <div className="my-8">
            <ModalActions onCancel={() => navigate('..')} isLoading={isLoading} />
          </div>
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddCommentModal;
