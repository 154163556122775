export type BillingItemStatus = 'ACCEPTED' | 'BLOCKED' | 'CANCELED' | 'IGNORED' | 'NEW' | 'SETTLED';

export enum SettlementType {
  BALANCE_SETTLEMENT = 'BALANCE_SETTLEMENT',
  COMMISSION_SETTLEMENT = 'COMMISSION_SETTLEMENT',
  TRANSFER_SETTLEMENT = 'TRANSFER_SETTLEMENT',
}
export enum SettlementStatus {
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
  ERROR = 'ERROR',
}

export type SettlementStage =
  | 'ACCEPTED'
  | 'BALANCE_GENERATED'
  | 'BONUSES'
  | 'CANCELLED'
  | 'CONTRACTS'
  | 'CREATED'
  | 'DOCUMENTS_GENERATED'
  | 'FILE_EVENTS'
  | 'FINAL_ACCEPTATION'
  | 'PAYOUTS_CALCULATED'
  | 'SYSTEM_CORRECTION_EVENTS'
  | 'TO_ACCEPTATION';

export const SETTLEMENT_STATUS = {
  CANCELLED: 'CANCELLED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
};

export type SettlementProcessResult = {
  documentCreationDate: string;
  currentStage: SettlementStage;
  nextStages: SettlementStage[];
  previousStages: SettlementStage[];
  period: string;
  editable: boolean;
};

export type SettlementData = {
  accountingDocumentCreationDate: string;
  comment: string;
  createdAt: string;
  id: number;
  lastModifiedAt: string;
  lastModifiedBy: string;
  signature: string;
  stage: SettlementStage;
  status: keyof typeof SETTLEMENT_STATUS;
  type: SettlementType;
};

export type FileEvent = {
  importDate: string;
  eventId: number;
  subEventId: number;
  payoutAgentCode: string;
  payoutDisplayAgentName: string;
  policySignature: string;
  productSignature: string;
  policyYear: number;
  contributionNo: number;
  duration: number;
  commissionSchemaName: string;
  primarySettlement: string;
  employeePositionDictionaryKey: string;
  commissionCondition: string;
  vat: number;
  baseValueGross: number;
  contributionGross: number;
  currency: string;
  billingItems: BillingItem[];
  status?: BillingItemStatus;
};

export type SettlementStepPayload = {
  itemId: number;
  itemStatus: BillingItemStatus;
};

export type SettlementPayload = {
  documentCreationDate: string;
  period: string;
  settlementType: SettlementType;
};

export type Bonus = {
  eventId: number;
  eventCreateTime: string;
  payoutAgentCode: string;
  payoutAgentDisplayName: string;
  bonusType: string;
  title: string;
  grossValue: number;
  currency: string;
  correctedDocument: string;
  correction: boolean;
  status: BillingItemStatus;
};

export type Contract = {
  eventCreateTime: string;
  eventId: number;
  eventName: string;
  contractAgentCode: string;
  contractAgentDisplayName: string;
  contractNumber: string;
  startDate: string;
  endDate: string;
  isFulfilled: boolean;
  baseValueDescription: string;
  includedBillingItems: BillingItem[];
  generatedBillingItems: BillingItem[];
};

export type Correction = {
  eventCreateTime: string;
  payoutAgentCode: string;
  payoutAgentDisplayName: string;
  eventType: string;
  correctedDocumentsNo: number;
  correctedBillingItems: BillingItem[];
  generatedBillingItems: BillingItem[];
};

export type BillingItem = {
  baseCommission: number;
  baseValueGross: number;
  billingEventId: number;
  billingEventType: string;
  commissionRate: number;
  commissionSchemaId: number;
  condition: string;
  contribution: number;
  contributionNo: number;
  correctingDocumentId: number;
  correction: boolean;
  employeePositionDictionaryId: number;
  employeePositionDictionaryKey: string;
  employeePositionDictionaryValue: string;
  gross: number;
  id: number;
  invoiceItemName: string;
  net: number;
  payoutAgentCode: string;
  payoutAgentDisplayName: string;
  payoutAgentId: number;
  policySignature: string;
  policySplitId: number;
  policyTransferId: number;
  productSignature: string;
  salesPeriod?: string;
  sellerAgentCode: string;
  sellerAgentId: number;
  settlementDate: string;
  settlementId: number;
  status: BillingItemStatus;
  vat: number;
  vatRate: number;
};

type SettlingDocument = {
  documentNumber: string;
  grossValue: number;
};

type DocumentPosition = {
  documentPositionId: number;
  positionName: string;
  netValue: number;
  vatValue: number;
  vatRate: number;
  grossValue: number;
};

export type Document = {
  accountingDocumentId: number;
  documentNumber: string;
  grossValue: number;
  issueDate: string;
  documentType: string;
  documentStatus: string;
  documentFileId: number;
  documentFilename: string;
  agentId: number;
  settlingDocuments: SettlingDocument[];
  documentPositions: DocumentPosition[];
  correctedDocument: number;
  settlementId: number;
  billingItems: BillingItem[];
  salePeriod: string;
};

export type Balance = {
  agentCode: string;
  agentDisplayName: string;
  documentNumber: string;
  grossValue: number;
  issueDate: string;
  positionType: 'ADVANCE' | 'DEBT_REPAYMENT' | 'OTHER_PAYOUT' | 'SETTLED_DOCUMENTS';
  valueToPay: number;
};

export type Payout = {
  agentId: number;
  agentCode: string;
  agentDisplayName: string;
  balanceToPay: number;
  calculationTime: string;
};

export enum TemplateType {
  ACCOUNTING_NOTE_SENT_EMAIL = 'ACCOUNTING_NOTE_SENT_EMAIL',
  INVOICE_SENT_EMAIL = 'INVOICE_SENT_EMAIL',
  MONEY_TRANSFER_SENT_EMAIL = 'MONEY_TRANSFER_SENT_EMAIL',
}

export type MessageTemplateData = {
  content: string;
  templateType: TemplateType;
  title: string;
};

export type SettlementMessagePayload = {
  settlementId?: number;
  messageIds?: number[];
};

export type SettlementMessageQueryParam = {
  agentIds: string;
  messageStatus: string;
};
