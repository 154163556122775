import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { ActivityRangeRecord } from 'types/agent';

const formatDate = ({ value = '' }: Cell<ActivityRangeRecord>) =>
  value ? value.split(' ')[0] : '';

export const columns: Column<ActivityRangeRecord>[] = [
  {
    Header: 'Data od',
    accessor: 'validFrom',
    Cell: formatDate,
  },
  {
    Header: 'Data do',
    accessor: 'validTo',
    Cell: formatDate,
  },
  { Header: 'Uprawnienie', id: 'activityRange.value', accessor: 'activityRangeDictionaryKey' },
  {
    id: 'operations',
    disableSortBy: true,
    Cell: ({ row: { original: activityRange } }: Cell<ActivityRangeRecord>) => {
      const navigate = useNavigate();

      return (
        <div className="flex space-x-2">
          <Button
            onPress={() => navigate('addUpdate', { state: { activityRange } })}
            size="sm"
            variant="light">
            Edytuj
          </Button>

          <Button
            onPress={() => navigate('delete', { state: { activityRange } })}
            size="sm"
            variant="light">
            Usuń
          </Button>
        </div>
      );
    },
  },
];
