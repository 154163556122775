import { Header } from '@profitowi/component-library';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import NavigationToolbar from 'components/NavigationToolbar/NavigationToolbar';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';

import ReportList from './components/ReportList/ReportList';

const Reports = () => {
  const { dictionaries } = useDictionaryContext();

  const reportsUrl = useMemo<string>(
    () =>
      Array.isArray(dictionaries?.[DICTIONARY_TYPES.APPLICATION_CONFIGURATION])
        ? dictionaries?.[DICTIONARY_TYPES.APPLICATION_CONFIGURATION].find(
            (item) => item.key === 'RAPORTY_URL'
          )?.value ?? ''
        : '',
    [dictionaries]
  );

  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <Header as="h1" size="xl" weight="semibold">
        Raporty
      </Header>

      <NavigationToolbar
        rootPath="/reports"
        tabs={[
          { displayName: 'Lista raportów', route: '/' },
          { displayName: 'Raporty superset', route: reportsUrl, isExternal: true },
        ]}
      />

      <Routes>
        <Route path="/*" element={<ReportList />} />
      </Routes>
    </div>
  );
};

export default Reports;
