import qs from 'qs';

import { CommissionOtherImportPayload } from 'types/commission';
import { FileFromBackend, FileTypes } from 'types/file';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const uploadFileScan = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await recordmodApi.post(`/files/scan`, formData);

  return data;
};

export const uploadClientDataFile = async (payload: CommissionOtherImportPayload) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileType', FileTypes.CLIENT_DATA);

  if (payload.sheetName) {
    formData.append('sheetName', payload.sheetName);
  }

  const { data } = await recordmodApi.post('/files', formData);

  return data;
};

export const downloadFile = async (fileId: number, fileType: FileTypes) => {
  const data = await recordmodApi.get<Blob>(`/files/${fileId}?type=${fileType}`, {
    responseType: 'blob',
  });

  return data;
};

export const getFileStatus = async (fileId?: number) => {
  const { data } = await recordmodApi.get<string>(`/files/${fileId}/status`);

  return data;
};

export const getFileList = async (
  types: FileTypes[],
  page: number = 0,
  size: number = 10,
  isDashboardVersion?: boolean,
  sortBy?: string
) => {
  const sort = isDashboardVersion ? 'createdAt,desc' : sortBy ?? '';

  const { data } = await recordmodApi.get<Page<FileFromBackend>>(`/files`, {
    params: {
      type: types,
      page,
      size,
      sort,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

export const deleteFile = async (fileId?: number) => {
  const { data } = await recordmodApi.delete(`/files/${fileId}`);

  return data;
};
