import { Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { ChildList } from 'types/delegation';

export const columns: Column<ChildList>[] = [
  {
    accessor: 'commissionSchema',
    Header: 'Schemat prowizyjny',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return translate(DICTIONARY_TYPES.COMMISSION_SCHEMA_NAME, value.toString());
    },
  },
  {
    id: 'child.agentCode',
    accessor: 'child',
    Header: 'Kod kontrahenta podrzędnego',
    Cell: ({ value }) => value?.agentCode ?? '',
  },
  {
    id: 'child.name',
    accessor: 'child',
    Header: 'Kod kontrahenta podrzędnego',
    Cell: ({ value }) => value?.name ?? '',
  },
  {
    accessor: 'validFrom',
    Header: 'Data od',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0] ?? ''}</span>,
  },
  {
    accessor: 'validTo',
    Header: 'Data do',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value?.split(' ')?.[0] ?? ''}</span>,
  },
];
