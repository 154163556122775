import { Button } from '@profitowi/component-library';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { FileStatus } from 'types/file';
import { Reports } from 'types/reports';

export const columns = (setReportId: (report: Reports) => void): Column<Reports>[] => [
  {
    accessor: 'reportType',
    Header: 'Typ raportu',
    disableSortBy: true,
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate(DICTIONARY_TYPES.REPORT_TYPE, String(value)) : '';
    },
  },

  {
    accessor: 'fileName',
    Header: 'Nazwa pliku',
  },
  {
    accessor: 'createdBy',
    Header: 'Utworzony przez',
  },
  {
    accessor: 'createdAt',
    Header: 'Data utworzenia',
  },
  {
    accessor: 'size',
    Header: 'Ilość wierszy',
    disableSortBy: true,
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate(DICTIONARY_TYPES.FILE_STATUS, String(value)) : '';
    },
  },
  {
    id: 'params',
    Header: 'Filtry',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<Reports>) => {
      const navigate = useNavigate();

      if (Object.keys(original.params).length !== 0) {
        return (
          <Button
            variant="light"
            onPress={() => navigate('filters', { state: { report: original } })}
            className="flex gap-4 w-full items-center">
            <span>Pokaż filtry</span>
          </Button>
        );
      }
      return '';
    },
  },
  {
    id: 'operations',
    Cell: ({ row: { original } }: Cell<Reports>) => {
      const isFinished = original.status === FileStatus.FINISHED;

      if (isFinished) {
        return (
          <Button
            variant="light"
            onPress={() => setReportId(original)}
            className="flex gap-4 w-full items-center">
            <i className={clsx('bi cursor-pointer', isFinished ? 'bi-download' : 'bi-gear')} />
            <span>Pobierz</span>
          </Button>
        );
      }
      return '';
    },
  },
];
