import { Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { BillingItem } from 'types/settlement';
import { toPercent } from 'utils/number';

export const columns: Column<BillingItem>[] = [
  {
    Header: 'Data naliczenia',
    accessor: 'settlementDate',
    disableSortBy: true,
  },
  {
    Header: 'Id pozycji naliczeniowej',
    accessor: 'id',
  },
  {
    Header: 'Kod agenta do wypłaty',
    accessor: 'payoutAgentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: 'payoutAgentDisplayName',
  },
  {
    Header: 'Stanowisko',
    accessor: 'employeePositionDictionaryValue',
    id: 'employeePosition.dictionaryValue',
  },
  {
    Header: 'Sygnatura polisy',
    accessor: 'policySignature',
  },
  {
    Header: 'Sygnatura produktu',
    accessor: 'productSignature',
  },
  {
    Header: 'Składka',
    accessor: (row) => <div className="text-right">{row?.contributionNo}</div>,
  },
  {
    Header: 'Schemat prowizyjny',
    accessor: 'commissionSchemaId',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_SCHEMA_NAME', String(value)) : '';
    },
  },
  {
    Header: 'Warunek prowizyjny',
    accessor: 'condition',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_CONDITION', value) : '';
    },
  },
  {
    Header: 'Stawka VAT',
    accessor: 'vatRate',
    Cell: ({ value }) => {
      if (!value) return 'zw';

      return toPercent(value);
    },
  },
  {
    Header: 'Podstawa brutto',
    accessor: (row) => <div className="text-right">{row?.baseValueGross}</div>,
  },
  {
    Header: 'Składka brutto',
    accessor: (row) => <div className="text-right">{row?.contribution}</div>,
  },
  {
    Header: 'Kwota prowizji',
    accessor: (row) => <div className="text-right">{row?.gross}</div>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('BILLING_ITEM_STATUS', String(value)) : '';
    },
  },
];
