import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { REPORTS } from 'constants/queries/reports';
import { getReportsConfig } from 'services/reports';
import { ReportConfig, ReportType } from 'types/reports';

type Params = {
  reportType?: ReportType;
};

const useReportConfigQuery = ({ reportType }: Params) => {
  const { data: reportConfig, ...rest } = useQuery<ReportConfig[], AxiosError>(
    [REPORTS.REPORTS_CONFIG, reportType],
    () => getReportsConfig(reportType),
    {
      enabled: !!reportType,
      keepPreviousData: true,
    }
  );

  return { reportConfig, ...rest };
};

export default useReportConfigQuery;
