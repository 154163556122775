import { Box, Button, Header, Input, Loader, Table, Text } from '@profitowi/component-library';
import { useCallback, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { useAgentQuery } from 'hooks/useAgentQuery';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { parseSortParams } from 'utils/sort';

import { createColumns } from './columns';
import AddAgentModal from './components/AddAgentModal/AddAgentModal';

export const Agents = () => {
  const [queryParam, setQueryParam] = useState<string>('');
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([{ desc: false, id: 'agentCode' }]);
  const pagination = usePagination(10);
  const { activeDictionaries } = useDictionaryContext();
  const navigate = useNavigate();

  const parsedSortBy = useMemo(() => parseSortParams(sortBy), [sortBy]);

  const { currentPage, perPage } = pagination;
  const { data, error, isError, isLoading, isFetching, isSuccess } = useAgentQuery({
    queryParam,
    currentPage,
    perPage,
    sortBy: parsedSortBy,
  });

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Kontrahenci
          </Header>

          <Input
            label="Szukaj"
            onChange={(value: string) => setQueryParam(value)}
            value={queryParam}
          />

          <Button variant="outline-primary" onPress={() => navigate('addAgent')}>
            <Text weight="semibold">
              Dodaj kontrahenta
              <i className="bi bi-plus-lg ml-2" />
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && activeDictionaries && (
          <Box.FullWidth>
            <Table
              columns={createColumns(activeDictionaries)}
              data={data.content}
              pagination={pagination}
              onSortBy={handleSortBy}
              totalPages={data.totalPages}
              sortBy={sortBy}
              isLoading={isFetching}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path="addAgent" element={<AddAgentModal />} />
      </Routes>
    </>
  );
};

export default Agents;
