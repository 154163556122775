import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { AGENT } from 'constants/queries/agent';
import { getAgents } from 'services/agent';
import { AgentList } from 'types/agent';
import { Page } from 'types/request';

import { usePagination } from './usePagination';

export const useAllAgentsQuery = () => {
  const { currentPage, perPage, setPerPage, setPage } = usePagination(10000);

  const { data, isError, isFetching, isSuccess, isLoading, error } = useQuery<
    Page<AgentList>,
    AxiosError
  >([AGENT.AGENTS, currentPage, perPage], () => getAgents(undefined, currentPage, perPage), {
    keepPreviousData: true,
  });

  return {
    data,
    isError,
    isFetching,
    isSuccess,
    isLoading,
    error,
    currentPage,
    perPage,
    setPerPage,
    setPage,
  };
};
