import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { Payments } from 'types/bonus';

export const CreateColumn = (editPayment: (payment: Payments) => void): Column<Payments>[] => {
  const { translate } = useDictionaryContext();

  return [
    {
      Header: 'Kwota brutto',
      accessor: (row) => <div className="text-right">{row?.grossValue}</div>,
    },
    {
      Header: 'Kwota netto',
      accessor: (row) => <div className="text-right">{row?.netValue}</div>,
    },
    {
      Header: 'Data płatności',
      accessor: 'paymentDate',
      sortType: (a, b) =>
        parse(a.values.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
        parse(b.values.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
    },
    {
      Header: 'Wartość VAT',
      accessor: 'taxPercent',
    },
    {
      Header: 'Status',
      accessor: (row: Payments) => {
        return row.paymentStatus ? translate(DICTIONARY_TYPES.BONUS_STATUS, row.paymentStatus) : '';
      },
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Payments>) => (
        <div className="flex justify-end">
          <Button variant="light" onPress={() => editPayment(original)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
