import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { deleteAgentBonus, getBonuses } from 'services/agent';
import { Bonus } from 'types/bonus';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import AgentBonusesForm from './AgentBonusesForm/AgentBonusesForm';
import { createColumn } from './columns';

const AgentBonuses = () => {
  const [modalBonusVisible, setModalBonusVisible] = useState(false);
  const { activeDictionaries } = useDictionaryContext();
  const pagination = usePagination(10);
  const agentId = useAgentId();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, refetch, isError, isLoading, isFetching, error } = useQuery<
    Page<Bonus>,
    AxiosError
  >(
    [AGENT.AGENT_BONUSES, pagination.currentPage, pagination.perPage, sortBy],
    () =>
      getBonuses(agentId, pagination.currentPage, pagination.perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  const { mutate } = useMutation<AxiosResponse, AxiosError, number>(
    (agentBonusId) => deleteAgentBonus(agentId, agentBonusId),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Premie
          </Header>

          <Button
            variant="outline-primary"
            className="px-4 py-1"
            onPress={() => setModalBonusVisible(true)}>
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}

        {data && activeDictionaries && (
          <Box.FullWidth>
            <Table
              pagination={pagination}
              totalPages={data.totalPages}
              data={data.content}
              columns={createColumn(agentId, navigate, activeDictionaries, mutate)}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              isLoading={isFetching}
            />
          </Box.FullWidth>
        )}
      </Box>

      <AgentBonusesForm
        visible={modalBonusVisible}
        setVisible={setModalBonusVisible}
        refetchAgentBonuses={refetch}
      />
    </>
  );
};

export default AgentBonuses;
