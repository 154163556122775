import { Page } from 'types/request';
import { MessageTemplate } from 'types/template';
import { recordmodApi } from 'utils/axios';

export const getTemplate = async (type: string) => {
  const { data } = await recordmodApi.get<MessageTemplate>(`/messages/templates/${type}`);

  return data;
};

export const getTemplates = async (page: number, size: number, sort?: string) => {
  const { data } = await recordmodApi.get<Page<MessageTemplate>>('/messages/templates', {
    params: { page, size, sort },
  });
  return data;
};

export const updateTemplateEntry = (payload: MessageTemplate) => {
  return recordmodApi.put(`/messages/templates/${payload.templateType}`, payload);
};
