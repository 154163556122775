import { Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { Emails } from 'types/emails';

export const Columns: Column<Emails>[] = [
  {
    Header: 'Data i godzina wysłania',
    accessor: 'sentDate',
  },
  {
    id: 'agent.agentCode',
    Header: 'Numer PR',
    accessor: 'agentCode',
  },
  {
    Header: 'Rozliczenie',
    accessor: 'id',
  },
  {
    Header: 'Kontrahent',
    accessor: 'agentDisplayName',
  },
  {
    Header: 'Adres/adresy e-mail',
    accessor: 'addresses',
    disableSortBy: true,
    Cell: ({ value }) => value.map((item) => item),
  },
  {
    Header: 'Typ maila',
    accessor: 'templateType',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate(DICTIONARY_TYPES.MESSAGE_TEMPLATE_TYPE, value) : '';
    },
  },
  {
    Header: 'Status',
    accessor: 'messageStatus',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate(DICTIONARY_TYPES.MESSAGE_STATUS, value) : '';
    },
  },
  {
    Header: 'Wersja maila',
    accessor: 'resent',
    Cell: ({ value }) => (value ? 'Ponowiona' : 'Oryginalna'),
  },
];
