import { Box, Button, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { FILE } from 'constants/queries/file';
import { REPORTS } from 'constants/queries/reports';
import { usePagination } from 'hooks/usePagination';
import { getReportFile, getReports } from 'services/reports';
import { Reports } from 'types/reports';
import { Page } from 'types/request';
import { saveFile } from 'utils/file';
import { decodeUriSortParams } from 'utils/table';

import ReportFilterParamModal from '../ReportFilterParamModal/ReportFilterParamModal';
import ReportModal from '../ReportModal/ReportModal';
import { columns } from './columns';

const ReportList = () => {
  const [report, setReport] = useState<Reports>();
  const [sortBy, setSortBy] = useState<SortingRule<any>[]>([{ id: 'createdAt', desc: true }]);

  const pagination = usePagination(10);
  const { perPage, currentPage } = pagination;
  const navigate = useNavigate();

  const { error, data, isError, isFetching, isLoading, isSuccess, refetch } = useQuery<
    Page<Reports>,
    AxiosError
  >(
    [REPORTS.REPORTS, currentPage, perPage, sortBy],
    () => getReports(currentPage, perPage, decodeUriSortParams(sortBy)),
    {
      keepPreviousData: true,
    }
  );

  const { refetch: refetchDownload } = useQuery<Blob, AxiosError>(
    [FILE.REPORT_DOWNLOAD, report?.fileId],
    () => getReportFile(report?.fileId),
    {
      enabled: false,
      onSuccess: (data) => {
        saveFile(data, report?.fileName ?? '', 'application/xls');
        setReport(undefined);
      },
    }
  );

  useEffect(() => {
    if (report?.fileId) {
      refetchDownload();
    }
  }, [report]);

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Lista Raportów
          </Header>

          <Button size="sm" variant="outline-primary" onPress={() => navigate('generateReport')}>
            Wygeneruj Raport
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns(setReport)}
              data={data.content}
              pagination={pagination}
              totalPages={data.totalPages}
              isLoading={isFetching}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path="generateReport/*" element={<ReportModal refetchList={refetch} />} />
        <Route path="filters/*" element={<ReportFilterParamModal />} />
      </Routes>
    </>
  );
};

export default ReportList;
