import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { ECONOMY_ACTIVITY_PARAM_TYPES } from 'constants/economyActivityParamTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { EconomyActivityParams } from 'types/agent';
import { DictionaryTypes } from 'types/dictionary';
import { parseVatRate } from 'utils/lingual';

export const columns = (dictionaryTypes?: DictionaryTypes): Column<EconomyActivityParams>[] => {
  return [
    {
      Header: 'Data od',
      accessor: 'validFrom',
    },
    {
      Header: 'Data do',
      accessor: 'validTo',
    },
    {
      id: 'type',
      Header: 'Parametr',
      accessor: 'type',
      Cell: ({
        row: {
          original: { type },
        },
      }: Cell<EconomyActivityParams>) => {
        const { translate } = useDictionaryContext();
        return type ? translate(DICTIONARY_TYPES.PARAM_TYPE, type) : '';
      },
    },
    {
      Header: 'Wartość',
      id: 'value',
      Cell: ({
        row: {
          original: { value, type },
        },
      }: Cell<EconomyActivityParams>) => {
        const { translate } = useDictionaryContext();

        if (type === ECONOMY_ACTIVITY_PARAM_TYPES.SELF_BILLING) {
          return value === 'true' ? 'Tak' : 'Nie';
        } else if (type === ECONOMY_ACTIVITY_PARAM_TYPES.VAT_RATE) {
          const dictionary = findDictionaryByKey(value, dictionaryTypes?.[type] ?? []);
          return parseVatRate(dictionary?.value ?? '');
        } else if (type === ECONOMY_ACTIVITY_PARAM_TYPES.ECONOMY_ACTIVITY_STATE)
          return value ? translate(DICTIONARY_TYPES.ECONOMY_ACTIVITY_STATE, value) : '';
      },
    },
    {
      Header: 'Komentarz',
      accessor: 'description',
      disableSortBy: true,
    },
    {
      id: 'operations',
      Cell: ({ row: { original } }: Cell<EconomyActivityParams>) => {
        const navigate = useNavigate();

        if (
          original.validNowOrInFuture ||
          original.type === ECONOMY_ACTIVITY_PARAM_TYPES.VAT_RATE ||
          original.valid
        ) {
          return (
            <div className="flex justify-end">
              <Button
                variant="light"
                onPress={() =>
                  navigate('updateParameter', { state: { economyActivityParam: original } })
                }>
                <div className="flex space-x-4 items-center justify-center">
                  <i className="bi bi-trash cursor-pointer mx-2" />
                  Edytuj
                </div>
              </Button>
            </div>
          );
        }
        return null;
      },
    },
  ];
};
