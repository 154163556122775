import { Option } from '@profitowi/component-library';
import { compareDesc, parse } from 'date-fns';

import { AgentList, BasicAgent, EconomyActivities, EconomyActivityParams } from 'types/agent';
import { Optional } from 'types/common';

export const chooseEconomyActivity = (
  activities: EconomyActivities[]
): EconomyActivities | undefined => {
  if (!activities) {
    return undefined;
  }

  const sorted = activities.sort((a, b) =>
    compareDesc(
      parse(a.validTo, 'dd-MM-yyyy HH:mm:ss', new Date()),
      parse(b.validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
    )
  );

  const found = sorted.find((activity) => activity.validNowOrInFuture);

  if (found) {
    return found;
  } else if (sorted) {
    return sorted[0];
  } else {
    return undefined;
  }
};

export const chooseEconomyActivityParam = (
  params: EconomyActivityParams[],
  type: string
): EconomyActivityParams | undefined => {
  if (!params) {
    return undefined;
  }

  const sorted = params
    .filter((param) => param.type === type)
    .sort((a, b) =>
      compareDesc(
        parse(a.validTo, 'dd-MM-yyyy HH:mm:ss', new Date()),
        parse(b.validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
      )
    );

  const found = sorted.find((activity) => activity.validNowOrInFuture);

  if (found) {
    return found;
  } else if (sorted) {
    return sorted[0];
  } else {
    return undefined;
  }
};

export const createAgentsOptions = <T extends BasicAgent>(
  agents: Array<T>,
  propertyForKey: 'id' | 'agentCode' = 'agentCode',
  suffixConfig: { suffix: string; suffixConditionFlagField: keyof T } | null = null
): Option[] => {
  return agents.map((agent) => ({
    key: agent[propertyForKey],
    value: `${agent.agentCode} - ${getAgentDisplayName(agent)}${
      suffixConfig && !!agent[suffixConfig.suffixConditionFlagField] ? suffixConfig.suffix : ''
    }`,
  }));
};

export const getAgentDisplayName = (agent: BasicAgent): string => {
  const economyActivity: Optional<EconomyActivities, 'addresses'> = agent?.economyActivities?.[0];

  let displayName = ``;

  if (!!economyActivity?.name && !!economyActivity.lastName) {
    displayName += `${economyActivity.name} ${economyActivity.lastName}`;
  } else if (!!economyActivity?.companyName) {
    displayName += `${economyActivity?.companyName}`;
  }

  return displayName;
};

export const createAgentPositionsOptions = (
  agent: AgentList,
  commissionSchemaId: number
): Option[] => {
  const today = new Date();
  today.setHours(0, 0, 0);

  return (
    agent.positions
      ?.filter(
        (position) =>
          position.commissionSchemaId === commissionSchemaId &&
          (position.validTo === null || today.getTime() < new Date(position.validFrom).getTime())
      )
      ?.map((position) => ({
        key: position.positionDictionaryKey,
        value: position.positionDictionaryValue,
      })) || []
  );
};

export const getAgentName = (
  economyActivities?: Optional<EconomyActivities, 'addresses'>[]
): string => {
  let chosenEconomyActivity = chooseEconomyActivity(economyActivities as EconomyActivities[]);
  let result = '';
  if (chosenEconomyActivity) {
    const name = chosenEconomyActivity.name;
    const lastName = chosenEconomyActivity.lastName;

    result = `${lastName ? lastName : ''} ${name ?? ''}`;

    if (!result.trim()) {
      result = chosenEconomyActivity.companyName;
    }

    return result;
  } else {
    return 'Brak działalności na kontrahencie';
  }
};

export const getAgentBusinessInfo = (economyActivity?: EconomyActivities) =>
  economyActivity?.nip?.concat(' / ', economyActivity.regon) ?? 'Brak';

export const getAddress = (addressType: string, economyActivity?: EconomyActivities) =>
  economyActivity && economyActivity.addresses?.find(({ type }) => type === addressType);
