import { Modal, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import ModalActions from 'components/ModalActions';

type Props = {
  confirmMessage: string;
  isLoading?: boolean;
  isError?: boolean;
  error?: AxiosError | null;
  mutate: () => void;
  onCancel?: () => void;
};

const ConfirmationModal = ({
  confirmMessage,
  isLoading,
  isError,
  error,
  mutate,
  onCancel,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Modal visible>
      <Text className="text-center">{confirmMessage}</Text>
      <ModalActions
        confirmLabel="Zatwierdź"
        isLoading={isLoading}
        onCancel={() => (typeof onCancel === 'function' ? onCancel() : navigate('..'))}
        onConfirm={() => mutate()}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default ConfirmationModal;
