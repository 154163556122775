import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { changePassword } from 'services/user';

type ChangePasswordResponse = {
  userId: string;
  password: string;
  temporary: boolean;
};

export const useChangePassword = () => {
  return useMutation<AxiosResponse, AxiosError, ChangePasswordResponse>(
    ({ userId, password, temporary }) => changePassword(userId, password, temporary)
  );
};

export default useChangePassword;
