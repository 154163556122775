import { Tab } from '@headlessui/react';
import { Box, Header, Option, Tabs } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Form, Formik } from 'formik';
import Files from 'pages/Settlements/Files';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { GroupBox } from 'components';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { productCategories } from 'constants/groupCategories';
import { DASHBOARD } from 'constants/queries/dashboard';
import { summaryPeriod } from 'helpers/months';
import useAgentRoleContext from 'hooks/useAgentRoleContext';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getBalance } from 'services/balance';
import { getCommissionAggregatedByGroup, getCommissionForPeriod } from 'services/commission';
import { CommissionByGroup, CommissionByPeriod } from 'types/commission';
import { DictionaryType } from 'types/dictionary';
import { Roles } from 'types/user';

import PeriodDatePicker from './PeriodDatePicker';
import BalanceBox from './components/BalanceBox';
import CommissionChart from './components/CommissionChart';
import SummaryBox from './components/SummaryBox';
import SettlementTable from './components/tables/SettlementTable';

const Dashboard = () => {
  const [balancePeriod, setBalancePeriod] = useState('');
  const [commissionPeriod, setCommissionPeriod] = useState(summaryPeriod[3] as Option);
  const { activeDictionaries, dictionaries } = useDictionaryContext();
  const { hasRole, isAdminOrCommissionRole } = useAgentRoleContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminOrCommissionRole) {
      if (hasRole(Roles.RECORDS_MANAGEMENT)) navigate('/records');
      else navigate('/konto');
    }
  }, []);

  useMemo(() => {
    if (dictionaries) {
      const applicationConfig = dictionaries?.[DICTIONARY_TYPES.APPLICATION_CONFIGURATION]?.find(
        ({ key }) => key === 'WyswietlanyMiesiacNaDashboardzieOdDnia'
      );

      const currentDate = new Date();
      const currentDayOfMonth = currentDate.getDate();

      if (applicationConfig?.value && currentDayOfMonth >= parseInt(applicationConfig?.value))
        setBalancePeriod(format(new Date(), 'yyyy-LL', { locale: pl }));

      if (applicationConfig?.value && currentDayOfMonth < parseInt(applicationConfig?.value))
        setBalancePeriod(
          format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1), 'yyyy-LL', {
            locale: pl,
          })
        );
    }
  }, [dictionaries]);

  const initialValues = useMemo(() => {
    return balancePeriod ? balancePeriod : '';
  }, [balancePeriod]);

  const {
    data: commission,
    isFetched: commissionIsFetched,
    isLoading: commissionIsLoading,
    isSuccess: commissionIsSuccess,
  } = useQuery<CommissionByPeriod, AxiosError>(
    [DASHBOARD.COMMISSIONS, commissionPeriod.key],
    () => getCommissionForPeriod(commissionPeriod.key as string),
    { enabled: isAdminOrCommissionRole }
  );
  const {
    data: agentBalance,
    isSuccess,
    isLoading,
  } = useQuery<number, AxiosError>(
    [DASHBOARD.BALANCE, balancePeriod],
    () => getBalance(balancePeriod),
    { enabled: balancePeriod.length > 0 && isAdminOrCommissionRole }
  );
  const { data: commissionByGroup, isFetched: groupIsFetched } = useQuery<
    CommissionByGroup,
    AxiosError
  >(
    [DASHBOARD.COMMISSION_GROUP, balancePeriod],
    () => getCommissionAggregatedByGroup(balancePeriod),
    { enabled: balancePeriod.length > 0 && isAdminOrCommissionRole }
  );

  const handleBalancePeriod = useCallback(
    (period: string) => setBalancePeriod(format(new Date(period), 'yyyy-LL', { locale: pl })),
    []
  );

  const dashboardCommissionTilesData: DictionaryType[] = useMemo(
    () => [
      ...(!!activeDictionaries?.[DICTIONARY_TYPES.PRODUCT_GROUP]?.length
        ? activeDictionaries?.[DICTIONARY_TYPES.PRODUCT_GROUP]
        : []),
      {
        key: 'BONUS_OTHER',
        value: 'Premie inne',
      },
    ],
    [activeDictionaries]
  );

  return (
    <>
      {isAdminOrCommissionRole && (
        <div className="flex-1 p-8 space-y-6 overflow-hidden">
          <div className="flex justify-between">
            <Header as="h1" size="xl" weight="semibold">
              Dashboard
            </Header>

            <Formik
              initialValues={{ period: initialValues }}
              onSubmit={() => {}}
              enableReinitialize>
              <Form>
                <PeriodDatePicker handleBalancePeriod={handleBalancePeriod} />
              </Form>
            </Formik>
          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 lg:grid-cols-3">
            {dashboardCommissionTilesData?.map(({ key, value }, index) => {
              const category = productCategories.find((item) => item.key === key);

              return (
                <GroupBox
                  key={index}
                  value={commissionByGroup?.data?.find(({ group }) => group === key)?.value ?? 0}
                  isFetched={groupIsFetched}
                  name={value}
                  icon={category?.icon ?? 'bi-cash-stack'}
                  color={category?.color ?? 'bg-icon-orange'}
                  className="px-4"
                />
              );
            })}
          </div>

          <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 2xl:grid-cols-4">
            <CommissionChart
              commissionPeriod={commissionPeriod}
              setCommissionPeriod={(value) => setCommissionPeriod(value)}
              commission={commission}
              isFetched={commissionIsFetched}
            />

            <div className="flex justify-between flex-col sm:space-y-6">
              <SummaryBox
                total={commission?.total}
                isLoading={commissionIsLoading}
                isSuccess={commissionIsSuccess}
              />
              <BalanceBox balance={agentBalance} isSuccess={isSuccess} isLoading={isLoading} />
            </div>
          </div>

          <Box>
            <Tabs variant="light" categories={['Rozliczenia', 'Importowane pliki']}>
              <Tab.Panel>
                <SettlementTable />
              </Tab.Panel>
              <Tab.Panel>
                <Files isDashboardVersion />
              </Tab.Panel>
            </Tabs>
          </Box>
        </div>
      )}
    </>
  );
};

export default Dashboard;
