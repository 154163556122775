import {
  AutoCompleteField,
  Button,
  Input,
  InputField,
  Modal,
  SelectField,
  TextareaField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createAgentsOptions, getAgentName } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { postNewMessage, respondToMessage } from 'services/communication';
import { uploadFileScan } from 'services/file';
import { AgentList } from 'types/agent';
import {
  CommunicationMessage,
  NewMessagePayload,
  RespondToMessagePayload,
} from 'types/communication';
import { cleanObject } from 'utils/object';

const validationSchema = Yup.object({
  agentId: Yup.mixed().required(requiredFieldMessage),
  title: Yup.string().required(requiredFieldMessage),
  content: Yup.string().required(requiredFieldMessage),
  param: Yup.string(),
  reason: Yup.string().required(requiredFieldMessage),
  attachments: Yup.array().of(
    Yup.object({
      file: Yup.mixed(),
      uploadedFileId: Yup.number().min(1, 'Plik jest wymagany'),
    }).nullable()
  ),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  agentId: 0,
  title: '',
  content: '',
  param: '',
  reason: '',
  attachments: [],
};

type Props = {
  visible: boolean;
  closeModal: () => void;
  refetch?: () => void;
  agent?: AgentList;
  messageToRespond?: CommunicationMessage;
};

const SendMessageModal = ({ visible, closeModal, refetch, agent, messageToRespond }: Props) => {
  const [queryParam, setQueryParam] = useState<string>('');
  const { activeDictionaries } = useDictionaryContext();
  const { translate } = useDictionaryContext();
  const { data: agentQueryData } = useAgentQuery({
    queryParam,
    minQueryLength: 3,
  });

  const agentOptions = useMemo(
    () => (agentQueryData?.content ? createAgentsOptions(agentQueryData?.content) : []),
    [agentQueryData]
  );

  const {
    isLoading: isUploadLoading,
    isError: isUploadError,
    error: fileUploadError,
    mutate: mutateFile,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      file: File;
      index: number;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    }
  >(({ file }) => uploadFileScan(file), {
    onSuccess: (response, mutateParams) => {
      mutateParams.setFieldValue(`attachments[${mutateParams.index}].uploadedFileId`, response);
    },
  });

  const isNewMessage = (
    data: NewMessagePayload | RespondToMessagePayload
  ): data is NewMessagePayload => {
    return Object.prototype.hasOwnProperty.call(data, 'agentId');
  };

  const {
    isLoading: isSubmitLoading,
    isError: isSubmitError,
    error: submitError,
    mutate: sendMessage,
  } = useMutation<AxiosResponse, AxiosError, NewMessagePayload | RespondToMessagePayload>(
    (payload) =>
      isNewMessage(payload)
        ? postNewMessage(payload)
        : respondToMessage(messageToRespond?.id as NonNullable<number>, payload),
    {
      onSuccess: () => {
        closeModal();
        if (refetch) {
          refetch();
        }
      },
    }
  );

  const onFileInputChange = (
    form: any,
    event: any,
    index: number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const file = event.target.files[0];
    form.setFieldValue(`attachments[${index}].file`, file);
    mutateFile({ file, index, setFieldValue });
  };

  const handleSubmit = (values: FormValues) => {
    const attachments = values?.attachments?.map((att) => att?.uploadedFileId);
    const agentId = agent
      ? values.agentId
      : agentQueryData?.content.find((agent) => agent.agentCode === values.agentId.toString())?.id;

    sendMessage(
      cleanObject(
        messageToRespond
          ? { content: values.content, attachments }
          : {
              ...values,
              attachments,
              agentId,
            }
      )
    );
  };

  let initValues = agent ? { ...initialValues, agentId: agent.id } : { ...initialValues };
  if (messageToRespond) {
    initValues = {
      ...initValues,
      title: messageToRespond.title,
      reason: translate(DICTIONARY_TYPES.COMMUNICATION_REASON, messageToRespond.reason),
    };
  }

  return (
    <Modal visible={visible} title="Wyślij wiadomość">
      <Formik
        validationSchema={validationSchema}
        initialValues={initValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ values, setFieldValue, errors, touched }) => (
          <Form className="space-y-2">
            <div>
              {agent ? (
                <>
                  <div hidden={true}>
                    <InputField name="agentId" label="Użytkownik" isReadOnly={true} />
                  </div>
                  <Input
                    label="Użytkownik"
                    isReadOnly={true}
                    value={getAgentName(agent.economyActivities)}
                  />
                </>
              ) : (
                <AutoCompleteField
                  name="agentId"
                  options={agentOptions}
                  query={queryParam}
                  setQuery={setQueryParam}
                  placeholder="Użytkownik"
                />
              )}
            </div>
            <InputField name="title" label="Tytuł" isReadOnly={!!messageToRespond} />
            <TextareaField name="content" label="Treść" className="h-48" />
            <div>
              {!!messageToRespond ? (
                <InputField
                  name="reason"
                  label="Powód wiadomości"
                  isReadOnly={!!messageToRespond}
                />
              ) : (
                <SelectField
                  options={
                    activeDictionaries
                      ? activeDictionaries?.[DICTIONARY_TYPES.COMMUNICATION_REASON]
                      : []
                  }
                  name="reason"
                  label="Powód wiadomości"
                />
              )}
            </div>
            <div>
              <FieldArray name="attachments">
                {(helpers) => (
                  <>
                    <div className="flex flex-col my-8">
                      <p>Załączniki</p>
                      <Button onPress={() => helpers.push(0)}>Dodaj załącznik</Button>
                    </div>
                    <div className="flex flex-col">
                      {values?.attachments?.map((attachment, index) => (
                        <div
                          className="flex flex-col my-2"
                          key={attachment?.uploadedFileId || index}>
                          <div className="flex">
                            <Field name={`attachments[${index}].file`}>
                              {({ form }: any) =>
                                values?.attachments?.[index]?.file instanceof File ? (
                                  <div className="w-full flex items-center">
                                    <i className="bi bi-paperclip cursor-pointer mr-2" />
                                    <p>{values?.attachments?.[index]?.file?.name}</p>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    className="w-full"
                                    name={`attachments[${index}].file`}
                                    onChange={(event) =>
                                      onFileInputChange(form, event, index, setFieldValue)
                                    }
                                  />
                                )
                              }
                            </Field>
                            <Button onPress={() => helpers.remove(index)}>
                              <i className="bi bi-trash cursor-pointer" />
                            </Button>
                          </div>
                          {touched.attachments && errors?.attachments?.[index] && (
                            <p className="text-xs font-normal text-red-700">
                              {errors?.attachments?.[index]}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
            <div className="my-8">
              <ModalActions
                onCancel={() => closeModal()}
                isLoading={isUploadLoading || isSubmitLoading}
              />
              {(isUploadError || isSubmitError) && (
                <ErrorMessages error={fileUploadError ?? submitError} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SendMessageModal;
