import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { InsuranceCompany } from 'types/agent';

const formatDate = ({ value = '' }: Cell<InsuranceCompany>) => (value ? value.split(' ')[0] : '');

export const columns: Column<InsuranceCompany>[] = [
  {
    Header: 'Data od',
    accessor: 'validFrom',
    Cell: formatDate,
  },
  {
    Header: 'Data do',
    accessor: 'validTo',
    Cell: formatDate,
  },
  {
    Header: 'Towarzystwo ubezpieczeniowe',
    id: 'insuranceCompany.value',
    accessor: 'insuranceCompanyDictionaryKey',
  },
  {
    id: 'operations',
    disableSortBy: true,
    Cell: ({ row: { original: insuranceCompany } }: Cell<InsuranceCompany>) => {
      const navigate = useNavigate();

      return (
        <div className="flex space-x-2">
          <Button
            onPress={() => navigate('addUpdate', { state: { insuranceCompany } })}
            size="sm"
            variant="light">
            Edytuj
          </Button>

          <Button
            onPress={() => navigate('delete', { state: { insuranceCompany } })}
            size="sm"
            variant="light">
            Usuń
          </Button>
        </div>
      );
    },
  },
];
