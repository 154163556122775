import qs from 'qs';

import {
  CommunicationMessage,
  NewMessagePayload,
  RespondToMessagePayload,
} from 'types/communication';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getMessages = async (
  agentId?: number,
  page?: number,
  size?: number,
  sort?: string[]
): Promise<Page<CommunicationMessage>> => {
  const url = `/communication${agentId ? `?agentId=${agentId}` : ''}`;
  const { data } = await recordmodApi.get<Page<CommunicationMessage>>(url, {
    params: {
      page,
      size,
      sort,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

export const getCommunicationThread = async (threadId: number) => {
  const { data } = await recordmodApi.get(`/communication/${threadId}`);

  return data;
};

export const postNewMessage = async (payload: NewMessagePayload) => {
  return recordmodApi.post('/communication', payload);
};

export const respondToMessage = async (messageId: number, payload: RespondToMessagePayload) => {
  return recordmodApi.post(`/communication/${messageId}`, payload);
};
