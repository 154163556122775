import qs from 'qs';

import {
  ActivityRangePayload,
  ActivityRangeRecord,
  AddAgentPayload,
  AddAgentPayoutDocumentPayload,
  AddAgentPayoutPayload,
  AgentBillingDetailsPayload,
  AgentDetails,
  AgentDetailsBlockade,
  AgentList,
  AgentParamPayload,
  AgentPaymentHistoryRecord,
  AgentsBusinessIdentifiersPayload,
  ChangePositionPayload,
  CooperationEconomyActivity,
  CooperationNaturalPerson,
  EconomyActivityParams,
  InsuranceCompany,
  InsuranceCompanyPayload,
  ModifyAgentsAddressPayload,
  ModifyAgentsContactPayload,
  Positions,
} from 'types/agent';
import { Bonus, BonusPayload, Payments } from 'types/bonus';
import { Page } from 'types/request';
import { recordmodApi } from 'utils/axios';

export const getBonuses = async (agentId: number, page: number, size: number, sort?: string) => {
  const { data } = await recordmodApi.get<Page<Bonus>>(`/agents/${agentId}/bonuses`, {
    params: { page, size, sort },
  });

  return data;
};

export const saveBonus = (agentId: number, payload: BonusPayload) => {
  return recordmodApi.post(`/agents/${agentId}/bonuses`, payload);
};

export const updateBonusSchedule = (agentId: number, bonusId: number, payload: Payments[]) => {
  return recordmodApi.put(`/agents/${agentId}/bonuses/${bonusId}`, payload);
};

export const importBonusFile = (file: any, dictionaryBonus: string) => {
  return recordmodApi.post(`/files?fileType=${dictionaryBonus}`, file);
};

export const getBonus = async (agentId: number, bonusId: number) => {
  const { data } = await recordmodApi.get<
    Bonus & {
      payments: Payments[];
    }
  >(`/agents/${agentId}/bonuses/${bonusId}`);

  return data;
};

export const deleteAgentBonus = (agentId: number, agentBonusId: number) => {
  return recordmodApi.delete(`/agents/${agentId}/bonuses/${agentBonusId}?cancelSettled=false`);
};

export const getAgents = async (
  queryParam?: string,
  page?: number,
  size?: number,
  sort?: string[]
) => {
  const { data } = await recordmodApi.get<Page<AgentList>>('/agents', {
    params: { page, size, queryParam, sort },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

export const getAgent = async (agentId: number) => {
  const { data } = await recordmodApi.get<AgentDetails>(`/agents/${agentId}`);

  return data;
};

export const getPeriodParams = async (
  agentId: number,
  page: number,
  size: number,
  cooperationId?: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<EconomyActivityParams>>(
    `/agents/${agentId}/cooperation/${cooperationId}/param`,
    { params: { page, size, sort } }
  );

  return data;
};

export const changeCooperation = (
  payload: CooperationEconomyActivity | CooperationNaturalPerson,
  agentId: number
) => {
  return recordmodApi.put(`/agents/${agentId}/cooperation`, payload);
};

export const getAllInsuranceCompanies = async (
  agentId: number,
  page: number,
  size: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<InsuranceCompany>>(
    `/agents/${agentId}/insurance-company`,
    { params: { page, size, sort } }
  );

  return data;
};

export const addInsuranceCompany = async (agentId: number, payload: InsuranceCompanyPayload) => {
  return recordmodApi.post(`/agents/${agentId}/insurance-company`, payload);
};

export const editInsuranceCompany = async (
  agentId: number,
  recordId: number,
  payload: Omit<InsuranceCompanyPayload, 'insuranceCompanyDictionaryKey'>
) => {
  return recordmodApi.put(`/agents/${agentId}/insurance-company/${recordId}`, payload);
};

export const deleteInsuranceCompany = async (agentId: number, recordId: number) => {
  return recordmodApi.delete(`/agents/${agentId}/insurance-company/${recordId}`);
};

export const getActivityRange = async (
  agentId: number,
  page: number,
  size: number,
  sort?: string
) => {
  const { data } = await recordmodApi.get<Page<ActivityRangeRecord>>(
    `/agents/${agentId}/activity-range`,
    { params: { page, size, sort } }
  );

  return data;
};

export const addActivityRange = async (agentId: number, payload: ActivityRangePayload) => {
  return recordmodApi.post(`/agents/${agentId}/activity-range`, payload);
};

export const editActivityRange = async (
  agentId: number,
  recordId: number,
  payload: Omit<ActivityRangePayload, 'activityRangeDictionaryKey'>
) => {
  return recordmodApi.put(`/agents/${agentId}/activity-range/${recordId}`, payload);
};

export const deleteActivityRange = async (agentId: number, recordId: number) => {
  return recordmodApi.delete(`/agents/${agentId}/activity-range/${recordId}`);
};

export const deleteAgent = async (agentId: number) => {
  return recordmodApi.delete(`/agents/${agentId}`);
};

export const endCooperation = async (agentId: number, endDate: string) => {
  return recordmodApi.delete(`/agents/${agentId}/cooperation`, { params: { endDate } });
};

export const markAgentAsInDebtRecovery = async (agentId: number) => {
  return recordmodApi.put(`/agents/${agentId}/in-debt`);
};

export const modifyAgentsContactInfo = async (
  agentId: number,
  cooperationId: number,
  payload: ModifyAgentsContactPayload
) => {
  return recordmodApi.put(`/agents/${agentId}/cooperation/${cooperationId}/contact`, payload);
};

export const modifyAgentsAddress = async (
  agentId: number,
  cooperationId: number,
  payload: ModifyAgentsAddressPayload
) => {
  return recordmodApi.put(`/agents/${agentId}/cooperation/${cooperationId}/address`, payload);
};

export const modifyAgentsBusinessIdentifiers = async (
  agentId: number,
  cooperationId: number,
  payload: AgentsBusinessIdentifiersPayload
) => {
  return recordmodApi.put(`/agents/${agentId}/cooperation/${cooperationId}`, payload);
};

export const modifyAgentsBillingInfo = async (
  agentId: number,
  cooperationId: number,
  payload: AgentBillingDetailsPayload
) => {
  return recordmodApi.put(`/agents/${agentId}/cooperation/${cooperationId}/billing`, payload);
};

export const changeAgentsParam = async (
  agentId: number,
  payload: Omit<AgentParamPayload, 'type'>,
  type?: string,
  cooperationId?: number
) => {
  return recordmodApi.put(`/agents/${agentId}/cooperation/${cooperationId}/param`, payload, {
    params: { cooperationId, type },
  });
};

export type DeletePositionPayload = {
  propagateToAllSchemasInGroup: boolean;
  commissionSchemaIdList: string[];
};

export const deletePosition = async (
  agentId: number,
  agentPositionId: number,
  date: string,
  payload: DeletePositionPayload,
  validate: boolean = false
) => {
  return recordmodApi.delete(`/agents/${agentId}/positions/${agentPositionId}`, {
    data: payload,
    params: {
      validate: validate ? 'true' : undefined,
      time: date,
    },
  });
};

export type EditPositionPayload = {
  validFrom: string;
  validTo: string | null;
  propagateToAllSchemasInGroup: boolean;
  commissionSchemaIdList: string[];
};

export const editPosition = (
  agentId: number,
  agentPositionId: number,
  payload: EditPositionPayload,
  validate: boolean = false
) =>
  recordmodApi.put(`/agents/${agentId}/positions/${agentPositionId}`, payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });

export const changePosition = async (
  agentId: number,
  payload: ChangePositionPayload,
  validate: boolean = false
) => {
  return recordmodApi.post(`/agents/${agentId}/positions`, payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });
};

export type PromoteAgentPayload = {
  agentsIds: number[];
  commissionSchemaId: number | string;
  mainPosition: true;
  positionDictionaryKey: string;
  superiorPositionKey: string;
  validFrom: string;
};

export const promoteAgent = async (payload: PromoteAgentPayload, validate: boolean = false) =>
  recordmodApi.post('/agents/promote-agent', payload, {
    params: {
      validate: validate ? 'true' : undefined,
    },
  });

export const getAgentPositions = async (
  agentId: number,
  validAt: string,
  commissionSchema: number
): Promise<Positions[]> => {
  const { data } = await recordmodApi.get(
    `/agents/${agentId}/positions?validAt=${validAt}&commissionSchema=${commissionSchema}`
  );

  return data.content;
};

export const addAgent = async (payload: AddAgentPayload) => {
  return recordmodApi.post('/agents', payload);
};

export const getBlockades = async (sort?: string) => {
  const { data } = await recordmodApi.get('/agents/policies/blockades', {
    params: {
      sort,
    },
  });

  return data;
};

export const createBlockade = async (signature: string) => {
  return recordmodApi.post(`/agents/policies/blockades/${signature}`);
};

export const deleteBlockade = async (signature: string) => {
  return recordmodApi.delete(`/agents/policies/blockades/${signature}`);
};

export const getAgentBlockades = async (agentId: number) => {
  const { data } = await recordmodApi.get<AgentDetailsBlockade[]>(`/agents/${agentId}/blockades`);

  return data;
};

export const updateAgentBlockade = async (agentId: number, payload: AgentDetailsBlockade) => {
  let url = `/agents/${agentId}/blockades/${payload.type}?state=${Number(payload.state)}`;

  if (payload.accountingDocumentNumber) {
    url += `&accountingDocumentNumber=${payload.accountingDocumentNumber}`;
  }

  return recordmodApi.put(url);
};

export const getAgentPayoutHistory = async (
  agentId: number,
  page: number,
  size: number,
  sort: string
) => {
  const { data } = await recordmodApi.get<Page<AgentPaymentHistoryRecord>>(
    `/payouts/history/agents/${agentId}`,
    { params: { page, size, sort } }
  );

  return data;
};

export const addAgentPayout = async (agentId: number, payload: AddAgentPayoutPayload) => {
  return recordmodApi.post(`/agents/${agentId}/payouts`, payload);
};

export const addAgentPayoutDocument = async (
  agentId: number,
  payload: AddAgentPayoutDocumentPayload
) => {
  return recordmodApi.post(
    `/agents/${agentId}/documents?documentType=${payload.documentType}`,
    payload
  );
};

export const generatePayment = async (agentId: number, payload: { transferTitle: string }) => {
  return recordmodApi.post(`/agents/${agentId}/balance/settle`, payload);
};
