import { Button, Icon } from '@profitowi/component-library';
import useSettlement from 'pages/Settlements/useSettlement';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { FileEvent } from 'types/settlement';

import StatusSelect from '../StatusSelect';

export const columns: Column<FileEvent>[] = [
  {
    Header: 'Data importu',
    accessor: 'importDate',
    id: 'importDate',
  },
  {
    Header: 'Id zdarzenia',
    accessor: 'eventId',
    id: 'commissionFileItemId',
  },
  {
    Header: 'Kod agenta sprzedającego',
    accessor: 'payoutAgentCode',
    id: 'agentCode',
  },
  {
    Header: 'Imię i nazwisko agenta',
    accessor: 'payoutDisplayAgentName',
    id: 'agentDisplayName',
  },
  {
    Header: 'Stanowisko',
    id: 'agentPositionDictionaryValue',
    accessor: 'employeePositionDictionaryKey',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('POSITION', value) : '';
    },
  },
  {
    Header: 'Sygnatura polisy',
    accessor: 'policySignature',
  },
  {
    Header: 'Sygnatura produktu',
    accessor: 'productSignature',
  },
  {
    Header: 'Rok życia',
    accessor: 'policyYear',
  },
  {
    Header: 'Składka',
    accessor: (row) => <div className="text-right">{row?.contributionNo}</div>,
  },
  {
    Header: 'Czas trwania',
    accessor: 'duration',
    id: 'policyDuration',
  },
  {
    Header: 'Schemat prowizyjny do wyliczeń',
    accessor: 'commissionSchemaName',
    id: 'commissionSchema.schemaName',
  },
  {
    Header: 'Pierwotne rozliczenie',
    accessor: 'primarySettlement',
    id: 'previousSettlementNumber',
  },
  {
    Header: 'Stawka VAT',
    accessor: 'vat',
    id: 'vatRate',
  },
  {
    Header: 'Podstawa brutto',
    accessor: (row) => <div className="text-right">{row?.baseValueGross}</div>,
    id: 'baseValue',
  },
  {
    Header: 'Składka brutto',
    accessor: (row) => <div className="text-right">{row?.contributionGross}</div>,
    id: 'contribution',
  },
  {
    Header: 'Waluta',
    accessor: 'currency',
    disableSortBy: true,
  },
  {
    Header: 'Pozycje naliczeniowe',
    Cell: ({
      row: {
        original: { billingItems = [] },
      },
    }: Cell<FileEvent>) => {
      const navigate = useNavigate();

      return (
        <Button
          className="space-x-2"
          onPress={() =>
            navigate('details', {
              state: {
                billingItems,
                title: 'Pozycje naliczeniowe',
              },
            })
          }
          size="sm"
          variant="light">
          <Icon name="search" />
          <span>{billingItems.length}</span>
        </Button>
      );
    },
  },
  {
    Header: 'Status',
    Cell: ({ row: { original } }: Cell<FileEvent>) => {
      const { isEditable } = useSettlement();

      const { billingItems = [] } = original;
      const items = billingItems.map(({ id }) => id);

      return billingItems.length && isEditable ? (
        <StatusSelect
          items={items}
          // FIXME: take first billing item status
          status={original.status || 'ACCEPTED'}
        />
      ) : null;
    },
  },
];
