import { Box, Loader, Text } from '@profitowi/component-library';
import { ReactNode } from 'react';

type Props = {
  value?: number | string;
  name: string;
  color?: string;
  icon?: string;
  isFetched: boolean;
  className?: string;
  children?: ReactNode;
};

const GroupBox = ({ value, name, icon, color, isFetched, children, className }: Props) => {
  return (
    <Box className={`min-h-20 flex overflow-hidden ${className}`}>
      {!isFetched && <Loader className="h-6 w-6" />}

      {isFetched && (
        <div className="flex justify-center items-center h-full space-x-3">
          {icon && color && (
            <div
              className={`h-12 w-12 flex flex-wrap rounded-lg content-center justify-center ${color}`}>
              <i className={`bi ${icon} mx-3 text-white`} />
            </div>
          )}

          <div className="flex flex-col">
            <Text className="text-secondary">{name}</Text>
            <Text weight="semibold">{value}</Text>
            {children}
          </div>
        </div>
      )}
    </Box>
  );
};

export default GroupBox;
