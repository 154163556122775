import { Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import DelegateViceModalForm from 'pages/Records/components/SalesStructure/components/DelegateViceModalForm/DelegateViceModalForm';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  ActionLabelOptions,
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { PropagationOptions, delegateAgentsBetweenVices } from 'services/structure';
import { AgentStructureRecord, DelegateAgentsBetweenVicePayload } from 'types/structure';

type DelegateVicePayload = {
  payload: DelegateAgentsBetweenVicePayload;
};

const validationSchema = Yup.object({
  validFrom: Yup.date().required(requiredFieldMessage),
  fromVice: Yup.number().required(requiredFieldMessage),
  toVice: Yup.number().required(requiredFieldMessage),
  commissionSchemas: Yup.array()
    .of(Yup.number().required(requiredFieldMessage))
    .required(requiredFieldMessage),
  delegatedAgents: Yup.array()
    .of(Yup.number().required(requiredFieldMessage))
    .required(requiredFieldMessage),
  propagateToSelectedSchemas: Yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: Yup.array().of(Yup.string()).optional(),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  validFrom: '' as unknown as Date,
  fromVice: 0,
  toVice: 0,
  commissionSchemas: [],
  delegatedAgents: [],
  propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
  selectedCommissionSchemaIds: [],
};

const DelegateViceModal = () => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<DelegateVicePayload>();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state === null || !state || !state.commissionSchemaId || !state.fromVice) {
      navigate('..');
    }
  }, [state, navigate]);

  const {
    commissionSchemaId,
    fromVice,
  }: { commissionSchemaId: number; fromVice: AgentStructureRecord } = state;

  const {
    isLoading: validationIsLoading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, DelegateVicePayload>(
    ({ payload }) => delegateAgentsBetweenVices(payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const { isLoading, isError, error, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    DelegateVicePayload
  >(({ payload }) => delegateAgentsBetweenVices(payload), {
    onSuccess: () => {
      navigate('..');
    },
  });

  const initValues = useMemo(() => {
    const values = { ...initialValues };

    if (commissionSchemaId) {
      values.commissionSchemas = [commissionSchemaId];
    }

    if (fromVice) {
      values.fromVice = fromVice.id;
    }

    return values;
  }, [commissionSchemaId, fromVice]);

  const handleSubmit = (values: FormValues) => {
    const propagationOptions = getSchemaPropagationPayload({
      commissionSchemaId: commissionSchemaId,
      ...values,
    });

    const payload = {
      payload: {
        ...values,
        validFrom: format(startOfDay(values.validFrom), 'dd-MM-yyyy HH:mm:ss'),
        commissionSchemas: values.commissionSchemas as [number],
        ...propagationOptions,
      },
    };
    setPayload(payload);

    if (!validationErrors?.length && !!validationWarnings?.length && payload) {
      mutate(payload);
    } else {
      validateForm(payload);
    }
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  return (
    <Modal visible title="Deleguj wicedyrektora">
      <Formik
        validationSchema={validationSchema}
        initialValues={initValues}
        onSubmit={handleSubmit}>
        <Form className="space-y-4" onBlur={onChange}>
          <DelegateViceModalForm fromVice={fromVice} commissionSchemaId={commissionSchemaId} />

          <SchemaPropagationFields
            commissionSchemaId={commissionSchemaId}
            isLoading={isLoading || validationIsLoading}
            actionLabel={ActionLabelOptions.DELEGATE}
          />

          <SchemaPropagationAlerts
            validationWarnings={validationWarnings}
            validationErrors={validationErrors}
          />

          {(isError || validationIsError) && <ErrorMessages error={error || validationError} />}

          <ModalActions
            onCancel={() => navigate('..')}
            isLoading={isLoading || validationIsLoading}
            disableSubmit={!!validationErrors?.length}
            confirmLabel={
              !validationErrors?.length && !!validationWarnings?.length
                ? 'Zapisz pomimo ostrzeżeń'
                : 'Zapisz'
            }
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default DelegateViceModal;
