import { Box, Button, Text } from '@profitowi/component-library';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { EconomyActivities } from 'types/agent';

import AgentContactForm from './ContactForm/ContactForm';

type Props = {
  economyActivities?: EconomyActivities;
  agentCode?: string;
  rauNumber?: string;
  deleted?: boolean;
  refetchAgent: () => void;
};

const Contact = ({ economyActivities, rauNumber, deleted, refetchAgent }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Box className="flex w-full">
        <div className="grid grid-cols-4 items-start xl:items-center w-full">
          <div className="flex flex-wrap col-span-3">
            <div className="flex flex-col pr-14">
              <Text className="text-secondary">Telefon</Text>
              <Text weight="semibold">{economyActivities?.phoneNumber ?? 'Brak'}</Text>
            </div>
            <div className="flex flex-col pr-14">
              <Text className="text-secondary">Email służbowy</Text>
              <Text weight="semibold">{economyActivities?.businessEmail ?? 'Brak'}</Text>
            </div>
            <div className="flex flex-col">
              <Text className="text-secondary">Email prywatny</Text>
              <Text weight="semibold">{economyActivities?.privateEmail ?? 'Brak'}</Text>
            </div>
          </div>

          <div className="col-span-1 sm:space-y-8 md:space-y-8 sm:flex-wrap md:col-span-2 lg:col-span-2 xl:col-span-1 w-full justify-end items-end flex space-y-4 space-x-4">
            <Button variant="outline-primary" onPress={() => navigate('update')}>
              Edycja
            </Button>
          </div>
        </div>
      </Box>

      <Routes>
        <Route
          path="update"
          element={
            <AgentContactForm economyActivities={economyActivities} refetchAgent={refetchAgent} />
          }
        />
      </Routes>
    </>
  );
};

export default Contact;
