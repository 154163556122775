import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { updateLastLoginDate } from 'services/user';

const keycloakConfig: KeycloakConfig = {
  url: getAuthUrl(),
  realm: process.env.REACT_APP_REALM_NAME || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
};

const _kc = Keycloak(keycloakConfig);

function initKeycloak(onAuthenticatedCallback: any): void {
  _kc
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      enableLogging: true,
    })
    .then((authenticated: boolean) => {
      if (authenticated) {
        onAuthenticatedCallback();
      }
    })
    .then(updateLastLoginDate)
    .catch(console.error);
}

function doLogin(): void {
  _kc.login();
}

function doLogout(): void {
  _kc.logout({ redirectUri: window.location.origin });
}

function getToken(): string | undefined {
  return _kc.token;
}

function isLoggedIn(): boolean {
  return !!_kc.token;
}

function getUsername(): string {
  return _kc.tokenParsed?.preferred_username;
}

function refreshToken() {
  return _kc.updateToken(300).catch(doLogin);
}

function getAuthUrl() {
  const SUFFIX = 'keycloak';

  return process.env.NODE_ENV === 'development'
    ? `https://proseccotst.profitowi.pl/${SUFFIX}/`
    : `${window.location.origin}/${SUFFIX}/`;
}

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  refreshToken,
  getUsername,
};

export default AuthService;
