import { isValidAgentCode } from 'validators/agent-code.validator';
import * as Yup from 'yup';
import { TestContext } from 'yup';

import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { EconomyActivities } from 'types/agent';

export const isCompanyOrSoleProprietorShip = (data: any): boolean => {
  return [COOPERATION_FORMS.COMPANY, COOPERATION_FORMS.SOLE_PROPRIETORSHIP].includes(data);
};

export const isIndividual = (data: any): boolean => {
  return data === COOPERATION_FORMS.INDIVIDUAL;
};

export const validateAddressPart = function (this: TestContext, value: any) {
  type FromArray = TestContext & {
    from: [
      { value: any; schema: Yup.AnySchema },
      { value: EconomyActivities; schema: Yup.AnySchema },
      { value: FormValues; schema: Yup.AnySchema }
    ];
  };
  const type = (this as FromArray).from[1].value?.type as COOPERATION_FORMS;

  if ((this as FromArray).from[0].value.sameAsLivingAddress) {
    return true;
  }

  // 0 - home, 1 - billing, 2 - correspondence (check initial values below)
  const index = this.path.match(/[0-9]/g)?.[1] || '';

  if (
    [COOPERATION_FORMS.COMPANY, COOPERATION_FORMS.SOLE_PROPRIETORSHIP].includes(type) &&
    index === '1'
  ) {
    return value ? true : this.createError({ message: this.schema.tests[0].OPTIONS.message });
  } else {
    return true;
  }
};

const addressValidationSchema = Yup.object({
  street: Yup.string().test('street', requiredFieldMessage, validateAddressPart),
  houseNumber: Yup.string().test('houseNumber', requiredFieldMessage, validateAddressPart),
  apartmentNumber: Yup.string(),
  postalCode: Yup.string().test('postalCode', requiredFieldMessage, validateAddressPart),
  city: Yup.string().test('city', requiredFieldMessage, validateAddressPart),
  sameAsLivingAddress: Yup.boolean(),
  type: Yup.string(),
});

export const validationSchema = Yup.object({
  agentCode: Yup.string()
    .required(requiredFieldMessage)
    .test('agentCode', 'Nieprawidłowy format. Prawidłowy to PRXXXX, gdzie X to cyfra.', (value) =>
      isValidAgentCode(value)
    ),
  rauNumber: Yup.string(),
  cooperationStatus: Yup.string().required(),
  officeDictionaryKey: Yup.string().when('economyActivities[0].type', {
    is: isCompanyOrSoleProprietorShip,
    then: Yup.string().required(requiredFieldMessage),
    otherwise: Yup.string(),
  }),
  economyActivities: Yup.array().of(
    Yup.object({
      type: Yup.string().required(requiredFieldMessage),
      validFrom: Yup.date().required(requiredFieldMessage),
      companyName: Yup.string().when('type', {
        is: isCompanyOrSoleProprietorShip,
        then: Yup.string().required(requiredFieldMessage),
        otherwise: Yup.string(),
      }),
      nip: Yup.string().when('type', {
        is: isCompanyOrSoleProprietorShip,
        then: Yup.string().required(requiredFieldMessage).isValidNip(),
        otherwise: Yup.string(),
      }),
      regon: Yup.string().when('type', {
        is: isCompanyOrSoleProprietorShip,
        then: Yup.string().required(requiredFieldMessage).isValidRegon(),
        otherwise: Yup.string(),
      }),
      krs: Yup.string().when('type', {
        is: (type: any) => type === COOPERATION_FORMS.COMPANY,
        then: Yup.string()
          .matches(/[0-9]{10}/, 'Nieprawidłowy numer KRS.')
          .required(requiredFieldMessage),
        otherwise: Yup.string(),
      }),
      name: Yup.string().when('type', {
        is: isIndividual,
        then: Yup.string().required(requiredFieldMessage),
        otherwise: Yup.string(),
      }),
      middleName: Yup.string(),
      lastName: Yup.string().when('type', {
        is: isIndividual,
        then: Yup.string().required(requiredFieldMessage),
        otherwise: Yup.string(),
      }),
      pesel: Yup.string().isValidPesel(),
      birthDate: Yup.date(),
      birthPlace: Yup.string(),
      addresses: Yup.array().of(addressValidationSchema),
      economyActivityParams: Yup.array().of(
        Yup.object({
          type: Yup.string().required(requiredFieldMessage),
          value: Yup.string(),
        })
      ),
      bankAccount: Yup.string().when('type', {
        is: isCompanyOrSoleProprietorShip,
        then: Yup.string()
          .matches(/^[0-9]{26}$/, 'Niepoprawny numer konta bankowego.')
          .required(requiredFieldMessage),
        otherwise: Yup.string(),
      }),
      education: Yup.string(),
      businessEmail: Yup.string()
        .email('Nieprawidłowy adres e-mail.')
        .required(requiredFieldMessage),
      privateEmail: Yup.string()
        .email('Nieprawidłowy adres e-mail.')
        .required(requiredFieldMessage),
      phoneNumber: Yup.string()
        .matches(/^[0-9]{9,11}$/, 'Numer telefonu może zawierać tylko cyfry.')
        .required(requiredFieldMessage),
      primaryBillingEmailAddress: Yup.string().email(),
      secondBillingEmailAddress: Yup.string().email(),
      sendInvoiceByEmail: Yup.boolean(),
    })
  ),
  referees: Yup.array().of(
    Yup.object({
      referee: Yup.object({
        agentCode: Yup.string().test(
          'referee',
          'Nieprawidłowy format. Prawidłowy to PRXXXX, gdzie X to cyfra.',
          (value) => isValidAgentCode(value, true)
        ),
      }),
      validFrom: Yup.date().when(['referees', 'referee'], {
        is: (...args: any[]) => !!args?.[1]?.agentCode,
        then: Yup.date().required(requiredFieldMessage),
        otherwise: Yup.date(),
      }),
      validTo: Yup.date().when(['referees', 'referee'], {
        is: (...args: any[]) => !!args?.[1]?.agentCode,
        then: Yup.date().required(requiredFieldMessage),
        otherwise: Yup.date(),
      }),
    })
  ),
  insuranceCompanies: Yup.array().of(
    Yup.object({
      insuranceCompanyDictionaryKey: Yup.string().required(requiredFieldMessage),
      validFrom: Yup.date().required(requiredFieldMessage),
      validTo: Yup.date(),
    })
  ),
  activityRanges: Yup.array().of(
    Yup.object({
      activityRangeDictionaryKey: Yup.string(),
      validFrom: Yup.date().required(requiredFieldMessage),
      validTo: Yup.date(),
    })
  ),
});

export type FormValues = Yup.InferType<typeof validationSchema>;
