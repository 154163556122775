export const cleanObject = function (object: any) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      cleanObject(v);
    }

    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === '' ||
      v === null ||
      v === undefined ||
      (v as Array<any>).length === 0
    ) {
      if (Array.isArray(object)) {
        object.splice(k as unknown as number, 1);
      } else if (!(v instanceof Date) && !Array.isArray(v)) {
        delete object[k];
      }
    }
  });

  return object;
};
