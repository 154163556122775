import { DatePickerField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { addActivityRange, editActivityRange } from 'services/agent';
import { ActivityRangePayload, ActivityRangeRecord } from 'types/agent';

import useAgentId from '../../../useAgentId';

const validationSchema = yup.object({
  activityRangeDictionaryKey: yup.string().defined(requiredFieldMessage),
  validFrom: yup.date().default(undefined).defined().typeError('Nieprawidłowa data'),
  validTo: yup.date().default(undefined).defined().typeError('Nieprawidłowa data'),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetch: () => void;
};

const AddUpdateActivityRangeModal = ({ refetch }: Props) => {
  const agentId = useAgentId();
  const navigate = useNavigate();
  const { activeDictionaries } = useDictionaryContext();
  const { state } = useLocation();
  const activityRange: ActivityRangeRecord = state?.activityRange;
  const title = activityRange
    ? 'Edytuj uprawnienie kontrahenta'
    : 'Dodaj uprawnienie do kontrahenta';

  const initialValues = useMemo(() => {
    if (activityRange) {
      const { activityRangeDictionaryKey, validFrom, validTo } = activityRange;

      return {
        activityRangeDictionaryKey,
        validFrom: validFrom
          ? parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date())
          : (undefined as unknown as Date),
        validTo: validTo
          ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
          : (undefined as unknown as Date),
      };
    }

    return {
      activityRangeDictionaryKey: '',
      validFrom: undefined as unknown as Date,
      validTo: undefined as unknown as Date,
    };
  }, [activityRange]);

  const onSuccess = () => {
    refetch();
    navigate('..');
  };

  const {
    isLoading: isSaveLoading,
    error: saveError,
    mutate: save,
  } = useMutation<AxiosResponse, AxiosError, ActivityRangePayload>(
    (payload) => addActivityRange(agentId, payload),
    {
      onSuccess,
    }
  );

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate: update,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    Omit<ActivityRangePayload, 'activityRangeDictionaryKey'>
  >((payload) => editActivityRange(agentId, activityRange?.id, payload), {
    onSuccess,
  });

  const onSubmit = ({ activityRangeDictionaryKey, validFrom, validTo }: FormValues) => {
    const payload = {
      activityRangeDictionaryKey,
      validFrom: format(new Date(validFrom), 'dd-MM-yyyy 00:00:00'),
      validTo: format(new Date(validTo), 'dd-MM-yyyy 00:00:00'),
    };

    if (activityRange) {
      update(payload);
    } else {
      save(payload);
    }
  };

  return (
    <Modal title={title} visible>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values: { validTo, validFrom } }) => (
          <Form className="space-y-4">
            {!activityRange?.id && (
              <SelectField
                label="Uprawnienie"
                name="activityRangeDictionaryKey"
                options={activeDictionaries?.[DICTIONARY_TYPES.ACTIVITY_RANGE] || []}
              />
            )}
            <DatePickerField
              preventOpenOnFocus
              label="Data od"
              name="validFrom"
              maxDate={validTo}
            />
            <DatePickerField label="Data do" name="validTo" minDate={validFrom} />
            <ModalActions
              isLoading={isSaveLoading || isUpdateLoading}
              onCancel={() => navigate('..')}
            />
            {saveError && <ErrorMessages error={saveError} />}
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUpdateActivityRangeModal;
