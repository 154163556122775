import { Box, Header, Loader, Text } from '@profitowi/component-library';

type Props = {
  balance?: number;
  isSuccess: boolean;
  isLoading: boolean;
};

const BalanceBox = ({ balance, isSuccess, isLoading }: Props) => {
  return (
    <Box className="space-y-8">
      <Header as="h3" size="lg" weight="semibold">
        Saldo
      </Header>

      <div className="flex flex-col space-y-2">
        <Text className="text-secondary">Na koniec okresu</Text>

        <Header as="h2" size="xl" weight="semibold">
          {isSuccess && `${balance ?? 0} PLN `}
          {isLoading && <Loader className="h-6 w-6 mt-2" />}
        </Header>
      </div>
    </Box>
  );
};

export default BalanceBox;
