import { Table } from '@profitowi/component-library';

import { AgentCommissionConditionRate } from 'types/commission';

import { columns } from './columns';

type Props = {
  data: Array<AgentCommissionConditionRate>;
};

const CommissionTable = ({ data }: Props) => {
  return <Table columns={columns} data={data || []} />;
};

export default CommissionTable;
