import {
  Box,
  Button,
  Header,
  InputField,
  Loader,
  Text,
  TextareaField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { SETTLEMENT } from 'constants/queries/settlement';
import { getEmailContent, putEmailContent } from 'services/settlement';
import { MessageTemplateData } from 'types/settlement';

import useSettlement from '../../../useSettlement';

const validationSchema = yup.object({
  ACCOUNTING_NOTE_SENT_EMAIL: yup
    .object({
      content: yup.string().default(''),
      title: yup.string().default(''),
    })
    .default({}),
  INVOICE_SENT_EMAIL: yup
    .object({
      content: yup.string().default(''),
      title: yup.string().default(''),
    })
    .default({}),
});
type FormValues = yup.InferType<typeof validationSchema>;

const emails = [
  {
    label:
      'Komunikat e-mail po wykonaniu Naliczeń z załącznikiem Not Księgowych / Not Księgowych Korygujących',
    templateType: 'ACCOUNTING_NOTE_SENT_EMAIL',
  },
  {
    label: 'Komunikat e-mail po wykonaniu Naliczeń z załącznikiem Faktur/Faktur Korygujących',
    templateType: 'INVOICE_SENT_EMAIL',
  },
];

const FinalAcceptationStage = () => {
  const { settlementId = NaN } = useSettlement();

  const {
    data: messageTemplates,
    error,
    isError,
    isLoading,
    isSuccess,
  } = useQuery<MessageTemplateData[], AxiosError>(
    [SETTLEMENT.SETTLEMENT_EMAIL_CONTENT, settlementId],
    () => getEmailContent(settlementId)
  );

  const initialValues = useMemo(() => {
    const values = messageTemplates
      ? Object.fromEntries(
          messageTemplates
            ?.filter(({ templateType }) => templateType !== 'MONEY_TRANSFER_SENT_EMAIL')
            ?.map(({ templateType, title, content }) => [templateType, { content, title }])
        )
      : {};

    return validationSchema.cast(values);
  }, [messageTemplates]);

  const {
    error: saveError,
    isError: isSaveError,
    isLoading: isSaveLoading,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, MessageTemplateData[]>((payload) =>
    putEmailContent(settlementId, payload)
  );

  const onSubmit = (values: FormValues) => {
    const payload = Object.entries(values).map(([templateType, values]) => ({
      templateType,
      ...values,
    }));

    mutate(payload as MessageTemplateData[]);
  };

  return (
    <Box className="space-y-6">
      {isError && <ErrorMessages error={error} />}
      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && (
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}>
          <Form className="space-y-6">
            {emails.map(({ label, templateType }) => (
              <div className="space-y-4" key={templateType}>
                <Header as="h3" size="lg">
                  {label}
                </Header>
                <InputField label="Tytuł" name={`${templateType}.title`} />
                <TextareaField label="Treść" name={`${templateType}.content`} />
              </div>
            ))}

            <div className="flex justify-end">
              <Button isDisabled={isSaveLoading} type="submit" variant="primary">
                Zapisz
              </Button>
            </div>

            {isSaveError && <ErrorMessages error={saveError} />}
          </Form>
        </Formik>
      )}

      <Text>
        Pliki do ING i do księgowości będzie można podejrzeć w szczegółach rozliczenia. Jeśli w tych
        plikach będą błędy będzie można je wygenerować ponownie, jednak kwoty prowizji i numery
        dokumentów już nie ulegną zmianie.
      </Text>

      <Text>
        Po zakończeniu rozliczenia rozpocznie się wysyłka e-maili oraz dokumentów do kontrahentów.
      </Text>
    </Box>
  );
};
export default FinalAcceptationStage;
