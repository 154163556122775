import { DatePickerField, Input, Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  ActionLabelOptions,
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { getAgentName } from 'helpers/agents';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { DeletePositionPayload, deletePosition } from 'services/agent';
import { PropagationOptions } from 'services/structure';
import { Positions } from 'types/agent';
import { AgentRelationStructureRecord } from 'types/structure';

const validationSchema = yup.object({
  validFrom: yup.date().optional(),
  validTo: yup.date().nullable(),
  propagateToSelectedSchemas: yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: yup.array().of(yup.string()).optional(),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetch: () => void;
};

const DeletePositionModal = ({ refetch }: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<DeletePositionPayload>();
  const { translate } = useDictionaryContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('..');
      refetch();
    }
  }, [state, navigate]);

  const { record, position, agentId, positionId } = (state || {}) as {
    agentId: number;
    positionId: number;
    record: AgentRelationStructureRecord;
    position: Positions;
  };
  const { child, commissionSchema } = record || {};
  const { validFrom, validTo } = position;

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    DeletePositionPayload
  >(
    (payload) =>
      deletePosition(agentId, positionId, format(new Date(), 'dd-MM-yyyy 00:00:00'), payload),
    {
      onSuccess: () => {
        navigate('..');
        refetch();
      },
    }
  );

  const {
    isLoading: validationIsloading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, DeletePositionPayload>(
    (payload) =>
      deletePosition(agentId, positionId, format(new Date(), 'dd-MM-yyyy 00:00:00'), payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const initialValues = useMemo(
    () => ({
      validFrom: parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date()),
      validTo: validTo ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date()) : null,
      propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
      selectedCommissionSchemaIds: [],
    }),
    [validFrom, validTo]
  );

  const childName = useMemo(() => getAgentName(child?.economyActivities), [child]);

  const handleSubmit = (values: FormValues) => {
    const payload = getSchemaPropagationPayload({
      commissionSchemaId: commissionSchema,
      ...values,
    });
    setPayload(payload);

    if (!validationErrors?.length && !!validationWarnings?.length && payload) {
      mutate(payload);
    } else {
      validateForm(payload);
    }
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }
  return (
    <Modal title="Usuń stanowisko" visible>
      <div>
        <Text className="flex flex-col my-4 gap-2">
          <p>Czy na pewno chcesz usunąć stanowisko?</p>
          <p>Wszystkie programy prowizyjne zostaną usunięte.</p>
        </Text>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          {({ values: { validFrom, validTo } }) => (
            <Form className="space-y-4" onBlur={onChange}>
              <Input
                isDisabled
                label="Schemat prowizyjny"
                isReadOnly
                value={translate('COMMISSION_SCHEMA_NAME', String(commissionSchema))}
              />
              <Input isDisabled label="Kontrahent" isReadOnly value={childName} />
              <DatePickerField readOnly label="Data od" name="validFrom" maxDate={validTo} />
              <DatePickerField readOnly label="Data do" name="validTo" minDate={validFrom} />

              {(isError || validationIsError) && <ErrorMessages error={error || validationError} />}

              <SchemaPropagationFields
                commissionSchemaId={commissionSchema}
                isLoading={isLoading || validationIsloading}
                actionLabel={ActionLabelOptions.DELETE}
              />

              <SchemaPropagationAlerts
                validationWarnings={validationWarnings}
                validationErrors={validationErrors}
              />
              <ModalActions
                onCancel={() => navigate('..')}
                disableSubmit={!!validationErrors?.length}
                confirmLabel={
                  !validationErrors?.length && !!validationWarnings?.length
                    ? 'Zapisz pomimo ostrzeżeń'
                    : 'Zapisz'
                }
                isLoading={isLoading || validationIsloading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default DeletePositionModal;
