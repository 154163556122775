import { Alert, Box, Button, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import useAgentId from 'pages/Agent/useAgentId';
import EditUserForm from 'pages/Settings/components/Users/EditUserForm/EditUserForm';
import useEditUser from 'pages/Settings/components/common/hooks/useEditUser';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import ConfirmationModal from 'components/ConfimartionModal/ConfirmationModal';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { USER } from 'constants/queries/user';
import { disableUser, enableUser, getUserByAgentId, resetPassword } from 'services/user';
import { EditUserPayload, User } from 'types/user';

const AgentAccount = () => {
  const [changeStatusModalVisible, setChangeStatusModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [agentModalVisible, setAgentModalVisible] = useState(false);
  const agentId = useAgentId();

  const {
    data: user,
    isLoading: userIsLoading,
    isSuccess: userIsSuccess,
    isError: userIsError,
    error,
    refetch: refetchUser,
  } = useQuery<User, AxiosError>([USER.USER, agentId], () => getUserByAgentId(agentId), {
    staleTime: Infinity,
    retry: false,
  });

  const {
    error: changeStatusError,
    isError: isChangeStatusError,
    isLoading: isChangeStatusLoading,
    mutate: mutateChangeStatus,
    reset: resetStatus,
  } = useMutation<AxiosResponse, AxiosError>(
    () => (user?.enabled ? disableUser(user?.keycloakId) : enableUser(user?.keycloakId)),
    {
      onSuccess: () => {
        closeStatusModal();
        refetchUser();
      },
    }
  );

  const {
    error: resetPasswordError,
    isError: isResetPasswordError,
    isLoading: isResetPasswordIsLoading,
    mutate: mutateResetPassword,
    reset: resetPasswordChange,
  } = useMutation<AxiosResponse, AxiosError>(() => resetPassword(user?.keycloakId), {
    onSuccess: () => closeResetPasswordModal(),
  });

  const editUser = useEditUser(user?.keycloakId || '', refetchUser, () => closeAgentModal());

  const onEditUserSave = (payload: EditUserPayload) => {
    editUser.mutate(payload, { onSuccess: () => refetchUser() });
  };

  const closeAgentModal = () => {
    setAgentModalVisible(false);
    editUser.reset();
  };

  const closeResetPasswordModal = () => {
    setResetPasswordModalVisible(false);
    resetPasswordChange();
  };

  const closeStatusModal = () => {
    setChangeStatusModalVisible(false);
    resetStatus();
  };

  return (
    <>
      <Box className="space-y-6">
        {userIsError && <ErrorMessages error={error} />}
        {userIsLoading && <Loader className="h-12 w-12" />}
        {userIsSuccess && (
          <>
            <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
              <>
                <div className="cols-span-1 space-y-4 ">
                  <div className="flex flex-col">
                    <Text className="text-secondary">Nazwa użytkownika</Text>
                    <Text weight="semibold">{user?.username.toUpperCase()}</Text>
                  </div>
                  <div className="flex flex-col">
                    <Text className="text-secondary">Imię</Text>
                    <Text weight="semibold">{user?.firstName}</Text>
                  </div>
                  <div className="flex flex-col">
                    <Text className="text-secondary">Nazwisko</Text>
                    <Text weight="semibold">{user?.lastName}</Text>
                  </div>
                </div>
                <div className="cols-span-1 space-y-4">
                  <div className="flex flex-col">
                    <Text className="text-secondary">E-mail</Text>
                    <Text weight="semibold">{user?.email}</Text>
                  </div>
                  <div className="flex flex-col">
                    <Text className="text-secondary">Status konta</Text>
                    <Text weight="semibold">{user?.enabled ? 'Aktywne' : 'Zablokowane'}</Text>
                  </div>
                  {user?.lastLoginTime && (
                    <div className="flex flex-col">
                      <Text className="text-secondary">Data ostatniego logowania</Text>
                      <Text weight="semibold">{user?.lastLoginTime}</Text>
                    </div>
                  )}
                </div>
              </>
            </div>

            <div className="sm:flex-wrap w-full justify-end items-end flex space-y-4 space-x-4">
              <Button
                variant="outline-primary"
                onPress={() => {
                  setAgentModalVisible(true);
                }}>
                Edytuj dane
              </Button>
              <Button
                variant="outline-primary"
                onPress={() => {
                  setResetPasswordModalVisible(true);
                }}>
                Resetuj hasło
              </Button>
              <Button
                variant="outline-primary"
                onPress={() => {
                  setChangeStatusModalVisible(true);
                }}>
                {user?.enabled ? 'Zablokuj dostęp' : 'Nadaj dostęp'}
              </Button>
            </div>
          </>
        )}
      </Box>

      {changeStatusModalVisible && (
        <ConfirmationModal
          confirmMessage="Czy na pewno chcesz zmienić status użytkownika?"
          isError={isChangeStatusError}
          isLoading={isChangeStatusLoading}
          error={changeStatusError}
          mutate={mutateChangeStatus}
          onCancel={closeStatusModal}
        />
      )}

      {resetPasswordModalVisible && (
        <ConfirmationModal
          confirmMessage="Czy na pewno chcesz zresetować hasło?"
          isError={isResetPasswordError}
          isLoading={isResetPasswordIsLoading}
          error={resetPasswordError}
          mutate={mutateResetPassword}
          onCancel={closeResetPasswordModal}
        />
      )}

      {agentModalVisible && user && (
        <EditUserForm
          editedItem={user}
          visible={agentModalVisible}
          onSave={onEditUserSave}
          setVisible={setAgentModalVisible}
          closeModal={closeAgentModal}
        />
      )}
    </>
  );
};

export default AgentAccount;
