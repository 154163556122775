import { Button, Modal, Text } from '@profitowi/component-library';
import { useLocation, useNavigate } from 'react-router-dom';

const ReportFilterParamModal = () => {
  const navigate = useNavigate();
  const {
    state: { report },
  } = useLocation();

  return (
    <Modal title="Zastosowane filtry" visible>
      <div className="space-y-4">
        {report &&
          Object.keys(report.params).map((key) => (
            <Text>
              {key} - {report.params[key]}
            </Text>
          ))}
      </div>
      <div className="flex justify-end">
        <Button onPress={() => navigate('..')}>Zakmnij</Button>
      </div>
    </Modal>
  );
};

export default ReportFilterParamModal;
