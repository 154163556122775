import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { AgentCommissionConditionRate } from 'types/commission';
import { parseVatRate } from 'utils/lingual';
import { toPercent } from 'utils/number';

export const columns: Array<Column<AgentCommissionConditionRate>> = [
  {
    accessor: 'condition',
    Header: 'Warunek prowizyjny',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('COMMISSION_CONDITION', value) : '';
    },
  },
  {
    accessor: 'commissionRate',
    Header: 'Stawka',
    Cell: ({ value }) => (value ? toPercent(value) : ''),
  },
  {
    accessor: 'vatRate',
    Header: 'Vat',
    Cell: ({ value }) => (value ? parseVatRate(value ?? '') : 'zw'),
  },
  {
    accessor: 'validFrom',
    Header: 'Data od',
    Cell: ({ value }) => value?.split(' ')?.[0] || null,
  },
  {
    accessor: 'validTo',
    Header: 'Data do',
    Cell: ({ value }) => value?.split(' ')?.[0] || null,
  },
  {
    id: 'operations',
    Header: 'Akcje',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<AgentCommissionConditionRate>) => {
      const navigate = useNavigate();

      return (
        <div className="flex space-x-2">
          <Button
            onPress={() =>
              navigate('editCommissionRate', {
                state: original,
              })
            }
            size="sm"
            variant="light">
            Edytuj
          </Button>

          <Button
            onPress={() =>
              navigate('deleteCommissionRate', {
                state: {
                  agentId: original.agentId,
                  id: original.id,
                  schemaId: original.commissionSchema.commissionSchemaId,
                },
              })
            }
            size="sm"
            variant="light">
            Usuń
          </Button>
        </div>
      );
    },
  },
];
