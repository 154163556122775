import { InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createBlockade } from 'services/agent';

const validationSchema = Yup.object({
  signature: Yup.string().required(requiredFieldMessage),
});

type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  signature: '',
};

type Props = {
  refetch: () => void;
};

const AddBlockadeModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const closeModal = () => navigate('..');

  const { isError, isLoading, mutate, error } = useMutation<
    AxiosResponse,
    AxiosError,
    { signature: string }
  >(({ signature }) => createBlockade(signature), {
    onSuccess: () => {
      refetch();
      navigate('..');
    },
  });

  const handleSubmit = (formValues: FormValues) => {
    mutate(formValues);
  };

  return (
    <Modal title="Dodaj blokadę" visible>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        <Form>
          <div className="my-4">
            <InputField label="Nazwa blokady" name="signature" />
          </div>
          <ModalActions onCancel={closeModal} isLoading={isLoading} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddBlockadeModal;
