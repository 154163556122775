import { useMemo } from 'react';

import useDictionaryContext from './useDictionaryContext';

const useDictionaryOptions = (dictionaryType: string) => {
  const { activeDictionaries } = useDictionaryContext();

  return useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(activeDictionaries?.[dictionaryType]?.map(({ key, value }) => ({
        key,
        value,
      })) || []),
    ],
    [activeDictionaries, dictionaryType]
  );
};

export default useDictionaryOptions;
