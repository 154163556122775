import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { deleteRecommendation } from 'services/recommendation';
import { Recommendation, RecommendationDeletePayload } from 'types/recommendation';

type Props = {
  refetch: () => void;
};

const RecommendationDeleteModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const recommendation: Recommendation = state?.recommendation;

  useEffect(() => {
    if (!recommendation) {
      navigate('..');
    }
  }, [recommendation, navigate]);

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    RecommendationDeletePayload
  >((payload) => deleteRecommendation(payload), {
    onSuccess: () => {
      refetch();
      navigate('..');
    },
  });

  const onConfirm = () => {
    const { referred, referee } = recommendation;

    const payload = {
      referred: referred.id,
      referee: referee.id,
    };

    mutate(payload);
  };

  return (
    <Modal title="Potwierdź usunięcie" visible>
      <Text>Czy na pewno chcesz usunąć?</Text>
      <ModalActions
        confirmLabel="Zatwierdź"
        isLoading={isLoading}
        onCancel={() => navigate('..')}
        onConfirm={() => onConfirm()}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default RecommendationDeleteModal;
