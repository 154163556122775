import { AutoCompleteField, DatePickerField, Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { groupBy, mapValues, toPairs } from 'lodash-es';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  ActionLabelOptions,
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { COMMISSION } from 'constants/queries/commission';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createAgentsOptions } from 'helpers/agents';
import { getCommissionSchema } from 'services/commission';
import { MoveAgentsPayload, PropagationOptions, moveAgents } from 'services/structure';
import { CommissionSchema } from 'types/commission';
import { AgentRelationStructureRecord } from 'types/structure';

import { useAgentQuery } from '../../../../../../hooks/useAgentQuery';

const validationSchema = Yup.object({
  dateFrom: Yup.date().default(undefined).required(requiredFieldMessage),
  newSuperior: Yup.number().required(requiredFieldMessage),
  propagateToSelectedSchemas: Yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: Yup.array().of(Yup.string()).optional(),
});

type FormValues = Yup.InferType<typeof validationSchema>;

type Props = {
  isVisible: boolean;
  closeModal: () => void;
  commissionSchemaId?: number;
  selectedRows: AgentRelationStructureRecord[];
};

const ChangeSuperiorModal = ({
  isVisible,
  closeModal,
  commissionSchemaId,
  selectedRows,
}: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<MoveAgentsPayload>();
  const [newAgentQuery, setNewAgentQuery] = useState<string>('');
  const {
    data: agentsList,
    isError: isAgentListError,
    isFetching: isFetchingAgentList,
    isLoading: isLoadingAgentList,
    error: agentListError,
  } = useAgentQuery({ queryParam: newAgentQuery, minQueryLength: 3 });

  const {
    data: commissionSchema,
    isFetching: isFetchingCommissionSchema,
    isError: isCommissionSchemaError,
    error: commissionSchemaError,
  } = useQuery<CommissionSchema | undefined, AxiosError>(
    [COMMISSION.COMMISSION_SCHEMA, commissionSchemaId],
    () => {
      if (commissionSchemaId) {
        return getCommissionSchema(commissionSchemaId);
      }
    }
  );

  const newAgentOptions = useMemo(
    () => createAgentsOptions(agentsList?.content || [], 'id'),
    [agentsList?.content]
  );

  const agentsCodes = useMemo(
    () => selectedRows.map(({ child }) => child?.agentCode).filter(Boolean),
    [selectedRows]
  );

  const {
    isLoading: validationIsloading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, MoveAgentsPayload>(
    (payload) => moveAgents(payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const {
    isLoading,
    mutate,
    isError: mutateIsError,
    error: mutateError,
  } = useMutation<AxiosResponse, AxiosError, MoveAgentsPayload>((payload) => moveAgents(payload), {
    onSuccess: () => {
      closeModal();
    },
  });

  const onSubmit = (values: FormValues) => {
    if (!commissionSchemaId) return;

    const propagationOptions = getSchemaPropagationPayload({
      commissionSchemaId: commissionSchemaId,
      ...values,
    });

    const map = toPairs(
      mapValues(
        groupBy(selectedRows, (row) => row.parent.id),
        (rows) => rows.map((row) => row.child.id)
      )
    );

    try {
      for (const request in map) {
        const [oldParentId, agentsToMove] = map[request];

        const payload = {
          agentsToMove,
          commissionSchemaId,
          newParentId: values.newSuperior,
          oldParentId: Number(oldParentId),
          validFrom: format(new Date(values.dateFrom), 'dd-MM-yyyy HH:mm:ss'),
          ...propagationOptions,
        };
        setPayload(payload);

        if (!validationErrors?.length && !!validationWarnings?.length && payload) {
          mutate(payload);
        } else {
          validateForm(payload);
        }
      }
    } catch {}
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  const initialValues = {
    dateFrom: undefined as unknown as Date,
    newSuperior: 0,
    propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
    selectedCommissionSchemaIds: [],
  };

  return (
    <Modal visible={isVisible} title="Zmień nadrzędnego">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form className="space-y-4" onBlur={onChange}>
          <Text>Zmiana dla: {agentsCodes.join(', ')}</Text>
          <div className="flex w-full justify-between items-center my-2">
            <p>Schemat prowizyjny</p>
            <p className="w-1/2 p-2">{commissionSchema?.schemaName}</p>
          </div>
          <div className="flex w-full justify-between items-center my-2">
            <p>Data od</p>
            <div className="w-1/2">
              <DatePickerField
                dateFormat="dd-MM-yyyy"
                placeholder="Data od"
                name="dateFrom"
                autoFocus={false}
                preventOpenOnFocus
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center my-2">
            <p>Nowy nadrzędny</p>
            <div className="w-1/2">
              <AutoCompleteField
                placeholder="Nowy nadrzędny"
                name="newSuperior"
                options={newAgentOptions}
                query={newAgentQuery}
                setQuery={setNewAgentQuery}
              />
            </div>
          </div>

          {isCommissionSchemaError && <ErrorMessages error={commissionSchemaError} />}
          {isAgentListError && <ErrorMessages error={agentListError} />}
          {mutateIsError && <ErrorMessages error={mutateError} />}
          {validationIsError && <ErrorMessages error={validationError} />}

          <SchemaPropagationFields
            commissionSchemaId={commissionSchemaId}
            isLoading={isLoading || validationIsloading}
            actionLabel={ActionLabelOptions.CHANGE}
          />

          <SchemaPropagationAlerts
            validationWarnings={validationWarnings}
            validationErrors={validationErrors}
          />
          <ModalActions
            onCancel={closeModal}
            isLoading={
              isLoadingAgentList || isFetchingAgentList || isLoading || isFetchingCommissionSchema
            }
            disableSubmit={!!validationErrors?.length}
            confirmLabel={
              !validationErrors?.length && !!validationWarnings?.length
                ? 'Zapisz pomimo ostrzeżeń'
                : 'Zapisz'
            }
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default ChangeSuperiorModal;
