import { Header, InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { ADDRESS_TYPE } from 'constants/addressType';
import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { modifyAgentsAddress } from 'services/agent';
import { Addresses, EconomyActivities, ModifyAgentsAddressPayload } from 'types/agent';

import { CompanySchema, IndividualAgentSchema } from './validation';

const defaultAddress: Omit<Addresses, 'type'> = {
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postalCode: '',
  city: '',
};

type ModifyAgentsAddressParams = {
  payload: ModifyAgentsAddressPayload;
  cooperationId: number;
};

type Props = {
  economyActivities?: EconomyActivities;
  refetchAgent: () => void;
};

const AgentAddressForm = ({ economyActivities, refetchAgent }: Props) => {
  const agentId = useAgentId();
  const navigate = useNavigate();

  const findAddressByType = (addressType: string) =>
    economyActivities?.addresses &&
    economyActivities?.addresses.find(({ type }) => type === addressType);

  const initialValues = useMemo(() => {
    const homeAddress = findAddressByType(ADDRESS_TYPE.HOME_ADDRESS);
    const correspondenceAddress = findAddressByType(ADDRESS_TYPE.CORRESPONDENCE_ADDRESS);
    const billingAddress = findAddressByType(ADDRESS_TYPE.BILLING_ADDRESS);

    const defaultValues = {
      homeAddress: homeAddress
        ? homeAddress
        : { ...defaultAddress, type: ADDRESS_TYPE.HOME_ADDRESS },
      correspondenceAddress: correspondenceAddress
        ? correspondenceAddress
        : { ...defaultAddress, type: ADDRESS_TYPE.CORRESPONDENCE_ADDRESS },
    };

    if (
      economyActivities?.type === COOPERATION_FORMS.COMPANY ||
      COOPERATION_FORMS.SOLE_PROPRIETORSHIP
    )
      return {
        ...defaultValues,
        billingAddress: billingAddress
          ? billingAddress
          : { ...defaultAddress, type: ADDRESS_TYPE.BILLING_ADDRESS },
      };

    return defaultValues;
  }, [economyActivities]);

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, ModifyAgentsAddressParams>(
    ({ payload, cooperationId }) => modifyAgentsAddress(agentId, cooperationId, payload),
    {
      onSuccess: () => {
        refetchAgent();
        navigate('..');
      },
    }
  );

  return (
    <Modal title={'Edytuj dane adresowe Kontrahenta'} visible>
      {economyActivities && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => mutate({ payload: values, cooperationId: economyActivities?.id })}
          validationSchema={
            economyActivities.type === COOPERATION_FORMS.COMPANY
              ? CompanySchema
              : IndividualAgentSchema
          }>
          <Form className="space-y-4">
            <>
              <Header as="h3" size="lg">
                Adres Zamieszkania:
              </Header>
              <InputField name="homeAddress.street" label="Ulica" isRequired />
              <InputField name="homeAddress.houseNumber" label="Numer domu" isRequired />
              <InputField name="homeAddress.apartmentNumber" label="Numer mieszkania" />
              <InputField name="homeAddress.postalCode" label="Kod pocztowy" isRequired />
              <InputField name="homeAddress.city" label="Miasto" isRequired />
            </>

            <>
              <Header as="h3" size="lg">
                Adres korespondencyjny:
              </Header>
              <InputField name="correspondenceAddress.street" label="Ulica" isRequired />
              <InputField name="correspondenceAddress.houseNumber" label="Numer domu" isRequired />
              <InputField name="correspondenceAddress.apartmentNumber" label="Numer mieszkania" />
              <InputField name="correspondenceAddress.postalCode" label="Kod pocztowy" isRequired />
              <InputField name="correspondenceAddress.city" label="Miasto" isRequired />
            </>

            {economyActivities.type === COOPERATION_FORMS.COMPANY ||
              (COOPERATION_FORMS.SOLE_PROPRIETORSHIP && (
                <>
                  <Header as="h3" size="lg">
                    Adres działalności:
                  </Header>
                  <InputField name="billingAddress.street" label="Ulica" isRequired />
                  <InputField name="billingAddress.houseNumber" label="Numer domu" isRequired />
                  <InputField name="billingAddress.apartmentNumber" label="Numer mieszkania" />
                  <InputField name="billingAddress.postalCode" label="Kod pocztowy" isRequired />
                  <InputField name="billingAddress.city" label="Miasto" isRequired />
                </>
              ))}
            <ModalActions isLoading={isUpdateLoading} onCancel={() => navigate('..')} />
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        </Formik>
      )}
    </Modal>
  );
};

export default AgentAddressForm;
