import { Column } from 'react-table';

import { CommissionImportRecords } from 'types/commission';

export const columns: Column<CommissionImportRecords>[] = [
  {
    Header: 'PROW_NUMER',
    accessor: 'policyNumber',
  },
  {
    Header: 'PROW_JSTA_SYMBOL',
    accessor: 'generalAgentNumber',
  },
  {
    Header: 'NAZWA_BIURA',
    accessor: 'officeNumber',
  },
  {
    Header: 'MPK',
    accessor: 'mpk',
  },
  {
    Header: 'PROW_KUBE_KOD',
    accessor: 'productPrefix',
  },
  {
    Header: 'PAR_NAZWA',
    accessor: 'parName',
  },
  {
    Header: 'NAZWA_PRODUKTU',
    accessor: 'productName',
  },
  {
    Header: 'PROWIZJA',
    accessor: (row) => <div className="text-right">{row?.commission}</div>,
  },
  {
    Header: 'KOD',
    accessor: 'code',
  },
  {
    Header: 'SLOW_NAZWA',
    accessor: 'fullName',
  },
  {
    Header: 'DATA_EFEKTYWANA',
    accessor: 'effectiveDate',
  },

  {
    Header: 'DATA_NALICZENIA',
    accessor: 'accrualDate',
  },
  {
    Header: 'SYGNATURA',
    accessor: 'productSignature',
  },
  {
    Header: 'NUMER_FAKTURY',
    accessor: 'invoiceNumber',
  },
  {
    Header: 'DATA_ROZLICZENIA_FAKTURY',
    accessor: 'invoiceSettlementDate',
  },

  {
    Header: 'DATA_SPRZEDAŻY_FAKTURY',
    accessor: 'invoiceSaleDate',
  },

  {
    Header: 'DATA_UTOWRZENIA_FAKTURY',
    accessor: 'invoiceCreationDate',
  },

  {
    Header: 'DATA_WYSTAWIENIA_FAKTURY',
    accessor: 'invoiceDate',
  },

  {
    Header: 'KWOTA_NETTO_Z_FAKTURY',
    accessor: 'invoiceNet',
  },

  {
    Header: 'KWOTA_VAT_Z_FAKTURY',
    accessor: (row) => <div className="text-right">{row?.invoiceVat}</div>,
  },
  {
    Header: 'KWOTA_BRUTTO_Z_FAKTURY',
    accessor: (row) => <div className="text-right">{row?.invoiceGross}</div>,
  },

  {
    Header: 'TERMIN_PLATNOŚCI',
    accessor: 'paymentDate',
  },
  {
    Header: 'MIESIAC_SPRAWOZDAWCZY',
    accessor: 'reportingMonth',
  },
  {
    Header: 'ROK_SPRAWOZDAWCZY',
    accessor: 'reportingYear',
  },

  {
    Header: 'FAKT_RODZAJ',
    accessor: 'invoiceType',
  },

  {
    Header: 'STAWKA',
    accessor: 'rate',
  },
  {
    Header: 'FAKT_JSTA_SYMBOL_ODBIORCA',
    accessor: 'receiverSymbol',
  },
  {
    Header: 'GSPR',
    accessor: 'sellAgentCode',
  },
  {
    Header: 'GOBSL',
    accessor: 'splitAgentCode',
  },
  {
    Header: 'SPR',
    accessor: 'supportAgentCode',
  },
  {
    Header: 'SKLADKA',
    accessor: 'contribution',
  },
  /*
  {
    Header: 'DRS',
    accessor: '',
  },
  */
  {
    Header: 'ROK_ŻYCIA_POLISY',
    accessor: 'policyYear',
  },

  {
    Header: 'NUMER_SKLADKI',
    accessor: 'contributionNumber',
  },
  {
    Header: 'CZAS_TRWANIA_POLISY',
    accessor: 'policyDurationTime',
  },
  {
    Header: 'WARIANT',
    accessor: 'productType',
  },
  {
    Header: 'ZDARZENIE',
    accessor: 'event',
  },
];
