import { Option, SelectField } from '@profitowi/component-library';
import { getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';

type Props = {
  schemaOptions?: Option[];
};

const SchemaField = ({ schemaOptions }: Props) => {
  const { values, setFieldValue } = useFormikContext();

  const commissionSchemas = getIn(values, 'commissionSchemas');
  const settlementCommissionSchema = getIn(values, 'settleCommissionSchemaId');

  useEffect(() => {
    if (!commissionSchemas.length && settlementCommissionSchema) {
      setFieldValue('settleCommissionSchemaId', '');
    }
    if (!settlementCommissionSchema)
      setFieldValue('settleCommissionSchemaId', commissionSchemas[0]);
  }, [commissionSchemas]);

  return (
    <div className="pointer-events-none">
      <SelectField
        options={schemaOptions ?? []}
        label="Schemat do pobrania warunków prowizyjnych"
        name="settleCommissionSchemaId"
      />
    </div>
  );
};

export default SchemaField;
