import { AutoCompleteField, Button, InputField, Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { FieldArray, Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { createAgentsOptions } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';
import { getAgent } from 'services/agent';
import { createPolicySplit, updatePolicySplit } from 'services/polices';
import { AgentDetails } from 'types/agent';
import { Policy } from 'types/polices';
import { Recommendation } from 'types/recommendation';

const validationSchema = Yup.object().shape({
  policySignature: Yup.string().required(requiredFieldMessage).default(''),
  productSignatures: Yup.array().of(Yup.string()).required(requiredFieldMessage),
  secondAgentId: Yup.string().required(requiredFieldMessage).default(''),
  percentageForSecondAgent: Yup.number()
    .required(requiredFieldMessage)
    .min(0, 'Wysokość udziału musi być liczbą z przedziału 0-100')
    .max(100, 'Wysokość udziału musi być liczbą z przedziału 0-100')
    .default(50),
});

type Props = {
  visible: boolean;
  editedPolicy?: Policy;
  closeModal: () => void;
  refetch: () => void;
};

const PolicyForm = ({ visible, editedPolicy, closeModal, refetch }: Props) => {
  const [referredQueryAgent, setReferredQueryAgent] = useState('');
  const agentId = useAgentId();
  const { state } = useLocation();
  const { data: searchAgents } = useAgentQuery({
    queryParam: referredQueryAgent,
    minQueryLength: 3,
  });

  const recommendation: Recommendation = state?.recommendation;

  useEffect(() => {
    if (editedPolicy) setReferredQueryAgent(editedPolicy.secondAgentCode);
  }, [editedPolicy]);

  const { data: selectedAgent } = useQuery<AgentDetails, AxiosError>(
    [AGENT.AGENT, agentId],
    () => getAgent(agentId),
    { enabled: !!recommendation }
  );
  const {
    mutate: saveMutate,
    isError: saveIsError,
    error: saveError,
    isLoading: saveIsLoading,
  } = useMutation<AxiosResponse, AxiosError, Policy>((payload) => createPolicySplit(payload), {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });

  const {
    mutate: updateMutate,
    isError: updateIsError,
    error: updateError,
    isLoading: updateIsLoading,
  } = useMutation<AxiosResponse, AxiosError, Policy>((payload) => updatePolicySplit(payload), {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });

  const onSubmit = (policy: Partial<Policy>) => {
    if (policy) {
      const { percentageForSecondAgent } = policy;

      const payload = {
        ...policy,
        percentageForSecondAgent: percentageForSecondAgent && percentageForSecondAgent / 100,
        agentId,
      };

      editedPolicy ? updateMutate(payload as Policy) : saveMutate(payload as Policy);
    }
  };

  const initialSearchOptions = useMemo(() => {
    if (recommendation && selectedAgent) {
      return createAgentsOptions([selectedAgent]);
    }
    return [{ key: 0, value: '' }];
  }, [recommendation, selectedAgent]);

  const referredAgentsOption = useMemo(
    () => (searchAgents ? createAgentsOptions(searchAgents.content, 'id') : initialSearchOptions),
    [searchAgents, initialSearchOptions]
  );

  const initialValues = useMemo(() => {
    if (editedPolicy)
      return {
        ...editedPolicy,
        percentageForSecondAgent: editedPolicy.percentageForSecondAgent * 100,
      };

    return {
      agentId: 0,
      policySignature: '',
      productSignatures: [],
      secondAgentId: 0,
      percentageForSecondAgent: 50,
    };
  }, [editedPolicy]);

  return (
    <Modal
      title={`${editedPolicy ? 'Edytuj' : 'Dodaj'} podział polisy`}
      visible={visible}
      setVisible={closeModal}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({ values }) => {
          return (
            <Form className="space-y-4">
              <InputField name="policySignature" label="Sygnatura polisy" />

              <div>
                <Text className="text-secondary">Kod Agenta</Text>
                <AutoCompleteField
                  placeholder="Wyszukaj kod PR agenta"
                  name="secondAgentId"
                  options={referredAgentsOption}
                  query={referredQueryAgent}
                  setQuery={setReferredQueryAgent}
                />
              </div>

              <div className="my-2 space-y-4">
                <h2 className="mt-4">Wybrane produkty:</h2>
                <FieldArray name="productSignatures">
                  {(helpers) => {
                    return (
                      <div>
                        {values?.productSignatures?.map((productSignature, index) => {
                          return (
                            <div className="flex flex-col my-2" key={'productSignature_' + index}>
                              <div className="flex gap-4">
                                <div className="w-2/3">
                                  <InputField
                                    name={`productSignatures[${index}]`}
                                    label="Sygnatura produktu"
                                  />
                                </div>
                                <div className="w-1/3 flex items-end">
                                  <Button
                                    onPress={() => helpers.remove(index)}
                                    className="w-full h-12 !py-2">
                                    Usuń
                                  </Button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <Button
                          variant="outline-primary"
                          onPress={() => helpers.push('')}
                          className="w-full">
                          <Text weight="semibold">
                            Dodaj produkt
                            <i className="bi bi-plus-lg ml-2"></i>
                          </Text>
                        </Button>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>

              <InputField
                name="percentageForSecondAgent"
                label="Procent otrzymany przez drugiego agenta"
                type="number"
              />

              <ModalActions isLoading={saveIsLoading || updateIsLoading} onCancel={closeModal} />

              {saveIsError && <ErrorMessages error={saveError} />}
              {updateIsError && <ErrorMessages error={updateError} />}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PolicyForm;
