import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { FILE } from 'constants/queries/file';
import { SETTLEMENT } from 'constants/queries/settlement';
import { usePagination } from 'hooks/usePagination';
import {
  getDocumentsPreview,
  getSettlementBillingItems,
  getSettlementsBillingItemsFile,
} from 'services/settlement';
import { Page } from 'types/request';
import { BillingItem } from 'types/settlement';
import { saveFile } from 'utils/file';
import { decodeUriSortParams } from 'utils/table';

import useSettlement from '../../../useSettlement';
import { columns } from './columns';

const AcceptanceStage = () => {
  const { settlementId = NaN } = useSettlement();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, error, isError, isLoading, isSuccess, isFetching } = useQuery<
    Page<BillingItem>,
    AxiosError
  >(
    [SETTLEMENT.SETTLEMENT_BILLING_ITEMS, settlementId, currentPage, perPage, sortBy],
    () =>
      getSettlementBillingItems(settlementId, currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  const { mutate: mutateSettlementsBillingItemsFile, isLoading: mutateBillingFileIsLoading } =
    useMutation<Blob, AxiosError>(
      [FILE.SETTLEMENT_BILLING_ITEMS_FILE],
      () => getSettlementsBillingItemsFile(settlementId),
      {
        onSuccess: (data) => {
          if (data) {
            saveFile(data, `rozliczenie_${settlementId}_`, 'application/vnd.ms-excel');
          }
        },
      }
    );

  const { mutate: mutateDocuments, isLoading: mutateDocumentsIsLoading } = useMutation<
    Blob,
    AxiosError
  >([FILE.DOCUMENTS], () => getDocumentsPreview(settlementId), {
    onSuccess: (data) => {
      if (data) {
        saveFile(data, `rozliczenie_${settlementId}_documents`, 'application/vnd.ms-excel');
      }
    },
  });

  return (
    <Box className="space-y-6">
      <div className="flex justify-between ">
        <Header as="h4" size="lg" weight="semibold">
          Pozycje naliczeniowe
        </Header>

        <div className="flex space-x-4">
          <Button
            className="flex space-x-2"
            variant="outline-primary"
            isDisabled={mutateBillingFileIsLoading}
            onPress={() => mutateSettlementsBillingItemsFile()}>
            {mutateBillingFileIsLoading && <Loader className="h-4 w-4 " />}
            <Text weight="semibold">Pobierz pozycje naliczeniowe</Text>
          </Button>

          <Button
            className="flex space-x-2"
            variant="outline-primary"
            isDisabled={mutateDocumentsIsLoading}
            onPress={() => mutateDocuments()}>
            {mutateDocumentsIsLoading && <Loader className="h-4 w-4 " />}
            <Text weight="semibold">Pobierz dokumenty księgowe</Text>
          </Button>
        </div>
      </div>
      {isError && <ErrorMessages error={error} />}
      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && data && (
        <Box.FullWidth>
          <Table
            columns={columns}
            data={data.content}
            pagination={pagination}
            totalPages={data.totalPages}
            isLoading={isFetching}
            sortBy={sortBy}
            onSortBy={handleSortBy}
          />
        </Box.FullWidth>
      )}
    </Box>
  );
};

export default AcceptanceStage;
