import { DatePickerField, Modal } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { Program } from 'types/commission';

const validationSchema = Yup.object({
  endDateTime: Yup.string().required(requiredFieldMessage).default(''),
});

type Props = {
  editedConfig?: Program;
  displayDeleteModal: boolean;
  isLoading: boolean;
  setDisplayDeleteModal: (value: boolean) => void;
  deleteSchemaError?: AxiosError | null | any;
  cancelDelete: () => void;
  mutate: ({ endDateTime, programId }: { endDateTime: string; programId: number }) => void;
};

const DeleteConfigurationLevel = ({
  displayDeleteModal,
  editedConfig,
  setDisplayDeleteModal,
  cancelDelete,
  mutate,
  isLoading,
  deleteSchemaError,
}: Props) => {
  const handleSubmit = (values: { endDateTime: string }) => {
    if (!editedConfig) return;
    const endDateTime = format(new Date(values.endDateTime), 'dd-MM-yyyy 00:00:00');

    mutate({
      endDateTime,
      programId: editedConfig.commissionSchemaProgramId,
    });
  };

  useEffect(() => {
    if (displayDeleteModal === false) {
      cancelDelete();
    }
  }, [displayDeleteModal]);

  return (
    <Modal
      title={`Zakończ konfigurację schematu`}
      visible={displayDeleteModal}
      setVisible={setDisplayDeleteModal}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          endDateTime: '',
        }}>
        <Form className="flex flex-col gap-4">
          <DatePickerField
            preventOpenOnFocus
            name="endDateTime"
            placeholder="Data końca obowiązywania konfiguracji"
            label="Data końca obowiązywania konfiguracji"
          />
          {deleteSchemaError && <ErrorMessages error={deleteSchemaError} />}
          <ModalActions isLoading={isLoading} onCancel={() => cancelDelete()} />
        </Form>
      </Formik>
    </Modal>
  );
};

export default DeleteConfigurationLevel;
