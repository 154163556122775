import { Button, Modal, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { REPORTS } from 'constants/queries/reports';
import { getReportFileStatus } from 'services/reports';
import { GenerateReportStatus, Reports } from 'types/reports';

import useDownloadReport from '../hooks/useDownloadReport';

type Props = {
  report?: Reports;
  setReport: (value: Reports | undefined) => void;
  refetchList?: () => void;
};

const DownloadReportModal = ({ report, setReport, refetchList }: Props) => {
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const reportDownload = useDownloadReport({ report, setReport, refetchList });

  useQuery<string, AxiosError>(
    [REPORTS.REPORTS_CONFIG, report?.fileId],
    () => getReportFileStatus(report?.fileId),
    {
      onSuccess: (data) => {
        if (data === GenerateReportStatus.FINISHED) {
          reportDownload.refetch();
        }
        if (data === GenerateReportStatus.ERROR) {
          setIsError(true);
          refetchList && refetchList();
        }
      },
      enabled: !!report && !isError,
      refetchInterval: 1500,
    }
  );

  const closeModal = () => {
    report && setReport(undefined);
    navigate('..');
  };

  return (
    <Modal visible>
      {!isError && (
        <Text>
          Raport jest w trakcie generowania. Pozostań na stronie aby pobrać go automatycznie lub
          pobierz raport ręcznie z listy plików po wygenerowaniu.
        </Text>
      )}

      {isError && (
        <Text>
          Wystąpił błąd podczas generowania raportu. Spróbuj ponownie lub skontaktuj się z
          Administratorem.
        </Text>
      )}

      <div className="flex justify-end">
        <Button onPress={closeModal}>Zamknij</Button>
      </div>
    </Modal>
  );
};

export default DownloadReportModal;
