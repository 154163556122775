import { Button } from '@profitowi/component-library';
import { format, parse } from 'date-fns';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { Program } from 'types/commission';
import { DictionaryTypes } from 'types/dictionary';

export const createColumn = (
  deleteConfiguration: (index: number) => void,
  editConfiguration: (index: number) => void,
  dictionaries?: DictionaryTypes
): Column<Program>[] => {
  return [
    {
      Header: 'Poziom',
      accessor: 'level',
    },
    {
      Header: 'Stanowisko Podrzędne',
      accessor: 'subordinatePosition',
      Cell: ({
        row: {
          original: { subordinatePosition },
        },
      }: Cell<Program>) => {
        const { translate } = useDictionaryContext();

        return subordinatePosition ? translate(DICTIONARY_TYPES.POSITION, subordinatePosition) : '';
      },
    },
    {
      Header: 'Stanowisko nadrzędne',
      accessor: 'superiorPosition',
      Cell: ({
        row: {
          original: { superiorPosition },
        },
      }: Cell<Program>) => {
        const { translate } = useDictionaryContext();

        return superiorPosition ? translate(DICTIONARY_TYPES.POSITION, superiorPosition) : '';
      },
    },
    {
      Header: 'typ',
      Cell: ({
        row: {
          original: { positionType },
        },
      }: Cell<Program>) => {
        const { translate } = useDictionaryContext();

        return positionType ? translate(DICTIONARY_TYPES.POSITION_TYPE, positionType) : '';
      },
    },
    {
      Header: 'Warunki prowizji',
      Cell: ({ row: { original } }: Cell<Program>) => {
        const { translate } = useDictionaryContext();
        let conditions: string[] = [];

        if (dictionaries) {
          original.conditions.map((item) => {
            const conditionValue = findDictionaryByKey(
              item.condition,
              dictionaries[DICTIONARY_TYPES.COMMISSION_CONDITION]
            );

            if (conditionValue) {
              const vatRate = translate(DICTIONARY_TYPES.VAT_RATE, item.vatRate);

              conditions.push(
                `${conditionValue.value} ${parseFloat(
                  (item.commissionRate * 100).toPrecision(4)
                )}%, ${
                  vatRate !== 'zw' ? (parseFloat(vatRate) * 100).toPrecision(4) + '%' : vatRate
                } VAT `
              );
            }
          });
        }

        if (conditions && conditions.length > 0)
          return conditions.map((item) => (
            <>
              <span>{item}</span>
              <br />
            </>
          ));
        return <></>;
      },
    },
    {
      Header: 'Data od',
      accessor: 'validFrom',
      Cell: ({
        row: {
          original: { validFrom },
        },
      }: Cell<Program>) => {
        const validFromDate = validFrom && parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date());
        return !!validFromDate ? format(validFromDate, 'dd-MM-yyyy') : '-';
      },
    },
    {
      Header: 'Data do',
      accessor: 'validTo',
      Cell: ({
        row: {
          original: { validTo },
        },
      }: Cell<Program>) => {
        const validToDate = validTo && parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date());
        return !!validToDate ? format(validToDate, 'dd-MM-yyyy') : '-';
      },
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { commissionSchemaProgramId },
        },
      }: Cell<Program>) => (
        <div className="flex space-x-6 justify-end">
          <Button variant="light" onPress={() => editConfiguration(commissionSchemaProgramId)}>
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>

          <Button variant="light" onPress={() => deleteConfiguration(commissionSchemaProgramId)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
