import { DatePickerField, Input, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  ActionLabelOptions,
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { getAgentName } from 'helpers/agents';
import useDictionaryContext from 'hooks/useDictionaryContext';
import {
  PropagationOptions,
  RemoveAgentFromSchemaPayload,
  removeAgentFromSchema,
} from 'services/structure';
import { AgentRelationStructureRecord } from 'types/structure';

const validationSchema = yup.object({
  validTo: yup.date().required(requiredFieldMessage).default(undefined).defined(),
  propagateToSelectedSchemas: yup.string().required(requiredFieldMessage),
  selectedCommissionSchemaIds: yup.array().of(yup.string()).optional(),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetch: () => void;
};

const RemoveAgentFromSchemaModal = ({ refetch }: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<RemoveAgentFromSchemaPayload>();
  const { translate } = useDictionaryContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('..');
      refetch();
    }
  }, [state, navigate]);

  const { commissionSchema, child, parent, validFrom, validTo } = (state ||
    {}) as AgentRelationStructureRecord;

  const initialValues = useMemo(() => {
    return {
      validTo: validTo
        ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
        : (undefined as unknown as Date),
      propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
      selectedCommissionSchemaIds: [],
    };
  }, [validTo]);

  const { error, isError, isLoading, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    RemoveAgentFromSchemaPayload
  >((payload) => removeAgentFromSchema(payload), {
    onSuccess: () => {
      navigate('..');
      refetch();
    },
  });

  const {
    isLoading: validationIsloading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, RemoveAgentFromSchemaPayload>(
    (payload) => removeAgentFromSchema(payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const handleSubmit = (values: FormValues) => {
    const propagationOptions = getSchemaPropagationPayload({
      commissionSchemaId: commissionSchema,
      ...values,
    });

    const payload = {
      commissionSchemaId: commissionSchema,
      subordinateAgentId: child.id,
      validTo: format(new Date(values.validTo), 'dd-MM-yyyy HH:mm:ss'),
      ...propagationOptions,
    };
    setPayload(payload);

    if (!validationErrors?.length && !!validationWarnings?.length && payload) {
      mutate(payload);
    } else {
      validateForm(payload);
    }
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  const maxDate = useMemo(
    () => (validTo ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date()) : undefined),
    [validTo]
  );

  return (
    <Modal title="Zakończ nadrzędność w schemacie" visible>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        <Form className="space-y-4" onBlur={onChange}>
          <Input
            label="Schemat prowizyjny"
            isReadOnly
            value={translate('COMMISSION_SCHEMA_NAME', String(commissionSchema))}
          />
          <Input label="Kontrahent" isReadOnly value={getAgentName(child?.economyActivities)} />
          <Input label="Nadrzędny" isReadOnly value={getAgentName(parent?.economyActivities)} />
          <Input label="Data od" isReadOnly value={validFrom.split(' ')[0]} />
          <DatePickerField label="Data do" name="validTo" maxDate={maxDate} />
          {(isError || validationIsError) && <ErrorMessages error={error || validationError} />}

          <SchemaPropagationFields
            commissionSchemaId={commissionSchema}
            isLoading={isLoading || validationIsloading}
            actionLabel={ActionLabelOptions.FINISH}
          />

          <SchemaPropagationAlerts
            validationWarnings={validationWarnings}
            validationErrors={validationErrors}
          />
          <ModalActions
            onCancel={() => navigate('..')}
            disableSubmit={!!validationErrors?.length}
            confirmLabel={
              !validationErrors?.length && !!validationWarnings?.length
                ? 'Zapisz pomimo ostrzeżeń'
                : 'Zapisz'
            }
            isLoading={isLoading || validationIsloading}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default RemoveAgentFromSchemaModal;
