import { Box, Button, Header, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { AGENT } from 'constants/queries/agent';
import { usePagination } from 'hooks/usePagination';
import { getAgentPayoutHistory } from 'services/agent';
import { AgentPaymentHistoryRecord } from 'types/agent';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import { columns } from './columns';
import AddPayoutHistoryDocumentModal from './components/AddPayoutHistoryDocumentModal/AddPayoutHistoryDocumentModal';
import AddPayoutHistoryRecordModal from './components/AddPayoutHistoryRecordModal/AddPayoutHistoryRecordModal';

type Props = {
  agentId: number;
};

const PayoutHistory = ({ agentId }: Props) => {
  const [isAddPayoutModalVisible, setIsAddPayoutModalVisible] = useState(false);
  const [isAddPayoutDocumentModalVisible, setIsAddPayoutDocumentModalVisible] = useState(false);
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, isLoading, isError, isSuccess, error, refetch, isFetching } = useQuery<
    Page<AgentPaymentHistoryRecord>,
    AxiosError
  >(
    [AGENT.AGENT_PAYOUT_HISTORY, agentId, currentPage, perPage, sortBy],
    () => getAgentPayoutHistory(agentId, currentPage, perPage, decodeUriSortParams(sortBy)),
    { keepPreviousData: true }
  );

  return (
    <Box className="space-y-6">
      <div className="flex justify-between">
        <Header as="h4" size="lg" weight="semibold">
          Historia rozliczeń
        </Header>

        <div className="flex flex-col gap-2">
          <Button variant="outline-primary" onPress={() => setIsAddPayoutModalVisible(true)}>
            <Text weight="semibold">
              Dodaj wpłatę/wypłatę zewn.
              <i className="bi bi-plus-lg ml-2" />
            </Text>
          </Button>
          <AddPayoutHistoryRecordModal
            visible={isAddPayoutModalVisible}
            closeModal={() => setIsAddPayoutModalVisible(false)}
            agentId={agentId}
            refetch={refetch}
          />

          <Button
            variant="outline-primary"
            onPress={() => setIsAddPayoutDocumentModalVisible(true)}>
            <Text weight="semibold">
              Dodaj dokument
              <i className="bi bi-plus-lg ml-2" />
            </Text>
          </Button>
          <AddPayoutHistoryDocumentModal
            visible={isAddPayoutDocumentModalVisible}
            closeModal={() => setIsAddPayoutDocumentModalVisible(false)}
            agentId={agentId}
            refetch={refetch}
          />
        </div>
      </div>

      {isError && <ErrorMessages error={error} />}
      {isLoading && <Loader className="h-12 w-12" />}

      {isSuccess && data && (
        <Box.FullWidth>
          <Table
            columns={columns}
            data={data.content ?? []}
            pagination={pagination}
            totalPages={data.totalPages}
            onSortBy={handleSortBy}
            sortBy={sortBy}
            isLoading={isFetching}
          />
        </Box.FullWidth>
      )}
    </Box>
  );
};

export default PayoutHistory;
