import { InputField } from '@profitowi/component-library';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { BonusPayload } from 'types/bonus';

const NetValueInputField = () => {
  const { translate } = useDictionaryContext();
  const { values, setFieldValue } = useFormikContext<BonusPayload>();

  useEffect(() => {
    const { grossValue, taxPercent } = values;

    if (grossValue && taxPercent) {
      if (taxPercent === 'VAT_ZW') return setFieldValue('netValue', grossValue);
      else {
        const taxPercentValue = translate(DICTIONARY_TYPES.VAT_RATE, taxPercent);
        const netValue = grossValue / (parseFloat(taxPercentValue) + 1);

        setFieldValue('netValue', netValue.toFixed(2));
      }
    }
  }, [values.grossValue, values.taxPercent]);

  return <InputField name="netValue" label="Kwota netto" isDisabled />;
};

export default NetValueInputField;
