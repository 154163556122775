import { InputType, ReportConfig, ReportType } from 'types/reports';

type ParamValue = string | boolean | string[];

export const generateReportInitialValue = (
  reportType?: ReportType,
  reportConfig?: ReportConfig[]
) => {
  const values: Record<string, ParamValue> = {
    reportType: reportType ?? '',
  };

  reportConfig &&
    reportConfig[0].params.map(({ paramKey, paramType, inputType }) => {
      if (inputType === InputType.DICTIONARY_MULTISELECT) return (values[paramKey] = []);
      return (values[paramKey] = '');
    });

  return values;
};
