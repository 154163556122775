import {
  AutoCompleteField,
  Box,
  Button,
  Header,
  Loader,
  SelectField,
  Table,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SortingRule } from 'react-table';

import BackArrow from 'components/BackArrow/BackArrow';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { SETTLEMENT } from 'constants/queries/settlement';
import { createAgentsOptions } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { getSettlementEmails, resend } from 'services/settlement';
import { Emails } from 'types/emails';
import { Page } from 'types/request';
import { SettlementMessagePayload, SettlementMessageQueryParam } from 'types/settlement';
import { decodeUriSortParams } from 'utils/table';

import { Columns } from './columns';

const initialValues = {
  agentIds: '',
  messageStatus: '',
};

const SettlementEmails = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [queryAgent, setQueryAgent] = useState<string>('');
  const [queryParams, setQueryParams] = useState<SettlementMessageQueryParam>();
  const [sortBy, setSortBy] = useState<SortingRule<any>[]>([]);

  const { activeDictionaries } = useDictionaryContext();
  const { settlementId: paramSettlementId = '' } = useParams();
  const pagination = usePagination(10);
  const { currentPage, perPage } = pagination;

  const settlementId = useMemo(() => {
    const id = Number.parseInt(paramSettlementId, 10);

    if (Number.isNaN(id) || Math.sign(id) !== 1) {
      return NaN;
    }

    return id;
  }, [paramSettlementId]);

  const { data, refetch, isError, isLoading, isSuccess, error, isFetching } = useQuery<
    Page<Emails>,
    AxiosError
  >(
    [SETTLEMENT.SETTLEMENT_EMAILS, queryParams, sortBy, currentPage, perPage],
    () =>
      getSettlementEmails(
        settlementId,
        currentPage,
        perPage,
        queryParams,
        decodeUriSortParams(sortBy)
      ),
    {
      keepPreviousData: true,
    }
  );

  const { mutate, isLoading: isMutateLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    { payload: SettlementMessagePayload }
  >(({ payload }) => resend(payload), { onSuccess: () => refetch() });

  const { data: searchAgents } = useAgentQuery({
    queryParam: queryAgent,
    minQueryLength: 3,
  });

  const agentsOptions = useMemo(
    () => createAgentsOptions(searchAgents?.content || [], 'id'),
    [searchAgents]
  );

  const emailMessageStatusOptions = useMemo(
    () => [
      { key: '', value: 'Dowolny' },
      ...(activeDictionaries?.MESSAGE_STATUS?.map(({ key, value }) => ({ key, value })) || []),
    ],
    []
  );

  const handleSelectionChange = useCallback(
    (rows: Emails[]) => {
      selectedRows.length !== rows.length && setSelectedRows(rows.map(({ id }) => id));
    },
    [selectedRows.length]
  );

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const handleSubmit = (values: SettlementMessageQueryParam) => {
    setQueryParams(values);
  };

  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <Header as="h1" size="xl" weight="semibold">
        Wiadomości
      </Header>
      <BackArrow navigateTo="/settlements" />

      <Box className="space-y-6">
        <Header as="h4" size="lg" weight="semibold">
          Lista Wiadomości
        </Header>

        <div className="flex space-x-4">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form className="w-full space-y-4">
              <AutoCompleteField
                label="Kontrahent"
                name="agentIds"
                options={agentsOptions}
                placeholder="Kontrahent"
                query={queryAgent}
                setQuery={setQueryAgent}
              />
              <SelectField
                label="Status"
                name="messageStatus"
                options={emailMessageStatusOptions}
              />
              <div className="flex space-x-4">
                <Button
                  isDisabled={isFetching || isLoading}
                  size="sm"
                  variant="primary"
                  type="submit">
                  Szukaj
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  type="reset"
                  onPress={() => setQueryParams(initialValues)}>
                  Wyczyść
                </Button>
              </div>
            </Form>
          </Formik>
          <div className="w-full ">
            <div className="flex space-x-4 justify-end">
              <Button
                isDisabled={data && !data.content.length}
                size="sm"
                variant="primary"
                type="submit"
                onPress={() => mutate({ payload: { messageIds: selectedRows } })}>
                Wyślij wybrane
              </Button>

              <Button
                isDisabled={data && !data.content.length}
                size="sm"
                variant="primary"
                type="submit"
                onPress={() => mutate({ payload: { settlementId } })}>
                Wyślij ponownie wszystkie
              </Button>
            </div>
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}

        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              data={data ? data.content : []}
              columns={Columns}
              isLoading={isFetching || isMutateLoading}
              isSelectable
              onSelectionChange={handleSelectionChange}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              pagination={pagination}
              totalPages={data.totalPages}
            />
          </Box.FullWidth>
        )}
      </Box>
    </div>
  );
};

export default SettlementEmails;
