import { InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import ModalActions from 'components/ModalActions';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { generatePayment } from 'services/agent';

const validationSchema = Yup.object({
  transferTitle: Yup.string().required(requiredFieldMessage),
});

type FormValues = Yup.InferType<typeof validationSchema>;

type Props = {
  visible: boolean;
  setVisible: (values: boolean) => void;
};

const GeneratePaymentForm = ({ visible, setVisible }: Props) => {
  const agentId = useAgentId();

  const { isLoading, isError, error, mutate } = useMutation<
    AxiosResponse,
    AxiosError,
    { agentId: number; transferTitle: string }
  >(({ agentId, transferTitle }) => generatePayment(agentId, { transferTitle }), {
    onSuccess: () => {
      setVisible(false);
    },
  });

  const handleSubmit = (formValues: FormValues) => {
    mutate({ agentId, transferTitle: formValues.transferTitle });
  };

  return (
    <Modal visible={visible} title="Generuj płatność">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ transferTitle: '' }}
        onSubmit={handleSubmit}>
        <Form className="space-y-4">
          <InputField name="transferTitle" label="Tytuł przelewu" />

          <ModalActions onCancel={() => setVisible(false)} isLoading={isLoading} />
          {isError && <ErrorMessages error={error} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default GeneratePaymentForm;
