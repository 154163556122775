import { Box, Button, Header, Loader, Modal, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import useAgentId from 'pages/Agent/useAgentId';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { ADVANCE } from 'constants/queries/advance';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import {
  deleteAgentAdvance,
  generatePayoutsAdvance,
  getAdvancesForAgent,
} from 'services/advancePayment';
import { AdvancePayment } from 'types/advancePayment';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import AdvancePaymentForm from './AdvancePaymentForm/AdvancePaymentForm';
import { createColumn } from './columns';

const AdvancePayments = () => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [generatedPaymentId, setGeneratedPaymentId] = useState<number>();
  const [visible, setVisible] = useState(false);
  const pagination = usePagination(10);
  const { activeDictionaries } = useDictionaryContext();
  const agentId = useAgentId();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, refetch, isError, isLoading, isFetching, isSuccess, error } = useQuery<
    Page<AdvancePayment>,
    AxiosError
  >(
    [ADVANCE.ADVANCES, pagination.currentPage, pagination.perPage, sortBy],
    () =>
      getAdvancesForAgent(
        agentId,
        pagination.currentPage,
        pagination.perPage,
        decodeUriSortParams(sortBy)
      ),
    { keepPreviousData: true }
  );

  const { mutate } = useMutation<AxiosResponse, AxiosError, number>(
    (agentAdvanceId) => deleteAgentAdvance(agentId, agentAdvanceId),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const mutateGeneratePayoutsAdvance = useMutation<AxiosResponse, AxiosError, number>(
    (advanceId) => generatePayoutsAdvance(agentId, advanceId),
    {
      onSuccess: () => {
        refetch();
        setConfirmModalVisible(false);
        setGeneratedPaymentId(undefined);
      },
    }
  );

  const openConfirmModal = (paymentId: number) => {
    setConfirmModalVisible(true);
    setGeneratedPaymentId(paymentId);
  };

  const closeConfirmModal = () => {
    setConfirmModalVisible(false);
    setGeneratedPaymentId(undefined);
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Zaliczki
          </Header>

          <Button variant="outline-primary" className="px-4 py-1" onPress={() => setVisible(true)}>
            <Text weight="semibold">
              Dodaj
              <i className="bi bi-plus-lg ml-2"></i>
            </Text>
          </Button>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}

        {isSuccess && data && activeDictionaries && (
          <Box.FullWidth>
            <Table
              totalPages={data.totalPages}
              pagination={pagination}
              data={data.content}
              columns={createColumn(
                agentId,
                navigate,
                activeDictionaries,
                mutate,
                openConfirmModal
              )}
              sortBy={sortBy}
              onSortBy={handleSortBy}
              isLoading={isFetching}
            />
          </Box.FullWidth>
        )}
      </Box>

      {visible && (
        <AdvancePaymentForm visible={visible} setVisible={setVisible} refetch={refetch} />
      )}

      {confirmModalVisible && (
        <Modal visible>
          <Text className="text-center">Czy na pewno chcesz wygenerować wypłatę zaliczki ?</Text>
          <ModalActions
            confirmLabel="Zatwierdź"
            isLoading={mutateGeneratePayoutsAdvance.isLoading}
            onCancel={closeConfirmModal}
            onConfirm={() =>
              generatedPaymentId && mutateGeneratePayoutsAdvance.mutate(generatedPaymentId)
            }
          />
          {mutateGeneratePayoutsAdvance.isError && (
            <ErrorMessages error={mutateGeneratePayoutsAdvance.error} />
          )}
        </Modal>
      )}
    </>
  );
};

export default AdvancePayments;
