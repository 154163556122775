import { DatePickerField, Modal, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { addInsuranceCompany, editInsuranceCompany } from 'services/agent';
import { InsuranceCompany, InsuranceCompanyPayload } from 'types/agent';

import useAgentId from '../../../useAgentId';

const validationSchema = yup.object({
  insuranceCompanyDictionaryKey: yup.string().defined(requiredFieldMessage),
  validFrom: yup.date().default(undefined).defined().typeError('Nieprawidłowa data'),
  validTo: yup.date().default(undefined).defined().typeError('Nieprawidłowa data'),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetch: () => void;
};

const AddUpdateInsuranceCompanyModal = ({ refetch }: Props) => {
  const agentId = useAgentId();
  const navigate = useNavigate();
  const { activeDictionaries } = useDictionaryContext();
  const { state } = useLocation();
  const insuranceCompany: InsuranceCompany = state?.insuranceCompany;
  const title = insuranceCompany
    ? 'Edytuj towarzystwo ubezpieczeniowe kontrahenta'
    : 'Dodaj towarzystwo ubezpieczeniowe do kontrahenta';

  const initialValues = useMemo(() => {
    if (insuranceCompany) {
      const { insuranceCompanyDictionaryKey, validFrom, validTo } = insuranceCompany;

      return {
        insuranceCompanyDictionaryKey,
        validFrom: validFrom
          ? parse(validFrom, 'dd-MM-yyyy HH:mm:ss', new Date())
          : (undefined as unknown as Date),
        validTo: validTo
          ? parse(validTo, 'dd-MM-yyyy HH:mm:ss', new Date())
          : (undefined as unknown as Date),
      };
    }

    return {
      insuranceCompanyDictionaryKey: '',
      validFrom: undefined as unknown as Date,
      validTo: undefined as unknown as Date,
    };
  }, [insuranceCompany]);

  const onSuccess = () => {
    refetch();
    navigate('..');
  };

  const {
    isLoading: isSaveLoading,
    error: saveError,
    mutate: save,
  } = useMutation<AxiosResponse, AxiosError, InsuranceCompanyPayload>(
    (payload) => addInsuranceCompany(agentId, payload),
    {
      onSuccess,
    }
  );

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate: update,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    Omit<InsuranceCompanyPayload, 'insuranceCompanyDictionaryKey'>
  >((payload) => editInsuranceCompany(agentId, insuranceCompany?.id, payload), {
    onSuccess,
  });

  const onSubmit = ({ insuranceCompanyDictionaryKey, validFrom, validTo }: FormValues) => {
    const payload = {
      insuranceCompanyDictionaryKey,
      validFrom: format(new Date(validFrom), 'dd-MM-yyyy 00:00:00'),
      validTo: format(new Date(validTo), 'dd-MM-yyyy 00:00:00'),
    };

    if (insuranceCompany) {
      update(payload);
    } else {
      save(payload);
    }
  };

  return (
    <Modal title={title} visible>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values: { validFrom, validTo } }) => (
          <Form className="space-y-4">
            {!insuranceCompany?.id && (
              <SelectField
                label="Towarzystwo ubezpieczeniowe"
                name="insuranceCompanyDictionaryKey"
                options={activeDictionaries?.[DICTIONARY_TYPES.INSURANCE_COMPANY] || []}
              />
            )}
            <DatePickerField
              preventOpenOnFocus
              label="Data od"
              name="validFrom"
              maxDate={validTo}
            />
            <DatePickerField label="Data do" name="validTo" minDate={validFrom} />
            <ModalActions
              isLoading={isSaveLoading || isUpdateLoading}
              onCancel={() => navigate('..')}
            />
            {saveError && <ErrorMessages error={saveError} />}
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUpdateInsuranceCompanyModal;
