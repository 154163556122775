import { Alert } from '@profitowi/component-library';
import { useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { SettlementContextProvider } from '../useSettlement';
import ItemDetailsModal from './components/ItemDetailsModal';
import Stages from './components/Stages';

const Settlement = () => {
  const { settlementId: paramSettlementId = '' } = useParams();

  const settlementId = useMemo(() => {
    const id = Number.parseInt(paramSettlementId, 10);

    if (Number.isNaN(id) || Math.sign(id) !== 1) {
      return NaN;
    }

    return id;
  }, [paramSettlementId]);

  if (settlementId) {
    return (
      <SettlementContextProvider settlementId={settlementId}>
        <Stages />

        <Routes>
          <Route path="details" element={<ItemDetailsModal />} />
        </Routes>
      </SettlementContextProvider>
    );
  }

  return <Alert type="error">Nieprawidłowy identyfikator rozliczenia.</Alert>;
};

export default Settlement;
