import { Box, Header, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useParams } from 'react-router-dom';

import BackArrow from 'components/BackArrow/BackArrow';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import NavigationToolbar from 'components/NavigationToolbar/NavigationToolbar';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { SETTLEMENT } from 'constants/queries/settlement';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getSettlement } from 'services/settlement';
import { SettlementProcessResult } from 'types/settlement';

import BalanceGeneratedStage from '../Settlement/components/BalanceGeneratedStage/BalanceGeneratedStage';
import BonusesStage from '../Settlement/components/BonusesStage/BonusesStage';
import ContractsStage from '../Settlement/components/ContractsStage/ContractsStage';
import CorrectionsStage from '../Settlement/components/CorrectionsStage/CorrectionsStage';
import DocumentsStage from '../Settlement/components/DocumentsStage/DocumentsStage';
import FileEventsStage from '../Settlement/components/FileEventsStage/FileEventsStage';
import ItemDetailsModal from '../Settlement/components/ItemDetailsModal';
import PayoutsStage from '../Settlement/components/PayoutsStage/PayoutsStage';
import { SettlementContextProvider } from '../useSettlement';

const SettlementPreview = () => {
  const { translate } = useDictionaryContext();
  const { settlementId: paramSettlementId = '' } = useParams();
  const settlementId = useMemo(() => {
    const id = Number.parseInt(paramSettlementId, 10);

    if (Number.isNaN(id) || Math.sign(id) !== 1) {
      return NaN;
    }

    return id;
  }, [paramSettlementId]);

  const { data, error, isError, isSuccess } = useQuery<SettlementProcessResult, AxiosError>(
    [SETTLEMENT.SETTLEMENT_STAGE, settlementId],
    () => getSettlement(settlementId)
  );

  const stages = useMemo(() => {
    const items =
      data?.previousStages
        .filter(
          (item) => item !== 'CREATED' && item !== 'FINAL_ACCEPTATION' && item !== 'TO_ACCEPTATION'
        )
        .map((item) => {
          return {
            displayName: translate(DICTIONARY_TYPES.SETTLEMENT_STAGE, item),
            route: item.toLowerCase(),
          };
        }) ?? [];

    return [...items].reverse();
  }, [data]);

  return (
    <SettlementContextProvider settlementId={settlementId} isEditable={false}>
      <div className="flex-1 p-8 space-y-6 overflow-hidden">
        <Header as="h1" size="xl" weight="semibold">
          Szczegóły rozliczenia
        </Header>
        <BackArrow navigateTo="/settlements" />

        <Box className="flex space-x-8">
          {isError && <ErrorMessages error={error} />}
          {isSuccess && (
            <>
              <div>
                <Text className="text-secondary">Identyfikator rozliczenia</Text>
                <Text weight="semibold">{settlementId}</Text>
              </div>

              <div>
                <Text className="text-secondary">Okres rozliczeniowy</Text>
                <Text weight="semibold">{data?.period}</Text>
              </div>

              <div>
                <Text className="text-secondary">Data wystawienia dokumentów księgowych</Text>
                <Text weight="semibold">{data?.documentCreationDate}</Text>
              </div>
            </>
          )}
        </Box>

        {isSuccess && (
          <NavigationToolbar
            rootPath={`/file_events`}
            tabs={
              stages.map(({ displayName, route }) => {
                return {
                  displayName: displayName,
                  route: route,
                };
              }) ?? []
            }
          />
        )}

        <Routes>
          <Route path="file_events/*" element={<FileEventsStage />}>
            <Route path="details" element={<ItemDetailsModal />} />
          </Route>

          <Route path="documents/*" element={<DocumentsStage />}>
            <Route path="details" element={<ItemDetailsModal />} />
          </Route>

          <Route path="bonuses/*" element={<BonusesStage />} />

          <Route path="system_correction_events/*" element={<CorrectionsStage />}>
            <Route path="details" element={<ItemDetailsModal />} />
          </Route>

          <Route path="contracts/*" element={<ContractsStage />}>
            <Route path="details" element={<ItemDetailsModal />} />
          </Route>

          <Route path="documents_generated/*" element={<DocumentsStage />}>
            <Route path="details" element={<ItemDetailsModal />} />
          </Route>

          <Route path="balance_generated/*" element={<BalanceGeneratedStage />} />
          <Route path="payouts_calculated/*" element={<PayoutsStage />} />
        </Routes>
      </div>
    </SettlementContextProvider>
  );
};

export default SettlementPreview;
