export const saveFile = (data: Blob, fileName: string, fileType?: string) => {
  const url = fileType
    ? window.URL.createObjectURL(new Blob([data], { type: fileType }))
    : window.URL.createObjectURL(new Blob([data]));

  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
