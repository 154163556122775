import { DatePickerField, Modal, Option, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import SchemaPropagationAlerts from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationAlerts';
import SchemaPropagationFields, {
  ActionLabelOptions,
  getSchemaPropagationPayload,
} from 'pages/Records/components/SalesStructure/components/SchemaPropagationFields/SchemaPropagationFields';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DELEGATION } from 'constants/queries/delegation';
import { endDelegationTime } from 'services/delegations';
import { PropagationOptions } from 'services/structure';
import { ChildList, EndDelegationTimePayload } from 'types/delegation';

import { useDelegationViceQuery } from '../hooks/useDelegationViceQuery';
import { validationSchema } from './validationSchema';

const initialValues = {
  newParentId: '',
  validTo: undefined as unknown as Date,
  propagateToSelectedSchemas: PropagationOptions.PROPAGATE_TO_ALL_SCHEMAS_IN_GROUP,
  selectedCommissionSchemaIds: [],
};

type Payload = {
  newParentId: string;
  validTo: Date;
  propagateToSelectedSchemas: PropagationOptions;
  selectedCommissionSchemaIds: string[];
};

type Props = {
  commissionSchemaId: number;
  selectedAgentRelationIdList: ChildList[];
  refetch: () => void;
};

const EndDelegationForm = ({ commissionSchemaId, selectedAgentRelationIdList, refetch }: Props) => {
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [payload, setPayload] = useState<EndDelegationTimePayload>();
  const navigate = useNavigate();

  const { data: delegationViceAll } = useDelegationViceQuery({
    queryKey: DELEGATION.DELEGATION_VICE_ALL,
    commissionSchemaId,
  });

  const {
    isLoading: validationIsloading,
    isError: validationIsError,
    error: validationError,
    mutate: validateForm,
  } = useMutation<AxiosResponse, AxiosError, EndDelegationTimePayload>(
    (payload) => endDelegationTime(payload, true),
    {
      onSuccess: (response) => {
        response.data.warnings && setValidationWarnings(response.data.warnings);
        response.data.errors && setValidationErrors(response.data.errors);

        const canPropagateSchema =
          !response.data.warnings?.length && !response.data.errors?.length && payload;

        if (canPropagateSchema) {
          mutate(payload);
        }
      },
    }
  );

  const { mutate, isLoading, isError, error } = useMutation<
    AxiosResponse,
    AxiosError,
    EndDelegationTimePayload
  >((payload) => endDelegationTime(payload), {
    onSuccess: () => {
      refetch();
      navigate('..');
    },
  });

  const delegationViceOptions: Option[] = useMemo(
    () => [
      ...(delegationViceAll?.map(({ id, name, agentCode }) => ({
        key: id,
        value: `${agentCode} - ${name}`,
      })) || []),
    ],
    [delegationViceAll]
  );

  const handleSubmit = (values: Payload) => {
    const propagationOptions = getSchemaPropagationPayload({
      commissionSchemaId: commissionSchemaId,
      ...values,
    });

    const payload = {
      agentRelationIdList: selectedAgentRelationIdList.map(({ relationId }) => relationId),
      ...values,
      validTo: format(values.validTo, 'dd-MM-yyyy 00:00:00'),
      ...propagationOptions,
    };
    setPayload(payload);

    if (!validationErrors?.length && !!validationWarnings?.length && payload) {
      mutate(payload);
    } else {
      validateForm(payload);
    }
  };

  function onChange() {
    setValidationErrors([]);
    setValidationWarnings([]);
  }

  return (
    <Modal title="Edytuj datę końcową delegacji" visible>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        <Form className="space-y-4" onBlur={onChange}>
          <DatePickerField label="Zakończ z dniem" name="validTo" preventOpenOnFocus />
          <SelectField
            label="Kod wycedyrektora do którego przeniesiono podrzędnych"
            name="newParentId"
            options={delegationViceOptions}
          />
          {(isError || validationIsError) && <ErrorMessages error={error || validationError} />}

          <SchemaPropagationFields
            commissionSchemaId={commissionSchemaId}
            isLoading={isLoading || validationIsloading}
            actionLabel={ActionLabelOptions.FINISH}
          />

          <SchemaPropagationAlerts
            validationWarnings={validationWarnings}
            validationErrors={validationErrors}
          />

          <ModalActions
            onCancel={() => navigate('..')}
            isLoading={isLoading || validationIsloading}
            disableSubmit={!!validationErrors?.length}
            confirmLabel={
              !validationErrors?.length && !!validationWarnings?.length
                ? 'Zapisz pomimo ostrzeżeń'
                : 'Zapisz'
            }
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default EndDelegationForm;
