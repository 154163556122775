import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { AgentList } from 'types/agent';
import { CommunicationMessage } from 'types/communication';

export const createColumns = (
  agents: AgentList[],
  openRespondModal: (agent: AgentList, message: CommunicationMessage) => void
): Column<CommunicationMessage>[] => [
  {
    Header: 'Data otrzymania',
    accessor: 'createdAt',
  },
  {
    Header: 'Użytkownik',
    accessor: 'agentName',
  },
  {
    Header: 'Tytuł',
    id: 'thread.title',
    accessor: 'title',
  },
  {
    Header: 'Treść',
    accessor: 'prefix',
  },
  {
    Header: 'Powód',
    id: 'thread.reason.value',
    accessor: 'reason',
    Cell: ({
      row: {
        original: { reason },
      },
    }: Cell<CommunicationMessage>) => {
      const { translate } = useDictionaryContext();

      return reason ? translate(DICTIONARY_TYPES.COMMUNICATION_REASON, reason) : '';
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({
      row: {
        original: { status },
      },
    }: Cell<CommunicationMessage>) => {
      const { translate } = useDictionaryContext();

      return status ? translate(DICTIONARY_TYPES.COMMUNICATION_STATUS, status) : '';
    },
  },
  {
    id: 'operations',
    Cell: ({ row: { original } }: Cell<CommunicationMessage>) => {
      const navigate = useNavigate();

      return (
        <div className="flex">
          <Button
            onPress={() => navigate(`/communication/thread/${original.id}`)}
            size="sm"
            variant="light">
            <i className="bi bi-three-dots-vertical cursor-pointer" />
          </Button>
          {['PENDING_FOR_SERVICE', 'READ'].includes(original.status) && (
            <Button
              onPress={() => {
                const agent = agents.find((agent) => agent.agentCode === original.agentCode);

                if (agent) {
                  openRespondModal(agent, original);
                }
              }}
              size="sm"
              variant="light">
              <i className="bi bi-reply cursor-pointer" />
            </Button>
          )}
        </div>
      );
    },
  },
];
