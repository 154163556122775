import { Column } from 'react-table';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { Balance } from 'types/settlement';

export const columns: Column<Balance>[] = [
  {
    Header: 'Data wystawienia',
    accessor: 'issueDate',
    id: 'issueDate',
  },
  {
    Header: 'Kod agenta',
    accessor: 'agentCode',
    id: 'agent.agentCode',
  },
  {
    Header: 'Kontrahent',
    accessor: 'agentDisplayName',
    id: 'agentDisplayName',
  },
  {
    Header: 'Saldo z rozliczenia',
    accessor: (row) => <div className="text-right">{row?.valueToPay}</div>,
    disableSortBy: true,
  },
  {
    Header: 'Kwota brutto',
    accessor: (row) => <div className="text-right">{row?.grossValue}</div>,
  },
  {
    Header: 'Numer dokumentu',
    accessor: 'documentNumber',
    id: 'documentNumber',
  },
  {
    Header: 'Typ pozycji',
    accessor: 'positionType',
    Cell: ({ value }) => {
      const { translate } = useDictionaryContext();

      return value ? translate('PAYOUT_POSITION_TYPE', value) : '';
    },
  },
];
